var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "p-multi-select",
      class: { "display-mode": _vm.displayMode },
      attrs: { translate: "no" },
    },
    [
      _c(
        "multiselect",
        {
          ref: "multiselect",
          class: { error: _vm.error, required: _vm.required },
          attrs: {
            options: _vm.customOptions,
            placeholder: _vm.placeholderSeletor(),
            forcedLabelUp: Boolean(_vm.forcedPlaceholder),
            multiple: "",
            "preserve-search": true,
            "close-on-select": false,
            "clear-on-select": false,
            "track-by": _vm.trackBy,
            label: _vm.labelItem,
            loading: _vm.objectLoading || _vm.commonLoading,
            openDirection: _vm.openDirection,
            selectLabel: "",
            deselectLabel: "",
            selectedLabel: "",
            selectGroupLabel: "",
            deselectGroupLabel: "",
            disabled: _vm.disabled,
            name: "multiselect",
            internalSearch: false,
          },
          on: {
            dblclick: _vm.dblclick,
            select: _vm.handleSelect,
            "search-change": _vm.changeSearch,
            open: function ($event) {
              _vm.seletorAberto = true
            },
            close: function ($event) {
              _vm.seletorAberto = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function ({ values, search, isOpen }) {
                return [
                  !isOpen
                    ? _c("div", [
                        !_vm.forcedPlaceholder && values.length > 1
                          ? _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(
                                _vm._s(values.length) +
                                  " itens " +
                                  _vm._s(
                                    _vm.displayMode
                                      ? ""
                                      : _vm.$t(
                                          "compartilhados.selectors.PMultiSelect.selecionados"
                                        )
                                  )
                              ),
                            ])
                          : !_vm.forcedPlaceholder && values.length === 1
                          ? _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(_vm._s(values[0][_vm.labelItem])),
                            ])
                          : _c("span", { staticClass: "multiselect__single" }, [
                              _vm._v(_vm._s(_vm.forcedPlaceholder)),
                            ]),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "tag",
              fn: function ({ option, remove }) {
                return [
                  _c("span", {
                    staticClass: "custom__tag",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.parsedState,
            callback: function ($$v) {
              _vm.parsedState = $$v
            },
            expression: "parsedState",
          },
        },
        [
          _vm.mostrarBotaoCarregarMais
            ? [
                _c(
                  "PButton",
                  {
                    attrs: {
                      slot: "opcaoEspecial",
                      color: "primary",
                      size: "sm",
                    },
                    on: { click: _vm.fetchItemsComPaginacao },
                    slot: "opcaoEspecial",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "compartilhados.selectors.PMultiSelect.carregarMais"
                        )
                      )
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "multiselect__no-result",
              attrs: { slot: "noResult" },
              slot: "noResult",
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("compartilhados.selectors.PMultiSelect.nadaEncontrado")
                )
              ),
            ]
          ),
          _c(
            "template",
            { staticClass: "multiselect__no-options", slot: "noOptions" },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("compartilhados.selectors.PMultiSelect.nenhumaOpcao")
                )
              ),
            ]
          ),
        ],
        2
      ),
      _c("ErrorMsg", { attrs: { error: _vm.error } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }