<template lang="pug">
  div.menu-container
    uiv-popover(trigger="hover")
      img(:src="Avatar" alt="Avatar do usuário" class="menu-usuario__avatar")
      template(slot="popover")
        .menu-info-do-usuario(v-if="infoDoUsuario")
          div(v-for="(item, key) in infoDoUsuario" :key="key" :class="`info-do-usuario__${key}`")
            template(v-if="item.conteudo")
              .legenda {{ item.legenda }}
              .conteudo {{ item.conteudo }}

    Dropdown(posicao="down-right" v-model="mostrarDropdown")
      .arrow-icon.icon-ptt-icons-dropdown.to-click
      template(#dropdown)
        li(v-if="mostrarSeletorDeIdioma")
          Dropdown(posicao="left" v-model="subDropdown")
            li.flex-espacado.to-click
              |  {{ idiomaAtual }}
              .arrow-icon.icone-chapado-seta-direita
            template(#dropdown)
              li.flex-espacado(v-for="(idioma, index) in idiomas" :key="index" @click="selecionarIdioma(idioma)")
                | {{ idioma }}
                .icon-ptt-icons-check(v-if="idioma === idiomaAtual" style="color:var(--color-info)")
        li.flex-espacado.to-click(@click="sair" @keydown.enter="sair")
          span {{ $t('mainHeader.MainHeader.sair') }}

</template>

<script>
import Avatar from '../../../static/images/header_user_avatar.svg'
import idiomasSuportados from 'src/typescript/servicos/i18n/helpers/idiomasSuportados'
import Dropdown from 'src/common/Dropdown/Dropdown.vue'

export default {
  components: {
    Dropdown
  },
  props: {
    infoDoUsuario: { required: true, type: Object },
    mostrarSeletorDeIdioma: { required: true, type: Boolean }
  },
  data() {
    return {
      Avatar,
      idiomas: idiomasSuportados,
      idiomaAtual: this.$i18n.locale,
      mostrarDropdown: false,
      subDropdown: false,
    }
  },
  methods: {
    selecionarIdioma(idioma) {
      localStorage.setItem('idioma', idioma);
      this.idiomaAtual = idioma;
      this.$i18n.locale = idioma;

      this.mostrarDropdown = false;
      window.location.reload()
    },
    sair() {
      this.$store.dispatch('logout')
    },
  },
}
</script>

<style scoped lang="scss">
.menu-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
}

.menu-usuario__avatar {
  margin-right: 10px;
}


.menu-info-do-usuario {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &>*:not(:first-child) {
    margin-top: 1rem;
  }

  .legenda {
    font-weight: normal;
    font-size: small;
    color: #58585B;
  }

  .conteudo {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--web-color-header);
  }
}

.flex-espacado {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  height: 25px;
  width: -webkit-fill-available;
}
</style>
