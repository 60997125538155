import VueResource from 'vue-resource'
import * as XLSX from 'xlsx'
import i18n from '@/typescript/servicos/i18n/i18n.servico'
import servicoLocalStorage from '@/typescript/servicos/localStorage/localStorage.servico'
import store from '@/store'
import { LocalDeTrabalho } from '@/tipos'

type LinhaDePlanilha = string[]

/**
 * @param resposta O objeto de resposta de uma requisição assícrona feita com o VueResource
 * @param padroes Um objeto que define as mensagens de sucesso e erro para caso o back não envie nenhuma
 * @returns A mensagem do back, se não a mensagem definida em padroes, se não uma mensagem genérica
 */
export function pegarMensagemDeResposta (resposta: VueResource.HttpResponse, padroes?: Record<string, string>): string {
  function pegarMensagemDeRespostaDoBack (): string | null | undefined {
    if (resposta.ok) {
      return resposta.data.success
    }
    return resposta.data.error
  }

  function pegarMensagemDeRespostaPadrao (): string | null | undefined {
    if (!padroes) return null
    if (resposta.ok) {
      return padroes.success
    }
    return padroes.error
  }

  function pegarMensagemDeRespostaGenerica (): string {
    if (resposta.ok) {
      return i18n.t('typescript.utils.respostaSucesso').toString()
    }
    return i18n.t('typescript.utils.respostaErro').toString()
  }

  return pegarMensagemDeRespostaDoBack() || pegarMensagemDeRespostaPadrao() || pegarMensagemDeRespostaGenerica()
}

/**
 * Exibe no modal apropriado a mensagem resultante de uma requisição
 * @param resposta O objeto de resposta de uma requisição assícrona feita com o VueResource
 * @param modais Um objeto mapeando quais modais serão utilizados
 */
export function mostrarModalDeResposta (
  resposta: VueResource.HttpResponse,
  modais: Record<string, (text: string) => void>
): void {
  function selecionarModalConformeResposta (): ((text: string) => void) {
    if (resposta.ok) {
      return modais.modalDeSucesso
    }
    return modais.modalDeErro
  }
  const modal = selecionarModalConformeResposta()
  const textoDoModal = pegarMensagemDeResposta(resposta)
  modal(textoDoModal)
}

/**
 * Interface que define a estrutura dos dados da planilha.
 */
interface DadosPlanilha {
  nomeAbaPlanilha: string;
  linhasDaPlanilha: LinhaDePlanilha[];
}

/**
 * Interface que define um contrato para classes que processam dados.
 */
interface ProcessadorDeDados {
  /**
   * Método para processar os dados e retornar os dados da planilha.
   * @param dados - Array de bytes representando os dados da planilha.
   * @returns Dados da planilha processados.
   */
  processar(dados: Uint8Array): DadosPlanilha;
}

/**
 * Classe que lê dados de planilhas usando um processador específico.
 */

export class XLSXAdapter implements ProcessadorDeDados {
  processar (dados: Uint8Array): DadosPlanilha {
    const planilha = XLSX.read(dados, { type: 'array' })
    const nomeAbaPlanilha = planilha.SheetNames[0]
    const folhaDeTrabalho = planilha.Sheets[nomeAbaPlanilha]
    const linhasDaPlanilha = XLSX.utils.sheet_to_json<string[]>(folhaDeTrabalho, { header: 1 }) || []
    return { nomeAbaPlanilha, linhasDaPlanilha }
  }
}

export class LeitorDePlanilha {
  private processadorDeDados: ProcessadorDeDados;

  constructor (processador: ProcessadorDeDados) {
    this.processadorDeDados = processador
  }

  tratarLinhasEmBranco (linhasDaPlanilha: LinhaDePlanilha[]): LinhaDePlanilha[] | undefined {
    const linhasTratadas = linhasDaPlanilha.filter(linha => linha[0] !== undefined || linha[1] !== undefined)

    if (linhasTratadas.length === 0) {
      return undefined
    }

    return linhasTratadas
  }

  planilhaEhValida (arquivo: File): boolean {
    const extensoesValidas = ['xls', 'xlsx']
    const nomeDoArquivo = arquivo.name

    // Dividir o nome do arquivo usando o ponto como delimitador
    const partesDoNome = nomeDoArquivo.split('.')

    // Obter a última parte, que deve ser a extensão
    const extensaoDoArquivo = partesDoNome[partesDoNome.length - 1]

    return extensoesValidas.includes(extensaoDoArquivo)
  }

  lerPlanilha (arquivo: File): Promise<DadosPlanilha> {
    return new Promise((resolve, reject) => {
      const leitor = new FileReader()
      leitor.onload = (evento) => {
        if (evento.target?.result) {
          const dados = new Uint8Array(evento.target.result as ArrayBuffer)
          const resultado = this.processadorDeDados.processar(dados)
          resolve(resultado)
        }
      }
      leitor.onerror = () => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(i18n.t('typescript.utils.erroLeitura'))
      }
      leitor.readAsArrayBuffer(arquivo)
    })
  }
}

// Identificação de usuário
export interface DadosUsuarioIdentificator {
  idUsuario: string;
  nomeUsuario: string;
  emailUsuario: string;
  papelUsuario: string;
  dataCriacaoUsuario: string;
  semanasUsoUsuario: number;
  mesesUsoUsuario: number;

  idEmpresaUsuario: string;
  nomeEmpresaUsuario: string;
  tamanhoEmpresaUsuario: string;
  canal: string;
  dataCriacaoEmpresa: string;
  semanasUsoEmpresa: number;
  mesesUsoEmpresa: number;
}

export interface DadosUsuarioAPIPersonalShort {
  idUsuario: string;
  nomeUsuario: string;
  emailUsuario: string;
  papelUsuario: string;
  idEmpresaUsuario: string;
  nomeEmpresaUsuario: string;
  tamanhoEmpresaUsuario: string;
  canal: string;
}

enum PapelUsuario {
  RH = 'Acesso geral (RH)',
  GESTOR = 'Acesso a grupos (Gestor/Supervisor)',
  FUNCIONARIO = 'Acesso a suas infos (Empregado)'
}

export class UsuarioIdentificador {
  private readonly idUsuario: string;
  private readonly nomeUsuario: string;
  private readonly emailUsuario: string;
  private readonly papelUsuario: string;
  private readonly dataCriacaoUsuario: string;
  private readonly semanasUsoUsuario: number;
  private readonly mesesUsoUsuario: number;

  private readonly idEmpresaUsuario: string;
  private readonly nomeEmpresaUsuario: string;
  private readonly tamanhoEmpresaUsuario: string;
  private readonly canal: string;
  private readonly dataCriacaoEmpresa: string;
  private readonly semanasUsoEmpresa: number;
  private readonly mesesUsoEmpresa: number;

  constructor (dadosUsuario: DadosUsuarioAPIPersonalShort) {
    this.idUsuario = dadosUsuario.idUsuario
    this.nomeUsuario = dadosUsuario.nomeUsuario
    this.emailUsuario = dadosUsuario.emailUsuario
    this.papelUsuario = this.definirPapelDoUsuarioNaEmpresa(dadosUsuario.papelUsuario)
    this.idEmpresaUsuario = dadosUsuario.idEmpresaUsuario
    this.nomeEmpresaUsuario = dadosUsuario.nomeEmpresaUsuario
    this.tamanhoEmpresaUsuario = dadosUsuario.tamanhoEmpresaUsuario
    this.canal = dadosUsuario.canal
    this.dataCriacaoUsuario = this.definirDataCriacaoApartirObjectId(this.idUsuario)
    this.dataCriacaoEmpresa = this.definirDataCriacaoApartirObjectId(this.idEmpresaUsuario)
    this.semanasUsoUsuario = this.calcularQuantidadeDeSemanasDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoUsuario)
    this.mesesUsoUsuario = this.calcularQuantidadeDeMesesDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoUsuario)
    this.semanasUsoEmpresa = this.calcularQuantidadeDeSemanasDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoEmpresa)
    this.mesesUsoEmpresa = this.calcularQuantidadeDeMesesDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoEmpresa)
  }

  private definirPapelDoUsuarioNaEmpresa (papel: string): string {
    return {
      'all': PapelUsuario.RH,
      'group': PapelUsuario.GESTOR,
      'mine': PapelUsuario.FUNCIONARIO
    }[papel] || 'Não foi possivel identificar o papel do usuário'
  }

  private definirDataCriacaoApartirObjectId (objectId: string): string {
    const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000
    return new Date(timestamp).toISOString()
  }

  public calcularQuantidadeDeSemanasDeUsoComBaseNaDataAtual (dataAtual: Date, dataReferenciaEmISOString: string): number {
    const dataReferencia = new Date(dataReferenciaEmISOString)
    const milisegundosPorDia = 24 * 60 * 60 * 1000
    const milisegundosPorSemana = milisegundosPorDia * 7
    const diferencaEmMilisegundos = dataAtual.getTime() - dataReferencia.getTime()
    const semanasDeUso = Math.floor(diferencaEmMilisegundos / milisegundosPorSemana) + 1

    return semanasDeUso
  }

  public calcularQuantidadeDeMesesDeUsoComBaseNaDataAtual (dataAtual: Date, dataReferenciaEmISOString: string): number {
    const dataReferencia = new Date(dataReferenciaEmISOString)
    const timestampAtual = dataAtual.getTime()
    const timestampReferencia = dataReferencia.getTime()

    const milisegundosPorMes = 30 * 24 * 60 * 60 * 1000 // Assumindo um mês de 30 dias
    const mesesDeUso = Math.floor((timestampAtual - timestampReferencia) / milisegundosPorMes) + 1

    return Math.max(mesesDeUso, 1)
  }

  public obterDadosDoUsuario (): DadosUsuarioIdentificator {
    return {
      idUsuario: this.idUsuario,
      nomeUsuario: this.nomeUsuario,
      emailUsuario: this.emailUsuario,
      papelUsuario: this.papelUsuario,
      idEmpresaUsuario: this.idEmpresaUsuario,
      nomeEmpresaUsuario: this.nomeEmpresaUsuario,
      tamanhoEmpresaUsuario: this.tamanhoEmpresaUsuario,
      canal: this.canal,
      dataCriacaoUsuario: this.dataCriacaoUsuario,
      dataCriacaoEmpresa: this.dataCriacaoEmpresa,
      semanasUsoUsuario: this.semanasUsoUsuario,
      mesesUsoUsuario: this.mesesUsoUsuario,
      semanasUsoEmpresa: this.semanasUsoEmpresa,
      mesesUsoEmpresa: this.mesesUsoEmpresa
    }
  }
}

/**
 * Função para retornar o local de trabalho padrão de usuários que não sejam do tipo mine
 * primeiro tenta pegar do localStorage (o mesmo selecionado na home),
 * se não existir, pega do store e o ultimo fallback é pegar
 * o firstCompany do userInfo (VAI RETORNAR UM OBJETO COM APENAS O ID)
 * @returns O local de trabalho padrão do usuário
 */
export const pegarLocalDeTrabalhoPadrao = (): LocalDeTrabalho | Pick<LocalDeTrabalho, "id"> | null => {
  const chaveNoLocalStorage = 'home-local-trabalho'
  let localDeTrabalho: LocalDeTrabalho | Pick<LocalDeTrabalho, "id"> | null = servicoLocalStorage.obterItem(chaveNoLocalStorage) as LocalDeTrabalho | null

  if (localDeTrabalho === null || 'id' in localDeTrabalho === false) {
    localDeTrabalho = store.state.common.companies.items.find((local: LocalDeTrabalho) => local.active)
  }

  if (!localDeTrabalho) {
    localDeTrabalho = {
      id: store.state.userInfo.firstCompany
    }
  }

  return localDeTrabalho
}
