import Vue from 'vue'
import { range } from 'src/utils'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { API } from 'src/services'
import { uniqueSelect } from 'src/common/selectors'
import moment from 'moment'
import swal from 'sweetalert2'
import coreState from './core'
import toolBox from './toolBox'
import lineActions from './lineActions'
import PQueue from 'p-queue'
import { valuesIn } from 'lodash'
import i18n from 'src/typescript/servicos/i18n/i18n.servico'

const defaultState = () => ({
  ...uniqueSelect.mapStates(['reasons']),
  ...coreState.state(),
  lines: [],
  linesMap: [], // row [ kinds[[id],[],[],[]] ]
  promiseQueues: {},
  employees: [],
  employeesPerId: {},
  populating: false,
  selectedLines: [], // index of lines
  selection: { 'from': null, to: null },
  focusedPt: [0, 0, 0], // this should always be a list in that format
  typeSelection: 'select',
  start: 0,
  journeySearch: '',
  dragging: null // Component being dragged
})

const state = defaultState()

const getters = {
  employees: state => state.employees,
  lines: state => state.lines,
  linesMap: state => state.linesMap,
  employeesPerId: state => state.employeesPerId,
  populating: state => state.populating,
  start: state => state.start,
  matchJourneySearch (state, getters, rootState) {
    const removerDaPesquisa = ['Dispensa', 'Licença', 'Férias']
    const journeys = rootState.common.journeys.items.filter(d => !removerDaPesquisa.includes(d.name))

    if (!journeys.length || !state.journeySearch) { return null }
    let match = null
    for (const i in journeys) {
      const journey = journeys[i]
      if ((journey.code && journey.code.includes(state.journeySearch)) ||
        journey.name.toLowerCase().includes(state.journeySearch.toLowerCase())) {
        match = journey
        break
      }
    }
    return match
  },
  promiseQueueIsNotEmpty: state => {
    return valuesIn(state.promiseQueues).some(queue => queue.pending > 0)
  },
  regraDeBhDoEmpregado (state, getters, rootState, rootGetters) {
    return rootGetters['timesheet/header/currentQueueEmployee']?.workHourBankRules || state.lines[0]?.workHoursBank?.rule
  }
}

const mutations = {
  ...uniqueSelect.mapMutations(['reasons']),
  ...coreState.mutations,
  resetState: state => {
    resetarParaEstadoInicial(state, defaultState())
  },
  selection (state, { from, to }) {
    state.selection.from = from !== undefined ? from : state.selection.from
    state.selection.to = to !== undefined ? to : state.selection.to
  },

  selectLinesRange (state, payload) {
    const from = payload.from || state.selection.from
    const to = payload.to || state.selection.to
    const nums = range(from, to)

    if (state.typeSelection === 'select') {
      state.selectedLines = Array.from(new Set([...state.selectedLines, ...nums]))
    } else {
      state.selectedLines = state.selectedLines.filter(i => !nums.includes(i))
    }
  },

  typeSelection (state, payload) {
    state.typeSelection = payload
  },

  focusedPt (state, payload) {
    if (!payload || payload.length < 2) {
      throw new Error('Tried to set focusedPt to a wrong value')
    }
    if (payload.length < 2) {
      payload.push(0)
    }
    state.focusedPt = payload
  },
  start (state, payload) { state.start = payload },
  dragging (state, payload) { state.dragging = payload },
  removeDoubleMergedLines (state, linesIdsToRemove) {
    linesIdsToRemove.forEach(lId => {
      state.lines.splice(state.lines.findIndex(l => l.id === lId), 1)
    })

    const linesMap = []
    state.lines.forEach(l => {
      let row = []
      if (!l.timelogs || !l.timelogs.length) {
        row = [[], [], [], []]
      } else {
        l.timelogs.forEach(kind => {
          row.push(kind.map(tl => tl.id))
        })
      }
      linesMap.push(row)
    })
    state.linesMap = linesMap
  },

  selectLineByIndex (state, payload) {
    if (state.typeSelection === 'deselect') {
      state.selectedLines = state.selectedLines.filter(i => i !== payload)
    } else {
      state.selectedLines = Array.from(new Set([...state.selectedLines, payload]))
    }
  },
  selectedLines (state, payload) {
    state.selection = { 'from': null, to: null }
    state.selectedLines = payload
  },
  switchLineSelection (state, rowNumber) {
    if (state.selectedLines.includes(rowNumber)) {
      state.selectedLines = state.selectedLines.filter(l => l !== rowNumber)
    } else {
      state.selectedLines = Array.from(new Set([rowNumber, ...state.selectedLines]))
    }
  },
  journeySearch (state, payload) {
    state.journeySearch = payload
  },
  addPromiseQueue (state, {queue, row}) {
    Vue.set(state.promiseQueues, row, queue)
  },
  clearAllPromiseQueues (state) {
    valuesIn(state.promiseQueues).forEach(queue => {
      queue.clear()
    })
  },
  adicionarTrabalhoRemotoNaLinha (state, {funcionarioId, data}) {
    // setar o trabalho remoto apenas nas linhas do dia do funcionário
    state.lines = state.lines.map(line => {
      if (line.employee.id === funcionarioId && moment(line.data).isSame(moment(data), 'days')) {
        return {
          ...line,
          dayJourney: {
            ...line.dayJourney,
            trabalhoRemoto: true
          }
        }
      }
      return line
    })
  },
  removerTrabalhoRemotoNaLinha (state, {funcionarioId, data}) {
    // setar o trabalho remoto apenas nas linhas do dia do funcionário
    state.lines = state.lines.map(line => {
      if (line.employee.id === funcionarioId && moment(line.data).isSame(moment(data), 'days')) {
        return {
          ...line,
          dayJourney: {
            ...line.dayJourney,
            trabalhoRemoto: false
          }
        }
      }
      return line
    })
  }
}

const actions = {
  ...coreState.actions,

  selectLineByIndex ({ commit }, payload) { commit('selectLineByIndex', payload) },

  async mergeLines ({ commit, dispatch, state }, { lines }) {
    const linesRowNumbers = state.lines
      .map((stateLine, index) => ({ use: lines.includes(stateLine.id), index }))
      .filter(i => i.use)
      .map(i => i.index)

    // If user merge two lines of diferent days we need to reload all days,
    // cuz its a lot easier to just reload all days instead of create a fakeline in front
    const needToReloadAll = new Set(
      lines.map(i => moment(state.lines.find(l => l.id === i).data).format('YYY-MM-DD'))
    ).size !== 1

    commit('processing', { add: linesRowNumbers, types: ['lines'] })

    try {
      await API.lineJoin.save({ lines: lines })
      if (needToReloadAll) {
        await dispatch('timesheet/populateLines', null, { root: true })
      } else {
        await commit('removeDoubleMergedLines', lines.slice(1))
      }
    } catch {
      swal({ type: 'warning', title: i18n.t('sheets.Sheet.State.state.mesclarLinhas.titulo') })
    } finally {
      commit('selectedLines', [])
      commit('processing', { remove: linesRowNumbers, types: ['lines'] })
      await dispatch('updateMultipleLines', {
        indexes: [linesRowNumbers[0]],
        toLoad: ['pts', 'tms', 'whbs'],
        recalculate: true
      })
      setTimeout(() => {
        dispatch('recalcLines', [linesRowNumbers[0]])
      }, 500)
    }
  },

  mergeSelectedLines ({ dispatch, state }) {
    const lines = state.lines.filter((i, index) => state.selectedLines.includes(index))
    if (!lines.length) { return }
    if (new Set(lines.map(i => i.employee.id)).size !== 1) { return }
    dispatch('mergeLines', { lines: lines.map(i => i.id) })
  },

  async saveDayJourneys ({ commit, state, dispatch }, { linesIndexes, journey, onCall = null, useSelectedToo = true }) {
    commit('journeySearch', '')

    // data is for changeMultipleDjs and dataForce is to force line djs
    const data = { newDjJourneys: [{ groups: [], journey, onCall }] }
    const dataForce = []

    linesIndexes = useSelectedToo ? [...state.selectedLines, ...linesIndexes] : linesIndexes
    linesIndexes = linesIndexes.filter(i => {
      return i !== undefined && (!state.lines[i].dayJourney.isVacation && !state.lines[i].dayJourney.isLeave)
    })

    commit('processing', { add: linesIndexes, types: ['djs', 'tms', 'whbs'] })
    commit('selectedLines', [])
    linesIndexes.forEach(l => {
      const line = state.lines[l]
      if (!line.isDoubleLine) {
        const item = {
          employee: line.employee.id,
          company: line.employee.company,
          day: line.dayJourney.day || line.data
        }
        if (!line.dayJourney.isVacation && !line.dayJourney.isLeave && !line.justify) {
          data.newDjJourneys[0].groups.push(item)
        }
      } else {
        dataForce.push({
          params: { id: line.id },
          data: { force_journey: journey.id }
        })
      }
    })

    const lines = linesIndexes.map(i => ({ line: state.lines[i], index: i }))

    try {
      // If the line is a double line we will not update the dj
      // we gonna force it to the line
      await API.changeMultipleDayjourney.save(data)

      // Send multiple force_line_journey
      const lineRequests = []
      dataForce.forEach(toForce => {
        lineRequests.push(API.line.save(toForce.params, toForce.data))
      })
      await Promise.all(lineRequests)
      await dispatch('updateMultipleLines', { toLoad: ['djs', 'tms', 'whbs'], indexes: linesIndexes, recalculate: true })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro.body?.error || i18n.t('sheets.Sheet.State.state.salvarJornadasDiarias.titulo')
      })
      commit('processing', { remove: lines.map(l => l.index), types: ['djs', 'tms', 'whbs'] })
    }
  },

  async recalculateSelectedLines ({ dispatch, commit, state }) {
    if (!state.selectedLines.length) { return }
    dispatch('recalcLines', [...state.selectedLines])
    commit('selectedLines', [])
  },

  async forceOptionsLine ({ dispatch, commit }, { rowNumber, lineId, ...flags }) {
    const params = { id: lineId }
    const data = { ...params, ...flags }
    commit('processing', { types: ['tms'], add: [rowNumber] })
    await API.line.save(params, data)
    dispatch('recalcLines', [rowNumber])
  },

  async addRemoveWHBLines ({ state, commit, dispatch }, { linesIndexes, action }) {
    // action = 'add' or 'delete'

    commit('processing', { types: ['whbs'], add: linesIndexes })

    const requests = []

    // TODO Create a resource that we can send multiple lines
    linesIndexes.forEach(index => {
      const line = state.lines[index]
      const whb = line.workHoursBank.workHourBank

      let kind = 'add'
      if (whb.active && action === 'delete') {
        kind = 'delete'
      } else if (whb.active && action === 'add') {
        kind = 'update'
      }

      const params = {
        kind,
        id: whb.id || undefined
      }

      const data = {
        employee: line.employee.id,
        date: moment(line.data).format('YYYY-MM-DD'),
        isArbitrary: false,
        id: whb.id || undefined
      }

      if (action === 'add') {
        requests.push(API.whboCreate.save(params, data))
      } else {
        requests.push(API.whboCreate.delete(params))
      }
    })

    try {
      await Promise.all(requests)
    } catch (erro) {
      swal({
        type: 'error',
        title: i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasWHB.titulo'),
        text: erro.data && erro.data.error ? erro.data.error : i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasWHB.texto')
      })
    }

    await dispatch('updateMultipleLines', { toLoad: ['whbs'], indexes: linesIndexes, recalculate: true })
    commit('processing', { types: ['whbs'], remove: linesIndexes })
  },

  async addRemoveWHBSelectedLines ({ state, commit, dispatch }, action) {
    // action = 'add' or 'delete'

    const selected = state.selectedLines

    if (!selected.length) {
      swal({
        title: i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasSelecionadasWHB.titulo'),
        text: i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasSelecionadasWHB.texto'),
        type: 'info'
      })
      return
    }

    commit('selectedLines', [])
    dispatch('addRemoveWHBLines', { linesIndexes: selected, action })
  },

  async approveDisapproveWhb ({ state, commit, dispatch }, { linesIndexes, action }) {
    // action = approve or disapprove

    if (!['approve', 'disapprove'].includes(action)) {
      throw new Error(`Action approveDisapproveWhb only accepts 'approve' or 'disapprove' as payload`)
    }

    commit('processing', { types: ['whbs'], add: linesIndexes })

    const requests = []

    linesIndexes.forEach(index => {
      const line = state.lines[index]
      const whb = line.workHoursBank.workHourBank

      const params = { whobId: whb.id, approve: action }

      requests.push(
        API.whboAuth.save(params, { aprove: action, whobId: whb.id })
      )
    })

    try {
      await Promise.all(requests)
    } catch (erro) {
      swal({
        type: 'error',
        title: i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasWHB.titulo'),
        text: erro.data && erro.data.error ? erro.data.error : i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasWHB.texto')
      })
    }
    await dispatch('updateMultipleLines', { toLoad: ['whbs', 'approvals'], indexes: linesIndexes, recalculate: true })
    commit('processing', { types: ['whbs', 'approvals'], remove: linesIndexes })
  },

  async actionArbTms ({ state, commit, dispatch }, { rowNumber, action }) {
    if (!['remove', 'approve', 'reprove'].includes(action)) {
      throw new Error('actionArbTms received an invalid action')
    }

    const line = state.lines[rowNumber]

    commit('processing', { types: ['tms'], add: [rowNumber] })

    if (action === 'remove') {
      await API.arbitraryTms.delete({
        id: line.arbTms.arbitraryTmsId
      })
    } else {
      await API.arbitraryApprove.save({
        atmsId: line.arbTms.arbitraryTmsId,
        approved: action === 'approve',
        remove: action === 'remove'
      })
    }

    await dispatch('updateMultipleLines', { toLoad: ['tms'], indexes: [rowNumber], recalculate: true })
    commit('processing', { types: ['tms'], remove: [rowNumber] })
  },

  async autoCorrectActions ({ state, dispatch, commit }, action) {
    if (!['correct', 'undo'].includes(action)) {
      throw new Error('autoCorrectActions received an invalid action')
    }

    const lines = state.lines
      .filter(l => l.id)
      .map(l => l.id)

    if (!lines.length) {
      swal({
        type: 'info',
        text: i18n.t('sheets.Sheet.State.state.acoesDeAutoCorrecao.nenhumaLinhaEncontrada')
      })
      return
    }

    const indexesToProcess = range(0, state.lines.length - 1)

    const params = { correct: action }
    const data = { lines_id: lines }

    commit('processing', { add: indexesToProcess, types: ['pts'] })

    try {
      await API.autoCorrect.save(params, data)

      // separate tms and pts because recalc takes more time
      dispatch('recalcLines', indexesToProcess)
      await dispatch('updateMultipleLines', {
        toLoad: ['pts'],
        indexes: indexesToProcess
      })
    } catch {
      swal({
        type: 'error',
        title: i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasWHB.titulo'),
        text: i18n.t('sheets.Sheet.State.state.adicionarOuRemoverLinhasWHB.texto')
      })
      commit('processing', { remove: indexesToProcess, types: ['pts'] })
    }
  },

  async reprocessLinePts ({ state, commit, dispatch }, indexes) {
    // reprocess the timelogs of the given lines

    indexes.forEach(i => {
      commit('switchLineSelection', i)
    })
    commit('processing', { add: indexes, types: ['lines', 'pts'] })

    try {
      await API.reprocessLinePts.save({
        lines: indexes
          .map(i => state.lines[i])
          .filter(i => i.timelogs.find(g => g.length >= 1))
          .map(i => i.id)
      })
    } catch {}

    commit('processing', { remove: indexes, types: ['lines', 'pts'] })

    await dispatch('updateMultipleLines', {
      toLoad: ['pts', 'djs', 'tms', 'whbs', 'approvals'],
      recalculate: true,
      // reload previous line and next one too
      indexes: range(Math.max(Math.min(...indexes) - 1, 0), Math.max(...indexes) + 1)
    })
  },
  enqueueLinePromise ({state, commit}, {callback, row, updateLine}) {
    const queueAlredyExist = state.promiseQueues.hasOwnProperty(row)

    const queue = queueAlredyExist
      ? state.promiseQueues[row]
      : new PQueue({concurrency: 1})

    queue.add(callback, {priority: 1})
    if (updateLine && queue.sizeBy({priority: 0}) === 0) {
      queue.add(updateLine, {priority: 0})
    }

    if (!queueAlredyExist) {
      commit('addPromiseQueue', {queue, row})
    }
  },

  async adicionarTrabalhoRemotoNaLinha ({ commit }, { funcionarioId, data }) {
    try {
      await API.trabalhoRemoto.save({}, {funcionarioId, data})
      commit('adicionarTrabalhoRemotoNaLinha', {funcionarioId, data})
    } catch {}
  },
  async removerTrabalhoRemotoNaLinha ({ commit }, { funcionarioId, data }) {
    try {
      await API.trabalhoRemoto.delete({}, {funcionarioId, data})
      commit('removerTrabalhoRemotoNaLinha', {funcionarioId, data})
    } catch {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: { toolBox, lineActions }
}
