<template>
<div v-if="!estaSolicitandoFerias" class="container-minhas-solicitacoes">
  <PButton color="warning" size="md" @click="solicitarFerias">
    {{ $t('gerenciamentoDeFerias.minhasFerias.solicitarMinhasFerias') }}
  </PButton>
</div>
<div v-else>
  <SolicitarMinhasFerias @buscar="buscar" @cancelar="cancelar"/>
</div>
</template>

<script>
import SolicitarMinhasFerias from '../solicitacoes/SolicitarMinhasFerias.vue'

export default {
  name: 'MinhasFeriasAgendadas',
  mounted () {
    this.$emit('buscarDados')
  },
  data () {
    return {
      estaSolicitandoFerias: false
    }
  },
  methods: {
    buscar() {
      this.$emit('buscarDados')
    },
    solicitarFerias() {
      this.estaSolicitandoFerias = true
    },
    cancelar() {
      this.estaSolicitandoFerias = false
    }
  },
  components: {
    SolicitarMinhasFerias
  },
}
</script>

<style lang="scss" scoped>
div.container-minhas-solicitacoes {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.status-select {
  width: 25rem;
}
</style>
