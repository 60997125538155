var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal-chat-inkeep__wrapper",
      on: { click: _vm.clicouNoContainer },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-chat-inkeep__container",
          on: { click: _vm.prevenirFechamento },
        },
        [_c("div", { attrs: { id: "ikp-embedded-chat-target" } })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }