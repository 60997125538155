import { ServicoAbstrato } from '@/typescript/servicos/servicoAbstrato/servicoAbstrato.servico'
import { ISolicitacaoRepositorio } from '../repositorio/solicitacoes.repositorio.interface'
import { IRequestJustificarHorasExtras, IRequestSolicitacaoHorasExtras, IRequestSolicitarHorasExtrasEmGrupo, IRequestConfiguracaoRegraVigente } from '../repositorio/solicitacoes.repositorio.request'
import { AxiosError } from 'axios'
import { FiltradorDeErros } from '@/erros'
import { modalDeErro, modalDeInformacao, modalDeSucesso } from 'src/utils.js'
import i18n from '@/typescript/servicos/i18n/i18n.servico'
import { IResponseConfiguracaoRegraVigente } from '../repositorio/solicitacoes.repositorio.response'

interface ISolicitacoesServico {
  salvarSolicitacaoHorasExtras(dados: IRequestSolicitacaoHorasExtras): Promise<void>
  justificarHorasExtras(dados: IRequestJustificarHorasExtras): Promise<void>
  salvarSolicitacaoHorasExtrasEmGrupo(dados: IRequestSolicitarHorasExtrasEmGrupo): Promise<void>
  obterConfiguracaoSolicitacaoVigente(dados: IRequestConfiguracaoRegraVigente): Promise<IResponseConfiguracaoRegraVigente>
  lidarComErroHorasExtras(erro: unknown): void
}

export class SolicitacoesServico extends ServicoAbstrato<ISolicitacaoRepositorio> implements ISolicitacoesServico {
  async salvarSolicitacaoHorasExtras(dados: IRequestSolicitacaoHorasExtras): Promise<void> {
    await this.repositorio.solicitarHorasExtras(dados)
      .then(() => {
        modalDeInformacao(
          i18n.t('solicitacoes.dominio.Solicitacoes.avisoPedidoHorasExtrasFeito.titulo'),
          i18n.t('solicitacoes.dominio.Solicitacoes.avisoPedidoHorasExtrasFeito.mensagem')
        )
    })
      .catch(this.lidarComErroHorasExtras)

  }
  async justificarHorasExtras(dados: IRequestJustificarHorasExtras): Promise<void> {
    await this.repositorio.justificarHorasExtras(dados)
      .then(() => {
        modalDeInformacao(
          i18n.t('solicitacoes.dominio.Solicitacoes.avisoJustificativaRealizada.titulo'),
          i18n.t('solicitacoes.dominio.Solicitacoes.avisoJustificativaRealizada.mensagem')
        )
      })
      .catch(this.lidarComErroHorasExtras)
  }
  async obterConfiguracaoSolicitacaoVigente(dados: IRequestConfiguracaoRegraVigente): Promise<IResponseConfiguracaoRegraVigente> {
    try {
      const response = await this.repositorio.configuracaoRegraVigente(dados)
      return response.data
    } catch (error) {
      throw error
    }
  }
  async salvarSolicitacaoHorasExtrasEmGrupo(dados: IRequestSolicitarHorasExtrasEmGrupo): Promise<void> {
    await this.repositorio.solicitarHorasExtrasEmGrupo(dados)
      .then(() => {
        modalDeSucesso(
          i18n.t('solicitacoes.dominio.Solicitacoes.avisoSolicitacaoHorasExtrasEmGrupoFeito.mensagem'),
          i18n.t('solicitacoes.dominio.Solicitacoes.avisoSolicitacaoHorasExtrasEmGrupoFeito.titulo')
        )
      })
        .catch(this.lidarComErroHorasExtras)
  }
  lidarComErroHorasExtras(erro: unknown): Promise<void> {
    let textoGenerico = i18n.t('solicitacoes.dominio.Solicitacoes.erroInesperadoGenerico.texto')
    let tituloGenerico = i18n.t('solicitacoes.dominio.Solicitacoes.erroInesperadoGenerico.titulo')

    if (erro instanceof AxiosError) {
      /**
       * o backend retorna um erro quando a solicitação é feita, vamos executar
       * a condicional e continuar o try de onde a função é chamada
       */
      if (erro.response?.data?.error === 'Sua solicitação foi feita com sucesso!') {
        modalDeInformacao(erro.response.data?.description, erro.response.data?.error)
        return Promise.resolve()
      } else if (erro.response?.status === 400) {
        const textoErro = erro?.response?.data.error || textoGenerico
        const textoDescricao = erro?.response?.data.description || textoGenerico

        // como o back manda o error e o description de forma despadronizada, verificamos qual
        // é o maior texto para saber qual vai ser o título do modal e qual vai ser o texto
        if (textoErro.length > textoDescricao.lenght) {
          textoGenerico = textoErro
          tituloGenerico = textoDescricao
        } else {
          textoGenerico = textoDescricao
          tituloGenerico = textoErro
        }
      } else {
        FiltradorDeErros.capturarErro(erro as Error)
      }
    } else {
      FiltradorDeErros.capturarErro(erro as Error)
    }

    modalDeErro(textoGenerico, tituloGenerico)
    return Promise.reject(erro)
  }
}