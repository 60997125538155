<template>
  <div id="app">
    <div id="header-space">
      <MainHeader v-if="userInfo && !estaNaRotaDeAcessoNegado"></MainHeader>
    </div>

    <div id="view-space">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view class="view"/>
      </transition>
    </div>
    <BotaoFlutuante
       v-if="userInfo && usuarioEhRH"
      texto="Fale Conosco"
      direcaoExpansao="direita"
      orientacao="inferior-esquerda"
      backgroundColor="#03ac55"
      aria-label="Converse conosco no WhatsApp"
      @clique="abrirLinkWhatsapp"
    >
      <div slot="icon" style="width: 30px; height: 30px; overflow: hidden; border-radius: 100%;" class="container-icone">
        <img src="https://www.pontotel.com.br/wp-content/uploads/2020/10/whatsapp-icon.png" alt="ícone do whatsapp" style="width: 100%;">
      </div>
    </BotaoFlutuante>
    <BotaoFlutuante
      v-if="mostrarBotaoChatInkeep"
      texto="Assistente"
      direcaoExpansao="esquerda"
      orientacao="inferior-direita"
      backgroundColor="#211b1f"
      @clique="mostrarModalInkeep = true"
    >
      <div slot="icon" style="width: 30px; height: 30px; overflow: hidden; border-radius: 100%;" class="container-icone">
        <img src="../public/favicon.png" alt="" style="width: 100%;">
      </div>
    </BotaoFlutuante>
    <ModalInkeep v-show="mostrarModalInkeep" @fechar="mostrarModalInkeep = false"></ModalInkeep>
    <!-- Desabilitado temporariamente -->
    <BotaoFlutuante
      v-if="false"
      :texto="$t('compartilhados.ModalReportarErroSentry.legendaBotaoReporte')"
      orientacao="inferior-esquerda"
      direcaoExpansao="direita"
      espacamentoVertical="75px"
      espacamentoHorizontal="10px"
      backgroundColor="#79397d"
      @clique="abrirModalReportarErro"
    />
    <ModalReportarErroSentry v-if="mostrarModalReportarErro" @fechar="mostrarModalReportarErro = false" />
  </div>
</template>
<script>
import BotaoFlutuante from './common/BotaoFlutuante'
import ModalInkeep from './common/Modalnkeep'
import MainHeader from './components/MainHeader/MainHeader'
import ModalReportarErroSentry from './common/ModalReportarErroSentry.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      mostrarModalReportarErro: false,
      mostrarModalInkeep: false
    }
  },
  async created () {
    // eslint-disable-next-line no-console
    console.log('Versão:', process?.env?.VUE_APP_TAG_VERSION ? process.env.VUE_APP_TAG_VERSION : '-')
    this.$store.dispatch('common/loadMainCommons')
  },
  computed: {
    ...mapState(['userInfo']),
    ehLar21 () {
      return this.$store?.state?.userInfo?.email?.includes('lar21@')
    },
    estaNaRotaDeAcessoNegado () {
      return this.$route.name === 'AcessoNegado'
    },
    usuarioEhRH () {
      return this.userInfo && this.userInfo.roleGroup.kind === 'all'
    },
    mostrarBotaoChatInkeep () {
      return this.ambienteProducao && this.userInfo && this.userInfo.roleGroup.kind === 'all'
    },
    ambienteProducao () {
      return process.env.NODE_ENV === 'production'
    },
  },
  methods: {
    abrirLinkWhatsapp () {
      window.open('https://wa.me/551131852700', '_blank')
    },
    abrirModalReportarErro() {
      this.mostrarModalReportarErro = true
    }
  },
  components: { MainHeader, BotaoFlutuante, ModalInkeep, ModalReportarErroSentry }
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
}

html {
  height: 100%;
  max-height: 100%;
  background-color: var(--front-background-color);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.42857143;
  color: #333;
  background-color: var(--front-background-color);
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 0.1fr) minmax(0, 1fr) minmax(0, 0.1fr);
  grid-template-rows: min-content minmax(0, 100%);
  grid-template-areas:
    "header-space header-space header-space"
    ". view-space .";
}

#header-space { grid-area: header-space; }

#view-space {
  grid-area: view-space;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.whatsapp {
  position: fixed;
  left: 1.4rem;
  bottom: 1.1rem;
  color: white;
  text-align: left;
  cursor: pointer;
  z-index: 10000;
}


.whatsapp img { height: 60px; padding: 0;}
</style>
