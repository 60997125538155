/* eslint-disable @typescript-eslint/no-explicit-any */
export type HttpHeaders = Record<string, string | string[] | undefined>;

/**
 * Objeto com detalhes da request
 * a serem enviados para o sentry
 */
export type DetalhesErroSentry = {
  codigoErroApi: number | string;
  mensagem: string;
  horarioDaRequisicao: string;
  urlCompleta: string;
  metodo: string;
  retornoApi?: unknown;
  titulo: string;
  payload?: unknown;
}

/**
 * Representa uma resposta HTTP genérica.
 *
 * @template T Tipo dos dados contidos na resposta (por padrão, `any`).
 */
export interface HttpResponse<T = any> {
  /** Os dados retornados pela resposta. */
  data: T;

  /** O código de status HTTP. */
  status: number;

  /** O texto do status HTTP. */
  statusText: string;

  /** Os cabeçalhos da resposta. */
  headers: HttpHeaders;

  /** Configurações usadas na requisição. */
  config: any;

  /** O objeto de requisição original (opcional). */
  request?: any;
}

/**
 * Configurações para uma requisição HTTP.
 */
export interface HttpRequestConfig {
  /** A URL da requisição. */
  url?: string;

  /** O método HTTP utilizado (e.g., 'GET', 'POST'). */
  method?: string;

  /** Cabeçalhos HTTP adicionais. */
  headers?: HttpHeaders;

  /** Parâmetros de consulta (query params). */
  params?: Record<string, any>;

  /** Dados enviados no corpo da requisição. */
  data?: any;

  /** Tempo limite da requisição em milissegundos. */
  timeout?: number;

  /** Indica se credenciais (cookies) devem ser enviadas. */
  withCredentials?: boolean;

  /** Tipo de resposta esperada (e.g., 'json', 'blob'). */
  responseType?: 'json' | 'text' | 'blob' | 'arraybuffer' | 'document' | 'stream';

  /** Um sinal para abortar a requisição. */
  signal?: AbortSignal;
}

/**
 * Tipos de requisição para estratégias de retry.
 */
export enum TipoRequisicaoParaRetry {
  /** Requisição para a URL base. */
  BASE = 'base',
  /** Requisição para a URL fallback. */
  FALLBACK = 'fallback'
}

/**
 * Configuração para uma tentativa de retry.
 */
export interface IConfiguracaoRetry {
  /** O tipo da requisição (base ou fallback). */
  tipo: TipoRequisicaoParaRetry;

  /** O tempo limite (timeout) para a tentativa de retry. */
  timeout: number;
}

/**
 * Tipos de métodos para retrys.
 */
export enum IMetodoHttp {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch'
}

/**
 * Configuração para uma requisição com suporte a retries.
 */
export interface IConfiguracaoRequisicaoComRetry {
  /** O método HTTP utilizado na requisição. */
  metodo: IMetodoHttp.GET | IMetodoHttp.POST | IMetodoHttp.PUT | IMetodoHttp.DELETE | IMetodoHttp.PATCH;

  /** O endpoint da requisição. */
  endpoint: string;

  /** Configurações adicionais da requisição (opcional). */
  configuracoesDeRequisicao?: HttpRequestConfig;

  /** Lista de configurações de retries (opcional). */
  configuracaoRetrys?: IConfiguracaoRetry[];

  /** Intervalo em milissegundos entre cada tentativa de retry (opcional). */
  intervaloEntreRetrys?: number;
}

/**
 * Interface para um serviço HTTP genérico.
 */
export interface IServicoHttp {
  /**
   * Realiza uma requisição HTTP GET.
   *
   * @template T Tipo dos dados esperados na resposta.
   * @param url A URL da requisição.
   * @param config Configurações adicionais (opcional).
   * @returns {Promise<HttpResponse<T>>} A resposta HTTP.
   */
  get<T = any>(url: string, config?: HttpRequestConfig): Promise<HttpResponse<T>>;

  /**
   * Realiza uma requisição HTTP POST.
   *
   * @template T Tipo dos dados esperados na resposta.
   * @param url A URL da requisição.
   * @param data Dados enviados no corpo da requisição (opcional).
   * @param config Configurações adicionais (opcional).
   * @returns {Promise<HttpResponse<T>>} A resposta HTTP.
   */
  post<T = any>(url: string, data?: any, config?: HttpRequestConfig): Promise<HttpResponse<T>>;

  /**
   * Realiza uma requisição HTTP PUT.
   *
   * @template T Tipo dos dados esperados na resposta.
   * @param url A URL da requisição.
   * @param data Dados enviados no corpo da requisição (opcional).
   * @param config Configurações adicionais (opcional).
   * @returns {Promise<HttpResponse<T>>} A resposta HTTP.
   */
  put<T = any>(url: string, data?: any, config?: HttpRequestConfig): Promise<HttpResponse<T>>;

  /**
   * Realiza uma requisição HTTP PATCH.
   *
   * @template T Tipo dos dados esperados na resposta.
   * @param url A URL da requisição.
   * @param data Dados enviados no corpo da requisição (opcional).
   * @param config Configurações adicionais (opcional).
   * @returns {Promise<HttpResponse<T>>} A resposta HTTP.
   */
  patch<T = any>(url: string, data?: any, config?: HttpRequestConfig): Promise<HttpResponse<T>>;

  /**
   * Realiza uma requisição HTTP DELETE.
   *
   * @template T Tipo dos dados esperados na resposta.
   * @param url A URL da requisição.
   * @param config Configurações adicionais (opcional).
   * @returns {Promise<HttpResponse<T>>} A resposta HTTP.
   */
  delete<T = any>(url: string, config?: HttpRequestConfig): Promise<HttpResponse<T>>;

  /**
   * Obtém a URL base utilizada pelo serviço HTTP.
   *
   * @returns {string} A URL base.
   */
  obterUrlBase(): string;

  /**
   * Obtém a URL fallback utilizada pelo serviço HTTP.
   *
   * @returns {string} A URL fallback.
   */
  obterUrlFallback(): string;

  /**
   * Realiza uma requisição com suporte a retries.
   *
   * Esse método realiza uma requisição HTTP ao endpoint especificado,
   * utilizando uma lógica de retries em caso de falhas. É configurável
   * para realizar múltiplas tentativas antes de lançar um erro.
   *
   * @template T Tipo dos dados esperados na resposta.
   * @param {IConfiguracaoRequisicaoComRetry} configuracao Configuração da requisição com retries.
   * @returns {Promise<HttpResponse<T>>} A resposta HTTP após sucesso ou erro final.
   *
   * @example
   * // Exemplo: Realizar uma requisição GET com retries padrão
   * const servicoHttp: IServicoHttp = new MeuServicoHttp();
   * const configuracao = {
   *   metodo: 'get',
   *   endpoint: '/usuarios',
   *   configuracaoRetrys: [
   *     { tipo: 'base', timeout: 5000 },
   *     { tipo: 'fallback', timeout: 7000 }
   *   ],
   *   intervaloEntreRetrys: 2000
   * };
   *
   * const resposta = await servicoHttp.realizarRequisicaoComRetrys(configuracao);
   * console.log(resposta.data);
   *
   * @example
   * // Exemplo: Realizar uma requisição POST com dados no corpo e retries personalizados
   * const servicoHttp: IServicoHttp = new MeuServicoHttp();
   * const configuracao = {
   *   metodo: 'post',
   *   endpoint: '/usuarios',
   *   configuracoesDeRequisicao: {
   *     data: { nome: 'Leandro', email: 'leandro@example.com' },
   *     headers: { Authorization: 'Bearer token123' }
   *   },
   *   configuracaoRetrys: [
   *     { tipo: 'base', timeout: 10000 },
   *     { tipo: 'fallback', timeout: 15000 }
   *   ],
   *   intervaloEntreRetrys: 3000
   * };
   *
   * const resposta = await servicoHttp.realizarRequisicaoComRetrys(configuracao);
   * console.log(resposta.data);
   *
   * @example
   * // Exemplo: Realizar múltiplas requisições em paralelo usando Promise.all
   * const servicoHttp: IServicoHttp = new MeuServicoHttp('https://api.example.com');
   *
   * const configuracoes = [
   *   { metodo: 'get', endpoint: '/usuarios', configuracaoRetrys: [{ tipo: 'base', timeout: 5000 }] },
   *   { metodo: 'get', endpoint: '/produtos', configuracaoRetrys: [{ tipo: 'base', timeout: 5000 }] },
   * ];
   *
   * const respostas = await Promise.all(
   *   configuracoes.map(config => servicoHttp.realizarRequisicaoComRetrys(config))
   * );
   *
   * respostas.forEach((resposta, index) => {
   *   console.log(`Resposta ${index + 1}:`, resposta.data);
   * });
   *
   * @example
   * // Exemplo: Realizar múltiplas requisições com Promise.allSettled
   * const servicoHttp: IServicoHttp = new MeuServicoHttp('https://api.example.com');
   *
   * const configuracoes = [
   *   { metodo: 'get', endpoint: '/usuarios', configuracaoRetrys: [{ tipo: 'base', timeout: 5000 }] },
   *   { metodo: 'get', endpoint: '/produtos', configuracaoRetrys: [{ tipo: 'base', timeout: 5000 }] },
   *   { metodo: 'get', endpoint: '/invalido', configuracaoRetrys: [{ tipo: 'base', timeout: 5000 }] },
   * ];
   *
   * const resultados = await Promise.allSettled(
   *   configuracoes.map(config => servicoHttp.realizarRequisicaoComRetrys(config))
   * );
   *
   * resultados.forEach((resultado, index) => {
   *   if (resultado.status === 'fulfilled') {
   *     console.log(`Requisição ${index + 1} bem-sucedida:`, resultado);
   *   } else {
   *     console.error(`Requisição ${index + 1} falhou:`, resultado);
   *   }
   * });
   */
  realizarRequisicaoComRetrys<T = any>(configuracao: IConfiguracaoRequisicaoComRetry): Promise<HttpResponse<T>>;
}
