import Vue from 'vue'
import VueI18n from 'vue-i18n'
import mensagens from '@/typescript/servicos/i18n/locales'
import moment from 'moment'
import ptBrlocaleMoment from '../../../../static/js/pt-br'
import esCoLocaleMoment from '../../../../static/js/es-co'
import * as Sentry from '@sentry/vue'
import { ErroDeTraducao } from './i18n.excecoes'
import idiomasSuportados from './helpers/idiomasSuportados'

const obterIdiomaDoNavegador = (): string => {
  const idiomaSalvo = localStorage.getItem('idioma')
  const idiomaNavegador = navigator.language;

  let idioma: string

  if (idiomaSalvo && idiomasSuportados.includes(idiomaSalvo)) {
    idioma = idiomaSalvo
  } else if (idiomasSuportados.includes(idiomaNavegador)) {
    idioma = idiomaNavegador
  } else {
    idioma = 'pt-BR'
  }

  // Caso o idioma seja do tipo es-XX, converte para es
  if (idioma.startsWith('es')) {
    idioma = 'es'
  }

  return idioma
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: obterIdiomaDoNavegador(),
  fallbackLocale: 'pt-BR',
  messages: mensagens,
  missing: (locale, key) => {
    Sentry.captureException(new ErroDeTraducao(key), {
      extra: {
        linguagem: locale,
        chaveComErro: key
      }
    })
  }
})

switch (i18n.locale) {
  case 'pt-BR':
    moment.locale('pt-br', ptBrlocaleMoment)
    break
  case 'es-CO':
    moment.locale('es-co', esCoLocaleMoment)
    break
  default:
    moment.locale('pt-br', ptBrlocaleMoment)
}

// TODO Legado movido para este arquivo
export function wp(palavra: string): string { return i18n.tc('words.' + palavra, 2) }
export function ws(palavra: string): string { return i18n.tc('words.' + palavra, 1) }
Vue.prototype.$wp = wp // plural
Vue.prototype.$ws = ws // singular
// TODO Legado movido para este arquivo

export default i18n
