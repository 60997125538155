/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueConstructor } from 'vue'
import * as Sentry from '@sentry/vue'
import { ErroDoUsuarioPontotel } from 'src/tipos/erros/classes'
import {
  ErroNavegadorNaoSuportaGeolocalizacao,
  ErroNavegadorNaoSuportaNotificacoes,
  ErroUsuarioNaoPermitiuNotificacoes
} from 'src/tipos/erros'

interface ReportadorDeErros {
  reportarErro(erro: Error, tags?: Record<string, any>): void;
  reportarMensagem(mensagem: string): void;
}

export class SentryAdapter implements ReportadorDeErros {
  reportarErro(erro: Error, tags?: Record<string, any>): void {
    const opcoes = {
      ...(tags && {
        tags: tags,
      }),
      ...('detalhesErroSentry' in erro && {
        extra: {
          detalhesErroSentry: erro.detalhesErroSentry,
        }
      })
    }

    Sentry.captureException(erro, opcoes)
  }

  reportarMensagem (mensagem: string): void {
    Sentry.captureMessage(mensagem)
  }
}

const verificarEhAmbienteDeProducao = () => {
  return process.env.NODE_ENV === 'production'
}

interface ErroDoServidorComData extends Error {
  data: {
    error?: string;
    detail?: string;
    description?: string;
    id?: string;
    message?: string;
  }
}

interface ErroDoServidorComBody extends Error {
  body: {
    error?: string;
    detail?: string;
    description?: string;
    id?: string;
    message?: string;
  }
}

export class FiltradorDeErros {
  private static reportadorDeErros: ReportadorDeErros = new SentryAdapter();

  private static verificarSeEhErroDoUsuario (erro: Error) {
    return erro instanceof ErroDoUsuarioPontotel
  }

  static capturarErro (erro: Error, tags?: Record<string, any>) {
    this.filtrarErroParaDiagnostico(erro, tags)
  }

  private static extrairMensagemDoErro (erro: ErroDoServidorComData | ErroDoServidorComBody) {
    const data = (erro as ErroDoServidorComData).data
    const body = (erro as ErroDoServidorComBody).body

    // formatos encontrados ao longo do repositório e que necessitam de refatoração para padronizar
    const mensagem = (data && (data.error || data.detail || data.description || data.message)) ||
      (body && (body.error || body.detail || body.description || body.message))

    // caso não encontre nenhum dos formatos acima, retorna a mensagem padrão
    if (mensagem) {
      return mensagem
    } else {
      // caso não encontre nenhum dos formatos acima, retorna a mensagem padrão
      return ''
    }
  }

  /**
   * Envia a mensagem para o reportador (Sentry), caso o ambiente seja de produção.
   */
  static enviarMensagem (mensagem: string) {
    if (verificarEhAmbienteDeProducao()) {
      this.reportadorDeErros.reportarMensagem(mensagem)
    } else {
      // deve ser o único console.log do projeto, executando APENAS em ambiente de desenvolvimento
      // eslint-disable-next-line no-console
      console.log(mensagem)
    }
  }

  /**
   * Envia o erro para o reportador (Sentry), caso o ambiente seja de produção.
   */
  private static enviarErro (erro: Error, tags?: Record<string, any>) {
    if (verificarEhAmbienteDeProducao()) {
      this.reportadorDeErros.reportarErro(erro, tags)
    } else {
      // deve ser o único console.error do projeto, executando APENAS em ambiente de desenvolvimento
      // eslint-disable-next-line no-console
      console.error(erro)
    }
  }

  static capturarErroDandoFeedback (erro: Error, mostrarFeedbackParaUsuario: (mensagemDoErro?: string) => void, tags?: Record<string, any>) {
    if (this.verificarSeEhErroDoUsuario(erro)) {
      // Se o erro for do usuário, devemos informá-lo.
      const mensagemDoErro = this.extrairMensagemDoErro(erro as ErroDoServidorComBody | ErroDoServidorComData)
      mostrarFeedbackParaUsuario(mensagemDoErro)
    } else {
      // Se não for do usuário, devemos informar o desenvolvedor.
      this.filtrarErroParaDiagnostico(erro, tags)
    }
  }

  private static verificarSeEhErroConhecido (erro: Error) {
    return (
      erro instanceof ErroNavegadorNaoSuportaNotificacoes ||
      erro instanceof ErroUsuarioNaoPermitiuNotificacoes ||
      erro instanceof ErroNavegadorNaoSuportaGeolocalizacao
    )
  }

  private static filtrarErroParaDiagnostico (erro: Error, tags?: Record<string, any>) {
    const ehErroConhecido = this.verificarSeEhErroConhecido(erro)

    if (!ehErroConhecido) {
      this.enviarErro(erro, tags)
    }
  }
}

/**
 * Exemplo de uso
 */
/**
 * try {
 *   throw new EntradaInvalidaDoUsuarioErro("Entrada inválida.");
 * } catch (erro) {
 *   this.$filtradorDeErros.capturarErro(erro as Error);
 * }
 */

export const FiltradorDeErrosPlugin = {
  install (Vue: VueConstructor) {
    Vue.prototype.$filtradorDeErros = FiltradorDeErros
  }
}
