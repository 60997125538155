import { rolarParaOElemento } from "src/utils/outro/rolarParaOElemento"

export function useValidationsMixin ({deepCheck = false}) {
  function getValidators () {
    function getValidators (component) {
      return component.$children.filter(i => i.$options.name === 'Validate')
    }

    let validators = getValidators(this)

    if (deepCheck) {
      // todo, actually here we are only checking for 2 deep, update this if you need more levels
      this.$children.forEach(child => {
        validators = [...validators, ...getValidators(child)]
      })
    }
    return validators
  }

  return {
    methods: {
      isAllValid () {
        // if deep is true we will check all children of children
        const validators = getValidators.bind(this)()

        return validators.length === validators.filter(i => i.valid).length
      },
      displayAllErrors () {
        getValidators.bind(this)().forEach(i => {
          i.setTouch(true)
        })
      },
      focarTabComErro () {
        const validadores = getValidators.bind(this)()
        const invalidos = validadores.filter(validador => !validador.valid)

        for (const validador of invalidos) {
          const tab = validador.$el.getAttribute('tab')
          if (tab != null) {
            this.selectTab(null, Number(tab))
            break
          }
        }
      },
      rolarPrimeiroCampoInvalido () {
        const validadores = getValidators.bind(this)()
        const invalidos = validadores.filter(validador => !validador.valid);

        if (invalidos.length > 0) {
          rolarParaOElemento(invalidos[0].$el, { block: 'center', behavior: 'smooth' });
        }
      }
    }
  }
}
