/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store, Commit } from 'vuex'
import { Line, CategoriaDeLotesDaFolha } from '@/tipos'
import {
  DadosAninhadosParaCarregamento,
  DatasLimitesRequisicao,
  ICarregadoraDeLotesFolha,
  IStateFolha,
  ParametrosDeRequisicaoAninhada,
  PayloadLoteDeCarregamento
} from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/dominio/folha.interface'
import { montarItemsDaListaEmBlocos, formatarDataParaEnvio } from '@/utils/formatadoras/formatadoras'
import moment from 'moment'
import { FiltradorDeErros } from '@/erros'
import { IFolhaRepositorio, SubRotaFolhaParcial } from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.repositorio.interface'
import { IRequestFolhaParcialSubRotaBase, IRequestLoteDeJustificativasDasLinhas } from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.respositorio.request'
import { FolhaRepositorio } from '../repositorio/folha.repositorio'
import { ServicoAbstrato } from '@/typescript/servicos/servicoAbstrato/servicoAbstrato.servico'

const criarControladorDeCancelamentoDeRequisicao = () => {
  return new AbortController()
}

type FuncaoMapeadora = {
  djs: (linhas: Line[]) => { djs: string[] };
  pts: (linhas: Line[]) => void;
  tms: (linhas: Line[]) => void;
  justifies: (linhas: Line[]) => void;
  whbs: (linhas: Line[]) => void;
  approvals: (linhas: Line[]) => void;
};

/**
 * Classe responsável por carregar lotes de dados da folha. São dados necessarios para exibição de informações das linhas.
 * São eles: Apontamentos, aprovações, marcações, banco de horas e justificativas.
 * São carregados após o carregamento da API de linhas que não vem com todas essas informações a fim de carregar parcialmente.
 * @implements {ICarregadoraDeLotesFolha}
 */
export class CarregadoraDeLotesFolha implements ICarregadoraDeLotesFolha {
  repositorioFolha: IFolhaRepositorio;
  commit: Commit;
  state: { lines: Line[], requisicoes: any[] };
  categoriaSendoCarregada: CategoriaDeLotesDaFolha;
  dadosAninhados: DadosAninhadosParaCarregamento[];
  quantidadeDeItensNoLote: number;
  dataInicial: string;
  dataFinal: string;
  mergearMarcacoes: boolean;

  /**
 * @param {IFolhaRepositorio} repositorioFolha - O repositório de folha.
 * @param {Commit} commit - A função de commit para mutações de estado.
 * @param {{ lines: Line[], requisicoes: any[] }} state - O estado atual contendo linhas e requisições.
 * @param {CategoriaDeLotesDaFolha} categoriaSendoCarregada - A categoria de lotes da folha a ser carregada.
 * @param {DadosAninhadosParaCarregamento[]} dadosAninhados - Dados aninhados para carregamento.
 * @param {number} quantidadeDeItensNoLote - A quantidade de itens por lote.
 * @param {string} dataInicial - A data inicial do período de carregamento.
 * @param {string} dataFinal - A data final do período de carregamento.
 * @param {boolean} [mergearMarcacoes=false] - Se deve mesclar marcações.
 */
  constructor (
    repositorioFolha: IFolhaRepositorio,
    commit: Commit,
    state: { lines: Line[], requisicoes: any[] },
    categoriaSendoCarregada: CategoriaDeLotesDaFolha,
    dadosAninhados: DadosAninhadosParaCarregamento[],
    quantidadeDeItensNoLote: number,
    dataInicial: string,
    dataFinal: string,
    mergearMarcacoes = false
  ) {
    this.repositorioFolha = repositorioFolha
    this.commit = commit
    this.state = state
    this.categoriaSendoCarregada = categoriaSendoCarregada
    this.dadosAninhados = dadosAninhados || []
    this.quantidadeDeItensNoLote = quantidadeDeItensNoLote
    this.dataInicial = dataInicial
    this.dataFinal = dataFinal
    this.mergearMarcacoes = mergearMarcacoes
  }

  /**
 * Inicia o processo de carregamento dos lotes executando todos os processos necessários para o carregamento em lotes
 * @returns {Promise<void>}
 */
  async carregar () {
    this.iniciarEstadoDeCarregamento()
    const listaDeIdsDosEmpregados: string[] = CarregadoraDeLotesFolha.obterIdsUnicosDeEmpregados(this.state.lines)
    const parametrosParaRequisitar: DatasLimitesRequisicao = this.formatarParametrosParaRequisicao()
    const lotesDeCarregamento: string[][] = CarregadoraDeLotesFolha.definirLotesDeCarregamento(listaDeIdsDosEmpregados, this.quantidadeDeItensNoLote)
    const payloadPrimeiroLote: PayloadLoteDeCarregamento | DatasLimitesRequisicao = await this.carregarPrimeiroLote(parametrosParaRequisitar, lotesDeCarregamento)
    await this.carregarDemaisLotes(payloadPrimeiroLote, lotesDeCarregamento)
    await Promise.allSettled(this.state.requisicoes)
    this.pararEstadoDeCarregamento()
  }

  /**
 * Registra uma requisição com commit.
 * @param {Record<string, unknown>} payload - O payload da requisição.
 * @param {boolean} mergearMarcacoes - Se deve mesclar marcações.
 * @param {CategoriaDeLotesDaFolha} categoriaACarregar - A categoria de lotes a carregar.
 * @returns {Promise<void>}
 */
  private async registrarRequisicaoCommitando (
    payload: Record<string, unknown>,
    mergearMarcacoes: boolean,
    categoriaACarregar: CategoriaDeLotesDaFolha
  ) {
    const requisicao = this.tentarFazerRequisicao({ load: categoriaACarregar }, payload)
    requisicao.then((resultado) => {
      this.commit('_populateLines', {
        calcLinesMap: categoriaACarregar === 'pts',
        lines: resultado.data.success.lines,
        loaded: categoriaACarregar,
        mergeTms: mergearMarcacoes
      })
    })
    this.commit('adicionarRequisicao', requisicao)
  }

  /**
 * Tenta fazer uma requisição para a rota con
 * @param {Record<string, unknown>} parametros - Os parâmetros da requisição.
 * @param {unknown} payload - O payload da requisição.
 * @returns {Promise<any>}
 */
  private tentarFazerRequisicao (
    parametros: Record<string, unknown>,
    payload: unknown
  ) {
    const controladorDeCancelamentoDeRequisicao = criarControladorDeCancelamentoDeRequisicao()
    this.commit('adicionarControladorDeCancelamentoDeRequisicao', controladorDeCancelamentoDeRequisicao)

    const configuracoesRequisicao = {
      signal: controladorDeCancelamentoDeRequisicao.signal
    }
    const loteParaCarregar = parametros.load as CategoriaDeLotesDaFolha

    if (loteParaCarregar === SubRotaFolhaParcial.Pts) {
      return this.repositorioFolha.obterLoteDeMarcacoesDasLinhas(payload as IRequestFolhaParcialSubRotaBase, configuracoesRequisicao)
    } else if (loteParaCarregar === SubRotaFolhaParcial.Djs) {
      return this.repositorioFolha.obterLoteDeJornadaDiariasDasLinhas(payload as IRequestFolhaParcialSubRotaBase, configuracoesRequisicao)
    } else if (loteParaCarregar === SubRotaFolhaParcial.Whbs) {
      return this.repositorioFolha.obterLoteDeBancoDeHorasDasLinhas(payload as IRequestFolhaParcialSubRotaBase, configuracoesRequisicao)
    } else if (loteParaCarregar === SubRotaFolhaParcial.Approvals) {
      return this.repositorioFolha.obterLoteDeAprovacoesDasLinhas(payload as IRequestFolhaParcialSubRotaBase, configuracoesRequisicao)
    } else if (loteParaCarregar === SubRotaFolhaParcial.Justifies) {
      return this.repositorioFolha.obterLoteDeJustificativasDasLinhas(payload as IRequestLoteDeJustificativasDasLinhas, configuracoesRequisicao)
    } else {
      throw new Error(`Categoria de lotes da folha inválido: ${loteParaCarregar}`)
    }
  }

  /**
   * Obtém os IDs únicos (lista sem repetição) dos empregados das linhas
   * @param {Line[]} linhas - As linhas a serem processadas.
   * @returns {string[]}
   */
  static obterIdsUnicosDeEmpregados (linhas: Line[]): string[] {
    try {
      const empregadosIdsSet = new Set(linhas.map(linha => {
        if (!linha.employee.id) {
          throw new Error('Empregado sem ID')
        }
        return linha.employee.id
      }))
      return [...empregadosIdsSet]
    } catch (error) {
      FiltradorDeErros.capturarErro(error as Error)
      return []
    }
  }

  /**
 * Define os lotes de carregamento, entregando uma matriz em que cada subindice é uma lista cheia de ids que serão usados para carregar
 * @param {string[]} listaDeIds - A lista de IDs.
 * @param {number} quantidadeDeItensNoLote - A quantidade de itens por lote.
 * @returns {string[][]}
 */
  static definirLotesDeCarregamento (listaDeIds: string[], quantidadeDeItensNoLote: number): string[][] {
    return quantidadeDeItensNoLote ? montarItemsDaListaEmBlocos(listaDeIds, quantidadeDeItensNoLote) : [listaDeIds]
  }

  /**
 * Filtra as linhas dos empregados inclusos na lista de IDs.
 * @param {Line[]} linhas - As linhas a serem filtradas.
 * @param {string[]} listaDeIdsEmpregados - A lista de IDs dos empregados.
 * @returns {Line[]}
 */
  static filtrarLinhasDosEmpregadosInclusosNaListaDeIds (linhas: Line[], listaDeIdsEmpregados: string[]): Line[] {
    return linhas.filter(linha => linha.id && listaDeIdsEmpregados.includes(linha.employee.id))
  }

  /**
 * Carrega o primeiro lote.
 * @param {DatasLimitesRequisicao} parametrosRequisicao - Os parâmetros da requisição.
 * @param {string[][]} loteDeIds - O lote de IDs.
 * @returns {Promise<PayloadLoteDeCarregamento | DatasLimitesRequisicao>}
 */
  private async carregarPrimeiroLote (
    parametrosRequisicao: DatasLimitesRequisicao,
    loteDeIds: string[][]
  ): Promise<PayloadLoteDeCarregamento | DatasLimitesRequisicao> {
    const {
      linhasDoLote,
      payloadDoLote
    } = await this.carregarLote(parametrosRequisicao, this.obterListaDeIdsPrimeiroLote(loteDeIds))
    await this.carregarLoteAninhado(this.dadosAninhados, payloadDoLote, linhasDoLote)
    return payloadDoLote
  }

  /**
   * Carrega os demais lotes.
   * @param {DatasLimitesRequisicao} parametrosRequisicao - Os parâmetros da requisição.
   * @param {string[][]} loteDeIds - O lote de IDs.
   * @returns {Promise<void>}
   */
  private async carregarDemaisLotes (
    parametrosRequisicao: DatasLimitesRequisicao,
    loteDeIds: string[][]
  ): Promise<void> {
    const requisicoes = this.obterMatrizDeIdsDemaisLotes(loteDeIds).map(loteParticionado => {
      return this.carregarLote(parametrosRequisicao, loteParticionado)
    })

    const resultados = await Promise.allSettled(requisicoes)

    resultados
      .filter(resultadoDoLote => resultadoDoLote.status === 'fulfilled')
      .map(resultadosDoLote => {
        if ('value' in resultadosDoLote && resultadosDoLote.value) {
          const valor = resultadosDoLote.value as { linhasDoLote: Line[]; payloadDoLote: PayloadLoteDeCarregamento; }
          const {
            linhasDoLote,
            payloadDoLote
          } = valor
          return this.carregarLoteAninhado(this.dadosAninhados, payloadDoLote, linhasDoLote)
        }
        return null
      })
      .filter(Boolean)
  }

  private async carregarLote (
    parametrosRequisicao: DatasLimitesRequisicao,
    loteDeIds: string[]
  ) {
    const linhasDoLote = CarregadoraDeLotesFolha.filtrarLinhasDosEmpregadosInclusosNaListaDeIds(this.state.lines, loteDeIds)
    const payloadDoLote = {
      ...parametrosRequisicao,
      employees: loteDeIds,
      lines: linhasDoLote.map(linha => linha.id) as string[],
      infoToRefetch: null
    }

    await this.registrarRequisicaoCommitando(payloadDoLote, this.mergearMarcacoes, this.categoriaSendoCarregada)

    return {
      linhasDoLote,
      payloadDoLote
    }
  }

  private async carregarLoteAninhado (
    dadosAninhados: DadosAninhadosParaCarregamento[],
    parametrosDeRequisicaoAninhada: ParametrosDeRequisicaoAninhada,
    linhasDoLote: Line[]
  ) {
    dadosAninhados.map(dados => {
      const dicionarioComFuncaoDeCarregamento = this.obterFuncaoMapeadoraDeDadosAninhadosPorCategoria()
      const categoria = this.categoriaSendoCarregada as keyof FuncaoMapeadora
      const categoriaAninhada = dados.load
      const mergearMarcacoesAinhados = dados.mergeTms

      const parametrosDeRequisicoesAninhadas = {
        ...parametrosDeRequisicaoAninhada,
        ...dicionarioComFuncaoDeCarregamento[categoria](linhasDoLote)
      }

      return this.registrarRequisicaoCommitando(parametrosDeRequisicoesAninhadas, mergearMarcacoesAinhados, categoriaAninhada)
    })
  }

  private definirCarregamentosDasInformacoesDasLinhas (estadoDeCarregamento: boolean) {
    const estadosDeCarregamentoPorCategoria = { [this.categoriaSendoCarregada]: estadoDeCarregamento }
    this.dadosAninhados.forEach(() => { estadosDeCarregamentoPorCategoria[this.categoriaSendoCarregada] = estadoDeCarregamento })
    return estadosDeCarregamentoPorCategoria
  }

  private formatarParametrosParaRequisicao (): DatasLimitesRequisicao {
    return {
      startDate: formatarDataParaEnvio(moment(this.dataInicial)),
      endDate: formatarDataParaEnvio(moment(this.dataFinal))
    }
  }

  private filtrarLinhasQuePossuemJornadas (linhas: Line[]) {
    return linhas.map(linha => linha.dayJourney?.id).filter(Boolean)
  }

  private iniciarEstadoDeCarregamento () {
    this.commit('loading', this.definirCarregamentosDasInformacoesDasLinhas(true))
  }

  private pararEstadoDeCarregamento () {
    this.commit('loading', this.definirCarregamentosDasInformacoesDasLinhas(false))
  }

  private obterFuncaoMapeadoraDeDadosAninhadosPorCategoria (): FuncaoMapeadora {
    return {
      djs: (linhas: Line[]) => {
        return { djs: this.filtrarLinhasQuePossuemJornadas(linhas) }
      },
      pts: (linhas: Line[]) => {
        return { pts: linhas }
      },
      tms: (linhas: Line[]) => {
        return { tms: linhas }
      },
      justifies: (linhas: Line[]) => {
        return { justifies: linhas }
      },
      whbs: (linhas: Line[]) => {
        return { whbs: linhas }
      },
      approvals: (linhas: Line[]) => {
        return { approvals: linhas }
      }
    }
  }

  private obterListaDeIdsPrimeiroLote (matrizDeIds: string[][]): string[] {
    return matrizDeIds[0]
  }

  private obterMatrizDeIdsDemaisLotes (matrizDeIds: string[][]): string[][] {
    return matrizDeIds.slice(1, matrizDeIds.length)
  }
}

const criarcontroladorDeCancelamento = () => {
  const controller = new AbortController()
  return controller
}

export class FolhaServico extends ServicoAbstrato<FolhaRepositorio, IStateFolha> {
  store!: Store<IStateFolha>

  // eslint-disable-next-line no-useless-constructor
  constructor (repositorio: FolhaRepositorio, store: Store<IStateFolha>) {
    super(repositorio, store)
  }

  carregarLoteDaFolha (
    categoriaSendoCarregada: CategoriaDeLotesDaFolha,
    dadosAninhados: DadosAninhadosParaCarregamento[],
    quantidadeDeItensNoLote: number,
    dataInicial: string,
    dataFinal: string,
    mergearMarcacoes: boolean
  ): Promise<void> {
    return new CarregadoraDeLotesFolha(
      this.repositorio,
      this.store.commit,
      this.store.state,
      categoriaSendoCarregada,
      dadosAninhados,
      quantidadeDeItensNoLote,
      dataInicial,
      dataFinal,
      mergearMarcacoes
    ).carregar()
  }

  async carregarFolha (
    dataInicial: string,
    dataFinal: string,
    pontosPorLocalDeTrabalho: boolean,
    locaisDeTrabalho: string[],
    empregados: string[],
    gruposDeUsuarios: string[]
  ) {
    this.store.commit('cancelarTodasAsRequisicoesPendentes')
    const controller = criarcontroladorDeCancelamento()
    this.store.commit('adicionarControladorDeCancelamentoDeRequisicao', controller)
    this.store.commit('resetLines')

    const payloadGenerico = {
      ptsByCompany: pontosPorLocalDeTrabalho,
      startDate: dataInicial,
      endDate: dataFinal
    }

    const configuracoesRequisicao = {
      signal: controller.signal
    }

    const requisicao = () => {
      if (locaisDeTrabalho && locaisDeTrabalho.length) {
        return this.repositorio.obterLoteDeLinhasPorLocalDeTrabalho(
          { ...payloadGenerico, companies: locaisDeTrabalho },
          configuracoesRequisicao
        )
      } else if (empregados && empregados.length) {
        return this.repositorio.obterLoteDeLinhasPorEmpregado(
          { ...payloadGenerico, employees: empregados },
          configuracoesRequisicao
        )
      } else if (gruposDeUsuarios && gruposDeUsuarios.length) {
        return this.repositorio.obterLoteDeLinhasPorGrupo(
          { ...payloadGenerico, userGroups: gruposDeUsuarios },
          configuracoesRequisicao
        )
      }
    }

    const resultado = await requisicao()
    this.store.commit('state', resultado?.data.success)
    this.store.commit('loading', { lines: false })
    return this.store.dispatch('populateLines', { startDate: dataInicial, endDate: dataFinal })
  }
}
