var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown", class: { aberto: _vm.dropdownVisivel } },
    [
      _c(
        "div",
        {
          ref: "trigger",
          staticClass: "dropdown__trigger",
          attrs: { "aria-expanded": _vm.value, role: "button" },
          on: { click: _vm.alterarVisibilidade },
        },
        [_vm._t("default")],
        2
      ),
      _vm.dropdownVisivel
        ? _c(
            "div",
            {
              ref: "dropdown",
              staticClass: "dropdown__conteudo",
              class: [`dropdown__conteudo--${_vm.posicao}`],
              attrs: { role: "menu" },
            },
            [_vm._t("dropdown")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }