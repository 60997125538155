import formbricks from "@formbricks/js"
import { DadosUsuarioIdentificator } from 'src/typescript/utils'

export interface FormbricksInterface {
  registrarEmailUsuario: (emailUsuario: string) => void
  registrarAtributo: (nomeAtributo: string, valorAtributo: unknown) => void
  registrarGatilho: (nomeGatilho: string) => void
  registrarMudancaDeRota (): void
  deslogarFormbricks: () => void
}

export default class Formbricks implements FormbricksInterface {
  private definirAmbiente (): string {
    const ambienteNode = process.env.NODE_ENV
    const ehaAmbientek8s = process.env.VUE_APP_K8s
    return ehaAmbientek8s ? 'development' : ambienteNode as string
  }

  public verificarSeUsuarioPermitido (emailUsuario: string): boolean {
    const usuarioEhLar21 = emailUsuario.includes('lar21')
    const usuararioEhPontotel = emailUsuario.includes('@pontotel')
    if (usuarioEhLar21 || usuararioEhPontotel) return false
    return true
  }

  public registrarEmailUsuario (emailUsuario: string): void {
    formbricks.setEmail(emailUsuario)
  }

  public registrarAtributo (nomeAtributo: string, valorAtributo: unknown): void {
    formbricks.setAttribute(nomeAtributo, valorAtributo)
  }

  public registrarGatilho (nomeGatilho: string): void{
    formbricks.track(nomeGatilho)
  }

  public registrarMudancaDeRota (): void {
    formbricks.registerRouteChange()
  }

  public deslogarFormbricks (): void {
    formbricks.logout()
  }

  public iniciarFormbricks (dadosDoUsuarioFormbricks: DadosUsuarioIdentificator): void {
    if (typeof window !== 'undefined') {
      const { idUsuario, ...atributos } = dadosDoUsuarioFormbricks
      formbricks.init({
        environmentId: process.env.VUE_APP_FORMBRICKS_KEY as string,
        apiHost: process.env.VUE_APP_FORMBRICKS_URL as string,
        userId: idUsuario,
        attributes: {
          email: atributos.emailUsuario,
          nomeUsuario: atributos.nomeUsuario,
          papelUsuario: atributos.papelUsuario,
          idEmpresaUsuario: atributos.idEmpresaUsuario,
          nomeEmpresaUsuario: atributos.nomeEmpresaUsuario,
          tamanhoEmpresaUsuario: atributos.tamanhoEmpresaUsuario,
          canal: atributos.canal,
          dataCriacaoUsuario: atributos.dataCriacaoUsuario,
          dataCriacaoEmpresa: atributos.dataCriacaoEmpresa,
          semanasUsoUsuario: String(atributos.semanasUsoUsuario),
          mesesUsoUsuario: String(atributos.mesesUsoUsuario),
          semanasUsoEmpresa: String(atributos.semanasUsoEmpresa),
          mesesUsoEmpresa: String(atributos.mesesUsoEmpresa)
        }
      })
    }

  }

  public verificarUsuarioEmSessaoAtiva (): boolean {
    const dadosFormbricks = localStorage.getItem('formbricks-js')
    if (!dadosFormbricks) {
      return false
    }
    try {
      const dadosParseados = JSON.parse(dadosFormbricks)
      return dadosParseados && dadosParseados?.status !== 'error'
    } catch {
      return false
    }
  }

  public verificarFormbricksEstaAtivo (): boolean {
    return Boolean(formbricks)
  }
}
