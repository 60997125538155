
/**
 * Classe para manipulação de dados no LocalStorage com prefixo específico.
 *
 * Esta classe permite armazenar, recuperar e remover itens do LocalStorage usando
 * um prefixo gerado a partir de identificadores específicos para a empresa e o usuário.
 * O prefixo é uma combinação do identificador da empresa (idCompMan) e do identificador
 * do usuário (idUsuario), seguido pela chave especificada. Isso garante que os dados
 * armazenados no LocalStorage sejam exclusivos para cada combinação de empresa e usuário,
 * evitando colisões entre diferentes usuários e empresas.
 */
class ServicoLocalStorage {
  /**
   * Identificador da empresa.
   * @type {string}
   */
  idCompMan: string;

  /**
   * Identificador do usuário.
   * @type {string}
   */
  idUsuario: string;

  /**
   * Cria uma nova instância de ServicoLocalStorage.
   */
  constructor () {
    this.idCompMan = ''
    this.idUsuario = ''
  }

  /**
   * Define os identificadores da empresa e do usuário.
   * @param {string} idCompMan - Identificador da empresa.
   * @param {string} idUsuario - Identificador do usuário.
   */
  definirIdentificador (idCompMan: string, idUsuario: string): void {
    this.idCompMan = idCompMan
    this.idUsuario = idUsuario
  }

  /**
   * Verifica se os identificadores estão definidos.
   * @throws {Error} Lança um erro se os identificadores não estiverem definidos.
   */
  verificarIdentificador (): void {
    if (!this.idCompMan || !this.idUsuario) {
      throw new Error('Identificador não definido')
    }
  }

  /**
   * Gera o prefixo completo para a chave com base nos identificadores.
   *
   * @param {string} chave - A chave a ser utilizada.
   * @returns {string} O prefixo completo.
   */
  obterPrefixo (chave: string): string {
    this.verificarIdentificador()
    return `${this.idCompMan}-${this.idUsuario}-${chave}`
  }

  /**
   * Salva um item no LocalStorage com a chave especificada.
   * @param {string} chave - A chave a ser utilizada.
   * @param {unknown} valor - O valor a ser salvo.
   */
  salvarItem (chave: string, valor: unknown): void {
    const chaveCompleta = this.obterPrefixo(chave)
    localStorage.setItem(chaveCompleta, JSON.stringify(valor))
  }

  /**
   * Obtém um item do LocalStorage com a chave especificada.
   * @param {string} chave - A chave a ser utilizada.
   * @returns {unknown} O valor obtido ou null se não existir.
   */
  obterItem (chave: string): unknown | null {
    const chaveCompleta = this.obterPrefixo(chave)
    const valor = localStorage.getItem(chaveCompleta)
    return valor ? JSON.parse(valor) : null
  }

  /**
   * Remove um item do LocalStorage com a chave especificada.
   * @param {string} chave - A chave a ser utilizada.
   */
  removerItem (chave: string): void {
    const chaveCompleta = this.obterPrefixo(chave)
    localStorage.removeItem(chaveCompleta)
  }

  /**
   * Remove todos os dados do LocalStorage, exceto os da empresa corrente.
   */
  removerDadosExcetoDaEmpresaCorrente (): void {
    this.verificarIdentificador()
    Object.keys(localStorage)
      .filter(chave => 
        !chave.startsWith(`${this.idCompMan}`) && 
        !chave.startsWith('relatoriosPendentes-'))
      .forEach(chave => localStorage.removeItem(chave))
  }
}

const servicoLocalStorage = new ServicoLocalStorage()

export default servicoLocalStorage
