<template lang="pug">
  .dropdown(:class="{ 'aberto': dropdownVisivel }")
    .dropdown__trigger(@click="alterarVisibilidade" ref="trigger" :aria-expanded="value" role="button")
      slot
    .dropdown__conteudo(
      v-if="dropdownVisivel"
      ref="dropdown"
      :class="[`dropdown__conteudo--${posicao}`]"
      role="menu"
    )
      slot(name="dropdown")
</template>

<script>
export default {
  data() {
    return {
      dropdownVisivel: false
    }
  },
  props: {
    posicao: {
      type: String,
      default: 'down',
      validator: (posicao) => ['left', 'right', 'up', 'down', 'down-right'].includes(posicao)
    },
    value: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    alterarVisibilidade(estado) {
      if (typeof estado === 'boolean') {
        this.dropdownVisivel = estado;
      } else {
        this.dropdownVisivel = !this.dropdownVisivel;
      }

      if (this.value !== undefined) {
        this.$emit('input', this.dropdownVisivel);
      }

      if (this.dropdownVisivel) {
        this.atualizarPosicao();
      }
    },
    fecharDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.alterarVisibilidade(false);
      }
    },
    atualizarPosicao() {
      const trigger = this.$refs.trigger;
      const dropdown = this.$refs.dropdown;

      if (!dropdown) return;

      const triggerRect = trigger.getBoundingClientRect();
      const dropdownRect = this.$el.getBoundingClientRect();

      let dropdownTop = 0;
      let dropdownLeft = 0;

      const alinharTopComTrigger = 8;
      const alinharDownRightComTrigger = 154;

      switch (this.posicao) {
        case 'up':
          dropdownTop = triggerRect.top - dropdownRect.top - dropdown.offsetHeight - alinharTopComTrigger;
          dropdownLeft = triggerRect.left - dropdownRect.left;
          break;
        case 'left':
          dropdownTop = triggerRect.top - dropdownRect.top - alinharTopComTrigger;
          dropdownLeft = triggerRect.left - dropdownRect.left - dropdown.offsetWidth;
          break;
        case 'right':
          dropdownTop = triggerRect.top - dropdownRect.top - alinharTopComTrigger;
          dropdownLeft = triggerRect.right - dropdownRect.left;
          break;
        case 'down-right':
          dropdownTop = triggerRect.bottom - dropdownRect.top;
          dropdownLeft = triggerRect.left - dropdownRect.left - alinharDownRightComTrigger;
          break
        default:
          dropdownTop = triggerRect.bottom - dropdownRect.top;
          dropdownLeft = triggerRect.left - dropdownRect.left;
      }

      dropdown.style.top = `${dropdownTop}px`;
      dropdown.style.left = `${dropdownLeft}px`;
    },
  },
  mounted() {
    this.dropdownVisivel = this.value;
    document.addEventListener('click', this.fecharDropdown);
    window.addEventListener('resize', this.atualizarPosicao);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.fecharDropdown);
    window.removeEventListener('resize', this.atualizarPosicao);
  },
  watch: {
    value(newValue) {
      this.dropdownVisivel = newValue;
      if (newValue) {
        this.$nextTick(this.atualizarPosicao);
      }
    }
  }
}
</script>


<style lang="scss" scoped>
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  &__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__conteudo {
    position: absolute;
    color: #333333;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.175);
    z-index: 1000;
    min-width: 170px;
    width: fit-content;
    height: fit-content;
    list-style: none;
    cursor: pointer;
    float: left;
    padding: 5px 0px;
    margin: 2px 0 0;
    font-size: 13px;
    text-align: left;
    animation: slide-down 0.1s ease-out;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    &--up {
      bottom: 100%;
    }

    &--down {
      top: 100%;
    }

    &--left {
      right: 100%;
    }

    &--right {
      left: 100%;
    }
  }
}
</style>