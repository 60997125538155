import { ErroDoUsuarioPontotel } from 'src/tipos/erros/classes'
import i18n from '@/typescript/servicos/i18n/i18n.servico'

export class ErroUsuarioNaoPermitiuNotificacoes extends ErroDoUsuarioPontotel {
  constructor () {
    super(i18n.t('tipos.erros.usuario.recusouNotificacao').toString())
  }
}

export class ErroPeriodoJornadaSobreavisoMaiorQue24Horas extends ErroDoUsuarioPontotel {
  constructor () {
    super(i18n.t('tipos.erros.usuario.erro24horas').toString())
  }
}

export class ErroPeriodoJornadasSobreavisoEstaoEmConflito extends ErroDoUsuarioPontotel {
  constructor () {
    super(i18n.t('tipos.erros.usuario.erroConflito').toString())
  }
}

export class ErroDataDeInicioDoBancoDeHorasObrigatoria extends ErroDoUsuarioPontotel {
  constructor () {
    super(i18n.t('tipos.erros.usuario.dataObrigatoria').toString())
  }
}
