import { IGerenciamentoDeFeriasServico } from './gerenciamentoDeFeriasServico.interface'
import { IGerenciamentoDeFeriasRepositorio } from './gerenciamentoDeFeriasRepositorio.interface'
import {
  IRespostaSolicitarFerias,
  IRespostaHistoricoDeSolicitacoesPaginada,
} from './gerenciamentoDeFerias.interface'
import { EmpregadoId, MensagemGenerica } from '@/tipos'

export class GerenciamentoDeFeriasServico
  implements IGerenciamentoDeFeriasServico
{
  gerenciamentoDeFeriasRepositorio: IGerenciamentoDeFeriasRepositorio
  constructor(
    gerenciamentoDeFeriasRepositorio: IGerenciamentoDeFeriasRepositorio
  ) {
    this.gerenciamentoDeFeriasRepositorio = gerenciamentoDeFeriasRepositorio
  }
  async solicitarFerias(
    idEmpregado: EmpregadoId,
    inicio: Date,
    fim: Date
  ): Promise<IRespostaSolicitarFerias> {
    return this.gerenciamentoDeFeriasRepositorio.solicitarFerias(
      idEmpregado,
      inicio,
      fim
    )
  }

  async deletarFerias(
    idEmpregado: EmpregadoId,
    inicio: Date,
    fim: Date
  ): Promise<MensagemGenerica> {
    return this.gerenciamentoDeFeriasRepositorio.deletarFerias(
      idEmpregado,
      inicio,
      fim
    )
  }

  async obterHistoricoDeSolicitacoesPaginada(
    idEmpregado: EmpregadoId,
    pagina: number,
    porPagina: number
  ): Promise<IRespostaHistoricoDeSolicitacoesPaginada> {
    return this.gerenciamentoDeFeriasRepositorio.obterHistoricoDeSolicitacoesPaginada(
      idEmpregado,
      pagina,
      porPagina
    )
  }

  async obterHistoricoDeFeriasAgendadasPaginada(
    idEmpregado: EmpregadoId,
    pagina: number,
    porPagina: number
  ): Promise<IRespostaHistoricoDeSolicitacoesPaginada> {
    return this.gerenciamentoDeFeriasRepositorio.obterHistoricoDeSolicitacoesPaginada(
      idEmpregado,
      pagina,
      porPagina
    )
  }
}
