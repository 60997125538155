<template>
<section v-if="!estaSolicitandoFerias" >
  <div class="wrapper-ferias">
    <Validate class="select-form" useState="gestaoDeFerias/categorias" :required="true">
      <PSelect
        name="categorias"
        labelItem="name"
        :allowEmpty="false"
        :title="$t('solicitacao.GestaoDeSolicitacoes.tituloFiltrarPor')"
      />
    </Validate>
    <PMultiSelect
      ref="itemSelector"
      labelItem="name"
      :error="mensagemDeErroDoSeletor"
      :title="categorias.selected.name"
      :useState="categorias.selected.state"
      :useCommon="categorias.selected.common"
      :disabled="!categorias.selected"
      :withMinimalInformation="true"
      :usaPaginacao="categorias.selected.id === 'employees'"
    />
    <Validate class="select-form" v-model="dataInicial" :required="true">
      <PDatePicker
        name="dataInicial"
        :label="$t('gerenciamentoDeFerias.meusLiderados.labelInicio')"
        format="DD/MM/YY"
      />
    </Validate>
    <Validate class="select-form" v-model="dataFinal" :required="true">
      <PDatePicker
        name="endDate"
        :label="$t('gerenciamentoDeFerias.meusLiderados.labelFim')"
        format="DD/MM/YY"
        :minDate="dataInicial"
      />
    </Validate>
  </div>
  <div class="container-busca">
    <div class="container-botoes">
      <PButton color="success" size="md" @click="buscarFerias">
        {{ $t('gerenciamentoDeFerias.meusLiderados.buscar') }}
      </PButton>
      <PButton color="warning" size="md" @click="solicitarFerias">
        {{ $t('gerenciamentoDeFerias.meusLiderados.agendarFerias') }}
      </PButton>
    </div>
  </div>
</section>
<SolicitarMeusLiderados v-else @buscar="buscarFerias" @cancelar="cancelar"/>
</template>

<script>
import SolicitarMeusLiderados from '../solicitacoes/SolicitarMeusLiderados'
import { useValidationsMixin } from 'src/common/validations'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'FeriasAgendadasMeusLiderados',
  data() {
    return {
      mensagemDeErroDoSeletor: '',
      estaSolicitandoFerias: false
    }
  },
  mounted() {
    const { categorias, agendadasMeusLideradosSelecionadas } = this.$store.state.gestaoDeFerias

    // Limpa todos os inputs quando muda de aba
    for (const categoria of categorias.loaded) {
      this.$store.commit(`gestaoDeFerias/${categoria.id}`, {
        loaded: [],
        selected: []
      })
    }

    // Caso haja inputs que já tenham sido selecionados, seta para eles, se não, mantém os valores padrão
    if (agendadasMeusLideradosSelecionadas.categoria) {
      this.$store.commit('gestaoDeFerias/dataInicial', agendadasMeusLideradosSelecionadas.dataInicial)
      this.$store.commit('gestaoDeFerias/dataFinal', agendadasMeusLideradosSelecionadas.dataFinal)
      this.$store.commit('gestaoDeFerias/categorias', {
        loaded: categorias.loaded,
        selected: categorias.loaded.find(categoria => categoria.id === agendadasMeusLideradosSelecionadas.categoria)
      })
      this.$store.commit(`gestaoDeFerias/${agendadasMeusLideradosSelecionadas.categoria}`, {
        loaded: agendadasMeusLideradosSelecionadas.loaded,
        selected: agendadasMeusLideradosSelecionadas.selected
      })
    } else {
      this.$store.commit('gestaoDeFerias/dataInicial', moment().startOf('month').toDate())
      this.$store.commit('gestaoDeFerias/dataFinal', moment().endOf('month').toDate())
      this.$store.commit('gestaoDeFerias/categorias', {
        loaded: categorias.loaded,
        selected: categorias.loaded[0]
      })
      this.$store.commit(`gestaoDeFerias/${categorias.loaded[0].id}`, {
        loaded: [],
        selected: []
      })
    }

    // Limpa a tela quando montada
    this.$emit('limpar')
  },
  computed: {
    ...mapState('gestaoDeFerias', ['companies', 'roleGroups', 'employees', 'userGroups']),
    dataInicial: {
      get() {
        return this.$store.state.gestaoDeFerias.dataInicial
      },
      set(value) {
        this.$store.commit('gestaoDeFerias/dataInicial', value)
      }
    },
    dataFinal: {
      get() {
        return this.$store.state.gestaoDeFerias.dataFinal
      },
      set(value) {
        this.$store.commit('gestaoDeFerias/dataFinal', value)
      }
    },

    categorias: {
      get() {
        return this.$store.state.gestaoDeFerias.categorias
      },
      set(value) {
        this.$store.commit('gestaoDeFerias/categorias', {
          loaded: this.$store.state.gestaoDeFerias.categorias.loaded,
          selected: value
        })
      }
    }
  },
  methods: {
    existeErrosDeInput() {
      let existeErro = false
      if (!this.isAllValid()) {
        this.displayAllErrors()
        existeErro = true
      }

      const categoriaSelecionada = this.categorias.selected

      if(!categoriaSelecionada) {
        this.mensagemDeErroDoSeletor = this.$t('comuns.genericos.singular.campoObrigatorio')
        existeErro = true
      } else {
        const ids = this[categoriaSelecionada.id]?.selected.map(item => item.id)

        if (!ids?.length) {
          this.mensagemDeErroDoSeletor = this.$t('comuns.genericos.singular.campoObrigatorio')
          existeErro = true
        }
      }

      return existeErro
    },
    async buscarFerias() {
      if (this.existeErrosDeInput()) {
        return
      }

      const categoriaSelecionada = this.categorias.selected
      const stateSelecionado = this[categoriaSelecionada.id]

      this.$store.commit('gestaoDeFerias/agendadasMeusLideradosSelecionadas', {
        ...stateSelecionado,
        categoria: categoriaSelecionada.id,
        dataInicial: this.$store.state.gestaoDeFerias.dataInicial,
        dataFinal: this.$store.state.gestaoDeFerias.dataFinal
      })

      this.$emit('buscarDados')
    },
    solicitarFerias() {
      this.estaSolicitandoFerias = true
    },
    cancelar() {
      this.estaSolicitandoFerias = false
    }
  },
  components: {
    SolicitarMeusLiderados
  },
  watch: {
    companies: {
      deep: true,
      handler(itens) {
        if (itens.selected.length) {
          this.mensagemDeErroDoSeletor = ''
        }
      }
    },
    employees: {
      deep: true,
      handler(itens) {
        if (itens.selected.length) {
          this.mensagemDeErroDoSeletor = ''
        }
      }
    },
    userGroups: {
      deep: true,
      handler(itens) {
        if (itens.selected.length) {
          this.mensagemDeErroDoSeletor = ''
        }
      }
    },
    roleGroups: {
      deep: true,
      handler(itens) {
        if (itens.selected.length) {
          this.mensagemDeErroDoSeletor = ''
        }
      }
    },
  },
  mixins: [useValidationsMixin({})],
}
</script>

<style lang="scss" scoped>
.wrapper-ferias {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  gap: 1.25rem;
}

.container-busca {
  display: flex;
  justify-content: space-between;

  .status-select {
    width: 25rem;
  }

  .container-botoes {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

</style>
