<template lang="pug">
div.p-textfield(:class="{'icon-slot': $slots.icon && $slots.icon.length}")

  input.p-textfield__input(
    v-model="state",
    ref="input",
    :disabled="disabled || loading",
    :type="type"
    :autocomplete="autocomplete"
    v-on="escutadoresDeEventosLocais",
    spellcheck="false"
    v-bind="atributosLocais",
    :style="hideLabel ? {paddingTop: '0'} : {}"
    :class="{error: error, naoMostrarPlaceholder: !mostrarPlaceholderQuandoForaDeFoco, sinalizarErroSemMensagem: sinalizarErroSemMensagem, [estilo]: estilo}",
  )

  label.p-textfield__title(for="textfield" :class="{error: error}")
    span.p-textfield__title-text(v-show="!hideLabel" :class="{required: required, [estilo]: estilo}") {{ label }}
      slot(name="logoIcon", v-if="mensagemDeInformacao")

  span.p-textfield__icon--span(v-if="$slots.icon && $slots.icon.length")
    slot(name="icon")

  span.p-textfield__icon--span-copy(v-if="copyicon", @click="copyToClipBoard")
    tooltip(
      :text="$t('compartilhados.inputs.PTextField.copiado')",
      trigger="manual",
      v-model="showTooltip"
    )
      img.p-textfield__icon(src="../../../static/images/copy_content.svg")

  ErrorMsg(v-if="error || !mensagemDeInformacao" :error="error")
  InfoMsg(v-if="mensagemDeInformacao" :info="mensagemDeInformacao")

</template>

<script>
import { Tooltip } from 'uiv'
import { handlerUseStateVmodel } from '@/common/mixins/handlerUseStateVmodel'
import { elementFocusable } from '@/common/mixins/elementFocusable'
import ErrorMsg from '@/common/inputs/ErrorMsg.vue'
import InfoMsg from '@/common/inputs/InfoMsg.vue'

export default {
  name: 'PTextField',
  mixins: [handlerUseStateVmodel(), elementFocusable('input')],
  components: {
    Tooltip, ErrorMsg, InfoMsg
  },
  data () {
    return {
      escutadoresDeEventosLocais: undefined,
      atributosLocais: undefined,
      showTooltip: false
    }
  },
  props: {
    label: { type: String, required: true },
    hideLabel: Boolean,
    disabled: Boolean,
    loading: Boolean,
    error: String,
    sinalizarErroSemMensagem: { type: Boolean, required: false, default: false },
    copyicon: Boolean,
    required: { type: Boolean, 'default': false },
    type: { default: 'text' },
    autocomplete: { default: 'off' },
    parser: { type: String, default: 'number' },
    mostrarPlaceholderQuandoForaDeFoco: { type: Boolean, default: false },
    estilo: { type: String },
    mensagemDeInformacao: { type: String, default: '' }
  },
  created () {
    this.filtrarEscutadoresDeEventos()
    this.configurarMensagemDeAjuda()
    this.filtrarAtributos()
  },
  computed: {
    state: {
      set (val) {
        if (this.type === 'number') {
          if (this.parser === 'number') {
            val = parseInt(val)
          } else if (this.parser === 'float') {
            val = parseFloat(val)
          }
        }
        this.__state = val
      },
      get () {
        return this.__state
      }
    },
    classInput () {
      return {
        'p-textfield__error--input': this.error
      }
    }
  },
  methods: {
    configurarMensagemDeAjuda () {
      const possuiTagDeAjuda = 'placeholder' in this.$attrs
      if (!possuiTagDeAjuda) {
        // O CSS precisa de um marcador para detectar se o campo de entrada possui algum conteúdo. Gambiarra.
        this.$attrs.placeholder = ' '
      }
    },
    filtrarEscutadoresDeEventos () {
      const listaDeChavesDosEventos = Object.keys(this.$listeners)
      const listaDeChavesASeremFiltradas = ['input', 'change']
      const listaDeChavesDosEventosFiltrada = listaDeChavesDosEventos.filter(chave => !listaDeChavesASeremFiltradas.includes(chave))
      const dicionarioComEscutadoresDeEventosFiltrados = listaDeChavesDosEventosFiltrada.reduce((resultado, evento) => {
        resultado[evento] = this.$listeners[evento]
        return resultado
      }, {})

      this.escutadoresDeEventosLocais = dicionarioComEscutadoresDeEventosFiltrados
    },
    filtrarAtributos () {
      const listaDeChavesDosAtributos = Object.keys(this.$attrs)
      const listaDeChavesASeremFiltradas = ['name', 'disabled']
      const listaDeChavesDosAtributosFiltrada = listaDeChavesDosAtributos.filter(chave => !listaDeChavesASeremFiltradas.includes(chave))
      const dicionarioComAtributosFiltrados = listaDeChavesDosAtributosFiltrada.reduce((resultado, atributo) => {
        resultado[atributo] = this.$attrs[atributo]
        return resultado
      }, {})

      this.atributosLocais = dicionarioComAtributosFiltrados
    },
    copyToClipBoard () {
      this.$refs.input.select()
      document.execCommand('copy')

      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }

      this.showTooltip = true
      setTimeout(() => {
        this.showTooltip = false
      }, 1000)
    },
    select () {
      // to outside use, dont remove, we want this component to act like a real input
      this.$refs.input.select()
    }
  }
}
</script>

<style scoped lang="scss">
.p-textfield {
  display: grid;
  box-sizing: border-box;
  position: relative;
  text-align: start;
  line-height: 1;
  width: 100%;

  &.icon-slot {
    grid-template-columns: auto min-content;
  }

  .error-msg {
    grid-row: -1;
    grid-column: 1/4;
  }

  input.p-textfield__input {
    display: block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 5px;
    font-size: 17px;
    font-family: var(--front-font);
    border: none;
    outline: none;
    color: black;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-primary);
    transition: all .3s ease;

    &::-moz-selection {
      /* Code for Firefox */
      color: white;
      background: var(--color-primary);
    }

    &::selection {
      color: white;
      background: var(--color-primary);
    }

    &::placeholder {
      font-size: 17px;
    }

    &:focus {
      border-bottom-color: var(--color-info);
    }

    /* Efeito de subir o texto*/
    &:not(:placeholder-shown)+.p-textfield__title .p-textfield__title-text {
      transform: translateY(0);
      font-size: .75rem;
      color: var(--color-default);
    }

    &:focus+.p-textfield__title .p-textfield__title-text {
      transform: translateY(0);
      font-size: .75rem;
      color: var(--color-primary);
    }

    /* Disabled style */
    &:disabled {
      border-bottom-color: var(--color-default-disabled);
      border-bottom-style: solid;
      color: #6a6a6a;
      background-color: transparent;
    }

    &:disabled+.p-textfield__title {
      color: #6a6a6a;
    }

    &.error {
      border-bottom-color: var(--color-danger);

      :focus+.p-textfield__title .p-textfield__title-text {
        color: var(--color-danger);
      }

      &:focus+.p-textfield--required .p-textfield__title-text::after {
        color: var(--color-danger);
      }
    }

    &.sinalizarErroSemMensagem {
      border-bottom-color: var(--color-danger);
      color: var(--color-danger);
    }

    &.required::after {
      content: " *";
      color: var(--color-danger);
      font-size: .70em;
      position: relative;
      bottom: 3px;
      right: 3px;
    }

    &.novoinfo {
      font-family: 'Hanken Grotesk';

      &:focus,
      &:active {
        border-bottom-color: #79397D;
      }
    }

    /* this disables the default browser arrows in input number */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
      /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type=number] {
      -moz-appearance: textfield;
      /* Firefox */
    }

  }

  label.p-textfield__title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 90%;
    font-family: var(--front-font);
    pointer-events: none;

    span.p-textfield__title-text {
      display: block;
      position: relative;
      transform: translateY(130%);
      transition: all .3s ease-out;

      &.required::after {
        content: " *";
        color: var(--color-danger);
        font-size: .70em;
        position: relative;
        bottom: 3px;
        right: 3px;
      }

      &.novoinfo {
        font-family: "Hanken Grotesk";
      }
    }

    /* Error style */
    &.error {
      color: var(--color-danger);

      &:focus {
        border-bottom-color: var(--color-danger);
      }
    }
  }
}

/* Sankhya style */

// Se a div pai tiver a classe sankhya e o input estiver desabilitado, a cor do texto do span deve ser #C4C4C4
.campoBloqueadoSankhya input:disabled + .p-textfield__title .p-textfield__title-text {
  color: #C4C4C4;
}
</style>
<style scoped>
/* Cria efeito de linha mudar de cor quando selecionado */

.p-textfield__error--message {
  display: block;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--color-danger);
}

.p-textfield__error--message {
  display: block;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--color-danger);
}

.p-textfield__error--placeholder {
  color: transparent;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  grid-row: 2;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Copy Icon style */
.p-textfield__icon {
  margin-right: 6px;
  position: relative;
  cursor: pointer;
  width: 15px;
}

.p-textfield__icon--span {
  display: grid;
  align-self: end;
}

.p-textfield__icon--span-copy {
  position: absolute;
  display: grid;
  right: 0;
  height: 100%;
  align-items: center;
  visibility: hidden;
}

.p-textfield:hover .p-textfield__icon--span-copy {
  visibility: visible !important;
}

.p-textfield__input.naoMostrarPlaceholder:not(:focus)::-webkit-input-placeholder {
  color: transparent;
}

.p-textfield__input.naoMostrarPlaceholder:not(:focus):-moz-placeholder {
  color: transparent;
}

/* FF 4-18 */
.p-textfield__input.naoMostrarPlaceholder:not(:focus)::-moz-placeholder {
  color: transparent;
}

/* FF 19+ */
.p-textfield__input.naoMostrarPlaceholder:not(:focus):-ms-input-placeholder {
  color: transparent;
}

/* IE 10+ */

.error {
  grid-row: 2;
}

/* Logo label style */
.p-textfield__logoicon {
  margin: 0px 3px 0px 5px;
}

.EspacoEmbaixo {
  margin-bottom: 12px;
}
</style>
