var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "column-header-wrapper",
      style: { "grid-column": _vm.index + 1 },
    },
    [
      _vm.pesquisavel
        ? [
            _c(
              "div",
              { staticClass: "group" },
              [
                !_vm.options
                  ? [
                      _vm.mask
                        ? [
                            _c("input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: _vm.mask,
                                  expression: "mask",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchValue,
                                  expression: "searchValue",
                                },
                              ],
                              ref: "input",
                              attrs: {
                                type: "text",
                                required: "",
                                placeholder: _vm.placeholder,
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.searchValue },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  )
                                    return null
                                  return (() =>
                                    _vm.$emit("focusFirstRow")).apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.searchValue = $event.target.value
                                },
                              },
                            }),
                          ]
                        : [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchValue,
                                  expression: "searchValue",
                                },
                                {
                                  name: "uiv-popover",
                                  rawName: "v-uiv-popover.bottom.hover",
                                  value: {
                                    content: _vm.mensagemAoPassarCursor,
                                  },
                                  expression:
                                    "{content: mensagemAoPassarCursor}",
                                  modifiers: { bottom: true, hover: true },
                                },
                              ],
                              ref: "input",
                              attrs: {
                                type: "text",
                                required: "",
                                placeholder: _vm.placeholder,
                                autocomplete: "off",
                              },
                              domProps: { value: _vm.searchValue },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  )
                                    return null
                                  return (() =>
                                    _vm.$emit("focusFirstRow")).apply(
                                    null,
                                    arguments
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.searchValue = $event.target.value
                                },
                              },
                            }),
                          ],
                      _c("span", { staticClass: "highlight" }),
                      _c("label", [_vm._v(_vm._s(_vm.column))]),
                    ]
                  : [
                      _c(
                        "div",
                        {
                          staticClass: "option-column-name to-click noselect",
                          on: { click: _vm.switchShowOptions },
                        },
                        [_vm._v(_vm._s(_vm.column))]
                      ),
                      _c("transition", { attrs: { name: "bounce2" } }, [
                        _vm.showOptions
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.closeOptions,
                                    expression: "closeOptions",
                                  },
                                ],
                                staticClass: "options-container",
                              },
                              _vm._l(_vm.options, function (op) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "noselect",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setOptionsSearch(op)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      class: op.iconClass,
                                      on: {
                                        click: function ($event) {
                                          return _vm.setOptionsSearch(op)
                                        },
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(op.text))]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
              ],
              2
            ),
            !_vm.options && _vm.ordenavel
              ? _c("div", {
                  staticClass:
                    "sort-icon fs-13 fw-bold icone-linha-seta-baixo to-click",
                  class: { active: _vm.currentFieldSort.field === _vm.field },
                  style:
                    _vm.currentFieldSort.field === _vm.field &&
                    _vm.currentFieldSort.order === "desc"
                      ? "transform: rotate(180deg)"
                      : "",
                  on: { click: _vm.switchOrder },
                })
              : _vm._e(),
            _c("div", {
              staticClass: "filter icone-linha-filtro to-click",
              style: _vm.searchValue
                ? "opacity: 1; color: var(--color-success)"
                : "",
              on: { click: () => _vm.$refs.input.focus() },
            }),
          ]
        : [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "uiv-popover",
                    rawName: "v-uiv-popover.bottom.hover",
                    value: { content: _vm.mensagemAoPassarCursor },
                    expression: "{content: mensagemAoPassarCursor}",
                    modifiers: { bottom: true, hover: true },
                  },
                ],
                staticClass: "coluna",
                class: [_vm.classeDeCentralizado],
              },
              [_c("label", [_vm._v(_vm._s(_vm.column))])]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }