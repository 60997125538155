import moment from 'moment'

export function copiarObjetoProfundamente (objeto) {
  return JSON.parse(JSON.stringify(objeto))
}

export function verificarSeEhObjeto (valor) {
  return valor !== null && (typeof valor === 'object' || typeof valor === 'function')
}

export function congelarObjetoProfundamente (objeto) {
  if (objeto === undefined || !verificarSeEhObjeto(objeto) || Object.isFrozen(objeto)) {
    return objeto
  }

  Object.freeze(objeto)

  Object.getOwnPropertyNames(objeto).forEach(function (prop) {
    congelarObjetoProfundamente(objeto[prop])
  })

  return objeto
}

export function congelarObjetosDaLista (lista) {
  if (!lista) return []
  lista.forEach(i => {
    congelarObjetoProfundamente(i)
  })
  return lista
}

export function formatarDataParaEnvio (data) {
  if (moment.isMoment(data)) {
    return data.format('YYYY-MM-DDTHH:mm:ss')
  }
  return moment(data).format('YYYY-MM-DDTHH:mm:ss')
}

export function formatarDataDiaMesAnoParaEnvio(data) {
  if (moment.isMoment(data)) {
    return data.format('YYYY-MM-DD')
  }
  return moment(data).format('YYYY-MM-DD')
}

export function formatarDataParaDiaMesAno (data) {
  if (moment.isMoment(data)) {
    return data.format('DD/MM/YYYY')
  }
  return moment(data).format('DD/MM/YYYY')
}

export function formatarDataParaHorasMinutosSegundos(data) {
  const horas = data.getHours().toString().padStart(2, '0');
  const minutos = data.getMinutes().toString().padStart(2, '0');
  const segundos = data.getSeconds().toString().padStart(2, '0');
  return `${horas}:${minutos}:${segundos}`;
}

export function converterHorarioParaObjetoMoment (horario) {
  return moment().set({ hour: horario.slice(0, 2), minutes: horario.slice(3, 5) })
}

export function removerItemsDuplicadosDoArray (array, chave) {
  const tester = new Set()
  const resultado = []

  array.forEach(elemento => {
    if (!tester.has(elemento[chave])) {
      tester.add(elemento[chave])
      resultado.push(elemento)
    }
  })

  return resultado
}

export function obterDataComHoraInicial (data) {
  if (moment.isMoment(data)) {
    return data.clone().startOf('day')
  }
  return moment(data).startOf('day').toDate()
}

export function obterDataComHoraFinal (data) {
  if (moment.isMoment(data)) {
    return data.endOf('day')
  }
  return moment(data).endOf('day').toDate()
}

export function montarItemsDaListaEmBlocos (lista, tamanhoDoBloco) {
  const resultado = []
  for (let i = 0; i < lista.length; i += tamanhoDoBloco) {
    resultado.push(lista.slice(i, i + tamanhoDoBloco))
  }
  return resultado
}

export function obterPeriodoDeFolhaDoEmpregado (dia, dataFechamento) {
  let dataInicial

  dia = moment.isMoment(dia) ? dia : moment(dia)

  if (dia.date() < dataFechamento) {
    dataInicial = dia.clone().subtract(1, 'month')
    dataInicial = dataInicial.set('date', dataFechamento)
  } else {
    dataInicial = dia.clone().set('date', dataFechamento)
    dataInicial = moment(obterDataComHoraInicial(dataInicial))
  }
  const endDate = dataInicial.clone().add(1, 'months')
  const dataFinal = obterDataComHoraFinal(endDate).subtract(1, 'days')

  return { startDate: dataInicial, endDate: dataFinal }
}

export function extrairHorarioDeUmaDataEmString (horarioEmString) { return horarioEmString.substr(11, 5) }

export function obterDatasEntreOsPeriodos (momentStartDate, momentEndDate) {
  const resultado = [momentStartDate.clone()]
  const diasDeDiferenca = parseInt(moment.duration(momentEndDate.diff(momentStartDate)).asDays())
  const dataAuxiliar = momentStartDate.clone()
  for (let i = 0; i < diasDeDiferenca; i++) {
    dataAuxiliar.add(1, 'days')
    resultado.push(moment(dataAuxiliar))
  }
  return resultado
}

export function obterHorasMinutosParaDecimal (horas, minutos) {
  return parseInt(horas) + (parseInt(minutos) / 60)
}

export function converterDataParaDecimal (data, adicionar24Horas = false) {
  const dataMoment = moment(data)
  const decimal = obterHorasMinutosParaDecimal(dataMoment.hour(), dataMoment.minutes())
  return adicionar24Horas
    ? decimal + 24
    : decimal
}

export function obterVersaoGitFiltrada (versao) {
  return versao && typeof versao === 'string' && versao.replace(/(\r\n|\n|\r)/gm, '').match(/^v\d+.\d+.\d+-?\w*$/) ? versao : '-'
}

export function obterDataValida (data) {
  return moment.isMoment(data) ? data.toDate() : data
}

export function adicionarZeroOuCaractereAoNumero (numero, tamanho = 2, character = '0') {
  const numeroArredondado = Math.round(numero)
  const numeroEmString = String(numeroArredondado)

  if (numeroEmString.length >= tamanho) {
    return numeroEmString
  }

  return new Array(tamanho - numeroEmString.length + 1).join(character) + numeroEmString
}

export function converterSegundosNumericosParaHorasNumericas(segundos) {
  return segundos / 3600
}

export function obterHorasEMinutosAPartirDeUmFloat (numero) {
  const hours = numero < 0 ? Math.ceil(numero) : Math.floor(numero)
  const minutes = (numero % 1) * 60
  return { hours, minutes, float: numero }
}

export function obterHorasEMinutosEmStringAPartirDeUmFloat (numero) {
  const { hours, minutes } = obterHorasEMinutosAPartirDeUmFloat(numero)

  if (hours < 0 || minutes < 0) {
    return `-${adicionarZeroOuCaractereAoNumero(hours * -1)}:${adicionarZeroOuCaractereAoNumero(minutes * -1)}`
  }

  return `${adicionarZeroOuCaractereAoNumero(hours)}:${adicionarZeroOuCaractereAoNumero(minutes)}`
}

export function arredondarNumero (numero, escala) {
  const multiplicador = 10 ** escala
  return Math.round(numero * multiplicador) / multiplicador
}

export function formatarDataParaISO8601 (data) { return moment(data, 'YYYY-MM-DDTHH:mm:ss') }

export const converterArrayParaObject = (array, chave) => {
  return array.reduce((objeto, item) => {
    objeto[item[chave]] = item
    return objeto
  }, {})
}

export const formatarDataComPadraoFornecido = (data, padrao) => {
  return moment(data).format(padrao)
}

export function capitalizarPrimeiraLetra (palavra) {
  return palavra.charAt(0).toUpperCase() + palavra.slice(1)
}

export function formatarMinutosParaHoraEMinutos (minutos) {
  return moment().startOf('day').add(minutos, 'minutes').format('HH:mm')
}

export function formatarSegundosParaHoraEMinutos(segundos) {
  const duration = moment.duration(segundos, 'seconds');
  const horas = Math.floor(duration.asHours());
  const minutos = duration.minutes();
  return `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
}

export function formatarHorasEMinutosParaSegundos(horario){
  const [horas, minutos] = horario.split(':').map(Number);
  const duracao = moment.duration({ hours: horas, minutes: minutos });
  return duracao.asSeconds();
}

export function colocarPalavraEmMinusculo (palavra) {
  return palavra ? palavra.toLowerCase() : ''
}

export function formatarDataEmTimezoneMenosTresParaTimezoneLocal (data) {
  if (!data) {
    return ''
  }
  const dataUTC = moment(data).utc()
  const dataFormatadaParaUsuario = dataUTC.local().format('DD/MM/YY HH:mm')

  return dataFormatadaParaUsuario
}

export const concatenarHorarioEmData = (data, horario) => {
  const ano = data.getFullYear()
  const mes = (data.getMonth() + 1).toString().padStart(2, '0')
  const dia = data.getDate().toString().padStart(2, '0')

  const [hora, minuto] = horario.split(':')

  const segundos = '00'

  const dataCombinada = new Date(`${ano}-${mes}-${dia}T${hora}:${minuto}:${segundos}`)

  return dataCombinada
}


export const formatarCPFcomMascara = (cpf) => {
  if (cpf.length !== 11) return cpf;
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatarCNPJcomMascara = (cnpj) => {
  if (cnpj.length !== 14) return cnpj;
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatarDocumento = (documento, tipo) => {
  const apenasNumeros = documento.replace(/\D/g, '');

  switch (tipo) {
      case "CPF":
          return formatarCPFcomMascara(apenasNumeros);
      case "CNPJ":
          return formatarCNPJcomMascara(apenasNumeros);
      default:
          return documento;
  }
}

export const formatarArraydeId = (array) => {
  if (!Array.isArray(array)) return null;
  return array.map(item => item.id)
}

/**
 * Retorna uma string limitada a um número máximo de caracteres
 * e adiciona elipse caso o texto seja maior que o limite e o último caractere não seja um espaço
 * @param {string} texto - Texto a ser limitado
 * @param {number} maximoDeCaracteres - Número máximo de caracteres
 * @param {string} elipse - Elipse a ser adicionada ao final do texto
 * @returns {string} - Texto limitado
 * @example
 * limitarString('Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 15, '...') // 'Lorem ipsum dol...'
 * limitarString('Limite com espaço ', 15) // 'Limite com espaço'
 */
export const limitarString = (texto, maximoDeCaracteres, elipse = '...') => {
  if (texto.length <= maximoDeCaracteres) return texto.trim();

  let resultado = texto.slice(0, maximoDeCaracteres).trim();

  return resultado + elipse;
}
