import moment from 'moment'

import { IGerenciamentoDeFeriasRepositorio } from '../dominio/gerenciamentoDeFeriasRepositorio.interface'
import {
  IRespostaSolicitarFerias,
  IRespostaHistoricoDeSolicitacoesPaginada,
  IRespostaHistoricoDeFeriasAgendadasPaginada,
} from '../dominio/gerenciamentoDeFerias.interface'
import { EmpregadoId, MensagemGenerica } from '@/tipos'
import { formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'
import { IServicoHttp } from '@/typescript/servicos/http/http.interfaces'

export class GerenciamentoDeFeriasRepositorio
  implements IGerenciamentoDeFeriasRepositorio
{
  http: IServicoHttp

  constructor(http: IServicoHttp) {
    this.http = http
  }

  async solicitarFerias(
    idEmpregado: EmpregadoId,
    inicio: Date,
    fim: Date
  ): Promise<IRespostaSolicitarFerias> {
    return this.http.post('gestao-de-ferias', {
      id_empregado: idEmpregado,
      inicio: moment(inicio).format('YYYY-MM-DD'),
      fim: moment(fim).format('YYYY-MM-DD')
    })
  }

  async deletarFerias(
    idEmpregado: EmpregadoId,
    inicio: Date,
    fim: Date
  ): Promise<MensagemGenerica> {
    const response = await this.http.delete<MensagemGenerica>(
      `gestao-de-ferias/${idEmpregado}/${inicio}/${fim}/`,
    )

    return response.data
  }

  async obterHistoricoDeSolicitacoesPaginada(
    idEmpregado: EmpregadoId,
    pagina: number,
    porPagina: number,
  ): Promise<IRespostaHistoricoDeSolicitacoesPaginada> {
    const diaInicialDoAnoAnterior = moment()
      .startOf('year')
      .subtract(1, 'year')
      .toDate()
    const diaFinalDoAnoSeguinte = moment()
      .endOf('year')
      .add(1, 'year')
      .toDate()

    return this.http.post(`gestao-de-ferias/${idEmpregado}/solicitadas/`, {
      data_inicial: formatarDataParaEnvio(diaInicialDoAnoAnterior),
      data_final: formatarDataParaEnvio(diaFinalDoAnoSeguinte ),
      paginacao: {
        pagina: pagina,
        por_pagina: porPagina
      }
    })
  }

  async obterHistoricoDeFeriasAgendadasPaginada(
    idEmpregado: EmpregadoId,
    pagina: number,
    porPagina: number
  ): Promise<IRespostaHistoricoDeFeriasAgendadasPaginada> {
    const diaInicialDoAnoAnterior = moment()
      .startOf('year')
      .subtract(1, 'year')
      .toDate()
    const diaFinalDoAnoSeguinte = moment()
      .endOf('year')
      .add(1, 'year')
      .toDate()

    return this.http.post(`gestao-de-ferias/${idEmpregado}/agendadas/`, {
      data_inicial: formatarDataParaEnvio(diaInicialDoAnoAnterior),
      data_final: formatarDataParaEnvio(diaFinalDoAnoSeguinte),
      paginacao: {
        pagina: pagina,
        por_pagina: porPagina
      }
    })
  }
}
