/* eslint-disable */
export default {
  months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
  monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
  weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
  weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
  weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
  longDateFormat: {
    LT: 'H:mm',
    LTS: 'H:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY H:mm',
    LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm'
  },
  calendar: {
    sameDay: function () {
      return '[hoy a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    nextDay: function () {
      return '[mañana a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    nextWeek: function () {
      return 'dddd [a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    lastDay: function () {
      return '[ayer a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    lastWeek: function () {
      return (
        '[el] dddd [pasado a la' +
        (this.hours() !== 1 ? 's' : '') +
        '] LT'
      )
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    w: 'una semana',
    ww: '%d semanas',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años'
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: '%dº',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4 // The week that contains Jan 4th is the first week of the year.
  },
  invalidDate: 'Fecha inválida'
}
/* eslint-enable */
