var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-container" },
    [
      _c(
        "uiv-popover",
        { attrs: { trigger: "hover" } },
        [
          _c("img", {
            staticClass: "menu-usuario__avatar",
            attrs: { src: _vm.Avatar, alt: "Avatar do usuário" },
          }),
          _c("template", { slot: "popover" }, [
            _vm.infoDoUsuario
              ? _c(
                  "div",
                  { staticClass: "menu-info-do-usuario" },
                  _vm._l(_vm.infoDoUsuario, function (item, key) {
                    return _c(
                      "div",
                      { key: key, class: `info-do-usuario__${key}` },
                      [
                        item.conteudo
                          ? [
                              _c("div", { staticClass: "legenda" }, [
                                _vm._v(_vm._s(item.legenda)),
                              ]),
                              _c("div", { staticClass: "conteudo" }, [
                                _vm._v(_vm._s(item.conteudo)),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "Dropdown",
        {
          attrs: { posicao: "down-right" },
          scopedSlots: _vm._u([
            {
              key: "dropdown",
              fn: function () {
                return [
                  _vm.mostrarSeletorDeIdioma
                    ? _c(
                        "li",
                        [
                          _c(
                            "Dropdown",
                            {
                              attrs: { posicao: "left" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "dropdown",
                                    fn: function () {
                                      return _vm._l(
                                        _vm.idiomas,
                                        function (idioma, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              staticClass: "flex-espacado",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selecionarIdioma(
                                                    idioma
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(_vm._s(idioma)),
                                              idioma === _vm.idiomaAtual
                                                ? _c("div", {
                                                    staticClass:
                                                      "icon-ptt-icons-check",
                                                    staticStyle: {
                                                      color:
                                                        "var(--color-info)",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      )
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                8305577
                              ),
                              model: {
                                value: _vm.subDropdown,
                                callback: function ($$v) {
                                  _vm.subDropdown = $$v
                                },
                                expression: "subDropdown",
                              },
                            },
                            [
                              _c(
                                "li",
                                { staticClass: "flex-espacado to-click" },
                                [
                                  _vm._v(" " + _vm._s(_vm.idiomaAtual)),
                                  _c("div", {
                                    staticClass:
                                      "arrow-icon icone-chapado-seta-direita",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      staticClass: "flex-espacado to-click",
                      on: {
                        click: _vm.sair,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.sair.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("mainHeader.MainHeader.sair"))),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.mostrarDropdown,
            callback: function ($$v) {
              _vm.mostrarDropdown = $$v
            },
            expression: "mostrarDropdown",
          },
        },
        [
          _c("div", {
            staticClass: "arrow-icon icon-ptt-icons-dropdown to-click",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }