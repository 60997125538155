import Vue from 'vue'

// Common components
import PAncora from '@/common/buttons/PAncora.vue'
import PButton from '@/common/buttons/PButton.vue'
import PConfirmButton from '@/common/buttons/PConfirmButton.js'
import PCheckbox from '@/common/inputs/PCheckbox.vue'
import PCheckboxTextField from '@/common/inputs/PCheckboxTextField.vue'
import PDatePicker from '@/common/date/PDatePicker.vue'
import PMultiSelect from '@/common/selectors/PMultiSelect.vue'
import PRadio from '@/common/inputs/PRadio.vue'
import PTextField from '@/common/inputs/PTextField.vue'
import PTimeCountField from '@/common/inputs/PTimeCountField.vue'
import PTimeField from '@/common/inputs/PTimeField.vue'
import PModal from '@/common/modal/PModal.vue'
import PSelect from '@/common/selectors/PSelect.vue'
import TransitionExpand from '@/common/transitions/Expand.vue'
import SmartTable from '@/common/SmartTable/SmartTable.vue'
import DBLabel from '@/common/SmartTable/DBLabel.vue'
import TableRow from '@/common/SmartTable/TableRow.vue'
import RadioSlider from '@/common/Tabs/RadioSlider.vue'
import PInputHourMinute from '@/common/inputs/PInputHourMinute.vue'
import Accordion from '@/common/VueAccordion.vue'
import Validate from '@/common/validations/Validate.vue'
import Slider from '@/common/inputs/Slider.vue'
import SkeletonBox from '@/common/Skeleton.vue'
import IconeSVG from '@/common/IconeSVG.vue'
import GraficoDeBarra from "src/common/graficos/GraficoDeBarra/GraficoDeBarra.vue"

Vue.component('Validate', Validate)
Vue.component('Accordion', Accordion)
Vue.component('PAncora', PAncora)
Vue.component('PButton', PButton)
Vue.component('PConfirmButton', PConfirmButton)
Vue.component('PCheckbox', PCheckbox)
Vue.component('PCheckboxTextField', PCheckboxTextField)
Vue.component('PDatePicker', PDatePicker)
Vue.component('PMultiSelect', PMultiSelect)
Vue.component('PRadio', PRadio)
Vue.component('PTextField', PTextField)
Vue.component('PTimeCountField', PTimeCountField)
Vue.component('PTimeField', PTimeField)
Vue.component('PInputHourMinute', PInputHourMinute)
Vue.component('PModal', PModal)
Vue.component('PSelect', PSelect)
Vue.component('Slider', Slider)

Vue.component('TransitionExpand', TransitionExpand)

Vue.component('RadioSlider', RadioSlider)

Vue.component('SmartTable', SmartTable)
Vue.component('DBLabel', DBLabel)
Vue.component('TableRow', TableRow)
Vue.component('SkeletonBox', SkeletonBox)

Vue.component('IconeSVG', IconeSVG)
Vue.component('GraficoDeBarra', GraficoDeBarra)
