var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showModal
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.fecharModal,
              expression: "fecharModal",
            },
          ],
          staticClass: "sobreposicao-modal",
        },
        [
          _c("div", { staticClass: "container-modal" }, [
            _c("div", { staticClass: "conteudo-modal" }, [
              _c("h2", [
                _vm._v(
                  _vm._s(
                    _vm.$t("compartilhados.ModalReportarErroSentry.titulo")
                  )
                ),
              ]),
              _c("div", { staticClass: "grupo-modal" }, [
                _c("label", { attrs: { for: "name" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "compartilhados.ModalReportarErroSentry.legendaNome"
                      )
                    )
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formulario.nome,
                      expression: "formulario.nome",
                    },
                  ],
                  staticClass: "entrada-modal",
                  attrs: {
                    type: "text",
                    id: "name",
                    placeholder: _vm.$t(
                      "compartilhados.ModalReportarErroSentry.placeholderNome"
                    ),
                    disabled: _vm.camposEmailUsuarioDesabilitado,
                  },
                  domProps: { value: _vm.formulario.nome },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formulario, "nome", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "grupo-modal" }, [
                _c("label", { attrs: { for: "email" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "compartilhados.ModalReportarErroSentry.legendaEmail"
                      )
                    )
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formulario.email,
                      expression: "formulario.email",
                    },
                  ],
                  staticClass: "entrada-modal",
                  attrs: {
                    type: "email",
                    id: "email",
                    placeholder: _vm.$t(
                      "compartilhados.ModalReportarErroSentry.placeholderEmail"
                    ),
                    required: "",
                    disabled: _vm.camposEmailUsuarioDesabilitado,
                  },
                  domProps: { value: _vm.formulario.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formulario, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "grupo-modal" }, [
                _c("label", { attrs: { for: "error" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "compartilhados.ModalReportarErroSentry.descrevaErro"
                      )
                    )
                  ),
                  _c("span", { staticClass: "required" }, [_vm._v("*")]),
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formulario.descricaoErro,
                      expression: "formulario.descricaoErro",
                    },
                  ],
                  staticClass: "entrada-modal",
                  attrs: {
                    id: "error",
                    placeholder: _vm.$t(
                      "compartilhados.ModalReportarErroSentry.placeholderDescrevaErro"
                    ),
                    required: "",
                    rows: "6",
                  },
                  domProps: { value: _vm.formulario.descricaoErro },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formulario,
                        "descricaoErro",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm.formulario.print.arquivo
                ? _c("div", { staticClass: "grupo-modal" }, [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "compartilhados.ModalReportarErroSentry.legendaArquivos"
                          )
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "nome-imagem" }, [
                      _vm._v(_vm._s(_vm.formulario.print.nome)),
                    ]),
                  ])
                : _vm._e(),
              _vm.erro
                ? _c("span", { staticClass: "form-erro" }, [
                    _vm._v(_vm._s(_vm.erro)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "grupo-modal" }, [
                _c(
                  "button",
                  {
                    staticClass: "botao-captura",
                    on: { click: _vm.adicionarCaptura },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "compartilhados.ModalReportarErroSentry.botaoAdicionarCapturaTela"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "botoes-acao" }, [
                _c(
                  "button",
                  {
                    staticClass: "botao-envio",
                    attrs: { disabled: !_vm.preencheuCampos },
                    on: { click: _vm.enviarFormulario },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "compartilhados.ModalReportarErroSentry.botaoEnviar"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "botao-cancelar",
                    on: { click: _vm.fecharModal },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "compartilhados.ModalReportarErroSentry.botaoCancelar"
                        )
                      )
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }