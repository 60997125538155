import reasons from './reasons'
import companies from './companies'
import employees from './employees'
import journeys from './journeys'
import userGroups from './userGroups'
import oncall from './oncall'
import routines from './routines'
import employers from './employers'
import customRules from './customRules'
import approvalsRules from './approvalsRules'
import regions from './regions'
import users from './users'
import usersPerfil from './usersPerfil'
import roleGroups from './roleGroups'
import permissions from './permissions'
import repModels from './repModels'
import reps from './reps'
import supervisors from './supervisors'

import store from 'src/store'

export default {
  namespaced: true,
  modules: { companies, employees, reasons, journeys, oncall, userGroups, routines, employers, customRules, approvalsRules, regions, users, usersPerfil, roleGroups, permissions, repModels, reps, supervisors },
  actions: {
    /**
     * Busca os dados comuns das stores usadas em várias telas
     * Caso o usuário não esteja logado as requisicoes não SERÃO feitas
     */
    loadMainCommons ({ dispatch }) {
      if (!store.getters.usuarioEstaAutenticado) {
        return
      }
      Promise.allSettled([
        dispatch('companies/fetchItems'),
        dispatch('employees/fetchItems')
      ])
    }
  }
}
