import HttpCondominio from '@/typescript/servicos/http/condominio'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { IResponseJustificarHorasExtras, IResponseSolicitacaoBase, IResponseSolicitacaoHorasExtras, IResponseConfiguracaoRegraVigente } from './solicitacoes.repositorio.response'
import { IRequestJustificarHorasExtras, IRequestSolicitacaoHorasExtras, IRequestSolicitarHorasExtrasEmGrupo, IRequestConfiguracaoRegraVigente } from './solicitacoes.repositorio.request'
import { HttpResponse } from '@/typescript/servicos/http/http.interfaces'

export type Resposta<T> = Promise<HttpResponse<IResponseSolicitacaoBase<T>>>

export enum RotasSolicitacoes {
  HorasExtras = 'planejamento-de-escala/horas-extras',
  JustificarHorasExtras = 'planejamento-de-escala/horas-extras/justificativa',
  HorasExtrasEmGrupo = 'planejamento-de-escala/horas-extras-em-grupo',
  ConfiguracaoRegraVigente = 'configuracao-de-solicitacao-vigente'
}

export type HelperSolicitacoesResponse = {
  ResponseSolicitarHorasExtras: Resposta<IResponseSolicitacaoHorasExtras>
  ResponseJustificarHorasExtras: Resposta<IResponseJustificarHorasExtras>
  ResponseSolicitarHorasExtrasEmGrupo: Resposta<IResponseSolicitacaoHorasExtras>
  ResponseConfiguracaoRegraVigente: Promise<AxiosResponse<IResponseConfiguracaoRegraVigente>>
}

export interface ISolicitacaoRepositorio {
  http: HttpCondominio

  solicitarHorasExtras(data: IRequestSolicitacaoHorasExtras, config?: AxiosRequestConfig): HelperSolicitacoesResponse['ResponseSolicitarHorasExtras']

  justificarHorasExtras(data: IRequestJustificarHorasExtras, config?: AxiosRequestConfig): HelperSolicitacoesResponse['ResponseJustificarHorasExtras']

  solicitarHorasExtrasEmGrupo(data: IRequestSolicitarHorasExtrasEmGrupo, config?: AxiosRequestConfig): HelperSolicitacoesResponse['ResponseSolicitarHorasExtrasEmGrupo']
  
  configuracaoRegraVigente(data: IRequestConfiguracaoRegraVigente, config?: AxiosRequestConfig): HelperSolicitacoesResponse['ResponseConfiguracaoRegraVigente']
}
