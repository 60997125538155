<template>
  <div class="container-solicitacao">
    <h2>
      <IconeSVG icone="aviao"/>
      {{ $t('gerenciamentoDeFerias.meusLiderados.solicitarFerias') }}
    </h2>
    <div class="container-form">
      <Validate class="select-form" v-model="empregados" :required="true">
        <PSelect
          ref="itemSelector"
          labelItem="name"
          useCommon="employees/onlyNotFired"
          :usaPaginacao="true"
          :withMinimalInformation="true"
          :title="`${$t('comuns.genericos.plural.empregados')}*`"
        />
      </Validate>
      <Validate class="select-form" v-model="dataInicial" :required="true">
        <PDatePicker
          name="dataInicial"
          format="DD/MM/YY"
          :label="`${$t('gerenciamentoDeFerias.meusLiderados.labelInicio')}*`"
          :minDate="dataMinima"
        />
      </Validate>
      <Validate class="select-form" v-model="dataFinal" :required="true">
        <PDatePicker
          name="endDate"
          format="DD/MM/YY"
          :label="`${$t('gerenciamentoDeFerias.meusLiderados.labelFim')}*`"
          :minDate="dataMinimaFim()"
        />
      </Validate>
      <div class="container-botoes">
        <PButton color="success" size="md" block :loading="estaSolicitando" @click="solicitarFerias">
          {{ $t('gerenciamentoDeFerias.meusLiderados.solicitar') }}
        </PButton>
        <PButton color="danger" size="md" block @click="cancelar">
          {{ $t('gerenciamentoDeFerias.meusLiderados.cancelar') }}
        </PButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ServicoHttpCondominio } from '@/main'
import { GerenciamentoDeFeriasServico } from '../../dominio/gerenciamentoDeFeriasServico'
import { GerenciamentoDeFeriasRepositorio } from '../../repositorio/gerenciamentoDeFeriasRepositorio'
import { useValidationsMixin } from 'src/common/validations'
import moment from 'moment'

export default {
  name: 'SolicitarMeusLiderados',
  data() {
    const http = ServicoHttpCondominio
    const gerenciamentoDeFeriasRepositorio = new GerenciamentoDeFeriasRepositorio(http)
    const gerenciamentoDeFeriasServico = new GerenciamentoDeFeriasServico(gerenciamentoDeFeriasRepositorio)
    const dataMinima = moment().add(30, 'days').toDate()

    return {
      estaSolicitando: false,
      empregados: { loaded: [], selected: null, loading: false },
      dataMinima,
      dataInicio: dataMinima,
      dataFim: moment().add(31, 'days').toDate(),
      gerenciamentoDeFeriasServico
    }
  },
  computed: {
    dataInicial: {
      get() {
        return this.dataInicio
      },
      set(value) {
        this.dataInicio = value
      }
    },
    dataFinal: {
      get() {
        return this.dataFim
      },
      set(value) {
        this.dataFim = value
      }
    }
  },
  methods: {
    async solicitarFerias() {
      if (!this.isAllValid()) {
        this.displayAllErrors()
        return
      }

      this.estaSolicitando = true
      try {
        const { id } = this.empregados.selected

        const resultadoRequisicao = await this.gerenciamentoDeFeriasServico.solicitarFerias(
          id,
          this.dataInicio,
          this.dataFim
        )

        this.$modalDeSucesso(resultadoRequisicao?.data?.mensagem || this.$t('gerenciamentoDeFerias.listagemDeFerias.feriasSolicitadaComSucesso').toString())

        // Sai do estado de criação de solicitação de férias
        this.cancelar()
      } catch (erro) {
        const { response } = erro

        // Trata a resposta de solicitação realizada com sucesso
        // Como ela é retornada do backend com o status 400, é necessário tratar o caso de sucesso no catch
        const ehMensagemDeSucesso = response.data?.error === 'Sua solicitação foi feita com sucesso!' || response.data?.error === 'Solicitação pré registrada com sucesso'

        if (ehMensagemDeSucesso) {
          this.$modalDeInformacao(response.data?.description, response.data?.error)
          this.cancelar()
          return Promise.resolve()
        }

        const mensagemDeErro = response?.data?.error || response?.data?.erro
        this.$modalDeErro(mensagemDeErro || this.$t('gerenciamentoDeFerias.listagemDeFerias.erroAoSolicitarFerias').toString())
      } finally {
        this.estaSolicitando = false
      }
    },
    cancelar() {
      this.dataInicio = null
      this.dataFim = null

      this.$emit('cancelar')
    },
    dataMinimaFim() {
      return moment(this.dataInicio).add(1, 'days').toDate()
    }
  },
  mixins: [useValidationsMixin({})]
}
</script>

<style lang="scss" scoped>
.container-solicitacao {
  h2 {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #c4c4c4;
  }

  .container-form {
    display: flex;
    gap: 0.75rem;

    .select-form {
      width: 100%;
    }

    .container-botoes {
      gap: 2px;
      display: flex;
      width: 100%;
    }
  }
}
</style>
