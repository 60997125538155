<script type="text/jsx">
import moment from 'moment'
import ErrorMsg from '@/common/inputs/ErrorMsg.vue'
import { handlerUseStateVmodel } from '@/common/mixins/handlerUseStateVmodel'
import { obterDataComHoraInicial } from '@/utils/formatadoras/formatadoras'

const SUPORTED_COMPONENTS = [
  'PTextField', 'PSelect', 'PDatePicker'
]

export default {
  name: 'Validate',
  props: {
    required: { type: Boolean, 'default': true },
    min: { type: Number, 'default': null },
    max: { type: Number, 'default': null },
    minLength: { type: Number, 'default': null },
    maxLength: { type: Number, 'default': null },
    minDate: { type: Date, 'default': null },
    maxDate: { type: Date, 'default': null },
    customError: { type: String, 'default': '' },
    onlyNumbers: { type: Boolean, 'default': false },
    // Para o validacaoRegex: precisa usar um objeto com duas chaves (mensagemDeErro e regex)
    validacaoRegex: { type: Object }
  },
  data () {
    return {
      touched: false,
      componentType: null
    }
  },
  computed: {
    valid () {
      return !this.error
    },
    error () {
      return this.requiredError || this.minMaxError || this.lengthError || this.minDateMaxDateError || this.customError || this.onlyNumbersError || this.erroValidacaoRegex
    },
    requiredError () {
      if (!this.required) return ''

      if (this.componentType === 'PSelect') {
        return this.state.selected ? '' : this.$t('compartilhados.validations.Validate.campoObrigatorio')
      }
      return !this.state ? this.$t('compartilhados.validations.Validate.campoObrigatorio') : ''
    },
    minMaxError () {
      if (this.componentType !== 'PTextField') return ''

      const number = parseFloat(this.state)
      if ((this.min !== null || this.max !== null) && !isNaN(number)) {
        const validMin = this.min !== null ? number >= this.min : true
        const validMax = this.max !== null ? number <= this.max : true

        if (this.min !== null && this.max !== null) {
          return validMin && validMax ? '' : this.$t('compartilhados.validations.Validate.entre', { min: this.min, max: this.max })
        } else if (this.max !== null) {
          return validMax ? '' : this.$t('compartilhados.validations.Validate.menorQue', { max: this.max })
        } else if (this.min !== null) {
          return validMin ? '' : this.$t('compartilhados.validations.Validate.maiorQue', { min: this.min })
        }
      }
      return ''
    },
    minDateMaxDateError () {
      if (this.componentType !== 'PDatePicker' || (!this.minDate && !this.maxDate)) return

      const date = this.state ? obterDataComHoraInicial(this.state) : null
      const min = this.minDate ? obterDataComHoraInicial(this.minDate) : null
      const max = this.maxDate ? obterDataComHoraInicial(this.maxDate) : null

      if (min && date && moment(date).isBefore(min)) {
        return this.$t('compartilhados.validations.Validate.posteriorData', { data: moment(min).format('DD/MM/YYYY') })
      } else if (max && date && moment(this.state).isAfter(max)) {
        return this.$t('compartilhados.validations.Validate.anteriorData', { data: moment(max).format('DD/MM/YYYY') })
      }
      return ''
    },
    lengthError () {
      if (this.componentType !== 'PTextField' || (!this.required && !this.state)) return ''

      const text = this.state

      if (this.maxLength !== null && text && text.length > this.maxLength) {
        return this.$t('compartilhados.validations.Validate.maximoCaracteres', { max: this.maxLength })
      } else if (this.minLength !== null && (!text || text.length < this.minLength)) {
        return this.$t('compartilhados.validations.Validate.minimoCaracteres', { min: this.minLength })
      }
      return ''
    },
    onlyNumbersError () {
      if (this.componentType !== 'PTextField' || !this.onlyNumbers) return ''

      const inputText = this.state
      const regex = /\D/g
      if (regex.test(inputText)) {
        return this.$t('compartilhados.validations.Validate.apenasNumeros')
      }
      return ''
    },
    erroValidacaoRegex () {
      if (this.componentType !== 'PTextField' || !this.validacaoRegex) return ''

      const texto = this.state
      if (!texto) return ''
      const regex = this.validacaoRegex.regex
      if (regex.test(texto)) {
        return this.validacaoRegex.mensagemDeErro || this.$t('compartilhados.validations.Validate.formatoInvalido')
      }
      return ''
    }
  },
  methods: {
    setTouch (value) {
      this.touched = value
    }
  },
  beforeMount () {
    if (this.componentType === null) {
      this.componentType = this.$slots.default[0].componentOptions.Ctor.options.name

      if (!SUPORTED_COMPONENTS.includes(this.componentType)) {
        throw new Error(`Validate component dont suport ${this.componentType} components`)
      }
    }
  },
  render () {
    const slotDefault = this.$slots.default

    // validations if the component in slot is valid

    if (slotDefault.length !== 1) {
      throw new Error('Invalid slot. This component only support one slot with only one element')
    }

    if (!slotDefault[0].elm && (
      slotDefault[0].componentOptions.propsData?.useState ||
      slotDefault[0].componentOptions.propsData?.value ||
      slotDefault[0].componentOptions.propsData?.customVmodel)) {
      throw new Error('The Validation component is the one that you must pass the useState, vmodel or customvmodel')
    }

    // this block basically create a v-model to the wrapped component

    slotDefault[0].componentOptions.propsData.customVmodel = {
      set: (value) => {
        this.setTouch(['PTextField', 'PDatePicker'].includes(this.componentType))
        this.state = value
      },
      get: () => {
        return this.state
      }
    }

    return (
      <span class="validate" {...{ 'class': { error: this.touched ? this.error : '' } }}>
        {slotDefault}
        <error-msg {...{ props: { error: this.touched ? this.error : '', keep: true } }} />
      </span>
    )
  },
  mixins: [
    handlerUseStateVmodel(false)
  ],
  components: { ErrorMsg }
}
</script>

<style lang="scss">
.validate {

  // hide all styles
  >.error-msg {
    display: block !important;
  }

  // Essa classe está sendo usada pelo PTextField que faz o uso desse componente
  .campoBloqueadoSankhya~.error-msg {
    display: none !important;
  }

  .error-msg {
    display: none;
  }

  &.error {

    input,
    .multiselect__tags {
      border-bottom-color: var(--color-danger) !important;
    }

    .p-textfield__title-text,
    .multiselect__placeholder {
      color: var(--color-danger) !important;
    }
  }
}
</style>
