export enum StatusSolicitacao {
  Pendente = 'PENDENTE',
  Aprovado = 'APROVADO',
  Recusado = 'RECUSADO',
  ComObservacao = 'COM_OBSERVACAO',
  PendenteDeJustificativa = 'PENDENTE_DE_JUSTIFICATIVA',
  VencidaDeJustificativa = 'VENCIDA_DE_JUSTIFICATIVA',
  VencidaDeResposta = 'VENCIDA_DE_RESPOSTA',
  Cancelado = 'CANCELADO'
}

export enum TipoDeSolicitacao {
  CorrecaoDePonto = 'correcao_de_ponto',
  Dispensa = 'dispensa',
  ConversaoDeApontamento = 'conversao_de_apontamento',
  AlteracaoDeEscala = 'alteracao_de_escala',
  Suspensao = 'suspensao',
  Ferias = 'ferias',
  HorasExtras = 'horas_extras'
}
