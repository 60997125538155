import { LocaleMessages } from 'vue-i18n'
import ptBr from './ptBr.i18n'
import esCo from './esCo.i18n'

const mensagens: LocaleMessages = {
  'pt-BR': ptBr,
  'es': esCo
}

export default mensagens
