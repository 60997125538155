<template>
  <div v-html="src" :style="css" @click="$emit('click', $event)" />
</template>

<script>
export default {
  name: "IconeSVG",
  props: {
    icone: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "24px",
    },
    height: {
      type: String,
      default: "24px",
    },
    cursor: {
      type: String,
      default: "unset",
    },
    cor: {
      type: String,
      default: null,
    },
  },
  computed: {
    src() {
      let icone = require(`../../static/icones/${this.icone}.svg?raw`);
      return icone;
    },
    css() {
      const variaveis = {
        "--largura": this.width,
        "--altura": this.height,
        "--cursor": this.cursor,
      };

      if (this.cor) {
        /**
         * Para utilizar a variavel --cor-icone, as cores que deseja trocar no icone
         * devem estar recebendo essa variável como fallback no arquivo .svg
         * @example var(--cor-icone, #FFF)
         */
        variaveis["--cor-icone"] = this.cor;
      }

      return variaveis;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

div ::v-deep svg {
  width: var(--largura);
  height: var(--altura);
  cursor: var(--cursor);
}
</style>
