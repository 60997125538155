<template>
<div v-if="!estaSolicitandoFerias" class="container-minhas-solicitacoes">
  <PSelect
    class="status-select"
    useState="gestaoDeFerias/statusSolicitacao"
    name="statusSolicitacao"
    labelItem="name"
    title=" "
    :allowEmpty="false"
    @select="buscar"
  />
  <PButton color="warning" size="md" @click="solicitarFerias">
    {{ $t('gerenciamentoDeFerias.minhasFerias.solicitarMinhasFerias') }}
  </PButton>
</div>
<div v-else>
  <SolicitarMinhasFerias @buscar="buscar" @cancelar="cancelar"/>
</div>
</template>

<script>
import SolicitarMinhasFerias from '../solicitacoes/SolicitarMinhasFerias.vue'

export default {
  name: 'MinhasSolicitacoesDeFerias',
  data () {
    return {
      estaSolicitandoFerias: false
    }
  },
  mounted() {
    this.$emit('buscarDados')
  },
  methods: {
    buscar() {
      this.$emit('buscarDados')
    },
    solicitarFerias() {
      this.estaSolicitandoFerias = true
    },
    cancelar() {
      this.estaSolicitandoFerias = false
    }
  },
  components: {
    SolicitarMinhasFerias
  }
}
</script>

<style lang="scss" scoped>
div.container-minhas-solicitacoes {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
div.container-proximas-ferias {
  flex: 0.33;
  border: 5px solid #d9f1f4;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 1rem 2.5rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 0;
  }

  span {
    font-weight: 300;
    font-size: 11px;
    line-height: 0;
  }
}

.status-select {
  width: 25rem;
}
</style>
