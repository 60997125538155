<template lang="pug">
  .panel(:style="computedStyle", ref="panel" :class="{open: open, ['back-color-' + color]: true}")

    .heading.to-click(
      @click="switchOpen"
      tabindex="0"
      @keydown.enter="switchOpen"
      :class="{'closer-button': closerButton}"
    )
      .heading-main.noselect
        slot(name="heading")

      .heading-button
        .icon.icone-linha-seta-baixo.fw-bold(:class="{open: open}")

    transition(name="pop")
      .panel-collapse(v-show="open")
        slot

</template>

<script>
export default {
  name: 'vue-accordion',
  props: {
    marginBottom: {default: 0},
    overflow: {type: String, default: 'visible'},
    maxHeight: {type: String, default: 'none'},
    noPanel: {default: null},
    startOpen: {default: false},
    color: {type: String, default: 'primary'},
    closerButton: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false}
  },
  mounted () {
    this.open = this.startOpen
    if (this.startOpen) {
      this.$refs.panel.classList.add('panel-open')
    }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    computedStyle () {
      return {
        overflow: this.overflow,
        marginBottom: this.marginBottom,
        maxHeight: this.maxHeight
      }
    }
  },
  methods: {
    switchOpen () {
      if (this.disabled) return
      this.open = !this.open
      this.$refs.panel.classList.toggle('panel-open')
      this.$emit('open', this.open)
      return false
    },
    close () {
      this.open = false
    }
  }
}
</script>

<style scoped lang="scss">
$colors: success, danger, info, warning, danger, default, primary;
@each $color in $colors {
  .back-color-#{$color} {
    border: 2px solid var(--color-#{$color});
    border-bottom: 2px solid var(--color-#{$color});
    border-left: 2px solid var(--color-#{$color});
    border-right: 2px solid var(--color-#{$color});
    .heading {
      .heading-main, .heading-button {
        background-color: var(--color-#{$color});
        color: var(--color-#{$color}-contrast);
      }
    }
  }
}
.panel {
  height: fit-content;
  &.open {
    .icon.open {
      transform: rotate(180deg);
    }
  }
  .heading {
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-template-rows: auto;
    align-items: center;
    outline: none;
    grid-template-areas:
      "main button";
    border: none;
    background: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    .heading-main {
      display: grid;
      grid-area: main;
      padding: 2px 5px 2px 5px;
    }
    .heading-button {
      display: grid;
      width: 100%;
      height: 100%;
      grid-area: button;
      align-items: center;
      justify-items: center;
      .icon {
        transition: all .3s ease;
        height: 16px;
        width: 16px;
      }
    }
  }

  .closer-button{
    grid-template-columns: max-content min-content;
  }

  .panel-collapse:not(:empty) {
    padding: 10px 10px 10px 10px;
  }

  .panel-open > .icon{
    transform: rotate(180deg);
    color: var(--color-primary);
  }
  .pop-enter-active .pop-leave-active {
    opacity: 1;
    max-height: 0;
    transition: max-height 0.4s, opacity 0.35s;
  }
  .pop-enter {
    opacity: 0;
    max-height: 0;
    transition: max-height 0.4s, opacity 0.35s;
  }
  .pop-enter-to {
    opacity: 1;
    max-height: 500px;
    transition: max-height 0.4s, opacity 0.35s;
  }
  .pop-leave {
    opacity: 0;
    max-height: 500px;
    transition: max-height 0.4s, opacity 0.35s;
  }
  .pop-leave-to {
    opacity: 0;
    max-height: 0;
    transition: max-height 0.4s, opacity 0.35s;
  }
}
</style>
