import { API } from '@/services.js'
import { UsuarioId } from '@/tipos'
import { FiltradorDeErros } from 'src/erros'
import type { Credenciais, PublicVapidKey, FirebaseFCMToken, DadosParaInicializarFirebase, FirebaseNotificationPayload } from './firebase.interface'

interface WindowComFirebase extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firebase: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firebaseMessaging: any
}

declare let window: WindowComFirebase

class ServicoFirebase {
  static CAMINHO_WORKER = './firebase-messaging-sw.js'

  static async inicializar (idUsuarioLogado: UsuarioId): Promise<void> {
    const navegadorPermiteNotificar = await ServicoFirebase.verificarPermissao()
    const ehProducao = ServicoFirebase.verificarSeEhProducao()

    if (ehProducao && navegadorPermiteNotificar === false) {
      return
    }

    const [credenciais, publicVapidKey] = ServicoFirebase.recuperarCredenciais()

    ServicoFirebase.inicializarFirebaseWindow(credenciais, publicVapidKey)

    await ServicoFirebase.inicializarFirebaseServiceWorker()

    const tokenFirebase = await ServicoFirebase.pedirTokenFirebase()

    await ServicoFirebase.enviarTokenParaCondominio(tokenFirebase, idUsuarioLogado)

    ServicoFirebase.registrarEventoDeMensagemAbaEmFoco()
  }

  static verificarSeEhProducao (): boolean {
    return process.env.NODE_ENV === 'production'
  }

  static async verificarPermissao (): Promise<boolean> {
    let permiteNotificar = false
    await Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        permiteNotificar = true
      }
    })
    return permiteNotificar
  }

  static recuperarCredenciais (): [Credenciais, PublicVapidKey] {
    const credenciaisDeInicializacao = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID
    }

    const chavePublicaVapid = process.env.VUE_APP_FIREBASE_PUBLIC_VAPID_KEY

    return [credenciaisDeInicializacao, chavePublicaVapid]
  }

  static inicializarFirebaseWindow (credenciais: Credenciais, publicVapidKey: PublicVapidKey): void {
    window.firebase.initializeApp(credenciais)
    window.firebaseMessaging = window.firebase.messaging()
    window.firebaseMessaging.usePublicVapidKey(publicVapidKey)
  }

  static async inicializarFirebaseServiceWorker (): Promise<void> {
    await navigator.serviceWorker.register(ServicoFirebase.CAMINHO_WORKER).then(registro => {
      window.firebaseMessaging.useServiceWorker(registro)
    })
  }

  static async pedirTokenFirebase (): Promise<FirebaseFCMToken> {
    try {
      const token = await window.firebaseMessaging.getToken()
      return token
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro as Error)
      return null
    }
  }

  static async enviarTokenParaCondominio (token: FirebaseFCMToken, idUsuarioLogado: UsuarioId): Promise<void> {
    try {
      if (!token) {
        return
      }
      await API.userFirebaseToken.save({ id: idUsuarioLogado, entity: 'user' }, { firebaseToken: token })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro as Error)
    }
  }

  /**
   * @description Se a aba do gestão estiver em foco, a notificação será disparada
   * por essa função, e não pelo service worker, a mensagem é enviada pelo backend
   * na função mandar_notificao_push_firebase
   */
  static registrarEventoDeMensagemAbaEmFoco (): void {
    window.firebaseMessaging.onMessage((payload: FirebaseNotificationPayload) => {
      const { title: titulo, text: texto, icon: icone } = payload.data

      new Notification(titulo, {
        body: texto,
        icon: icone || '/favicon.png'
      })
    })
  }
}

export const inicializadorFirebase = (dados: DadosParaInicializarFirebase): void => {
  try {
    const { idUsuarioLogado, ehLar21, possuiAcompanhamento } = dados

    if (ehLar21 || !possuiAcompanhamento || !idUsuarioLogado) {
      return
    }

    ServicoFirebase.inicializar(idUsuarioLogado)
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
}

export default ServicoFirebase
