var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    _vm._g({ staticClass: "p-checkbox p-checkbox__label" }, _vm.localListeners),
    [
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.state,
                expression: "state",
              },
            ],
            staticClass: "p-checkbox__input",
            class: _vm.classInput,
            attrs: {
              type: "checkbox",
              disabled: _vm.disabled || _vm.loading || _vm.readonly,
              readonly: "",
            },
            domProps: {
              checked: Array.isArray(_vm.state)
                ? _vm._i(_vm.state, null) > -1
                : _vm.state,
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                _vm.state = !_vm.state
              },
              change: function ($event) {
                var $$a = _vm.state,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.state = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.state = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.state = $$c
                }
              },
            },
          },
          "input",
          _vm.localAttributes,
          false
        )
      ),
      _c("div", { staticClass: "p-checkbox__indicator" }),
      _vm.$slots.icone
        ? _c("div", { staticClass: "icone" }, [_vm._t("icone")], 2)
        : _vm._e(),
      _c("span", { class: _vm.classLabel }, [
        _vm._v(_vm._s(_vm.label)),
        _vm.infoIcon
          ? _c("div", {
              staticClass: "icon-info icone-chapado-info color-info",
              style: `right: ${_vm.infoIconPosition}`,
              attrs: { title: _vm.infoIconTitle },
            })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }