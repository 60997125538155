var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-solicitacao" }, [
    _c(
      "h2",
      [
        _c("IconeSVG", { attrs: { icone: "aviao" } }),
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("gerenciamentoDeFerias.meusLiderados.solicitarFerias")
            ) +
            " "
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container-form" },
      [
        _c(
          "Validate",
          {
            staticClass: "select-form",
            attrs: { required: true },
            model: {
              value: _vm.dataInicial,
              callback: function ($$v) {
                _vm.dataInicial = $$v
              },
              expression: "dataInicial",
            },
          },
          [
            _c("PDatePicker", {
              attrs: {
                name: "dataInicial",
                format: "DD/MM/YY",
                label: `${_vm.$t(
                  "solicitacao.GestaoDeSolicitacoes.labelInicio"
                )}*`,
                minDate: _vm.dataMinima,
              },
            }),
          ],
          1
        ),
        _c(
          "Validate",
          {
            staticClass: "select-form",
            attrs: { required: true },
            model: {
              value: _vm.dataFinal,
              callback: function ($$v) {
                _vm.dataFinal = $$v
              },
              expression: "dataFinal",
            },
          },
          [
            _c("PDatePicker", {
              attrs: {
                name: "endDate",
                format: "DD/MM/YY",
                label: `${_vm.$t(
                  "solicitacao.GestaoDeSolicitacoes.labelTermino"
                )}*`,
                minDate: _vm.dataInicio,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "container-botoes" },
          [
            _c(
              "PButton",
              {
                attrs: {
                  color: "success",
                  size: "md",
                  block: "",
                  loading: _vm.estaSolicitando,
                },
                on: { click: _vm.solicitarFerias },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("gerenciamentoDeFerias.meusLiderados.solicitar")
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "PButton",
              {
                attrs: { color: "danger", size: "md", block: "" },
                on: { click: _vm.cancelar },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("gerenciamentoDeFerias.meusLiderados.cancelar")
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }