import { range, runMultipleFuncsInParallel } from 'src/utils'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

function saveInStorage (name, items, totalItems) {
  localStorage.setItem(`${name}-smart-table-items`, JSON.stringify({items, totalItems}))
}

function loadFromStorage (name) {
  try {
    const {items, totalItems} = JSON.parse(localStorage.getItem(`${name}-smart-table-items`))
    return {items: congelarObjetosDaLista(items), totalItems}
  } catch {
    localStorage.removeItem(`${name}-smart-table-items`)
    return null
  }
}

export default {
  async created () {
    if (this.loadBulkDataFunction) {
      // 1º get data from storage
      const cached = loadFromStorage(this.name)
      if (cached) {
        this.internalLoad.totalItems = cached.totalItems
        this.state.loaded = cached.items
      }

      // 2º load first page
      const success = await this.loadDataUsingGivenFunc()

      // 3º load other pages in bulk mode
      if (success) {
        // this.loadBulkIfNeeded()
      }
    }
  },
  data () {
    return {
      // we will only use this data if the component received the loadBulkDataFunction prop
      // is to keep track of what was already loaded
      internalLoad: {
        pagesLoaded: [],
        totalItems: null,
        loadFullInProgress: false
      }
    }
  },
  computed: {
    isBulkLoading () {
      return Boolean(this.loadBulkDataFunction ? this.internalLoad.loadFullInProgress : false)
    }
  },
  methods: {
    _applyData (items, reset = false) {
      this.state = {...this.state, loaded: [...(reset ? this.state.loaded : []), ...congelarObjetosDaLista(items)]}
    },
    async loadDataUsingGivenFunc () {
      this.state.loading = true

      // load first 2 pages
      const {items, totalItems} = await this.loadBulkDataFunction({perPage: this.mergedConfig.perPage, page: this.currentPage})

      if (!items?.length) return null

      this.state = {...this.state, loading: false, totalItems}
      this._applyData(items, true)

      this.internalLoad.pagesLoaded = [0]
      this.internalLoad.totalItems = totalItems

      return true
    },
    async loadBulkIfNeeded () {
      // this function is responsible to load all pages in a smart way :notsosure:

      // prevent run multiple times this function or if has no items to load
      if (this.internalLoad.totalPages === 0 || this.isBulkLoading) {
        return
      }

      if (this.internalLoad.totalPages === null) throw new Error('need to know how many pages exists')

      this.internalLoad.loadFullInProgress = true

      const totalPagesFullLoad = Math.ceil(this.internalLoad.totalItems / this.mergedConfig.loadBulkDataFunctionChunkSize)

      const loads = range(0, totalPagesFullLoad).map(page => {
        return async () => {
          const {items} = await this.loadBulkDataFunction({perPage: this.mergedConfig.loadBulkDataFunctionChunkSize, page})

          if (!items?.length) return null

          this._applyData(items)
        }
      })

      const maxNumberParallelRequests = 10 // prevents DDOS back

      await runMultipleFuncsInParallel(loads, maxNumberParallelRequests)
      this.internalLoad.loadFullInProgress = false

      saveInStorage(this.name, this.state.loaded, this.totalItems)
    }
  }
}
