import i18n from 'src/typescript/servicos/i18n/i18n.servico'
export default {
  async created () {
    if (this.loadPageFunction && !this.previnirCarregamentoNaMontagem) {
      await this.loadPageDataUsingGivenFunc()
    }
  },
  data () {
    return {
      efetuouPrimeiraBusca: false,
      serverSidePagination: {
        loadedPages: {},
        totalItems: 0,
        totalPages: 0,
        ocorreuErro: null
      }
    }
  },
  computed: {
    isServerSidePaginated () {
      return Boolean(this.loadPageFunction)
    }
  },
  methods: {
    async resetPagesAndReload () {
      this.serverSidePagination = {
        loadedPages: {},
        totalItems: 0,
        totalPages: 0,
        ocorreuErro: null
      }
      await this.changePage(0)
    },
    async loadPageDataUsingGivenFunc () {
      if (this.serverSidePagination.loadedPages.hasOwnProperty(this.currentPage)) {
        return
      }

      try {
        this.efetuouPrimeiraBusca = true
        this.state.loading = true
        const valorDaBusca = this.search[this.lastSearchedField || this.mergedConfig.defaultColumnSearch] || ''

        const { items, totalItems, totalPages } = await this.loadPageFunction({
          perPage: this.mergedConfig.perPage,
          page: this.currentPage,
          orderBy: this.currentFieldSort.field || this.mergedConfig.defaultSortField,
          search: valorDaBusca.trim(),
          searchedField: this.lastSearchedField || this.mergedConfig.defaultColumnSearch,
          reverse: this.currentFieldSort.order === 'desc',
          allSearch: this.search
        })

        this.serverSidePagination.totalPages = totalPages
        this.serverSidePagination.totalItems = totalItems

        if (!items?.length) {
          this.serverSidePagination.loadedPages = {}
        } else {
          this.serverSidePagination.loadedPages = {
            ...this.serverSidePagination.loadedPages,
            [this.currentPage]: [...items]
          }
        }
      } catch (erro) {
        const mensagem = erro?.data?.error ? erro.data.error : i18n.t('compartilhados.SmartTable.EstadoErroDeCarregamento.mensagemErro')
        this.$set(this.serverSidePagination, 'ocorreuErro', mensagem)
      } finally {
        this.state.loading = false
      }
    }
  }
}
