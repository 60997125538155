import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations
} from 'src/utils.store'

import { API } from 'src/services'
import { omit } from 'lodash'

import {
  formatarEscritaRegraCustomizada,
  formatarLeituraRegraCustomizada,
  obterEstadoPadrao
} from 'src/components/CustomizacoesDeCalculo/Vuex/RegrasDeCalculo/formatadoras'

const getters = {
  customRuleFormattedToSimulate (state) {
    return omit(formatarEscritaRegraCustomizada(state), ['id', 'showExtraHourAndDelayTypeTogether', 'dayCases', 'categorizarHorasExtrasPorPeriodo', 'categorizarHorasExtrasNoturnasPorPeriodo', 'periodoHoraExtraLimites'])
  }
}

const mutations = {
  ...vuexMapDefaultsMutations(obterEstadoPadrao()),
  resetState (state) {
    resetarParaEstadoInicial(state, obterEstadoPadrao())
  },
  setFromState (state, {...customRule}) {
    Object.assign(state, customRule)
  },
  setTitleState (state, {type, titleKey, value}) {
    state[type][titleKey] = value
  },
  setTitleNameState (state, {titleKey, value}) {
    state.titles[titleKey].text = value
  },
  setLimite (state, { tipo, campo, index, valor }) {
    const valorHoraEmString = valor?.toString()
    const ehHoraFracionada = valorHoraEmString?.includes('.')
    const ehHoras = campo === 'boundary'
    const limiteCaracteresParaHoraFracionada = valorHoraEmString?.length >= 5
    const limiteCaracteresParaHoraInteira = valorHoraEmString?.length >= 3

    if (ehHoraFracionada && limiteCaracteresParaHoraFracionada) return
    if (!ehHoraFracionada && !limiteCaracteresParaHoraInteira && !ehHoras) return

    state[tipo][index][campo] = valor
  },
  alternarUsoDeApontamentosCustomizados (state, { tipo, valor }) {
    const limites = state[tipo]

    if (valor && limites.length === 3) {
      const ultimoLimite = limites[2]?.boundary || 0
      const novoLimite = ultimoLimite + 8.0

      const novosLimites = [
        { boundary: novoLimite, overtime_name: 'overtime_d' },
        { boundary: 9999, overtime_name: 'overtime_e' }
      ]

      state[tipo].push(...novosLimites)
    } else if (!valor) {
      state[tipo] = limites.slice(0, 3)
    }
  },
  setPausePairs (state, {field, index, value}) {
    state.pausePairs[index][field] = value
  },
  setNightTimeDuration (state, {field, value}) {
    state.nightTimeDuration[field] = value
  },
  setDuracaoDaNonaHora (state, {field, value}) {
    state.duracaoDaNonaHora[field] = value
  },
  loading (state, payload) {
    state.loading = payload
  }
}

const actions = {
  async carregarGruposDeRubrica ({ commit }) {
    let gruposDeRubricas = []

    const resultado = await API.grupoDeRubrica.get()
    if (resultado.status === 200 && resultado.data['grupo_de_rubricas']) {
      gruposDeRubricas = resultado.data['grupo_de_rubricas']
    }

    commit('grupoDeRubricas', {selected: null, loaded: gruposDeRubricas})
  },
  async loadCalcs ({ commit, state }, customRuleId) {
    commit('loading', true)

    const api = await API.customRules.get({id: customRuleId})
    const regraDeCalculo = api.data.success[0]
    const infos = {gruposDeRubricas: state.grupoDeRubricas.loaded}

    commit('setFromState', {id: customRuleId, ...formatarLeituraRegraCustomizada(regraDeCalculo, infos)})

    commit('loading', false)
  },
  async save ({ state }, {dayCases, dayCasesHash}) {
    const caseFields = [
      'id', 'name', 'journey',
      'punch_times', 'last_exit_time', 'is_national_holiday',
      'is_tomorrow_holiday'
    ]

    const dayCasesReducer = (acc, dayCase) => {
      const caseReducer = (caseObj, key) => {
        if (caseFields.includes(key)) {
          return {...caseObj, [key]: dayCase[key]}
        } else if (dayCase[key].hasOwnProperty('value') && dayCase[key].value) {
          return {...caseObj, [key]: dayCase[key].value}
        }
        return caseObj
      }

      const caseFormatted = Object.keys(dayCase).reduce(caseReducer, {})
      return [...acc, caseFormatted]
    }
    const customRule = formatarEscritaRegraCustomizada(state)
    const params = {
      ...customRule,
      dayCases: dayCases.reduce(dayCasesReducer, []),
      dayCasesHash
    }

    if (state.id) {
      await API.customRules.save({id: state.id}, params)
    } else {
      await API.customRules.save(params)
    }
  }
}

export default {
  namespaced: true,
  state: obterEstadoPadrao(),
  getters,
  mutations,
  actions
}
