import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'
import moment from 'moment'
import store from 'src/store'
import { obterPeriodoDeFolhaDoEmpregado } from 'src/utils/formatadoras/formatadoras'
import { realizeSSOLogin, startRefreshTokensWorkerForSSO } from './sso'
import { retrieveCurrentAuthenticatedUserAmplify, verifyAuthAndLoadUserAndStyles } from './auth'
import {
  configurarPrototypePushParaNaoMostrarErroNavegacaoDuplicada,
  configurarPrototypeReplaceParaNaoMostrarErroNavegacaoDuplicada
} from './utils'
import { formbricksGlobal } from 'src/main'
import { inicializarKeycloakRefreshTokenWorker } from 'src/typescript/servicos/keycloak/renovarToken.servico'
import { enviarEventoPosthog } from 'src/typescript/servicos/posthog/posthog.servico'

Vue.use(Router)
configurarPrototypePushParaNaoMostrarErroNavegacaoDuplicada(Router)
configurarPrototypeReplaceParaNaoMostrarErroNavegacaoDuplicada(Router)

const router = new Router({routes})
// 1º Lets check if we need to sign in using sso. After this step, the next beforeEach will
// se that user is already sign in and use this user
// we use this variable to unregister this beforeeach. Its to ensure that is called only once
export const unregisterSSOLogin = router.beforeEach(realizeSSOLogin)

// 2º its not a router event each, its a pure function. it will only be callend when pages is lodaded
retrieveCurrentAuthenticatedUserAmplify()

// 3º Load current user infos from back, and verify if users still logged in, if is not the case
// the appropriate logout method will be applied
router.beforeEach(verifyAuthAndLoadUserAndStyles)

// 5º Set the title of current page
router.afterEach(function (to) {
  if (to.meta.title) {
    document.title = to.meta.title
    enviarEventoPosthog(`acessou a tela ${to.meta.title}`)
  } else {
    document.title = 'Pontotel'
  }
})

window.router = router

// 5º If is logged with SSO we must start the worker
router.afterEach(startRefreshTokensWorkerForSSO)
router.afterEach(inicializarKeycloakRefreshTokenWorker)
router.afterEach(() => {
  if (formbricksGlobal.verificarFormbricksEstaAtivo() && formbricksGlobal.verificarUsuarioEmSessaoAtiva()) {
    formbricksGlobal.registrarMudancaDeRota()
  }
})
router.queueEmployeesInTimesheet = function (employeesIds, baseDate) {
  // use this function when you want to go to timesheet with a queue of employees

  const employees = store.state.common.employees.items.filter(i => employeesIds.includes(i.id))

  if (employees.length === 0) {
    throw new Error('queueEmployeesInTimesheet received invalid params')
  }

  store.commit('timesheet/header/resetState')
  store.commit('sheet/resetState')
  store.commit('timesheet/header/queueEmployees', employees)

  router.push({
    name: 'timesheet',
    params: {
      type: 'queue',
      startDate: moment(baseDate).format('YYYY-MM-DD'),
      endDate: moment(baseDate).format('YYYY-MM-DD'),
      id: employees[0].id
    }
  })
}

router.goToTimesheet = function (baseDate, employee = null, company = null, blank = false) {
  if (!employee && !company) {
    throw new Error('goToTimesheet router method received no parameters')
  } else if (employee && !employee.closingDate) {
    throw new Error('goToTimesheet router method needs a employee object with the closing date')
  }

  let startDate, endDate

  if (employee) {
    const period = obterPeriodoDeFolhaDoEmpregado(baseDate, employee.closingDate)
    startDate = period.startDate
    endDate = period.endDate
  } else {
    const date = moment.isMoment(baseDate) ? baseDate : moment(baseDate)
    startDate = date
    endDate = date
  }

  const params = {
    name: 'timesheet',
    params: {
      type: employee ? 'employee' : 'work-place',
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      id: employee ? employee.id : company.id
    }
  }

  if (blank) {
    window.open(router.resolve(params).href, '_blank')
  } else {
    router.push(params)
  }
}

export default router
