<template lang="pug">
  div.p-multi-select(translate="no", :class="{'display-mode': displayMode}")
    multiselect(
      v-model="parsedState"
      :options="customOptions"
      :placeholder="placeholderSeletor()"
      :forcedLabelUp="Boolean(forcedPlaceholder)"
      multiple
      :preserve-search="true"
      :close-on-select="false"
      :clear-on-select="false"
      :track-by="trackBy"
      :label="labelItem"
      :loading="objectLoading || commonLoading"
      :openDirection="openDirection"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      selectGroupLabel=""
      deselectGroupLabel=""
      :disabled="disabled"
      name="multiselect"
      @dblclick="dblclick",
      @select="handleSelect"
      ref="multiselect"
      :class="{'error': error, 'required': required}"
      :internalSearch="false"
      @search-change="changeSearch"
      @open="seletorAberto = true"
      @close="seletorAberto = false"
    )
      template(v-if="mostrarBotaoCarregarMais")
        PButton(
          slot="opcaoEspecial"
          @click="fetchItemsComPaginacao"
          color="primary"
          size="sm"
        ) {{ $t('compartilhados.selectors.PMultiSelect.carregarMais') }}

      template(
        slot="selection"
        slot-scope="{ values, search, isOpen }"
      )
        div(v-if="!isOpen")
          span.multiselect__single(v-if="!forcedPlaceholder && values.length > 1")
            | {{ values.length }} itens {{ displayMode ? '' : $t('compartilhados.selectors.PMultiSelect.selecionados') }}

          span.multiselect__single(v-else-if="!forcedPlaceholder && values.length === 1")
            | {{ values[0][labelItem] }}

          span.multiselect__single(v-else="")
            | {{ forcedPlaceholder }}

      template(
        slot="tag",
        slot-scope="{ option, remove }"
      )
        span.custom__tag(style="display: none;")

      span(slot="noResult").multiselect__no-result
        | {{ $t('compartilhados.selectors.PMultiSelect.nadaEncontrado') }}

      template(slot="noOptions").multiselect__no-options
        | {{ $t('compartilhados.selectors.PMultiSelect.nenhumaOpcao') }}

    ErrorMsg(:error="error")

</template>

<script>
import canUseCommonState from 'src/common/mixins/canUseCommonState'
import Multiselect from './_MultiselectWrapper'
import ErrorMsg from 'src/common/inputs/ErrorMsg'
import { includesSearch } from 'src/utils'
import { uniq } from 'lodash'

export default {
  name: 'PMultiSelect',
  mixins: [canUseCommonState()],
  components: {
    Multiselect, ErrorMsg
  },
  props: {
    usaPaginacao: Boolean,
    disabled: Boolean,
    labelItem: { type: String, required: true },
    title: { type: String },
    forcedPlaceholder: { type: String },
    trackBy: { type: String, default: 'id' },
    customLabel: { type: Function },
    loading: { type: Boolean },
    required: { type: Boolean, default: false },
    displayMode: { type: Boolean, default: false },
    openDirection: { type: String, default: 'below' },
    permiteSelecionarTodos: { type: Boolean, default: true },
    error: String,
    tamanhoMaximoCaracteresInseridos: {
      type: Number,
      'default': 200
    }
  },
  data () {
    return {
      selectedAll: false,
      selectingAll: false,
      search: '',
      seletorAberto: false
    }
  },
  created () {
  },
  computed: {
    mostrarBotaoCarregarMais () {
      const {usaPaginacao} = this
      if (!usaPaginacao) return
      return this.aindaFaltaMostrarPaginas || false
    },
    aindaFaltaMostrarPaginas () {
      let { useCommon } = this
      if (useCommon.includes('/')) {
        useCommon = useCommon.split('/')[0]
      }
      return this.$store.getters[`common/${useCommon}/aindaFaltaMostrarPaginas`]
    },
    customOptions: {
      get () {
        if (this.state.loaded.length > 0) {
          let search = null
          const haveFieldCode = this.state.loaded[0].hasOwnProperty('code')
          if (this.displayMode) {
            search = (field) => {
              return this.state?.selected?.filter(option => {
                return option[field]
                  ? includesSearch(option[field], this.search)
                  : false
              })
            }
          } else {
            search = (field) => {
              return this.state.loaded.filter(option => {
                return option[field]
                  ? includesSearch(option[field], this.search)
                  : false
              })
            }
          }

          const searchLabelItemAndCode = () => {
            const searchLabelItemResult = search(this.labelItem)
            const buscarPorCodigo = search('code')
            const buscarPorPin = search('phoneclockPassword')
            const result = searchLabelItemResult?.concat(buscarPorCodigo, buscarPorPin)
            return uniq(result)
          }

          const options = haveFieldCode
            ? searchLabelItemAndCode()
            : search(this.labelItem)

          if (this.displayMode || !this.permiteSelecionarTodos) {
            return options
          } else {
            return [
              {[this.labelItem]: this.$t('compartilhados.selectors.PMultiSelect.todos'), id: '__internal_selectall'},
              ...options
            ]
          }
        } else {
          return []
        }
      }
    },
    parsedState: {
      set (value) {
        if (!this.selectingAll && !this.displayMode) {
          this.state = { selected: value.filter(i => i.id !== '__internal_selectall') }
          this.$emit('change')
        }
        this.selectingAll = false
      },
      get () {
        return this.state.selected
      }
    },
    objectLoading: {
      get () {
        return this.state.loading
      }
    }
  },
  methods: {
    placeholderSeletor () {
      if (this.seletorAberto && !this.displayMode) {
        return `${this.title} - ${this.quantidadeSelecionados()}`
      }
      return this.title
    },
    quantidadeSelecionados () {
      if (this.state.selected) {
        const quantidade = this.state.selected.length
        let texto = this.$t('compartilhados.selectors.PMultiSelect.nenhumSelecionado')

        if (quantidade === 1) {
          texto = quantidade + this.$t('compartilhados.selectors.PMultiSelect.quantidadeSelecionada')
        } else if (quantidade > 1) {
          texto = quantidade + this.$t('compartilhados.selectors.PMultiSelect.quantidadeSelecionados')
        }
        return texto
      }
      return '0'
    },
    async fetchItemsComPaginacao (append = true) {
      let {useCommon, search, withFields, withMinimalInformation, withParams} = this
      if (useCommon.includes('/')) {
        useCommon = useCommon.split('/')[0]
      }
      const payload = { append, search, withFields, withMinimalInformation, withParams }
      this.$store.dispatch(`common/${useCommon}/fetchItemsComPaginacao`, payload)
    },
    open () {
      this.$refs.multiselect.activate()
    },
    dblclick (...params) {
      if (params[0].name !== this.$t('compartilhados.selectors.PMultiSelect.todos')) {
        this.$emit('dblclick', ...params)
      }
    },
    async carregarItensComPaginacao () {
      let {useCommon, withFields, withMinimalInformation, withParams} = this
      if (useCommon.includes('/')) {
        useCommon = useCommon.split('/')[0]
      }
      const payload = {withFields, withMinimalInformation, withParams}
      await this.$store.dispatch(`common/${useCommon}/fetchItems`, payload)
    },
    async handleSelect (item) {
      if (item.id === '__internal_selectall') {
        this.selectingAll = true

        if (this.usaPaginacao) {
          await this.carregarItensComPaginacao()
        }

        let value

        if (this.selectedAll) {
          value = []
        } else {
          value = [...this.state.loaded]
        }
        const idsOpcoesCustomizadas = this.customOptions.map(opcao => opcao.id)

        this.state = { selected: value.filter(i => i.id !== '__internal_selectall' && idsOpcoesCustomizadas.includes(i.id)) }
        this.$emit('change')

        this.selectedAll = !this.selectedAll
      }
    },
    verificaTamanhoCaracteres () {
      const motivo = this.$refs.multiselect._data.search
      if (motivo.length > this.tamanhoMaximoCaracteresInseridos) {
        this.$refs.multiselect._data.search = motivo.substring(0, this.tamanhoMaximoCaracteresInseridos)
      }
    },
    changeSearch (query) {
      const querySemEspacos = query.trim()

      this.search = querySemEspacos
      this.verificaTamanhoCaracteres()
      if (this.usaPaginacao) {
        // false é importante pois quando mudar a string de pesquisa queremos iniciar uma nova e não adicionar ao final
        this.fetchItemsComPaginacao(false)
      }
    }
  }
}
</script>

<style>
.p-select__error--message {
  display: block;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--color-danger);
}
.p-select__error--placeholder {
  color: transparent;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.p-multi-select {
  box-sizing: border-box;
}

.multiselect__single {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.display-mode .multiselect__element {
  cursor: default !important;
}
.display-mode .multiselect__option--selected, .display-mode .multiselect__option--highlight, .display-mode .multiselect__option--highlight:after {
  cursor: default !important;
  color: #35495e !important;
  background: transparent !important;
}
</style>
