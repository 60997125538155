import swal from 'sweetalert2'
import moment from 'moment'
import store from './store'
import _ from 'lodash'
import chroma from 'chroma-js'
import { ErroRespostaHTTPInvalida } from 'src/tipos/erros/servidor'
import { obterComNotacaoPonto } from 'src/utils/outro/outro'

export function inputTimeToFloat (time) {
  if (time.includes('h')) {
    const hours = time.slice(0, -3)
    const minutes = time.slice(-3, -1)
    return parseInt(hours) + (parseInt(minutes) / 100)
  } else {
    const hours = time.slice(0, 2)
    const minutes = time.slice(3, 5)
    return hoursMinsToFloat(hours, minutes)
  }
}

export function hoursMinsToFloat (hours, minutes) {
  return parseInt(hours) + (parseInt(minutes) / 60)
}

export function modalDeSucesso (texto, titulo = '', executar = () => {}) {
  if (!texto) return
  swal({
    type: 'success',
    text: texto,
    title: titulo
  }).then(() => {
    executar()
  })
}

export function modalDeErro (texto, titulo = '', executar = () => {}) {
  if (!texto) return
  swal({
    type: 'error',
    text: texto,
    title: titulo
  }).then(() => {
    executar()
  })
}

export function modalDeInformacao (texto, titulo = '') {
  if (!texto) return
  swal({
    type: 'info',
    text: texto,
    title: titulo
  })
}

export function modalDeAlerta (texto, titulo = '', executar = () => {}) {
  if (!texto) return
  swal({
    type: 'warning',
    text: texto,
    title: titulo
  }).then(() => {
    executar()
  })
}

/**
 * Processa a promise e retorna mensagens personalizadas para cada cenário (success, info, error)
 * @param {Promise} promise
 * @param {Object.<string, string>} messages
 * @returns se houve sucesso ou falha na promise
 */
export async function processarRequisicaoFinalizandoComModal (promise, messages = {
  success: '',
  error: '',
  info: ''
}) {
  try {
    const response = await promise

    const {body} = response

    if (!body?.success && messages.success) {
      modalDeSucesso(messages.success || 'Ação executada com sucesso')
    }
    if (!body?.info && messages.info) {
      modalDeInformacao(messages.info || 'Ação executada com sucesso')
    }

    if (body.success || body.created || body.deleted) {
      modalDeSucesso(body.success || messages.success || 'Ação executada com sucesso')
    }
    return true
  } catch (erro) {
    modalDeErro(erro?.body?.error ? erro.body.error : messages.error || 'Ocorreu um erro executar sua ação')
    return false
  }
}

export async function modalDeConfirmacao (params, configDefault = {
  title: '',
  type: 'warning',
  showCancelButton: true,
  confirmButtonClass: 'btn-danger',
  confirmButtonText: 'Confirmar',
  cancelButtonText: 'Cancelar'
}) {
  if (!params.text) return false
  const boleano = await swal({
    title: params.title || configDefault.title,
    text: params.text,
    showCancelButton: true,
    type: configDefault.type,
    confirmButtonClass: configDefault.confirmButtonClass,
    confirmButtonText: params.confirmButtonText || configDefault.confirmButtonText,
    cancelButtonText: params.cancelButtonText || configDefault.cancelButtonText
  })
  return boleano
}

export function hasPermission (conditions, userRoles) {
  let isValid = false

  const verifyPermission = (spec, roles) => {
    return (typeof _.find(roles, _.pick(spec, 'module', 'action', 'filter')) !== 'undefined')
  }

  if (!userRoles) {
    userRoles = store.getters.getUserInfo.roles
  }

  const userRolesNames = userRoles.map(r => r.name)

  if (conditions.module) {
    if (conditions.filter) {
      const filters = conditions.filter

      for (let i = 0; i < filters.length; i++) {
        conditions.filter = filters[i]

        isValid = isValid || verifyPermission(conditions, userRoles)
      }
    } else {
      isValid = verifyPermission(conditions, userRoles)
    }
  } else if (conditions.any) {
    for (const role of conditions.any) {
      isValid = userRolesNames.includes(role)
      if (isValid) {
        break
      }
    }
  } else if (conditions.multiple) {
    const rolesUserHasThatIsRequired = []
    for (const role of conditions.any) {
      if (userRolesNames.includes(role)) {
        rolesUserHasThatIsRequired.push(role)
      }
    }
    isValid = rolesUserHasThatIsRequired.length === conditions.multiple.length
  }
  return isValid
}

export function rangeDates (startDate, endDate) {
  startDate = moment.isMoment(startDate) ? startDate.toDate() : startDate
  endDate = moment.isMoment(endDate) ? endDate.toDate() : endDate

  const dates = []
  let currentDate = startDate
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  while (currentDate <= endDate) {
    dates.push(currentDate)
    currentDate = addDays.call(currentDate, 1)
  }
  return dates.map(i => moment(i))
}

export function groupBy (data, key) {
  return _.chain(data)
    .groupBy(i => obterComNotacaoPonto(i, key))
    .toPairs()
    .value()
}

export function range (lowEnd, highEnd) {
  if (!Number.isInteger(lowEnd) || !Number.isInteger(highEnd)) {
    throw new Error('Range function of utils received invalid parameters')
  }

  const list = []
  for (let i = lowEnd; i <= highEnd; i++) {
    list.push(i)
  }
  return list
}

export function orderEmployees (listEmployees, by = 'name', reversed = false) {
  const final = [...listEmployees]
  if (by === 'name') {
    final.sort(function order (a, b) {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      }
      return 0
    })
  } else if (by === 'code') {
    final.sort(function order (a, b) {
      return parseInt(a.code) - parseInt(b.code)
    })
  }
  if (reversed) {
    final.reverse()
  }
  return final
}

export function includesSearch (campo, valorDaBusca) {
  const valorDaBuscaSemEspaco = valorDaBusca.trim()
  if (typeof campo === 'number') {
    campo = campo.toString()
  }
  return noSpecialChar(campo.toLowerCase()).includes(noSpecialChar(valorDaBuscaSemEspaco.toLowerCase()))
}

export function noSpecialChar (text) {
  text = text.toLowerCase()
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
  return text
}

export function validateCnpj (cnpj) {
  /* eslint-disable */
  cnpj = cnpj.replace(/[^\d]+/g,'');

  if(cnpj == '') return false;

  if (cnpj.length != 14)
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(0))
    return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
      pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
    return false;

  return true;
  /* eslint-enable */
}

export function validateCpf (cpf) {
  /* eslint-disable */
  cpf = cpf.replace(/[^\d]+/g,'');
  if(cpf == '') return false;

  // Valida 1o digito
  let add = 0;
  for (let i=0; i < 9; i ++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i ++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
  /* eslint-enable */
}

export async function openElementInFullscreen (elem, onCloseFullScreen) {
  if (elem.requestFullscreen) {
    await elem.requestFullscreen()
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    await elem.mozRequestFullScreen()
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    await elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    await elem.msRequestFullscreen()
  }

  if (document.addEventListener) {
    document.addEventListener('webkitfullscreenchange', exitHandler, false)
    document.addEventListener('mozfullscreenchange', exitHandler, false)
    document.addEventListener('fullscreenchange', exitHandler, false)
    document.addEventListener('MSFullscreenChange', exitHandler, false)
  }

  function exitHandler () {
    if (document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null) {
      onCloseFullScreen()
    }
  }
}

export function closeFullscreen () {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}

export function isostringToMoment (str) {
  return moment(str, 'YYYY-MM-DDTHH:mm:ss')
}

export function genPalletFromBaseColors (numColors, baseColors) {
  if (numColors === 1) {
    return baseColors
  }
  const backgroundColors = chroma.scale(baseColors).mode('lab')
  return range(0, numColors)
    .map((i, index) => backgroundColors(index / (numColors - 1)).hex())
}

export function rightJourneyTextAndColor (journey) {
  const colors = {
    'default': '#f5f5f5',
    'danger': '#ff6955',
    'success': '#0c9b73',
    'info': '#00a2b5',
    'warning': '#ffcb55',
    'primary': '#2c3e50'
  }
  const start = moment(journey.start)
  const end = moment(journey.end)

  let text = `${start.format('HH:mm')} - ${end.format('HH:mm')}`

  if (start.isSame(end)) {
    text = journey.name
  }
  return {
    text,
    color: colors[journey.color]
  }
}

export function validatePermission (module, action) {
  // use this function as a decorator in methods that you wanna
  // validate the prop AllowedActions of the component

  return function (target, key, descriptor) {
    const original = descriptor.value
    descriptor.value = function () {
      if (hasPermission({ module, action })) {
        return original.apply(this, arguments)
      }
    }
  }
}

export function executeShortcut (event, actions) {
  // event is a Keyboard event
  // actions must be a object where the key is the shortcut and the value a function
  // example of action:
  // {
  //    // you can use a | to have multiple shortcuts for the same function
  //    'esc|i': () => { alert('oii') },
  //    // to use ctrl and shift always use they in the following worder => [shift, ctrl]
  //    'i+ctrl': () => alert('vai filhao'),
  // }

  const friendlyKeys = {
    'Escape': 'esc',
    'ArrowUp': 'up',
    'ArrowDown': 'down',
    'ArrowRight': 'right',
    'ArrowLeft': 'left',
    'Tab': 'tab'
  }

  const key = friendlyKeys[event.key] || event.key.toLowerCase()
  const ctrl = event.ctrlKey
  const shift = event.shiftKey

  let shortcut = key
  if (shift) { shortcut = shortcut + '+shift' }
  if (ctrl) { shortcut = shortcut + '+ctrl' }

  let executedShortcut = false

  Object.keys(actions).forEach(s => {
    const ors = s.split('|')
    if (ors.find(i => i === shortcut)) {
      actions[s](event)
      executedShortcut = true
    }
  })

  return {executedShortcut}
}

export function runMultipleFuncsInParallel (listOfAsyncFunctions, limitParallel) {
  async function doWork (iterator) {
    /* eslint-disable */
    for (let [index, func] of iterator) {
      await func()
    }
    /* eslint-enable */
  }

  const workers = new Array(limitParallel).fill(listOfAsyncFunctions.entries()).map(doWork)

  return Promise.all(workers)
}

export function getHierarchy (region, regions) {
  const h = []

  if (region && (!_.isEmpty(region))) {
    h.push(region)

    let currentParent = region.parent

    while (currentParent && (currentParent !== '')) {
      const parent = _.find(regions, {id: currentParent})
      h.unshift(parent)

      if (_.has(parent, 'parent')) {
        currentParent = parent.parent
      } else {
        currentParent = ''
      }
    }
  }

  return h
}

export function getHierarchyName (region, regions) {
  const h = getHierarchy(region, regions)

  if (h.length) {
    let hName = h[0].name
    for (let i = 1; i < h.length; i++) {
      hName = hName + ' / ' + h[i].name
    }
    return hName
  }
}

export function convertKeysToSnakeCase (obj) {
  return _.mapKeys(obj, (value, key) => {
    return _.snakeCase(key)
  })
}

export function setCharAt (str, index, chr) {
  if (index > str.length - 1) return str
  return str.substring(0, index) + chr + str.substring(index + 1)
}

export function formatString24Hours (input) {
  let correctInput = input

  if (Number(correctInput[0]) > 2) {
    correctInput = setCharAt(correctInput, 0, '2')
  }
  if (Number(correctInput[0]) === 2 && Number(correctInput[1]) > 3) {
    correctInput = setCharAt(correctInput, 1, '3')
  }
  if (Number(correctInput[3]) > 5) {
    correctInput = setCharAt(correctInput, 3, '5')
  }

  return correctInput
}

export async function pegarJornada () {
  await store.dispatch('common/journeys/fetchItems')
  const loadedJourneys = store.state.common.journeys.items
  const firstWork = loadedJourneys.find(i => i.kind === 0)
  const folga = loadedJourneys.find(i => i.name.includes('Folga'))

  return [firstWork, folga]
}

export function escalaEstaValida (jornadaDeTrabalho, jornadaDeFolga) {
  if (jornadaDeTrabalho && jornadaDeFolga) {
    if (jornadaDeTrabalho.kind === 0 && jornadaDeFolga.name.includes('Folga')) {
      return true
    }
  }
  return false
}

export function montarEscala (firstWork, folga) {
  const escalaMontada = [firstWork, firstWork, firstWork, firstWork, firstWork, folga, folga]

  return escalaMontada
}

export async function adicionarNovaInvalida () {
  const [jornadaDeTrabalho, jornadaDeFolga] = await pegarJornada()
  if (!escalaEstaValida(jornadaDeTrabalho, jornadaDeFolga)) {
    return true
  }
  return false
}

export async function erroAdicionarNovaEscala (erro) {
  if (await erro) {
    modalDeErro('Para continuar com esta operação é necessário que existam jornadas cadastradas no sistema')
  }
}

export function darFeedbackDeAcordoComRespostaHttp (resposta) {
  if (resposta.data && resposta.data.success) {
    return resposta.data.success
  } else if (resposta.data && resposta.data.error) {
    return resposta.data.error
  } else if (resposta.status) {
    return darFeedbackGenericoDeAcordoComCodigoHttp(resposta.status)
  }
  throw new ErroRespostaHTTPInvalida()
}

export function darFeedbackGenericoDeAcordoComCodigoHttp (codigoHttp) {
  // TODO: completar com mais opções de códigos
  const retornarMensagemDeSucesso = () => {
    if (!(codigoHttp >= 200 && codigoHttp < 300)) return null

    const mensagensDeSucesso = {
      '200': 'Operação realizada com sucesso!',
      '204': 'Operação realizada com sucesso!'
    }
    return mensagensDeSucesso[codigoHttp] || 'Operação realizada com sucesso!'
  }

  const retornarMensagemDeErroNoCliente = () => {
    if (!(codigoHttp >= 400 && codigoHttp < 500)) return null

    const mensagensErrosNoCliente = {
      '403': 'Você não tem permissão para realizar essa operação.'
    }
    return mensagensErrosNoCliente[codigoHttp] || 'Ocorreu um erro inesperado, por favor tente novamente!'
  }

  const retornarMensagemDeErroNoServidor = () => {
    if (!(codigoHttp >= 500)) return null

    const mensagensErrosNoServidor = {
      '500': 'Aconteceu um erro no servidor e sua requisição não foi completada no momento. Por favor tente novamente.'
    }
    return mensagensErrosNoServidor[codigoHttp] || 'Ocorreu um erro inesperado, por favor tente novamente!'
  }

  if (codigoHttp >= 200 && codigoHttp < 300) {
    return retornarMensagemDeSucesso()
  } else if (codigoHttp >= 400 && codigoHttp < 500) {
    return retornarMensagemDeErroNoCliente()
  } else if (codigoHttp >= 500) {
    return retornarMensagemDeErroNoServidor()
  }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export function ehNumero (valor) {
  return !isNaN(Number.parseInt(valor))
}
