<template lang="pug">
  .wrapper-configs
    label.fs-21 {{ $t('compartilhados.SmartTable.Configs.titulo') }}

    h5 {{ $t('compartilhados.SmartTable.Configs.ordemDosCampos') }}
    draggable.grid(v-model='configs.headerItems', direction="horizontal", draggable=".draggable")
      .column(v-for="(item, index) in headerItemsDisplay" :key="item.column", :class="{'draggable': item.movable}")

        img(v-if="item.movable" src="https://cdn.iconscout.com/icon/free/png-256/drag-indicator-1782445-1512354.png")

        template(v-if="item.hiddable")
          PCheckbox(v-model="configs.headerItems[index].isToShow", :label="item.column")

        template(v-else)
          | {{item.column}}

    PButton(:color="corDoBotaoSalvar", size="md", @click="apply", :disabled="!validToSave") {{ $t('compartilhados.SmartTable.Configs.botaoSalvar') }}
    PButton(color="danger", size="md", @click="close") {{ $t('compartilhados.SmartTable.Configs.botaoCancelar') }}
    PButton(color="info", size="md", @click="reset") {{ $t('compartilhados.SmartTable.Configs.botaoVoltarPadrao') }}

</template>

<script>
import draggable from 'vuedraggable'
import { configHelpers } from './layout'

export default {
  name: 'Configs',
  components: {draggable},
  props: {
    name: {type: String, required: true},
    headerItems: {type: Array, required: true},
    config: {type: Object, required: true}
  },
  created () {
    this.configs = configHelpers.getConfigs(this.name, this.headerItems)
  },
  data () {
    return {
      configs: {
        headerItems: []
      }
    }
  },
  computed: {
    headerItemsDisplay () {
      return this.configs.headerItems.map(i => ({
        ...i,
        movable: i.userCustom?.movable !== undefined ? i.userCustom.movable : true,
        hiddable: i.userCustom?.hiddable !== undefined ? i.userCustom.hiddable : true
      }))
    },
    validToSave () {
      // only allow user to save if theres 4 or more itens set to be displayed
      // otherwise the layout could end really broken
      return this.configs.headerItems.filter(i => i.isToShow).length >= 4
    },
    corDoBotaoSalvar () {
      if (this.validToSave) {
        return 'success'
      }
      return 'default'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    apply () {
      if (!this.validToSave) {
        return
      }

      const configs = configHelpers.saveConfigs(this.name, this.configs)
      this.$emit('close')
      this.$emit('applyConfigs', configs)
    },
    reset () {
      localStorage.removeItem(configHelpers.storageName(this.name))
      this.$emit('applyConfigs', this.configs)
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.sortable-ghost { opacity: 0; }

.wrapper-configs {
  h5 {
    margin: 10px;
  }

  .grid {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 10px;
    border-top: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);

    .column {
      /*width: 100px;*/
      padding: 5px;
      position: relative;
      user-select: none;
      align-items: center;

      display: grid;
      grid-template-columns: 0.2fr minmax(0, 1fr);

      img {
        display: inline;
        height: 20px;
        opacity: 0.3;
        cursor: grab;
      }
    }
  }
}
</style>
