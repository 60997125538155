import { MODULOS } from 'src/core/modulos/index.ts'
import simpleChildrenWrapper from './wrapper'
import i18n from '@/typescript/servicos/i18n/i18n.servico'

const AcessoNegado = () => import('src/components/AcessoNegado/AcessoNegado.vue')
const AcompanhamentoTempoReal = () => import('src/components/AcompanhamentoTempoReal/AcompanhamentoTempoReal.vue')
const ApprovalsRulesDetails = () => import('src/components/ApprovalsRules/details/ApprovalsRulesDetails')
const ApprovalsRulesList = () => import('src/components/ApprovalsRules/list/ApprovalsRulesList.vue')
const Auditor = () => import('src/components/Auditor/Auditor.vue')
const Cognito = () => import('src/components/Cognito/MainScreens/Cognito.vue')
const CompanyDetails = () => import('src/components/Company/details/CompanyDetails.vue')
const CompanyList = () => import('src/components/Company/list/CompanyList.vue')
const ConfirmSignUp = () => import('src/components/Cognito/MainScreens/ConfirmSignUp.vue')
const CriacaoEdicaoDaRegraDeCalculo = () => import('src/components/CustomizacoesDeCalculo/Telas/CriacaoEdicaoRegraDeCalculo.vue')
const CriacaoEdicaoDePerfil = () => import('src/components/Coletores/Componentes/Perfil/CriarOuEditarPerfil.vue')
const CriacaoEdicaoGrupoDeRubricas = () => import('src/components/CustomizacoesDeCalculo/Telas/CriacaoEdicaoGrupoDeRubricas.vue')
const CriarEditarRegraDeNotificacao = () => import('src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/criarEditar/CriarEditarRegraDeNotificacao.vue')
const CriarOuEditarPermissao = () => import('src/components/Permissions/criacaoOuEdicao/CriarOuEditarPermissao.vue')
const DetalhesDadosDaEmpresa = () => import('src/components/CompMan/DetalhesDadosDaEmpresa.vue')
const DetalhesDeRegraDeBancoDeHoras = () => import('src/components/HourBankRules/details/DetalhesDeRegraDeBancoDeHoras.vue')
const DevicesList = () => import('src/components/Devices/DevicesList.vue')
const EmployeeDetails = () => import('src/components/Employee/details/EmployeeDetails.vue')
const EmployeeList = () => import('src/components/Employee/list/EmployeeList.vue')
const EmployerDetails = () => import('src/components/Employer/details/EmployerDetails.vue')
const EmployerList = () => import('src/components/Employer/list/EmployerList.vue')
const ExtraTimeDash = () => import('src/components/DashBoards/ExtraTimeDash')
const FaceBiometric = () => import('src/components/FaceBiometric/FaceBiometricScreen.vue')
const FeriasEAfastamentos = () => import('src/components/FeriasEAfastamentos/FeriasEAfastamentos.vue')
const FileManagementApproval = () => import('src/components/FileManagement/approval/FileManagementApproval.vue')
const FileManagementList = () => import('src/components/FileManagement/list/FileManagementList.vue')
const GerenciamentoDeFerias = () => import('src/typescript/modulos/calendario-de-trabalho/GerenciamentoDeFerias/apresentacao/GerenciamentoDeFerias.vue')
const GestaoDeSolicitacoes = () => import('src/components/Approvals/GestaoDeSolicitacoes')
const GroupsList = () => import('src/components/Groups/GroupsList.vue')
const HolidayList = () => import('src/components/Holiday/list/HolidayList.vue')
const Home = () => import('@/components/Home/Home.vue')
const JourneyList = () => import('src/components/Journey/JourneyList.vue')
const LancarSolicitacoes = () => import ('@/components/Approvals/LancarSolicitacoes')
const ListagemDaCustomizacaoDeCalculo = () => import('src/components/CustomizacoesDeCalculo/Telas/ListagemDaCustomizacaoDeCalculo.vue')
const ListagemDasRegrasDeBancoDeHoras = () => import('src/components/HourBankRules/list/ListagemDasRegrasDeBancoDeHoras.vue')
const ListagemDeColetores = () => import('src/components/Coletores/Telas/ListagemDeColetores.vue')
const ListagemDeLogDeOperacoes = () => import('src/components/LogDeOperacoes/ListagemDeLogDeOperacoes.vue')
const ListagemDePermissoes = () => import('src/components/Permissions/list/ListagemDePermissoes.vue')
const ListagemDeVerbas = () => import('src/components/ParametrizadorE02/Telas/ListagemDeVerbas.vue')
const LockerManagement = () => import('src/components/Locker/LockerManagement.vue')
const LockerRulesDetails = () => import('src/components/LockerRules/details/LockerRulesDetails.vue')
const LockerRulesList = () => import('src/components/LockerRules/list/LockerRulesList.vue')
const MassChanges = () => import('src/components/MassChanges/MassChanges.vue')
const MonitorEntrance = () => import('src/components/Monitors/Entrance/MonitorEntrance.vue')
const MonitorWorkplace = () => import('src/components/Monitors/Workplace/MonitorWorkplace.vue')
const MultipleChanges = () => import('src/components/Employee/multipleChanges/MultipleChanges.vue')
const PainelDePontos = () => import('src/components/PainelDePontos/PainelDePontos')
const PainelDeSolicitacoesDeHorasExtras = () => import('src/typescript/modulos/acompanhamento/PainelSolicitacoesDeHorasExtras/apresentacao/PainelSolicitacoesDeHorasExtras.vue')
const PegaPontoDetails = () => import('src/components/REP/pegaPontoDetails/PegaPontoDetails')
const ReasonList = () => import('src/components/Reason/list/ReasonList.vue')
const RegrasDeNotificacao = () => import('src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/RegraDeNotificacao.vue')
const RegrasDeNotificacaoListagem = () => import('src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/listagem/RegraDeNotificacaoListagem.vue')
const REP = () => import('src/components/REP/repList.vue')
const RepDetails = () => import('src/components/REP/repDetails/RepDetails')
const Reports = () => import('src/components/Reports/Reports.vue')
const RoutineDetails = () => import('src/components/Routine/details/RoutineDetails.vue')
const RoutineList = () => import('src/components/Routine/list/RoutineList.vue')
const SetupDeIntegracoes = () => import('src/components/Sankhya/SetupDeIntegracoes.vue')
const Timesheet = () => import('src/components/Sheets/Timesheet/Timesheet.vue')
const UserList = () => import('src/components/User/list/UserList.vue')
const VerifyTimesheet = () => import('src/components/Sheets/VerifyTimesheet/VerifyTimesheet')
const WhbManagement = () => import('src/components/Whb/WhbManagement.vue')
const WorkSchedule = () => import('src/components/WorkSchedule/WorkSchedule.vue')

const UUIDPathUrl = ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})'
const UUIDOrObjectIdPathUrl = ':id([a-fA-F0-9]{24}|[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})';



const ROTAS_BASE = [
  {
    path: '/cognito/confirmSignUp',
    name: 'cognito.confirmSignUp',
    component: ConfirmSignUp,
    props: function (route) {
      return {
        email: route.query.email,
        tempPassword: route.query.code
      }
    },
    meta: {
      moduloRequerido: MODULOS.BASE,
      dontNeedAuth: true,
      title: 'confirmar entrada'
    }
  },

  {
    path: '/cognito/applyForgotPassword',
    name: 'cognito.applyForgotPassword',
    component: Cognito,
    props: function (route) {
      return {
        type: 'applyForgotPassword',
        forgotPasswordData: route.query
      }
    },
    meta: {
      moduloRequerido: MODULOS.BASE,
      dontNeedAuth: true
    }
  },

  {
    path: '/cognito/:type',
    name: 'cognito',
    component: Cognito,
    props: true,
    meta: { dontNeedAuth: true, title: 'login', moduloRequerido: MODULOS.BASE }
  },

  {
    path: '/acesso-negado',
    name: 'AcessoNegado',
    component: AcessoNegado,
    meta: {
      title: 'Acesso Negado',
      moduloRequerido: MODULOS.BASE,
      dontNeedAuth: true
    }
  },

  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Pontotel | Início',
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/afast/:id([0-9abcdef]{15,})',
    name: 'afast',
    component: FeriasEAfastamentos,
    props: true,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: 'Afastamentos e Férias'
    }
  },

  {
    path: '/employee',
    name: 'employee',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'employee.list', component: EmployeeList, meta: {title: 'empregados', moduloRequerido: MODULOS.BASE } },
      { path: 'list/:filter/:fieldId([0-9abcdef]{15,})/:fieldName', name: 'employee.list.filter', component: EmployeeList, props: true, meta: {title: i18n.t('router.routes.employee.funcionarios'), moduloRequerido: MODULOS.BASE  } },
      { path: 'new', name: 'employee.new', component: EmployeeDetails, meta: {title: 'Novo empregado', moduloRequerido: MODULOS.BASE } },
      { path: 'all', name: 'employee.multipleChanges', component: MultipleChanges, meta: {title: i18n.t('router.routes.employee.alteracoesMultiplas'), moduloRequerido: MODULOS.BASE } },
      { path: ':id([0-9abcdef]{15,})', name: 'employee.edit', component: EmployeeDetails, props: true, meta: {title: 'Cadastro de empregado', moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/company',
    name: 'company',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'company.list', component: CompanyList, meta: {title: i18n.t('router.routes.company.locais'), moduloRequerido: MODULOS.BASE } },
      { path: 'new', name: 'company.new', component: CompanyDetails, meta: {title: i18n.t('router.routes.company.novoLocal'), moduloRequerido: MODULOS.BASE } },
      { path: ':id([0-9abcdef]{15,})', name: 'company.edit', component: CompanyDetails, props: true, meta: {title: i18n.t('router.routes.company.editarLocal'), moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/employer',
    name: 'employer',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'employer.list', component: EmployerList, meta: {title: i18n.t('router.routes.employer.empregadores'), moduloRequerido: MODULOS.BASE } },
      { path: 'new', name: 'employer.new', component: EmployerDetails, meta: {title: i18n.t('router.routes.employer.novoEmpregador'), moduloRequerido: MODULOS.BASE } },
      { path: ':id([0-9abcdef]{15,})', name: 'employer.edit', component: EmployerDetails, props: true, meta: {title: i18n.t('router.routes.employer.editarEmpregador'), moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/user-group',
    name: 'user-group',
    component: GroupsList,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: i18n.t('router.routes.grupos')
    }
  },

  {
    path: '/workjourney',
    name: 'workjourney',
    component: JourneyList,
    props: true,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: i18n.t('router.routes.jornadas')}
    },

  {
    path: '/routine',
    name: 'routine',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'routine.list', component: RoutineList, meta: {title: i18n.t('router.routes.routine.escalas'), moduloRequerido: MODULOS.BASE } },
      { path: 'new', name: 'routine.new', component: RoutineDetails, meta: {title: i18n.t('router.routes.routine.novaEscala'), moduloRequerido: MODULOS.BASE } },
      { path: ':id([0-9abcdef]{15,})', name: 'routine.edit', component: RoutineDetails, props: true, meta: {title: i18n.t('router.routes.routine.editarEscala'), moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/holidays',
    name: 'holidays',
    component: HolidayList,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: i18n.t('router.routes.feriados')
    }
  },

  {
    path: '/reason',
    name: 'reason',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'reason.list', component: ReasonList, meta: {title: i18n.t('router.routes.motivos'), moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/fechamentos',
    name: 'reports',
    component: Reports,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: i18n.t('router.routes.relatorios')
    }
  },

  {
    path: '/user',
    name: 'user',
    component: simpleChildrenWrapper,
    children: [
      { path: ':id([0-9abcdef]{15,})?', name: 'user.edit', component: UserList, props: true, meta: {title: i18n.t('router.routes.usuarios'), moduloRequerido: MODULOS.BASE } },
      { path: 'new/:employeeId([0-9abcdef]{15,})', name: 'user.new', component: UserList, props: true, meta: {title: i18n.t('router.routes.usuarios'), moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/permissions',
    name: 'permissions',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'permissions.list', component: ListagemDePermissoes, meta: {title: i18n.t('router.routes.permissions.permissoes'), moduloRequerido: MODULOS.BASE } },
      { path: 'new', name: 'permissions.new', component: CriarOuEditarPermissao, meta: {title: i18n.t('router.routes.permissions.novaPermissao'), moduloRequerido: MODULOS.BASE } },
      { path: ':id([0-9abcdef]{15,})', name: 'permissions.edit', component: CriarOuEditarPermissao, props: true, meta: {title: i18n.t('router.routes.permissions.editarPermissao'), moduloRequerido: MODULOS.BASE } }
    ],
    meta: {
      moduloRequerido: MODULOS.BASE
    }
  },

  {
    path: '/dados',
    name: 'dados',
    component: DetalhesDadosDaEmpresa,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: i18n.t('router.routes.dados')
    }
  },

  {
    path: '/log',
    name: 'LogDeOperacoes',
    component: ListagemDeLogDeOperacoes,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: i18n.t('router.routes.logOperacoes')
    }
  },

  {
    path: '/auditor',
    name: 'Auditor',
    component: Auditor,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: 'Auditorias de Integrações'
    }
  },

  {
    path: '/setup',
    name: 'SetupDeIntegracoes',
    component: SetupDeIntegracoes,
    meta: {
      moduloRequerido: MODULOS.BASE,
      title: 'Configuração de Integrações'
    }
  },
]

const ROTAS_MODULO_REGISTRO_DE_PONTO = [
  {
    path: '/timesheet/:type/:startDate/:endDate?/:id([0-9abcdef]{15,})?',
    name: 'timesheet',
    component: Timesheet,
    props: true,
    meta: {
      moduloRequerido: MODULOS.REGISTRO_DE_PONTO,
      dontNeedAuth: false,
      title: i18n.t('router.routes.folhas')
    },
  },

  {
    path: '/painel-pontos',
    name: 'painelDePontos',
    component: PainelDePontos,
    meta: {
      moduloRequerido: MODULOS.REGISTRO_DE_PONTO,
      dontNeedAuth: false,
      title: i18n.t('router.routes.painelDePontos')
    }
  },

  {
    path: '/deviceManage/list',
    name: 'devices-management',
    component: DevicesList,
    meta: {
      moduloRequerido: MODULOS.REGISTRO_DE_PONTO,
      title: i18n.t('router.routes.dispositivos')
    }
  },

  {
    path: '/coletores',
    name: 'coletores',
    component: simpleChildrenWrapper,
    children: [
      {
        path: 'listar',
        name: 'coletores.listar',
        component: ListagemDeColetores,
        meta: {title: 'Coletores', moduloRequerido: MODULOS.REGISTRO_DE_PONTO}
      },
      {
        path: 'perfil',
        name: 'perfil',
        component: simpleChildrenWrapper,
        children: [
          {
            path: 'criar',
            name: 'perfil.criar',
            component: CriacaoEdicaoDePerfil,
            meta: {title: 'Novo perfil', moduloRequerido: MODULOS.REGISTRO_DE_PONTO}
          },
          {
            path: ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
            name: 'perfil.editar',
            props: true,
            component: CriacaoEdicaoDePerfil,
            meta: {title: 'Editar perfil', moduloRequerido: MODULOS.REGISTRO_DE_PONTO}
          }
        ]
      }
    ],
    meta: {
      moduloRequerido: MODULOS.REGISTRO_DE_PONTO
    }
  }
]

const ROTAS_MODULO_REDUCAO_DE_RISCOS = [
  {
    path: '/face-biometric',
    name: 'face-biometric',
    component: FaceBiometric,
    meta: {
      moduloRequerido: MODULOS.REDUCAO_DE_RISCOS,
      title: i18n.t('router.routes.biometria')}
    },

  {
    path: '/gerenciamento-de-arquivos',
    name: 'fileManagement',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'fileManagement.list', component: FileManagementList, meta: {title: i18n.t('router.routes.fileManagement.gerenciamento'), moduloRequerido: MODULOS.REDUCAO_DE_RISCOS } },
      { path: 'aprovacao/:id', name: 'fileManagement.edit', component: FileManagementApproval, props: true, meta: {title: i18n.t('router.routes.fileManagement.aprovacao'), moduloRequerido: MODULOS.REDUCAO_DE_RISCOS } }
    ],
    meta: {
      moduloRequerido: MODULOS.REDUCAO_DE_RISCOS,
    }
  },
]

const ROTAS_MODULO_CALCULOS_E_TRATAMENTO = [
  {
    path: '/verify-timesheet',
    name: 'verifyTimesheet',
    component: VerifyTimesheet,
    meta: {
      title: i18n.t('router.routes.verificarFolha'),
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO
    }
  },

  {
    path: '/fechar-folha-em-conjunto',
    name: 'locker-management',
    component: LockerManagement,
    props: true,
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO,
      title: i18n.t('router.routes.gestaoTravamentos')
    }
  },

  {
    path: '/fast',
    name: 'mass-changes',
    component: MassChanges,
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO,
      title: i18n.t('router.routes.alteracoesRapidas')
    }
  },

  {
    path: '/gerenciamento-banco',
    name: 'whb-management',
    component: WhbManagement,
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO,
      title: i18n.t('router.routes.gestaoBH')
    }
  },

  {
    path: '/rep',
    component: simpleChildrenWrapper,
    children: [
      { path: '', name: 'rep.list', component: REP, meta: {title: i18n.t('router.routes.reps'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: 'new-rep', name: 'rep.new', component: RepDetails, meta: {title: i18n.t('router.routes.rep.novoRep'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: 'edit-rep/:id', name: 'rep.edit', component: RepDetails, props: true, meta: {title: i18n.t('router.routes.rep.editar'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: 'new-pega-ponto', name: 'pega-ponto.new', component: PegaPontoDetails, meta: {title: i18n.t('router.routes.rep.pegaPonto'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: 'edit-pega-ponto/:id', name: 'pega-ponto.edit', component: PegaPontoDetails, props: true, meta: {title: i18n.t('router.routes.rep.editarPegaPonto'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } }
    ],
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO
    }
  },


  {
    path: '/timeoff',
    name: 'timeoff',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'timeoff.list', component: ListagemDasRegrasDeBancoDeHoras, meta: {title: i18n.t('router.routes.timeoff.regraBH'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: 'new', name: 'timeoff.new', component: DetalhesDeRegraDeBancoDeHoras, meta: {title: i18n.t('router.routes.timeoff.novaRegra'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: ':id([0-9abcdef]{15,})', name: 'timeoff.edit', component: DetalhesDeRegraDeBancoDeHoras, props: true, meta: {title: i18n.t('router.routes.approvalsRules.editarRegra'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } }
    ],
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO
    }
  },

  {
    path: '/regras-de-travamento',
    name: 'lockerRules',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'lockerRules.list', component: LockerRulesList, meta: {title: i18n.t('router.routes.lockerRules.regraTravamento'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: 'criacao', name: 'lockerRules.criacao', component: LockerRulesDetails, meta: {title: i18n.t('router.routes.lockerRules.novaRegra'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } },
      { path: UUIDPathUrl, name: 'lockerRules.edit', component: LockerRulesDetails, props: true, meta: {title: i18n.t('router.routes.approvalsRules.editarRegra'), moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO } }
    ],
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO
    }
  },

  {
    path: '/customizacoes-de-calculo',
    name: 'customizacoesDeCalculo',
    component: simpleChildrenWrapper,
    children: [
      {
        path: 'listar',
        name: 'customizacoesDeCalculo.listar',
        component: ListagemDaCustomizacaoDeCalculo,
        meta: {title: 'Customizações de Cálculo', moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO}
      },
      {
        path: 'regra-de-calculo',
        name: 'regraDeCalculo',
        component: simpleChildrenWrapper,
        children: [
          {
            path: 'criar',
            name: 'regrasDeCalculo.criar',
            component: CriacaoEdicaoDaRegraDeCalculo,
            meta: {title: 'Nova Regra', moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO}
          },
          {
            path: ':id([0-9abcdef]{15,})',
            name: 'regrasDeCalculo.editar',
            component: CriacaoEdicaoDaRegraDeCalculo,
            props: true,
            meta: {title: 'Editar Regra', moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO}
          }
        ]
      },
      {
        path: 'grupo-de-rubricas',
        name: 'grupoDeRubricas',
        component: simpleChildrenWrapper,
        children: [
          {
            path: 'criar',
            name: 'grupoDeRubricas.criar',
            component: CriacaoEdicaoGrupoDeRubricas,
            meta: {title: 'Novo grupo', moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO}
          },
          {
            path: ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
            name: 'grupoDeRubricas.editar',
            component: CriacaoEdicaoGrupoDeRubricas,
            props: true,
            meta: {title: 'Editar grupo', moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO}
          }
        ]
      }
    ],
    meta: {
      moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO
    }
  },

  {
    path: '/parametrizador',
    name: 'parametrizador',
    component: simpleChildrenWrapper,
    children: [
      {
        path: 'listar',
        name: 'parametrizador.listar',
        component: ListagemDeVerbas,
        meta: {title: 'Listagem de Verbas', moduloRequerido: MODULOS.CALCULOS_E_TRATAMENTO}
      }
    ],
    meta: {
      moduloRequerido: MODULOS.CALCULO_E_TRATAMENTO
    }
  }
]

const ROTAS_MODULO_GESTAO_DE_PONTO_PARTICIPATIVO = [
  {
    path: '/approvals',
    name: 'approvals',
    component: GestaoDeSolicitacoes,
    meta: {
      moduloRequerido: MODULOS.GESTAO_DE_PONTO_PARTICIPATIVO,
      title: i18n.t('router.routes.gestaoSolicitacoes')
    }
  },
  {
    path: '/approvals-rules',
    name: 'approvalsRules',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'approvalRules.list', component: ApprovalsRulesList, meta: {title: i18n.t('router.routes.approvalsRules.regras'), moduloRequerido: MODULOS.GESTAO_DE_PONTO_PARTICIPATIVO} },
      { path: 'new', name: 'approvalRules.new', component: ApprovalsRulesDetails, meta: {title: i18n.t('router.routes.approvalsRules.novaRegra'), moduloRequerido: MODULOS.GESTAO_DE_PONTO_PARTICIPATIVO} },
      { path: UUIDOrObjectIdPathUrl, name: 'approvalRules.edit', component: ApprovalsRulesDetails, props: true, meta: {title: i18n.t('router.routes.approvalsRules.editarRegra'), moduloRequerido: MODULOS.GESTAO_DE_PONTO_PARTICIPATIVO} }
    ],
    meta: {
      moduloRequerido: MODULOS.GESTAO_DE_PONTO_PARTICIPATIVO
    }
  },
  {
    path: '/painel-solicitacoes-horas-extras',
    name: 'painelDeSolicitacoesDeHorasExtra',
    component: PainelDeSolicitacoesDeHorasExtras,
    meta: { title: i18n.t('router.routes.painelDeSolicitacoesDeHorasExtras') },
  },
  {
    path: '/approvals/lancar-solicitacoes',
    name: 'lancarSolicitacoes',
    component: LancarSolicitacoes,
    meta: { title: i18n.t('router.routes.lancarSolicitacoes') },
  }
]

const ROTAS_MODULO_CONTROLE_DE_CUSTOS = [
  {
    path: '/extra-time-dashboard',
    name: 'extraTimeDashboard',
    component: ExtraTimeDash,
    meta: {
      moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS,
      dontNeedAuth: false,
      title: i18n.t('router.routes.painelAcompanhamento')
    }
  },

  {
    path: '/work-schedule',
    name: 'workSchedule',
    component: WorkSchedule,
    props: true,
    meta: {
      moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS,
      title: i18n.t('router.routes.escalas')
    }
  },

  {
    path: '/monitor-workplace/*',
    name: 'monitor-workplace',
    component: MonitorWorkplace,
    props: true,
    meta: {
      moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS,
      title: i18n.t('router.routes.acompanhamentoPosto')
    }
  },

  {
    path: '/monitor-entrance/*',
    name: 'monitor-entrance',
    component: MonitorEntrance,
    props: true,
    meta: {
      moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS,
      title: i18n.t('router.routes.acompanhamentoEntrada')
    }
  },

  {
    path: '/acompanhamento-tempo-real',
    name: 'acompanhamentoTempoReal',
    component: AcompanhamentoTempoReal,
    meta: {
      moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS,
      title: i18n.t('router.routes.acompanhamentoTempoReal')
    }
  },

  {
    path: '/regras-de-notificacao',
    component: RegrasDeNotificacao,
    children: [
      { path: '', name: 'regrasDeNotificacao.listagem', component: RegrasDeNotificacaoListagem, meta: {title: i18n.t('router.routes.regrasDeNotificacao.regra'), moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS} },
      { path: 'new', name: 'regrasDeNotificacao.criacao', component: CriarEditarRegraDeNotificacao, meta: {title: i18n.t('router.routes.regrasDeNotificacao.novaRegra'), moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS} },
      { path: ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})', name: 'regrasDeNotificacao.edicao', component: CriarEditarRegraDeNotificacao, props: true, meta: {title: i18n.t('router.routes.regrasDeNotificacao.editarRegra'), moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS} }
    ],
    meta: {
      moduloRequerido: MODULOS.CONTROLE_DE_CUSTOS
    }
  },
]

const ROTAS_MODULO_TIMESHEET = []

const ROTAS_MODULO_GESTAO_DE_FERIAS_E_AUSENCIAS = [
  {
    path: '/gerenciamento-de-ferias',
    name: 'gerenciamentoDeFerias',
    component: GerenciamentoDeFerias,
    meta: {
      moduloRequerido: MODULOS.GESTAO_DE_FERIAS_E_AUSENCIAS,
      title: 'gerenciamento de férias'
    }
  }
]

export default [
  ...ROTAS_BASE,
  ...ROTAS_MODULO_REGISTRO_DE_PONTO,
  ...ROTAS_MODULO_REDUCAO_DE_RISCOS,
  ...ROTAS_MODULO_CALCULOS_E_TRATAMENTO,
  ...ROTAS_MODULO_GESTAO_DE_PONTO_PARTICIPATIVO,
  ...ROTAS_MODULO_CONTROLE_DE_CUSTOS,
  ...ROTAS_MODULO_TIMESHEET,
  ...ROTAS_MODULO_GESTAO_DE_FERIAS_E_AUSENCIAS,
  { path: '*', redirect: {name: 'home'} },
]
