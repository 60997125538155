import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import common from './common'

import baseStates from './baseStates'

import timesheet from '../components/Sheets/Timesheet/state'
import sheet from '../components/Sheets/Sheet/state'
import notification from '../components/MainHeader/state'
import dashboards from '../components/DashBoards/state'
import verifyTimesheet from '../components/Sheets/VerifyTimesheet/state'
import whb from '../components/Whb/state'
import workSchedule from 'src/components/WorkSchedule/state'
import employee from 'src/components/Employee/state'
import approvalsRules from 'src/components/ApprovalsRules/state'
import gestaoDeSolicitacoes from 'src/components/Approvals/state'
import gestaoDeFerias from 'src/typescript/modulos/calendario-de-trabalho/GerenciamentoDeFerias/apresentacao/state'
import lockerRules from 'src/components/LockerRules/state'
import company from 'src/components/Company/state'
import locker from 'src/components/Locker/state'
import timeoff from 'src/components/HourBankRules/state'
import faceBiometric from 'src/components/FaceBiometric/state'
import permissions from 'src/components/Permissions/state'
import regrasDeNotificacao from 'src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/state'
import customizacaoDeCalculo from 'src/components/CustomizacoesDeCalculo/index'
import home from 'src/components/Home/state'
import parametrizadorE02 from 'src/components/ParametrizadorE02/index'
import acompanhamentoEmTempoReal from 'src/components/AcompanhamentoTempoReal/state'
import painelSolicitacaoDeHorasExtras from 'src/typescript/modulos/acompanhamento/PainelSolicitacoesDeHorasExtras/dominio/state'
import modulosDoSistema from 'src/store/core/modulos'

Vue.use(Vuex)

const persistor = new VuexPersistence({
  key: 'vuex',
  modules: ['auth', 'visualConfigs']
})

const MODULES_PER_NAME = {
  timesheet: timesheet
}

const store = new Vuex.Store({
  state: {
    auth: {
      authorization: null,
      accessToken: null,
      refreshToken: null, // it wil only exists if theres login with saml, amplify handles it in normal logins
      userPoolId: null,
      userPoolWebClientId: null,
      tokenAPI: null,
      dataCriacaoToken: null,
      // salvamos a url da api de renovacao do token do keycloak no login para utilizá-la no Worker de refresh token
      renovacaoTokenKeycloakAPI: null
    },
    backNotification: null,
    userInfo: null,
    visualConfigs: null,
    CategoriaBarradeNavegacao: null
  },
  ...baseStates,
  modules: {
    common,
    timesheet,
    sheet,
    dashboards,
    notification,
    verifyTimesheet,
    whb,
    workSchedule,
    employee,
    approvalsRules,
    lockerRules,
    company,
    locker,
    timeoff,
    faceBiometric,
    permissions,
    regrasDeNotificacao,
    home,
    ...customizacaoDeCalculo.vuex,
    gestaoDeSolicitacoes,
    gestaoDeFerias,
    ...parametrizadorE02.vuex,
    acompanhamentoEmTempoReal,
    painelSolicitacaoDeHorasExtras,
    modulosDoSistema
  },
  plugins: [
    persistor.plugin
  ]
})

export async function requestModuleUse (modulePath) {
  if (!store.state[modulePath]) {
    await store.registerModule(modulePath, MODULES_PER_NAME[modulePath])
  }
}

window.vuex = store

export default store
