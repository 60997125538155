var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", staticClass: "grafico-de-barra" }, [
    _vm.carregamentoNaMontagem || _vm.carregandoPagina
      ? _c(
          "div",
          {
            staticClass: "grafico-de-barra__carregando",
            style: _vm.carregandoPagina && "opacity: 0.4;",
          },
          [_c("PulseLoading", { attrs: { color: "#969696" } })],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "grafico-de-barra__container" }, [
      _c(
        "div",
        {
          staticStyle: { width: "100%", outline: "none" },
          style: { height: _vm.alturaContainer },
        },
        [_c("canvas", { ref: "canvas", on: { click: _vm.lidarComClique } })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }