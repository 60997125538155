import { resetarParaEstadoInicial } from '@/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsGetters
} from '@/utils.store'

function defaultState () {
  return {
    id: null,
    nomeDaVerba: {
      selected: null,
      loaded: []
    },
    categoriasDeQuemSeAplica: {
      selected: {id: 'customRules', name: 'regras de cálculo'},
      loaded: [
        {id: 'all', name: 'todos'},
        {id: 'customRules', name: 'regras de cálculo'},
        {id: 'employers', name: 'empregados'},
        {id: 'companies', name: 'empresas'},
        {id: 'routines', name: 'escalas'}
      ]
    },
    itensDeQuemSeAplica: { selected: null, loaded: [] },
    codigo: '',
    verbasSincronizadas: [],
    quantidadeDeVerbasSincronizadas: 0,
    verbasCriadasOuEditadas: [],
    templates: {
      selected: null,
      loaded: []
    },
    regrasDeCalculo: [],
    empregadores: [],
    locaisDeTrabalho: [],
    parametrizador: null,
  }
}

const getters = {
  ...vuexMapDefaultsGetters(defaultState())
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  limparVerbasCriadasOuEditadas (state) {
    state.verbasCriadasOuEditadas = []
  }
}

const actions = {

}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
