import HttpCondominio from '@/typescript/servicos/http/condominio'
import { HelperSolicitacoesResponse, ISolicitacaoRepositorio, RotasSolicitacoes } from '@/typescript/modulos/governanca/solicitacoes/repositorio/solicitacoes.repositorio.interface'
import { IRequestJustificarHorasExtras, IRequestSolicitacaoHorasExtras, IRequestSolicitarHorasExtrasEmGrupo, IRequestConfiguracaoRegraVigente } from '@/typescript/modulos/governanca/solicitacoes/repositorio/solicitacoes.repositorio.request'
import { HttpRequestConfig } from '@/typescript/servicos/http/http.interfaces';

export class SolicitacoesRepositorio implements ISolicitacaoRepositorio {
  http: HttpCondominio;

  constructor(http: HttpCondominio) {
    this.http = http
  }

  /**
   * API para fazer o pedido de horas extras a partir das opções da linha do empregado
   */
  async solicitarHorasExtras(data: IRequestSolicitacaoHorasExtras, config?: HttpRequestConfig): HelperSolicitacoesResponse['ResponseSolicitarHorasExtras'] {
    return this.http.post(RotasSolicitacoes.HorasExtras, data, config)
  }

  /**
   * API para justificar horas extras já realizadas pela linha da folha do empregado
   */
  async justificarHorasExtras(data: IRequestJustificarHorasExtras, config?: HttpRequestConfig): HelperSolicitacoesResponse['ResponseJustificarHorasExtras'] {
    return this.http.post(RotasSolicitacoes.JustificarHorasExtras, data, config)
  }

  /**
   * API para fazer o pedido de hroas extras em grupo
   */
  async solicitarHorasExtrasEmGrupo(data: IRequestSolicitarHorasExtrasEmGrupo, config?: HttpRequestConfig): HelperSolicitacoesResponse['ResponseSolicitarHorasExtrasEmGrupo'] {
    return this.http.post(RotasSolicitacoes.HorasExtrasEmGrupo, data, config)
  }
  
  async configuracaoRegraVigente(data: IRequestConfiguracaoRegraVigente): HelperSolicitacoesResponse['ResponseConfiguracaoRegraVigente'] {
    return this.http.get(RotasSolicitacoes.ConfiguracaoRegraVigente, { params: data })
  }
}
