import { EmpregadoId, IConfiguracaoSmartTable } from '@/tipos'
import { GerenciamentoDeFeriasServico } from './gerenciamentoDeFeriasServico'

export interface IRespostaSolicitarFerias {
  data: {
    mensagem: string
  }
}

type SolicitacaoId = string
type FeriasAgendadaId = string
type StringDataNoFormatoISO = string
type TotalDeItens = number
type TotalDePaginas = number

export enum StatusSolicitacao {
  pendente = 'PENDENTE',
  aprovado = 'APROVADO',
  recusado = 'RECUSADO',
  comObservacao = 'COM_OBSERVACAO',
  cancelado = 'CANCELADO',
  agendado = 'AGENDADO'
}

export interface ISolicitacao {
  id: FeriasAgendadaId,
  status: StatusSolicitacao,
  inicio: StringDataNoFormatoISO,
  fim: StringDataNoFormatoISO
}

export interface IFeriasAgendada {
  id: SolicitacaoId,
  status: StatusSolicitacao,
  inicio: StringDataNoFormatoISO,
  fim: StringDataNoFormatoISO
}

export interface IRespostaHistoricoDeSolicitacoes {
  data: {
    resultado: ISolicitacao[]
  }
}

export interface IRespostaHistoricoDeSolicitacoesPaginada {
  data: {
    resultado: ISolicitacao[],
    totalDeItens: TotalDeItens,
    totalDePaginas: TotalDePaginas
  }
}

export interface IRespostaHistoricoDeFeriasAgendadasPaginada {
  data: {
    resultado: IFeriasAgendada[],
    totalDeItens: TotalDeItens,
    totalDePaginas: TotalDePaginas
  }
}

export interface IParametrosDaBuscaPaginadaApiHistoricoDeSolicitacoes {
  page: number,
  perPage: number
}

export interface IRetornoPaginaDaTabelaApiHistoricoDeSolicitacoes {
  items: ISolicitacao[],
  totalItems: TotalDeItens,
  totalPages: TotalDePaginas
}

export interface IErroAoSolicitarFerias {
  response: {
    data: {
      description: string,
      error: string
    } | null
  } | null
}

export interface Data {
  dataInicio: Date,
  dataFim: Date,
  carregandoCriacaoSolicitacaoDeFerias: boolean,
  carregandoBuscaDoHistorico: boolean,
  configuracaoTabelaDeHistorico: IConfiguracaoSmartTable,
  historicoDeSolicitacoes: ISolicitacao[],
  verFormulario: boolean,
  gerenciamentoDeFeriasServico: GerenciamentoDeFeriasServico,
  solicitacaoDeFeriasPorId: { [key: string]: ISolicitacao }
}

export interface Computed {
  tituloDaPagina: string,
  dataMinimaDosSeletores: Date,
  tabelaDeHistorico: { loading: boolean; loaded: ISolicitacao[] },
  idEmpregadoDoUsuarioLogado: EmpregadoId | null
}

export interface Methods {
  solicitarFerias: () => Promise<void>,
  obterHistoricoDeSolicitacoesPaginado: (
    parametrosRequisicao: IParametrosDaBuscaPaginadaApiHistoricoDeSolicitacoes,
  ) => Promise<IRetornoPaginaDaTabelaApiHistoricoDeSolicitacoes>,
  formatarDataParaMostrar: (data: Date) => string,
  formatarDuracao: (intervaloDoEvento: { inicio: Date; fim: Date }) => string,
  cancelar: () => void,
  mostrarFormulario: () => void,
  atualizarHistorico: () => void,
  aoClicarNaSolicitacao: (solicitacaoDeFerias: ISolicitacao) => void
}
