type IModulosDoSistema = 'modulo_base'
  | 'modulo_registro_de_ponto'
  | 'modulo_reducao_de_riscos'
  | 'modulo_calculos_e_tratamento'
  | 'modulo_gestao_de_ponto_participativo'
  | 'modulo_controle_de_custos'
  | 'modulo_timesheet'
  | 'modulo_gestao_de_ferias_e_ausencias'

interface IModulos {
  [key: string]: IModulosDoSistema;
}

const MODULOS: IModulos= {
  BASE: 'modulo_base',
  REGISTRO_DE_PONTO: 'modulo_registro_de_ponto',
  REDUCAO_DE_RISCOS: 'modulo_reducao_de_riscos',
  CALCULOS_E_TRATAMENTO: 'modulo_calculos_e_tratamento',
  GESTAO_DE_PONTO_PARTICIPATIVO: 'modulo_gestao_de_ponto_participativo',
  CONTROLE_DE_CUSTOS: 'modulo_controle_de_custos',
  TIMESHEET: 'modulo_timesheet',
  GESTAO_DE_FERIAS_E_AUSENCIAS: 'modulo_gestao_de_ferias_e_ausencias'
}

const MODULOS_VALORES_PADRAO = {
  modulo_base: true,
  modulo_registro_de_ponto: true,
  modulo_reducao_de_riscos: true,
  modulo_calculos_e_tratamento: true,
  modulo_gestao_de_ponto_participativo: true,
  modulo_controle_de_custos: true,
  modulo_timesheet: true,
  modulo_gestao_de_ferias_e_ausencias: true,
}


export {
  IModulosDoSistema,
  IModulos,
  MODULOS,
  MODULOS_VALORES_PADRAO
}
