/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { DetalhesErroSentry, HttpHeaders, HttpRequestConfig, HttpResponse, IConfiguracaoRequisicaoComRetry, IConfiguracaoRetry, IMetodoHttp, IServicoHttp, TipoRequisicaoParaRetry} from './http.interfaces'

const TIMEOUT_PADRAO = 50000
abstract class AxiosServicoHttp implements IServicoHttp {
  protected clienteHttp: AxiosInstance
  protected abstract urlBase: string
  protected abstract urlFallback: string

  constructor (baseURL: string, timeoutPadrao = TIMEOUT_PADRAO) {
    this.clienteHttp = axios.create({ baseURL, timeout: timeoutPadrao })
    this.inicializarInterceptadores()
  }

  protected inicializarInterceptadores (): void {
    this.adicionarInterceptadorDeRequisicao()
    this.adicionarInterceptadorDeResposta()
    this.adicionarInterceptadorDeEnvioDeErroParaSentry()
  }

  protected abstract adicionarInterceptadorDeRequisicao (): void

  protected abstract adicionarInterceptadorDeResposta (): void

  public abstract obterUrlBase (): string

  public abstract obterUrlFallback (): string

  async get<T = any> (url: string, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    const axiosConfig: AxiosRequestConfig = this.adaptarConfiguracoesDeRequisicao(config)
    const response: AxiosResponse<T> = await this.clienteHttp.get(url, axiosConfig)
    return this.adaptarResposta<T>(response)
  }

  async post<T = any> (url: string, data?: any, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    const axiosConfig: AxiosRequestConfig = this.adaptarConfiguracoesDeRequisicao(config)
    const response: AxiosResponse<T> = await this.clienteHttp.post(url, data, axiosConfig)
    return this.adaptarResposta<T>(response)
  }

  async put<T = any> (url: string, data?: any, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    const axiosConfig: AxiosRequestConfig = this.adaptarConfiguracoesDeRequisicao(config)
    const response: AxiosResponse<T> = await this.clienteHttp.put(url, data, axiosConfig)
    return this.adaptarResposta<T>(response)
  }

  async patch<T = any> (url: string, data?: any, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    const axiosConfig: AxiosRequestConfig = this.adaptarConfiguracoesDeRequisicao(config)
    const response: AxiosResponse<T> = await this.clienteHttp.patch(url, data, axiosConfig)
    return this.adaptarResposta<T>(response)
  }

  async delete<T = any> (url: string, config?: HttpRequestConfig): Promise<HttpResponse<T>> {
    const axiosConfig: AxiosRequestConfig = this.adaptarConfiguracoesDeRequisicao(config)
    const response: AxiosResponse<T> = await this.clienteHttp.delete(url, axiosConfig)
    return this.adaptarResposta<T>(response)
  }

  private adaptarConfiguracoesDeRequisicao (config?: HttpRequestConfig): AxiosRequestConfig {
    const axiosConfig: AxiosRequestConfig = {
      ...config,
      headers: config?.headers,
      params: config?.params,
      data: config?.data,
      timeout: config?.timeout,
      withCredentials: config?.withCredentials,
      responseType: config?.responseType,
      signal: config?.signal
    }
    return axiosConfig
  }

  private adaptarResposta<T> (response: AxiosResponse<T>): HttpResponse<T> {
    const headers: HttpHeaders = {}
    Object.entries(response.headers).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        headers[key] = value as string | string[]
      }
    })

    return {
      ...response,
      headers
    }
  }

  async realizarRequisicaoComRetrys<T = any> (configuracao: IConfiguracaoRequisicaoComRetry): Promise<HttpResponse<T>> {
    const { metodo, endpoint, configuracoesDeRequisicao, configuracaoRetrys, intervaloEntreRetrys } = configuracao

    this.validarMetodoHttp(metodo)

    const configuracaoRetryPadrao: IConfiguracaoRetry[] = [
      { tipo: TipoRequisicaoParaRetry.BASE, timeout: TIMEOUT_PADRAO },
      { tipo: TipoRequisicaoParaRetry.BASE, timeout: TIMEOUT_PADRAO },
      { tipo: TipoRequisicaoParaRetry.BASE, timeout: TIMEOUT_PADRAO },
    ]
    const retries = configuracaoRetrys && configuracaoRetrys.length > 0 ? configuracaoRetrys : configuracaoRetryPadrao

    const intervaloEntreRequisicoes = intervaloEntreRetrys || 2000
    let tentativas = 0

    const metodosComData = [
      IMetodoHttp.POST,
      IMetodoHttp.PUT,
      IMetodoHttp.PATCH,
      IMetodoHttp.DELETE
    ]

    while (tentativas < retries.length) {
      const configuracaoRetryAtual = retries[tentativas]
      const url = configuracaoRetryAtual.tipo === TipoRequisicaoParaRetry.BASE
        ? this.obterUrlBase()
        : this.obterUrlFallback()

      try {
        const configuracaoRequisicao: HttpRequestConfig = {
          timeout: configuracaoRetryAtual.timeout,
          params: configuracoesDeRequisicao?.params,
          headers: configuracoesDeRequisicao?.headers,
        }

        const aceitaData = metodosComData.includes(metodo)

        const dadosDaRequisicao = aceitaData ? configuracoesDeRequisicao?.data : undefined

        let resultado

        if (aceitaData && dadosDaRequisicao) {
          resultado = await this.clienteHttp[metodo](`${url}/${endpoint}`, dadosDaRequisicao, configuracaoRequisicao)
        } else if (!aceitaData) {
          resultado = await this.clienteHttp[metodo](`${url}/${endpoint}`, configuracaoRequisicao)
        } else {
          resultado = await this.clienteHttp[metodo](`${url}/${endpoint}`, {}, configuracaoRequisicao)
        }

        return this.adaptarResposta<T>(resultado)
      } catch (error: any) {
        const ehErroParaUsoDoRetry = this.verificarSeErroPermiteNovaTentativa(error)
        tentativas++
        if (!ehErroParaUsoDoRetry || tentativas >= retries.length) {
          throw error
        }
        await new Promise(resolve => setTimeout(resolve, intervaloEntreRequisicoes))
      }
    }

    throw new Error('Erro inesperado ao realizar tentativas de requisição')
  }

  private validarMetodoHttp (metodo: string): void {
    const metodosValidos = ['get', 'post', 'put', 'patch', 'delete']
    if (!metodosValidos.includes(metodo.toLowerCase())) {
      throw new Error(`Método HTTP inválido: ${metodo}`)
    }
  }

  private verificarSeErroPermiteNovaTentativa (error: AxiosError): boolean {
    if (error.response) {
      const status = error.response.status
      return status >= 500 && status < 600
    } else if (error.code) {
      return ['ECONNABORTED', 'ENOTFOUND', 'ETIMEDOUT'].includes(error.code)
    }
    return false
  }

  private adicionarInterceptadorDeEnvioDeErroParaSentry(): void {
    this.clienteHttp.interceptors.response.use(function (response) {
      return response;
    }, function(response) {
      try {
        const urlRequisitada = new URL(response.config.baseURL || '')

        const titulo = `[${response?.status || response?.code || 'N/A'}] ${urlRequisitada.host.split('.')[0].toUpperCase()} - ${response.config.url}`

        const detalhesErroSentry: DetalhesErroSentry = {
          codigoErroApi: response?.status || response?.code || 'nao-informado',
          mensagem: response?.request?.statusText || 'nao-informado',
          horarioDaRequisicao: new Date().toISOString(),
          urlCompleta: response.config.url || 'nao-informado',
          metodo: response.config.method?.toUpperCase() || 'nao-informado',
          retornoApi: response?.response?.data || 'nao-informado',
          payload: response.config.data || 'nao-informado',
          titulo,
        };

        (response as AxiosResponse & { 'detalhesErroSentry': DetalhesErroSentry }).detalhesErroSentry = detalhesErroSentry
        return Promise.reject(response)

      } catch {
        return Promise.reject(response)
      }
    })
  }

}

export default AxiosServicoHttp
