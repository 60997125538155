var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper-smart-table", attrs: { translate: "no" } },
    [
      _c(
        "div",
        {
          staticClass: "grid",
          class: {
            "details-box-open": _vm.showingDetailsOrConfigBox,
            "modo-estado-inicial": _vm.mostrarEstadoInicial,
          },
          style: _vm.estilosDinamicos,
        },
        [
          !_vm.mostrarEstadoInicial
            ? _c(
                "div",
                { staticClass: "header" },
                _vm._l(_vm.header, function (column, index) {
                  return _c(
                    "ColumnHeader",
                    _vm._g(
                      _vm._b(
                        { key: index, staticClass: "header-column" },
                        "ColumnHeader",
                        _vm.buildBinds("header", column, index),
                        false
                      ),
                      _vm.buildHeaderEvents(column)
                    )
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.mostrarEstadoInicial
            ? _c(
                "EstadoInicial",
                [_vm.$slots.estadoInicial ? _vm._t("estadoInicial") : _vm._e()],
                2
              )
            : _vm.mostrarEstadoErro
            ? _c(
                "EstadoErro",
                {
                  attrs: {
                    mensagemDeErro: _vm.serverSidePagination.ocorreuErro,
                  },
                },
                [
                  _vm._t("estadoErro", null, {
                    mensagemDeErro: _vm.serverSidePagination.ocorreuErro,
                  }),
                ],
                2
              )
            : _vm.mostrarEstadoVazio
            ? _c(
                "EstadoVazio",
                [_vm.$slots.estadoVazio ? _vm._t("estadoVazio") : _vm._e()],
                2
              )
            : _vm._l(
                _vm.state.loading ? _vm.mergedConfig.perPage : _vm.toDisplay,
                function (item, index) {
                  return _c(
                    "div",
                    {
                      key: `${_vm.name}-row-${index}`,
                      staticClass: "row",
                      class: {
                        selected:
                          _vm.state.selected &&
                          _vm.state.selected.index !== undefined
                            ? _vm.state.selected.index === item.realIndex
                            : false,
                      },
                      attrs: { id: `${_vm.name}-row-${index}`, tabindex: "-1" },
                      on: { focus: _vm.focusRow },
                    },
                    [
                      !_vm.state.loading
                        ? _vm._t(
                            "default",
                            function () {
                              return [
                                _c(
                                  "TableRow",
                                  _vm._b(
                                    {},
                                    "TableRow",
                                    _vm.buildBinds("body", item, index),
                                    false
                                  )
                                ),
                              ]
                            },
                            {
                              possuiColunaDeConfiguracao:
                                _vm.mergedConfig.possuiColunaDeConfiguracao,
                            },
                            _vm.buildBinds("body", item, index)
                          )
                        : _c(
                            "TableRow",
                            _vm._b(
                              {
                                key: "loading-" + index,
                                attrs: {
                                  possuiColunaDeConfiguracao:
                                    _vm.mergedConfig.possuiColunaDeConfiguracao,
                                  loading: true,
                                },
                              },
                              "TableRow",
                              _vm.buildBinds("body", item, index),
                              false
                            )
                          ),
                    ],
                    2
                  )
                }
              ),
          _c("transition", { attrs: { name: "details-box" } }, [
            _vm.toDisplay && _vm.detailsBox.show && !_vm.showConfigBox
              ? _c(
                  "div",
                  {
                    staticClass: "details-box",
                    style: _vm.configAndDetailsBoxPosition.gridStyle,
                  },
                  [
                    _c("div", {
                      staticClass:
                        "icone-chapado-fechar fs-12 fw-bold to-click",
                      on: { click: _vm.closeDetailsBox },
                    }),
                    _vm._t("detailsBox", null, { item: _vm.detailsBoxItem }),
                  ],
                  2
                )
              : _vm.showConfigBox
              ? _c(
                  "div",
                  {
                    staticClass: "details-box",
                    style: _vm.configAndDetailsBoxPosition.gridStyle,
                  },
                  [
                    _c("div", {
                      staticClass:
                        "icone-chapado-fechar fs-12 fw-bold to-click",
                      on: { click: _vm.toggleConfig },
                    }),
                    _c("Configs", {
                      attrs: {
                        name: _vm.name,
                        headerItems: _vm.headerItems,
                        config: _vm.mergedConfig,
                      },
                      on: {
                        applyConfigs: _vm.loadConfigs,
                        close: _vm.toggleConfig,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.mergedConfig.possuiColunaDeConfiguracao &&
          !_vm.mostrarEstadoInicial
            ? _c(
                "div",
                {
                  staticClass: "table-config to-click",
                  on: { click: _vm.toggleConfig },
                },
                [
                  _c("span", {
                    staticClass: "icone-chapado-configuracao fs-13",
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.showPaginate
            ? _c("Paginate", {
                attrs: {
                  currentPage: _vm.currentPage,
                  numberItemsDisplay: _vm.mergedConfig.perPage,
                  totalPages: _vm.totalPages,
                },
                on: { goToPage: _vm.changePage },
              })
            : _vm._e(),
          _c("PageInfo", {
            attrs: {
              totalItems: _vm.totalItems,
              perPage: _vm.mergedConfig.perPage,
              currentPage: _vm.currentPage,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }