import { API } from 'src/services'
import { Notification } from 'uiv'
import router from 'src/router'
import Cookies from 'js-cookie'
import { refreshToken } from 'src/components/Cognito'
import { UsuarioIdentificador } from 'src/typescript/utils.ts'
import { formbricksGlobal, ServicoFlagSmithGlobal } from 'src/main'
import { FiltradorDeErros } from 'src/erros'
import servicoLocalStorage from 'src/typescript/servicos/localStorage/localStorage.servico'
import { identificarUsuarioPosthog, inicializarPosthog, verificarSeUsuarioPermitido, verificarPosthogEstaAtivo } from 'src/typescript/servicos/posthog/posthog.servico'
import { inicializadorFirebase } from 'src/typescript/servicos/firebase/firebase.servico'
import i18n from 'src/typescript/servicos/i18n/i18n.servico'
import * as Sentry from '@sentry/vue'

export default {
  mutations: {
    userInfo (index, payload) {
      const roles = []

      for (const role of payload.roles) {
        const module = role[0].split('_')[0]
        const action = role[0].split('_')[1]
        const filter = role[0].split('_')[2]

        roles.push({
          name: role[0],
          module,
          action,
          filter
        })
      }
      index.userInfo = { ...payload, roles }
    },
    integracoesSankhyaInfo (index, payload) {
      index.integracoesSankhyaInfo = { ...payload }
    },
    removeUserInfo (state) {
      state.userInfo = null
    },
    backNotification (state, payload) {
      state.backNotification = payload
    }
  },
  actions: {
    getUserInfo: async ({ commit, state, dispatch, getters }, force = false) => {
      if (router.currentRoute.meta.dontNeedAuth && !force) {
        return
      } else if (!state.auth.accessToken && !state.auth.authorization) {
        const authorization = Cookies.get('Authorization')
        const accessToken = Cookies.get('accessToken')
        const userPoolId = Cookies.get('userPoolId')
        const userPoolWebClientId = Cookies.get('userPoolWebClientId')

        if (!authorization && !accessToken) {
          router.push('/cognito/login')
        } else {
          commit('cognitoTokens', {
            authorization,
            accessToken,
            userPoolId,
            userPoolWebClientId
          })
        }
      } else if (state.auth.accessToken) {
        await refreshToken()
      }

      async function obterDadosDoUsuarioLogado () {
        let respostaDaAPI
        try {
          if (!getters.usuarioEstaAutenticado) {
            throw new Error(i18n.t('store.basestates.userInfo.usuarioNaoEncontrado'))
          }

          respostaDaAPI = await API.info.get()
        } catch (erro) {
          respostaDaAPI = { error: erro }
        }
        return respostaDaAPI
      }

      async function obterInformacoesDeIntegracoesSankhya () {
        try {
          const resultadoApi = await API.novasIntegracoesSankhyaAtivas.get()
          const integracoesSankhya = resultadoApi.data
          commit('integracoesSankhyaInfo', integracoesSankhya)
        } catch (erro) {
          FiltradorDeErros.capturarErro(erro)
        }
      }

      let respostaDaAPI = await obterDadosDoUsuarioLogado()
      if (respostaDaAPI.error) {
        respostaDaAPI = await obterDadosDoUsuarioLogado()

        if (respostaDaAPI.error) {
          const respostaEmString = respostaDaAPI.error.data ? JSON.stringify(respostaDaAPI.error.data) : ''
          if (
            respostaDaAPI.error.status === 403 &&
            respostaEmString.includes('nginx') &&
            respostaEmString.includes('403 Forbidden')
          ) {
            await dispatch('logout', false)
            return false
          }
          await dispatch('logout')
          return false
        }
      }
      const idEmpresaUsuario = respostaDaAPI.data.success['0'].compMan.id
      const nomeEmpresaUsuario = respostaDaAPI.data.success['0'].compMan.name
      const idUsuario = respostaDaAPI.data.success['0'].id
      const nomeUsuario = respostaDaAPI.data.success['0'].name
      const emailUsuario = respostaDaAPI.data.success['0'].email
      const papelUsuario = respostaDaAPI.data.success['0'].roleGroup.kind
      const possuiIntegracaoAutomaticaCadastrosSankhya = respostaDaAPI.data.success['0'].compMan.possuiIntegracaoAutomaticaCadastrosSankhya
      Cookies.set('CompMan', idEmpresaUsuario)
      Cookies.set('UserId', idUsuario)
      servicoLocalStorage.definirIdentificador(idEmpresaUsuario, idUsuario)
      commit('userInfo', respostaDaAPI.data.success['0'])

      ServicoFlagSmithGlobal.inicializar(idEmpresaUsuario, nomeEmpresaUsuario)

      dispatch('updateLegacyCookies')
      dispatch('getBackNotifications')

      localStorage.setItem('angular_session_roles', JSON.stringify(respostaDaAPI.data.success[0].roles.map(i => i[0])))
      dispatch('gerenciarInicioUsuarioComDadosCompletosEmServicos', { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario })

      if (possuiIntegracaoAutomaticaCadastrosSankhya) {
        obterInformacoesDeIntegracoesSankhya()
      }

      const possuiAcompanhamento = state.userInfo?.compMan?.hasAcompanhamento === true
      inicializadorFirebase({
        possuiAcompanhamento,
        ehLar21: emailUsuario.includes('lar21@'),
        idUsuarioLogado: idUsuario
      })

      return respostaDaAPI.data.success['0']
    },
    async gerenciarInicioUsuarioComDadosCompletosEmServicos ({ dispatch }, { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario }) {
      try {
        const { tamanhoEmpresaUsuario, canal } = await dispatch('buscarDadosTamanhoCliente', { idUsuario, idEmpresaUsuario, nomeEmpresaUsuario })
        dispatch('enviarInformacoesUsuarioFormbricks', { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario, tamanhoEmpresaUsuario, canal })
        dispatch('enviarInformacoesUsuarioSentry', { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario, tamanhoEmpresaUsuario, canal })
        if (papelUsuario === 'all' && !emailUsuario.includes('lar21@') && !emailUsuario.includes('@pontotel')) {
          dispatch('enviarInformacoesUsuarioPosthog', { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario, tamanhoEmpresaUsuario, canal })
        }
      } catch (erro) {
        FiltradorDeErros.capturarErro(erro)
      }
    },
    async buscarDadosTamanhoCliente (_, { idUsuario, idEmpresaUsuario, nomeEmpresaUsuario }) {
      try {
        const resultadoApi = await API.consultarTamanhoCLiente.save({ idCliente: idEmpresaUsuario })
        return { tamanhoEmpresaUsuario: resultadoApi.body.tamanhoCliente, canal: resultadoApi.body.canal }
      } catch (erro) {
        FiltradorDeErros.capturarErro(erro, {
          endpoint: 'consultador-de-tamanho-do-cliente-no-auth',
          status: erro.status,
          bodyText: erro.bodyText,
          idUsuario,
          idEmpresaUsuario,
          nomeEmpresaUsuario
        })
        return { tamanhoEmpresaUsuario: false, canal: false }
      }
    },
    async enviarInformacoesUsuarioFormbricks (_, { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario, tamanhoEmpresaUsuario, canal }) {
      if (formbricksGlobal.verificarFormbricksEstaAtivo() && tamanhoEmpresaUsuario) {
        if (formbricksGlobal.verificarSeUsuarioPermitido(emailUsuario)) {
          const usuarioParaFormbricks = new UsuarioIdentificador(
            {
              idUsuario,
              nomeUsuario,
              emailUsuario,
              papelUsuario,
              idEmpresaUsuario,
              nomeEmpresaUsuario,
              tamanhoEmpresaUsuario,
              canal
            }).obterDadosDoUsuario()
          await formbricksGlobal.iniciarFormbricks(usuarioParaFormbricks)
          formbricksGlobal.registrarGatilho('Obteve Dados do Usuário')
        }
      }
    },
    async enviarInformacoesUsuarioPosthog (_, { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario, tamanhoEmpresaUsuario, canal }) {
      if (verificarPosthogEstaAtivo() && tamanhoEmpresaUsuario) {
        if (verificarSeUsuarioPermitido(emailUsuario)) {
          const usuarioParaPosthog = new UsuarioIdentificador(
            {
              idUsuario,
              nomeUsuario,
              emailUsuario,
              papelUsuario,
              idEmpresaUsuario,
              nomeEmpresaUsuario,
              tamanhoEmpresaUsuario,
              canal
            }).obterDadosDoUsuario()
          await inicializarPosthog()
          await identificarUsuarioPosthog(usuarioParaPosthog)
        }
      }
    },
    async enviarInformacoesUsuarioSentry (_, { idUsuario, nomeUsuario, emailUsuario, papelUsuario, idEmpresaUsuario, nomeEmpresaUsuario, tamanhoEmpresaUsuario, canal }) {
      const usuarioSentry = new UsuarioIdentificador({
        idUsuario,
        nomeUsuario,
        emailUsuario,
        papelUsuario,
        idEmpresaUsuario,
        nomeEmpresaUsuario,
        tamanhoEmpresaUsuario,
        canal
      }).obterDadosDoUsuario()
      const ehProducao = Boolean(process.env.NODE_ENV === 'production')
      if (!ehProducao) {
        return
      }
      const objetoUserSentry = {
        id: usuarioSentry.idUsuario,
        email: usuarioSentry.emailUsuario,
        username: usuarioSentry.nomeUsuario,
        papelUsuario: usuarioSentry.papelUsuario,
        idEmpresaUsuario: usuarioSentry.idEmpresaUsuario,
        nomeEmpresaUsuario: usuarioSentry.nomeEmpresaUsuario,
        tamanhoEmpresaUsuario: usuarioSentry.tamanhoEmpresaUsuario,
        canal: usuarioSentry.canal
      }

      Sentry.setUser(objetoUserSentry)
      const scope = Sentry.getCurrentScope()
      scope.setUser(objetoUserSentry)
    },
    updateLegacyCookies ({ state }) {
      // update cookies that angular use

      const { compMan, name, roles } = state.userInfo

      if (state.userInfo.email.includes('lar21@')) {
        document.cookie = 'isLar21User=' + true
      } else {
        document.cookie = 'isLar21User=' + ''
      }
      window.AngularPermissions = roles
      Cookies.set('login_compman', compMan.id)
      Cookies.set('login_email', name)
      Cookies.set('comp_man_has_tms', compMan.hasArbTms)
      Cookies.set('comp_man_has_whbank', compMan.hasWhbank)
      Cookies.set('comp_man_has_on_call', compMan.hasOnCall)
      Cookies.set('comp_man_has_locker', compMan.canLockFolha)
      Cookies.set('has_recognize_face', compMan.hasRecognizeFace)
      Cookies.set('has_acompanhamento', compMan.hasAcompanhamento)
      Cookies.set('possui_integracao_automatica', compMan.possuiIntegracaoAutomatica)
      Cookies.set('Authorization', state.auth.authorization)
    },
    async getBackNotifications ({ commit }) {
      const result = await API.notification.get()
      if (result.status === 200) {
        Notification.notify({
          type: 'info',
          content: result.data.success
        })
        commit('backNotification', result.data.success)
      }
    }
  }
}
