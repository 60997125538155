import moment, { Moment } from 'moment'
import { API } from 'src/services'
import { multiSelect } from 'src/common/selectors'
import { obterDataValida } from 'src/utils/formatadoras/formatadoras'
import i18n from '@/typescript/servicos/i18n/i18n.servico'
import store from 'src/store'
import FeriasSolicitadasMuesLiderados from './abas/FeriasSolicitadasMeusLiderados.vue'
import FeriasAgendadasMeusLiderados from './abas/FeriasAgendadasMeusLiderados.vue'
import MinhasSolicitacoesDeFerias from './abas/MinhasSolicitacoesDeFerias.vue'
import MinhasFeriasAgendadas from './abas/MinhasFeriasAgendadas'
import { StatusSolicitacao } from '../dominio/gerenciamentoDeFerias.interface'
import { TranslateResult } from 'vue-i18n'

/**
 * Interface que define a estrutura de uma categoria
 * @property name - Nome exibido da categoria (traduzido)
 * @property id - Identificador único da categoria
 * @property common - Endpoint comum para API
 * @property state - Nome do estado no Vuex
 * @property entity - Tipo de entidade relacionada
 */
interface Categoria {
  name: string
  id: string
  common: string
  state: string
  entity: string
}

interface IStatusSolicitacao {
  id: StatusSolicitacao | undefined,
  name: TranslateResult
}

interface IFormularioLiderados {
  categoria: string
  loaded: object[]
  selected: object[]
  dataInicial: Date | undefined
  dataFinal: Date | undefined
}
/**
 * Interface do estado global deste módulo Vuex
 * @property dataInicial - Data inicial do período selecionado
 * @property dataFinal - Data final do período selecionado
 * @property categorias - Objeto contendo categorias disponíveis e selecionada
 */
interface Estado {
  dataInicial: Date
  dataFinal: Date
  solicitadasMeusLideradosSelecionadas: IFormularioLiderados
  agendadasMeusLideradosSelecionadas: IFormularioLiderados
  categorias: {
    loaded: Categoria[]
    selected: Categoria
  }
  statusSolicitacao: {
    loaded: IStatusSolicitacao[]
    selected: IStatusSolicitacao
  }
  abasMinhasFerias: object[]
  abasMeusLiderados: object[]
  abaSelecionada: object
}

export enum TipoRequisicao {
  agendadas = 'agendadas',
  solicitadas = 'solicitadas'
}

const categorias = [
  {
    name: i18n.t('comuns.genericos.plural.locaisDeTrabalho').toString(),
    id: 'companies',
    common: 'companies/onlyActive',
    state: 'gestaoDeFerias/companies',
    entity: 'local_de_trabalho'
  },
  {
    name: i18n.t('comuns.genericos.plural.empregados').toString(),
    id: 'employees',
    common: 'employees/onlyNotFired',
    state: 'gestaoDeFerias/employees',
    entity: 'empregado'
  },
  {
    name: i18n.t('comuns.genericos.plural.grupos').toString(),
    id: 'userGroups',
    common: 'userGroups',
    state: 'gestaoDeFerias/userGroups',
    entity: 'grupo'
  },
  {
    name: i18n.t('comuns.genericos.plural.perfis').toString(),
    id: 'roleGroups',
    common: 'roleGroups/onlyNotRh',
    state: 'gestaoDeFerias/roleGroups',
    entity: 'perfil'
  }
]

const abasMeusLiderados = [
  {
    id: 0,
    name: i18n.t('gerenciamentoDeFerias.abas.solicitacoesDeFerias'),
    tipo: TipoRequisicao.solicitadas,
    component: FeriasSolicitadasMuesLiderados,
    chamadaDeApi: async (requisicao: object) =>
      await API.gestaoDeFerias.save({ tipo: TipoRequisicao.solicitadas }, requisicao),
  },
  {
    id: 1,
    name: i18n.t('gerenciamentoDeFerias.abas.feriasMeusLideradosJaProgramadas'),
    tipo: TipoRequisicao.agendadas,
    component: FeriasAgendadasMeusLiderados ,
    chamadaDeApi: async (requisicao: object) =>
      await API.gestaoDeFerias.save({ tipo: TipoRequisicao.agendadas }, requisicao),
  },
]

const abasMinhasFerias = [
  {
    id: 2,
    name: i18n.t('gerenciamentoDeFerias.abas.minhasSolicitacoesDeFerias'),
    component: MinhasSolicitacoesDeFerias,
    tipo: TipoRequisicao.solicitadas,
    chamadaDeApi: async (requisicao: object) => {
      const id = store.state.userInfo.compemployee.id

      return id ? API.gestaoDeFerias.save(
        {
          id: store.state.userInfo.compemployee.id,
          tipo: TipoRequisicao.solicitadas
        },
        {
          ...requisicao,
          data_inicial: moment().startOf('year').toDate(),
          data_final: moment().endOf('year').toDate()
        }) : {
        data: {
          totalDeItens: 0,
          totalDePaginas: 0,
          resultado: []
        }
      }
    }
  },
  {
    id: 3,
    name: i18n.t('gerenciamentoDeFerias.abas.minhasFeriasJaProgramadas'),
    tipo: TipoRequisicao.agendadas,
    component: MinhasFeriasAgendadas ,
    chamadaDeApi: async (requisicao: object) => {
      const id = store.state.userInfo.compemployee.id

      return id ? API.gestaoDeFerias.save(
        {
          id,
          tipo: TipoRequisicao.agendadas
        },
        {
          ...requisicao,
          data_inicial: moment().startOf('year').toDate(),
          data_final: moment().endOf('year').toDate()
        }) : {
        data: {
          totalDeItens: 0,
          totalDePaginas: 0,
          resultado: []
        }
      }
    }
  }
]

const statusSolicitacao: IStatusSolicitacao[] = [
  { id: undefined, name: i18n.t('gerenciamentoDeFerias.mostrarStatus.todos') },
  { id: StatusSolicitacao.cancelado ,name: i18n.t('gerenciamentoDeFerias.mostrarStatus.cancelado') },
  { id: StatusSolicitacao.pendente, name: i18n.t('gerenciamentoDeFerias.mostrarStatus.pendente') },
  { id: StatusSolicitacao.aprovado, name: i18n.t('gerenciamentoDeFerias.mostrarStatus.aprovado') },
  { id: StatusSolicitacao.recusado, name: i18n.t('gerenciamentoDeFerias.mostrarStatus.reprovado') },
  { id: StatusSolicitacao.comObservacao, name: i18n.t('gerenciamentoDeFerias.mostrarStatus.comObservacao') }
]

function estadoPadrao(): Estado {
  return {
    ...multiSelect.mapStates(['companies', 'employees', 'userGroups', 'roleGroups']),
    dataInicial: moment().startOf('month').toDate(),
    dataFinal: moment().endOf('month').toDate(),
    solicitadasMeusLideradosSelecionadas: {
      categoria: '',
      loaded: [],
      selected: []
    },
    agendadasMeusLideradosSelecionadas: {
      loaded: [],
      selected: []
    },
    categorias: {
      loaded: categorias,
      selected: categorias[0]
    },
    statusSolicitacao: {
      loaded: statusSolicitacao,
      selected: statusSolicitacao[0]
    },
    abasMinhasFerias,
    abasMeusLiderados,
    abaSelecionada: abasMeusLiderados[0]
  }
}

const state = estadoPadrao()

const mutations = {
  ...multiSelect.mapMutations(['companies', 'employees', 'userGroups', 'roleGroups']),
  dataInicial: (estado: Estado, payload: Moment) => estado.dataInicial = obterDataValida(payload),
  dataFinal: (estado: Estado, payload: Moment) => estado.dataFinal = obterDataValida(payload),
  categorias: (estado: Estado, payload: { loaded: Categoria[], selected: Categoria }) => estado.categorias = payload,
  solicitadasMeusLideradosSelecionadas: (estado: Estado, payload: IFormularioLiderados) => estado.solicitadasMeusLideradosSelecionadas = payload,
  resetarEstado: (estado: Estado) => Object.assign(estado, estadoPadrao()),
  agendadasMeusLideradosSelecionadas: (estado: Estado, payload: IFormularioLiderados) => estado.agendadasMeusLideradosSelecionadas = payload,
  statusSolicitacao: (estado: Estado, payload: { loaded: IStatusSolicitacao[], selected: IStatusSolicitacao}) => estado.statusSolicitacao = payload,
  abaSelecionada: (estado: Estado, payload: object) => estado.abaSelecionada = payload
}

export default {
  namespaced: true,
  mutations,
  state
}
