import store from 'src/store'
import Auth from '@aws-amplify/auth'

export const LOGIN_PATH = '/cognito/login'

export async function verifyAuthAndLoadUserAndStyles (to, from, next) {
  if ((to.path === from.path && from.name) || to.path.includes('HotUpdate')) { return }

  // logout if is main view and has not access tokens saved in cookies
  if (to.path === '/login') {
    await store.dispatch('logout')
    return
  }

  if (to.path !== LOGIN_PATH && to.meta.dontNeedAuth !== true && !store.state.userInfo) {
    await store.dispatch('getUserInfo')
    await store.dispatch('common/loadMainCommons')
  }

  if (from.name === null && store.state.userInfo) {
    // Browser just loaded
    await store.dispatch('getVisual', {forceReload: true})
  }

  next()
}

export async function retrieveCurrentAuthenticatedUserAmplify () {
  // loads the user in amplify. It must save the user in localstorage or something like that

  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    const { idToken } = await Auth.currentSession()
    if (currentUser) {
      store.commit('setFreshToken', idToken)
    }
  } catch {

  }
}
