import type { LocaleMessageObject } from 'vue-i18n'

import AcessoNegado from '@/components/AcessoNegado/acessoNegado.es-co.i18n.json'
import AcompanhamentoTempoReal from '@/components/AcompanhamentoTempoReal/acompanhamentoTempoReal.es-co.i18n.json'
import Atividades from '@/components/Activities/atividades.es-co.i18n.json'
import Auditoria from '@/components/Auditor/auditoria.es-co.i18n.json'
import Compartilhados from '@/common/compartilhados.es-co.i18n.json'
import Biometria from '@/components/FaceBiometric/facebiometric.es-co.i18n.json'
import Coletor from '@/components/Coletores/coletor.es-co.i18n.json'
import CustomizacoesDeCalculo from '@/components/CustomizacoesDeCalculo/customizacao.es-co.i18n.json'
import Compman from '@/components/CompMan/compman.es-co.i18n.json'
import Dashboards from '@/components/DashBoards/dashboards.es-co.i18n.json'
import Dispositivos from '@/components/Devices/dispositivos.es-co.i18n.json'
import Empregado from '@/components/Employee/employee.es-co.i18n.json'
import Empregador from '@/components/Employer/employer.es-co.i18n.json'
import FeriasEAfastamentos from '@/components/FeriasEAfastamentos/feriasEAfastamento.es-co.i18n.json'
import LocalDeTrabalho from '@/components/Company/local.es-co.i18n.json'
import RegraAprovacao from 'src/components/ApprovalsRules/regraAprovacao.es-co.i18n.json'
import Solicitacao from '@/components/Approvals/solicitacao.es-co.i18n.json'
import Comuns from '@/typescript/servicos/i18n/locales/comuns.es-co.i18n.json'
import Home from '@/components/Home/home.es-co.i18n.json'
import MainHeader from '@/components/MainHeader/mainHeader.es-co.i18n.json'
import Cognito from '@/components/Cognito/cognito.es-co.i18n.json'
import Groups from '@/components/Groups/groups.es-co.i18n.json'
import Holiday from '@/components/Holiday/holiday.es-co.i18n.json'
import Journey from '@/components/Journey/journey.es-co.i18n.json'
import Locker from '@/components/Locker/locker.es-co.i18n.json'
import LogDeOperacoes from '@/components/LogDeOperacoes/logDeOperacoes.es-co.i18n.json'
import HourBankRules from '@/components/HourBankRules/hourBankRules.es-co.i18n.json'
import FileManagement from '@/components/FileManagement/fileManagement.es-co.i18n.json'
import Monitors from '@/components/Monitors/monitors.es-co.i18n.json'
import LockerRules from '@/components/LockerRules/lockerRules.es-co.i18n.json'
import MassChanges from '@/components/MassChanges/massChanges.es-co.i18n.json'
import PainelDePontos from '@/components/PainelDePontos/paineldepontos.es-co.i18n.json'
import Permissions from '@/components/Permissions/permissions.es-co.i18n.json'
import Reason from '@/components/Reason/reason.es-co.i18n.json'
import Reports from '@/components/Reports/reports.es-co.i18n.json'
import Routine from '@/components/Routine/routine.es-co.i18n.json'
import Sheets from '@/components/Sheets/sheets.es-co.i18n.json'
import Supervisor from '@/components/Supervisor/supervisor.es-co.i18n.json'
import User from '@/components/User/user.es-co.i18n.json'
import Whb from '@/components/Whb/whb.es-co.i18n.json'
import WorkSchedule from '@/components/WorkSchedule/workSchedule.es-co.i18n.json'
import router from '@/router/router.es-co.i18n.json'
import store from '@/store/store.es-co.i18n.json'
import tipos from '@/tipos/tipos.es-co.i18n.json'
import typescript from '@/typescript/typescript.es-co.i18n.json'
import ModuloSolicitacoes from '@/typescript/modulos/governanca/solicitacoes/solicitacoes.es-co.i18n.json'
import ModuloFolha from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/folha.es-co.i18n.json'
import PainelSolicitacoesDeHorasExtras from '@/typescript/modulos/acompanhamento/PainelSolicitacoesDeHorasExtras/painelSolicitacoesDeHorasExtras.es-co.i18n.json'
import Utils from '@/utils/utils.es-co.i18n.json'
import ModuloGerenciamentoDeFerias from '@/typescript/modulos/calendario-de-trabalho/GerenciamentoDeFerias/gerenciamentoDeFeria.es-co.i18n.json'

const mensagens: LocaleMessageObject = {
  words: {
    user: 'usuario | usuarios',
    employee: 'empleado | empleados',
    company: 'lugar de trabajo | lugares de trabajo',
    companyShortName: 'lugar | lugares',
    employer: 'empleador | empleadores',
    supervisor: 'responsable | responsables',
    routine: 'horario | horarios',
    journey: 'jornada | jornadas',
    customRules: 'regla de cálculo | reglas de cálculo',
    locker: 'bloqueo | bloqueos',
    group: 'grupo | grupos',
    all: 'aplicar a todos'
  },
  acessoNegado: AcessoNegado,
  acompanhamentoTempoReal: AcompanhamentoTempoReal,
  atividades: Atividades,
  auditoria: Auditoria,
  biometria: Biometria,
  coletor: Coletor,
  customizacoesDeCalculo: CustomizacoesDeCalculo,
  compartilhados: Compartilhados,
  compman: Compman,
  dashboards: Dashboards,
  dispositivos: Dispositivos,
  empregado: Empregado,
  empregador: Empregador,
  feriasEAfastamento: FeriasEAfastamentos,
  local: LocalDeTrabalho,
  regraAprovacao: RegraAprovacao,
  solicitacao: Solicitacao,
  comuns: Comuns,
  home: Home,
  mainHeader: MainHeader,
  cognito: Cognito,
  groups: Groups,
  holiday: Holiday,
  journey: Journey,
  locker: Locker,
  logDeOperacoes: LogDeOperacoes,
  hourBankRules: HourBankRules,
  fileManagement: FileManagement,
  monitors: Monitors,
  lockerRules: LockerRules,
  massChanges: MassChanges,
  paineldepontos: PainelDePontos,
  permissions: Permissions,
  reason: Reason,
  reports: Reports,
  routine: Routine,
  sheets: Sheets,
  supervisor: Supervisor,
  user: User,
  whb: Whb,
  workSchedule: WorkSchedule,
  router: router,
  store: store,
  tipos: tipos,
  typescript: typescript,
  solicitacoes: ModuloSolicitacoes,
  folha: ModuloFolha,
  painelSolicitacoesDeHorasExtras: PainelSolicitacoesDeHorasExtras,
  utils: Utils,
  gerenciamentoDeFerias: ModuloGerenciamentoDeFerias
}

export default mensagens
