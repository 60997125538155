<template>
  <div class="sobreposicao-modal" v-if="showModal" v-click-outside="fecharModal">
    <div class="container-modal">
      <div class="conteudo-modal">
        <h2>{{ $t('compartilhados.ModalReportarErroSentry.titulo') }}</h2>
        <div class="grupo-modal">
          <label for="name">{{ $t('compartilhados.ModalReportarErroSentry.legendaNome') }}<span class="required">*</span></label>
          <input
            type="text"
            id="name"
            class="entrada-modal"
            v-model="formulario.nome"
            :placeholder="$t('compartilhados.ModalReportarErroSentry.placeholderNome')"
            :disabled="camposEmailUsuarioDesabilitado"
          >
        </div>

        <div class="grupo-modal">
          <label for="email">{{ $t('compartilhados.ModalReportarErroSentry.legendaEmail') }}<span class="required">*</span></label>
          <input
            type="email"
            id="email"
            class="entrada-modal"
            v-model="formulario.email"
            :placeholder="$t('compartilhados.ModalReportarErroSentry.placeholderEmail')"
            required
            :disabled="camposEmailUsuarioDesabilitado"
          >
        </div>

        <div class="grupo-modal">
          <label for="error">{{ $t('compartilhados.ModalReportarErroSentry.descrevaErro') }}<span class="required">*</span></label>
          <textarea
            id="error"
            class="entrada-modal"
            v-model="formulario.descricaoErro"
            :placeholder="$t('compartilhados.ModalReportarErroSentry.placeholderDescrevaErro')"
            required
            rows="6"
          ></textarea>
        </div>

        <div class="grupo-modal" v-if="formulario.print.arquivo">
          <label>{{ $t('compartilhados.ModalReportarErroSentry.legendaArquivos') }}</label>
          <span class="nome-imagem">{{ formulario.print.nome }}</span>
        </div>

        <span class="form-erro" v-if="erro">{{ erro }}</span>

        <div class="grupo-modal">
          <button class="botao-captura" @click="adicionarCaptura">
            {{ $t('compartilhados.ModalReportarErroSentry.botaoAdicionarCapturaTela') }}
          </button>
        </div>

        <div class="botoes-acao">
          <button class="botao-envio" :disabled="!preencheuCampos" @click="enviarFormulario">{{ $t('compartilhados.ModalReportarErroSentry.botaoEnviar') }}</button>
          <button class="botao-cancelar" @click="fecharModal">{{ $t('compartilhados.ModalReportarErroSentry.botaoCancelar') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';

export default {
  name: 'ErrorReportModal',
  data() {
    return {
      showModal: true,
      dadosUsuario: null,
      camposEmailUsuarioDesabilitado: false,
      erro: null,
      formulario: {
        nome: '',
        email: '',
        descricaoErro: '',
        print: {
          nome: null,
          arquivo: null
        }
      }
    }
  },
  computed: {
    preencheuCampos() {
      return this.formulario.nome && this.formulario.email && this.formulario.descricaoErro;
    }
  },
  methods: {
    fecharModal() {
      this.showModal = false;
      this.$emit('fechar');
    },
    adicionarCaptura() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (event) => {
        const arquivo = event.target.files[0]

        const tamanhoMaximo = 5 * 1024 * 1024;

        if (arquivo.size > tamanhoMaximo) {
          this.erro = this.$t('compartilhados.ModalReportarErroSentry.avisoTamanhoCapturaTela');
          return;
        }

        const reader = new FileReader();

        reader.onload = function(e) {
          const arrayBuffer = e.target.result;
          this.formulario.print.arquivo = new Uint8Array(arrayBuffer);
        }.bind(this);

        reader.readAsArrayBuffer(arquivo);

        this.formulario.print.nome = arquivo.name
        this.erro = null
      };
      input.click();
    },
    async enviarFormulario() {
      try {
        let attachments = []
        if (this.formulario.print.arquivo) {
          attachments.push({
            filename: this.formulario.print.nome,
            data: this.formulario.print.arquivo,
          })
        }

        await Sentry.captureFeedback({
          associatedEventId: Sentry.lastEventId(),
          message: this.formulario.descricaoErro,
          email: this.formulario.email,
          name: this.formulario.nome,
          url: window.location.href,
          tags: {
            idUsuario: this.dadosUsuario?.idUsuario,
            emailUsuario: this.dadosUsuario?.emailUsuario,
            nomeUsuario: this.dadosUsuario?.nomeUsuario,
            papelUsuario: this.dadosUsuario?.papelUsuario,
            idEmpresaUsuario: this.dadosUsuario?.idEmpresaUsuario,
            nomeEmpresaUsuario: this.dadosUsuario?.nomeEmpresaUsuario,
            tamanhoEmpresaUsuario: this.dadosUsuario?.tamanhoEmpresaUsuario,
            canal: this.dadosUsuario?.canal
          }
        }, { attachments });

        this.$modalDeSucesso(this.$t('compartilhados.ModalReportarErroSentry.modalSucesso.mensagem'), this.$t('compartilhados.ModalReportarErroSentry.modalSucesso.titulo'))

        this.fecharModal();

        this.formulario = {
          nome: '',
          email: '',
          descricaoErro: '',
          print: {
            nome: null,
            arquivo: null
          }
        };

      } catch (error) {
        Sentry.captureException(error);
      }
    },
    recuperarUserSentry() {
      const currentScope = Sentry.getCurrentScope()
      this.dadosUsuario = currentScope.getUser()

      if (this.dadosUsuario && 'username' in this.dadosUsuario && 'email' in this.dadosUsuario) {
        this.formulario.nome = this.dadosUsuario.username
        this.formulario.email = this.dadosUsuario.email
        this.camposEmailUsuarioDesabilitado = true
      }
    }
  },
  mounted() {
    this.recuperarUserSentry();
  }
}
</script>

<style lang="scss" scoped>
$primary-color: #0c9b73;
$border-color: #e1e1e1;
$text-color: #333;
$placeholder-color: #888;
$box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

.sobreposicao-modal {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  0% {
    left: -100%;
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    left: 10px;
    opacity: 1;
  }
}

.container-modal {
  background-color: white;
  width: 100%;
  max-width: 320px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: $box-shadow;
}

.conteudo-modal {
  padding: 24px;

  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 21px;
    color: $text-color;
  }
}

.required {
  color: red;
}

.grupo-modal {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;

  label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: $text-color;

    .required {
      font-weight: normal;
    }
  }

  .nome-imagem {
    font-size: 14px;
    color: $text-color;
  }
}

.entrada-modal {
  padding: 6px 12px;
  border: 1px solid $border-color;
  border-radius: 5px;
  font-size: 14px;
  font-family: inherit;
  line-height: inherit;
  transition: border-color 0.3s ease;

  &:focus-visible {
    outline: none;
    border-color: $primary-color;
  }

  &::placeholder {
    color: $placeholder-color;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
}

.form-erro {
  color: red;
  font-size: 12px;
  margin-bottom: .5rem;
  display: block;
}

.botao-captura {
  width: 100%;
  padding: 12px;
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 5px;
  color: $text-color;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(white, 5%);
  }
}

.botoes-acao {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.botao-envio {
  padding: 12px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.botao-cancelar {
  padding: 12px;
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 5px;
  color: $text-color;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(white, 5%);
  }
}
</style>
