import { API } from 'src/services'
import { formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'

export const defaultState = () => ({
  companiesGraph: {data: null, loading: false},
  employeesGraph: {data: null, loading: false},
  periodGraph: {data: null, loading: false},
  calendar: {data: null, loading: false}
})

const __state = defaultState()

const getters = {}

const mutations = {
  companiesGraph (state, payload) {
    state.companiesGraph = payload
  },
  employeesGraph (state, payload) {
    state.employeesGraph = payload
  },
  periodGraph (state, payload) {
    state.periodGraph = payload
  },
  calendar (state, payload) {
    state.periodGraph = payload
    state.calendar = {...payload, data: payload.data}
  }
}

const actions = {
  async loadGraphs ({ rootState, dispatch }, payload) {
    if (rootState.dashboards.typeView === 'extraTime') {
      dispatch('loadGraphsExtra', payload)
    } else if (rootState.dashboards.typeView === 'special') {
      dispatch('loadSpecialGraphs', payload)
    } else {
      dispatch('loadWHBGraphs', payload)
    }
  },

  async loadWHBGraphs (
    { commit, rootState },
    { view, companies = null, employees = null, graph = 'companiesGraph' }
  ) {
    const { typesWhb, startDate, endDate, sumType, orderBy } = rootState.dashboards

    const params = {
      startDate,
      endDate,
      companies,
      employees,
      sumType,
      types: typesWhb.selected.map(i => i.id),
      chartType: 'bars',
      orderBy,
      view
    }

    commit(graph, {data: null, loading: true})
    try {
      const result = await API.dashboardWHB.save(params)
      commit(graph, {data: result.body.success, loading: false})
    } catch {
      commit(graph, {data: null, loading: false})
    }
  },

  async loadGraphsExtra (
    { commit, rootState },
    { view, companies = null, employees = null, graph = 'companiesGraph', ignoreEmpty }
  ) {
    const { types, startDate, endDate, groupType, sumType, orderBy, groupPerLineCompany } = rootState.dashboards

    const params = {
      companies,
      employees,
      types: types.selected.map(i => i.id),
      startDate: formatarDataParaEnvio(startDate),
      endDate: formatarDataParaEnvio(endDate),
      groupPerLineCompany,
      ignoreEmpty,
      chartType: 'bars',
      orderBy,
      view,
      sumType,
      groupType
    }

    commit(graph, {data: null, loading: true})

    try {
      const result = await API.dashboardExtraTime.save(params)
      commit(graph, {data: result.body.success, loading: false})
    } catch {
      commit(graph, {data: null, loading: false})
    }
  },

  async loadSpecialGraphs (
    { commit, rootState },
    { view, companies = null, employees = null, graph = 'companiesGraph', ignoreEmpty }
  ) {
    const { typesSpecial, startDate, endDate, orderBy, sumType } = rootState.dashboards

    const params = {
      companies,
      employees,
      types: typesSpecial.selected.map(i => i.id),
      startDate: formatarDataParaEnvio(startDate),
      endDate: formatarDataParaEnvio(endDate),
      ignoreEmpty,
      chartType: 'bars',
      orderBy: orderBy,
      sumType,
      view
    }

    commit(graph, {data: null, loading: true})

    try {
      const result = await API.dashboardSpecial.save(params)
      commit(graph, {data: result.body.success, loading: false})
    } catch {
      commit(graph, {data: null, loading: false})
    }
  }
}

export default {
  namespaced: true,
  state: __state,
  getters,
  mutations,
  actions
}
