import moment from 'moment'
import { obterHorasEMinutosEmStringAPartirDeUmFloat } from 'src/utils/formatadoras/formatadoras'
import i18n from 'src/typescript/servicos/i18n/i18n.servico'
import { StatusSolicitacao } from '@/typescript/modulos/governanca/solicitacoes/dominio/solicitacoes.enum'

const FLAG_ERRO_NAO_MAPEADO = 'erro_nao_mapeado'

const EventoDeAprovacao = {
  correcaoDePonto: 'correcao_de_ponto',
  dispensa: 'dispensa',
  conversaoDeApontamento: 'conversao_de_apontamento',
  alteracaoDeEscala: 'alteracao_de_escala',
  suspensao: 'suspensao',
  ferias: 'ferias',
  horasExtras: 'horas_extras',
  horasExtrasPreAprovadas: 'horas_extras_pre_aprovadas'
}
const EventoDeAprovacaoParaExibicao = {
  'correcao_de_ponto': 'correção de ponto',
  'dispensa': 'dispensa',
  'conversao_de_apontamento': 'conversão de apontamento',
  'alteracao_de_escala': 'alteração de escala',
  'suspensao': 'suspensão',
  'ferias': 'férias',
  'horas_extras': 'horas extras',
  'horas_extras_pre_aprovadas': 'horas extras pré-aprovadas'
}
const TipoDeEntidade = {
  localDeTrabalho: 'local_de_trabalho',
  empregado: 'empregado',
  grupo: 'grupo',
  perfil: 'perfil'
}

const AcaoAplicadaSolicitacao = {
  aprovar: 'APROVAR',
  reprovar: 'REPROVAR',
  atestarCiencia: 'ATESTAR_CIENCIA_DE_AUTOAPROVACAO'
}

function validarProblema (item) {
  const possuiProblema = item && item.problematic
  const ehUmObjeto = typeof item.problematic === 'object'
  const possuiTodasAsChaves = ['verbo', 'mensagem', 'ultimaTentativaPor', 'ultimaTentativaEm']
    .every(key => Object.keys(item.problematic).includes(key))

  if (possuiProblema && ehUmObjeto && possuiTodasAsChaves) return

  throw Error(i18n.t('solicitacao.Utils.erroObjetoDescritivoProblemaNaoValido'))
}

function pegarMensagemDoProblemaTooltip (item) {
  if (item && !item.problematic) return

  validarProblema(item)
  const {verbo, mensagem} = item.problematic

  if (mensagem === FLAG_ERRO_NAO_MAPEADO) {
    return i18n.t('solicitacao.Utils.erroInesperadoAoAprovarPedido', {verbo})
  }

  return i18n.t('solicitacao.Utils.erroAoAprovarPedido', {verbo, mensagem})
}

function pegarMensagemDoProblemaDetalhes (item) {
  if (item && !item.problematic) return

  validarProblema(item)

  const {verbo, mensagem, ultimaTentativaPor, ultimaTentativaEm} = item.problematic
  const detalhes = i18n.t('solicitacao.Utils.ultimaTentativaEm', {ultimaTentativaPor, ultimaTentativaEm})

  if (mensagem === FLAG_ERRO_NAO_MAPEADO) {
    return {
      detalhes,
      observacao: i18n.t('solicitacao.Utils.erroInesperadoAoAprovarPedido', {verbo})
    }
  }

  return {
    detalhes,
    observacao: i18n.t('solicitacao.Utils.erroAoAprovarPedido', {verbo, mensagem})
  }
}

function obterSolicitacaoParaDispensa (aprovacao) {
  const formatarHora = hora => moment(hora).format('HH:mm')

  if (aprovacao.info.allDay) {
    return i18n.t('solicitacao.Utils.obterSolicitacaoparaDispensa.dispensaTotal')
  } else {
    if (aprovacao.info.startTime) {
      return i18n.t('solicitacao.Utils.obterSolicitacaoparaDispensa.dispensaParcialDas', {de: formatarHora(aprovacao.info.startTime), ate: formatarHora(aprovacao.info.endTime)})
    } else if (aprovacao.info.duration) {
      return i18n.t('solicitacao.Utils.obterSolicitacaoparaDispensa.dispensaParcialComDuracao', {duracao: obterHorasEMinutosEmStringAPartirDeUmFloat(aprovacao.info.duration)})
    } else {
      return i18n.t('solicitacao.Utils.obterSolicitacaoparaDispensa.dispensaParcial')
    }
  }
}

function obterSolicitacaoParaCorrecaoDePonto (aprovacao) {
  return aprovacao.info.kind + ' às ' + aprovacao.info.time
}

function obterSolicitacaoParaConversaoDeApontamento (aprovacao) {
  const apontamentos = Object.keys(aprovacao.info.apontamentos)
  if (apontamentos.length === 0) {
    return ''
  }

  if (apontamentos.length > 1) {
    return i18n.t('solicitacao.Utils.variosApontamentos')
  }

  return aprovacao.info.apontamentos[0].text
}

function obterSolicitacaoParaAlteracaoDeEscala (aprovacao) {
  const escalaOriginal = aprovacao.info.escalaOriginal.escala
  const escalaSubstituindo = aprovacao.info.escalaSubstituindo.escala

  if (escalaOriginal.cargaHorariaMensal === escalaSubstituindo.cargaHorariaMensal) {
    return i18n.t('solicitacao.Utils.mesmaCargaHoraria')
  }
  return i18n.t('solicitacao.Utils.diferenteCargaHoraria')
}

function obterInformacoesExtrasDispensa (aprovacao) {
  const formataBooleano = valor => valor ? i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.sim') : i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.nao')
  const formatarCampoQuePodeVirVazio = valor => valor || '-'

  const obterDataDoLancamento = _ => aprovacao?.date.replace(/\//g, '.') || '-'
  const obterDuracaoParaPausa = _ => {
    const duracaoParaPausa = aprovacao.info?.specifiedLunchDur
    if (duracaoParaPausa) return obterHorasEMinutosEmStringAPartirDeUmFloat(duracaoParaPausa)
    else return i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.naoConfigurado')
  }
  const obterTempoDeDeslocamento = _ => {
    const tempoDeDeslocamento = aprovacao.info?.travelTime
    if (tempoDeDeslocamento) return obterHorasEMinutosEmStringAPartirDeUmFloat(tempoDeDeslocamento)
    else return i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.naoConfigurado')
  }

  return {
    resumos: [
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.motivo'), valor: aprovacao.info?.reason},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.dataDeLancamento'), valor: obterDataDoLancamento()},
      // TODO: a implementação antiga tá meio complicada, no futuro é preciso incluir novamente
      // {titulo: 'período de dispensa', valor: aprovacao.info?.reason},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.duracaoParaPausa'), valor: obterDuracaoParaPausa()},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.tempoDeDeslocamento'), valor: obterTempoDeDeslocamento()},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.nomeDoMedico'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorName)},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.crm'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorCrm)},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.especialidade'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorSpeciality)},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.clinica'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorClinicName)},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.cnpj'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorClinicCnpj)},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.cid'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorCid)},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.classeProfissional'), valor: formatarCampoQuePodeVirVazio(aprovacao.info?.doctorClasse)}
    ],
    arquivo: aprovacao.info?.imgLink,
    detalhes: {
      cabecalho: ['detalhes', ''],
      linhas: [
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.descontarDsr'), formataBooleano(aprovacao.info?.isDsr)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.atestadoAnexado'), formataBooleano(aprovacao.info?.imgLink)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.declaracaoDeComparecimento'), formataBooleano(aprovacao.info?.doctorAttendence)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.atestadoDeDispensa'), formataBooleano(aprovacao.info?.doctorJustify)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.considerarComoHorasTrabalhadas'), formataBooleano(aprovacao.info?.hoursAreWork)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.descontarDispensaNasHorasDaJornadaDoDia'), formataBooleano(aprovacao.info?.hoursAreNothing)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.considerarComoFalta'), formataBooleano(aprovacao.info?.isFalta)],
        [i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.ignorarJornadasDeFolga'), formataBooleano(aprovacao.info?.ignorarFolga)]
      ]
    }
  }
}

function obterInformacoesExtrasParaConversaoDeApontamento (aprovacao) {
  const obterHorasEmFaltas = _ => {
    const horasEmFaltas = aprovacao.info?.apontamentos?.value
    if (horasEmFaltas) return obterHorasEMinutosEmStringAPartirDeUmFloat(horasEmFaltas)
    else return i18n.t('solicitacao.Utils.obterInfoExtrasDispensas.naoConfigurado')
  }

  return {
    resumos: [
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtraParaBH.saldoTotalDoBancoDeHoras'), valor: aprovacao.info?.bancoDeHoras?.allTime}
    ],
    arquivo: null,
    detalhes: {
      cabecalho: ['detalhes', ''],
      linhas: [
        [i18n.t('solicitacao.Utils.obterInfoExtraParaBH.horasEmFaltas'), obterHorasEmFaltas()]
      ]
    }
  }
}

function obterInformacoesExtrasParaAlteracaoDeEscala (aprovacao) {
  const obterDiaInicialEscala = escala => escala.diaInicial.replace(/\//g, '.') || '-'
  return {
    resumos: [
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtraParaAlteracaoDeEscala.quemSolicitou'), valor: aprovacao.info?.quemSolicitouMudanca.nome},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtraParaAlteracaoDeEscala.escalaOriginal'), valor: aprovacao.info?.escalaOriginal.escala.cargaHorariaMensal},
      {titulo: i18n.t('solicitacao.Utils.obterInfoExtraParaAlteracaoDeEscala.escalaSubstituindo'), valor: aprovacao.info?.escalaSubstituindo.escala.cargaHorariaMensal}
    ],
    arquivo: null,
    detalhes: {
      cabecalho: [i18n.t('solicitacao.Utils.obterInfoExtraParaAlteracaoDeEscala.detalhes'), ''],
      linhas: [
        [i18n.t('solicitacao.Utils.obterInfoExtraParaAlteracaoDeEscala.diaInicialEscalaOriginal'), obterDiaInicialEscala(aprovacao.info?.escalaOriginal)],
        [i18n.t('solicitacao.Utils.obterInfoExtraParaAlteracaoDeEscala.diaInicialEscalaSubstituindo'), obterDiaInicialEscala(aprovacao.info?.escalaSubstituindo)]
      ]
    }
  }
}

function obterStatus (aprovacao) {
  if (aprovacao.problematic) return StatusSolicitacao.ComObservacao
  else if (aprovacao.approved === true) return StatusSolicitacao.Aprovado
  else if (aprovacao.approved === false) return StatusSolicitacao.Recusado
  else return StatusSolicitacao.Pendente
}

function obterSolicitacao (aprovacao) {
  if (aprovacao.typeView === 'Dispensa') return obterSolicitacaoParaDispensa(aprovacao)
  else if (aprovacao.typeView === 'Correção de ponto') return obterSolicitacaoParaCorrecaoDePonto(aprovacao)
  else if (aprovacao.typeView === 'conversão de apontamento em bh') return obterSolicitacaoParaConversaoDeApontamento(aprovacao)
  else if (aprovacao.typeView === 'Alteração de Escala') return obterSolicitacaoParaAlteracaoDeEscala(aprovacao)
  else return ''
}

function obterMotivo (aprovacao) {
  if (!aprovacao?.employeeReason) {
    if (aprovacao.typeView === 'Correção de ponto') return aprovacao.info.justificativa
    else if (aprovacao.typeView === 'conversão de apontamento em bh') return i18n.t('solicitacao.Utils.apontamentoGeradoDeAcordoComAsHorasTrabalhadas')
    else return '-'
  }
  return aprovacao.info.reason || aprovacao.employeeReason
}

function obterTipoDeEvento (aprovacao) {
  if (aprovacao.typeView === 'Dispensa') return EventoDeAprovacao.dispensa
  else if (aprovacao.typeView === 'Correção de ponto') return EventoDeAprovacao.correcaoDePonto
  else if (aprovacao.typeView === 'conversão de apontamento em bh') return EventoDeAprovacao.conversaoDeApontamento
  else if (aprovacao.typeView === 'Alteração de Escala') return EventoDeAprovacao.alteracaoDeEscala
  else return ''
}

function obterAprovadoAutomaticamente (aprovacao) {
  return aprovacao.approvedBy === 'Autoapprovação' || aprovacao.approvedBy === 'Aprovação Automática'
}

function obterAcaoExecutadaPor (aprovacao) {
  const aprovadoAutomaticamente = obterAprovadoAutomaticamente(aprovacao)
  if (aprovadoAutomaticamente) return null
  else return aprovacao.approvedBy
}

function obterInformacoesExtras (aprovacao) {
  if (aprovacao.typeView === 'Dispensa') return obterInformacoesExtrasDispensa(aprovacao)
  else if (aprovacao.typeView === 'Correção de ponto') return null
  else if (aprovacao.typeView === 'conversão de apontamento em bh') return obterInformacoesExtrasParaConversaoDeApontamento(aprovacao)
  else if (aprovacao.typeView === 'Alteração de Escala') return obterInformacoesExtrasParaAlteracaoDeEscala(aprovacao)
  else if (aprovacao.typeView === 'Horas Extras') return null
  else return null
}

function obterTipoDeEventoNaTabela (tipoDeEvento) {
  switch (tipoDeEvento) {
    case EventoDeAprovacao.correcaoDePonto:
      return 'Correção de ponto'
    case EventoDeAprovacao.dispensa:
      return 'Dispensa'
    case EventoDeAprovacao.suspensao:
      return 'Suspensão'
    case EventoDeAprovacao.conversaoDeApontamento:
      return 'Conversão de apontamento'
    case EventoDeAprovacao.alteracaoDeEscala:
      return 'Alteração de escala'
    case EventoDeAprovacao.ferias:
      return 'Férias'
    case EventoDeAprovacao.horasExtras:
      return 'Horas extras'
    case EventoDeAprovacao.horasExtrasPreAprovadas:
      return 'Horas extras pré-aprovadas'
  }
}

function formatarAprovacaoAntiga (aprovacaoFormatoAntigo, ehInfoCompleta = false) {
  const erroNaSolicitacaoFormatoAntigo = pegarMensagemDoProblemaDetalhes(
    aprovacaoFormatoAntigo
  )
  const aprovacaoFormatoNovo = {
    id: aprovacaoFormatoAntigo.id,
    tipoDeEvento: obterTipoDeEvento(aprovacaoFormatoAntigo),
    status: obterStatus(aprovacaoFormatoAntigo),
    intervaloDoEvento: {
      inicio: moment(
        aprovacaoFormatoAntigo.date,
        'DD/MM/YYYY'
      ).toISOString(),
      fim: moment(aprovacaoFormatoAntigo.date, 'DD/MM/YYYY').toISOString()
    },
    empregado: {
      id: aprovacaoFormatoAntigo?.employeeId,
      nome: aprovacaoFormatoAntigo.employeeName
    },
    solicitacao: obterSolicitacao(aprovacaoFormatoAntigo),
    motivo: obterMotivo(aprovacaoFormatoAntigo),
    cienteDeAutoAprovacao: aprovacaoFormatoAntigo.isAware,
    erroNaSolicitacao: {
      tooltip: pegarMensagemDoProblemaTooltip(aprovacaoFormatoAntigo),
      observacao: erroNaSolicitacaoFormatoAntigo?.observacao,
      detalhes: erroNaSolicitacaoFormatoAntigo?.detalhes
    },
    ehAprovacaoAntiga: true,
    criadoEm: aprovacaoFormatoAntigo.criadoEm,
    quemSolicitou: aprovacaoFormatoAntigo.quemSolicitou
  }

  if (ehInfoCompleta) {
    return {
      ...aprovacaoFormatoNovo,
      regraDeAprovacao: {
        id: aprovacaoFormatoAntigo.ruleSource,
        nome: aprovacaoFormatoAntigo.ruleSourceName
      },
      aprovadoAutomaticamente: obterAprovadoAutomaticamente(
        aprovacaoFormatoAntigo
      ),
      acaoExecutadaPor: obterAcaoExecutadaPor(aprovacaoFormatoAntigo),
      acaoExecutadaEm: aprovacaoFormatoAntigo.approvedAt,
      motivoDaAcaoExecutada: aprovacaoFormatoAntigo.approvalReason,
      informacoesExtras: obterInformacoesExtras(aprovacaoFormatoAntigo),
      dataDoLancamento: null,
      quemAtestouCiencia: null,
      atestadoCienciaEm: null,
      devePermitirResposta: true
    }
  }

  return aprovacaoFormatoNovo
}

export {
  EventoDeAprovacao,
  EventoDeAprovacaoParaExibicao,
  StatusSolicitacao,
  TipoDeEntidade,
  AcaoAplicadaSolicitacao,
  pegarMensagemDoProblemaTooltip,
  pegarMensagemDoProblemaDetalhes,
  obterStatus,
  obterSolicitacao,
  obterMotivo,
  obterTipoDeEvento,
  obterAcaoExecutadaPor,
  obterAprovadoAutomaticamente,
  obterInformacoesExtras,
  obterTipoDeEventoNaTabela,
  formatarAprovacaoAntiga
}
