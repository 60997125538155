<template lang="pug">

  transition(name="datepicker")
    .p-datepicker(v-click-outside="fecharSeletorData")
      p-text-field.p-datepicker__textfield(
        v-model="dataRenderizadaNoInput",
        :label="label ? label : ' '",
        :name="name",
        type="text",
        autocomplete="off",
        :required="$attrs.hasOwnProperty('required')",
        :loading="loading",
        :disabled="disabled",
        v-mask="'##/##/####'",
        :error="error"
        :sinalizarErroSemMensagem="sinalizarErroSemMensagem"
        :readonly="inputDesabilitadoParaDigitacao"
        :mensagemDeInformacao="mensagemDeInformacao"
      )
        template(slot="logoIcon")
          slot(name="logoIcon")

        template(slot="icon")
          PButton(:size="buttonSize" color="primary" @click="abrirSeletorData" :disabled="disabled")
            span.fs-10.fw-bold.icone-chapado-calendario.color-primary-contrast(:v-uiv-tooltip="$t('compartilhados.date.PDatePicker.mostrarCalendario')" :disabled="disabled")

      transition(name="bounce")
        .p-datepicker__seletor(v-if="mostrarSeletorDeData")
          DatePicker(
            v-model="valorDataFormatada",
            :lang="configuracaoLocale",
            :inline="true"
            :range="range"
            :disabled-date="datasDesabilitadas"
            :type="tipoDoSeletor"
          )
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import PTextField from 'src/common/inputs/PTextField.vue'
import PButton from 'src/common/buttons/PButton.vue'

import moment from 'moment'

import { handlerUseStateVmodel } from '../mixins/handlerUseStateVmodel'
import { mask } from 'vue-the-mask'

export default {
  name: 'PDatePicker',
  mixins: [handlerUseStateVmodel()],
  directives: { mask },
  components: {
    PButton,
    PTextField,
    DatePicker
  },
  props: {
    name: { type: String, required: true },
    format: { type: String, 'default': 'DD/MM/YYYY' },
    loading: Boolean,
    disabled: Boolean,
    label: String,
    error: String,
    sinalizarErroSemMensagem: { type: Boolean, required: false, default: false },
    range: { type: Boolean, default: false },
    minDate: {type: Date},
    maxDate: {type: Date},
    buttonSize: {type: String, default: 'sm'},
    tipoDoSeletor: {type: String, default: 'date'},
    mensagemDeInformacao: {type: String, default: ''}
  },
  watch: {
    state () {
      this.definirInputPeloModoDeVisualizacao()
    },
    value () {
      this.definirInputPeloModoDeVisualizacao()
    }
  },
  data () {
    return {
      mostrarSeletorDeData: false,
      valorDoInput: ''
    }
  },
  mounted () {
    this.definirInputPeloModoDeVisualizacao()
  },
  methods: {
    abrirSeletorData () {
      this.mostrarSeletorDeData = !this.mostrarSeletorDeData
      this.$emit('abreDataPicker')
    },
    fecharSeletorData () { this.mostrarSeletorDeData = false },
    formatarDataComIntervaloParaInput (start, end) {
      const dataInicialFormatada = moment(start).format(this.format)
      const dataFinalFormatada = moment(end).format(this.format)
      this.valorDoInput = `${dataInicialFormatada} - ${dataFinalFormatada}`
    },
    definirInputPeloModoDeVisualizacao () {
      if (this.modoVisualizacao === 'unica') {
        this.valorDoInput = moment(this.valorDataFormatada)

        if (!this.valorDoInput.isValid()) {
          this.valorDoInput = ''
        } else {
          this.valorDoInput = this.valorDoInput.format(this.format)
        }
      } else if (this.modoVisualizacao === 'intervalo') {
        const dataFormatada = this.formatarDatasParaComponente(this.valorDataFormatada)
        this.formatarDataComIntervaloParaInput(dataFormatada.start, dataFormatada.end)
      }
    },
    formatarDatasParaComponente (value) {
      if (Array.isArray(value)) {
        return {
          start: value[0],
          end: value[1]
        }
      } else {
        return value
      }
    },
    datasDesabilitadas (data) {
      if (this.minDate && this.maxDate) {
        const dataEhMenorQueDataMinima = moment(data).isBefore(this.minDate, 'day')
        const dataEhMaiorQueDataMaxima = moment(data).isAfter(this.maxDate, 'day')
        return dataEhMenorQueDataMinima || dataEhMaiorQueDataMaxima
      }
      if (this.minDate) {
        const dataEhMenorQueDataMinima = moment(data).isBefore(this.minDate, 'day')
        return dataEhMenorQueDataMinima
      }

      if (this.maxDate) {
        const dataEhMaiorQueDataMaxima = moment(data).isAfter(this.maxDate, 'day')
        return dataEhMaiorQueDataMaxima
      }
    }
  },
  computed: {
    dataRenderizadaNoInput: {
      get () { return this.valorDoInput },
      set (value) {
        if (this.modoVisualizacao === 'unica') {
          if (value === '') {
            this.valorDoInput = ''
            this.state = null
          }
          value = moment(value, this.format, true)
          if (value.isValid()) {
            this.valorDataFormatada = value.toDate()
          }
        }
      }
    },
    valorDataFormatada: {
      get () {
        if (this.modoVisualizacao === 'intervalo') {
          return [this.state.start, this.state.end]
        } else {
          return this.state
        }
      },
      set (value) {
        if (value !== this.state) {
          const dataFormatada = this.formatarDatasParaComponente(value)
          this.fecharSeletorData()
          this.state = dataFormatada

          if (this.modoVisualizacao === 'unica') {
            this.valorDoInput = moment(dataFormatada, this.format, true)
          } else if (this.modoVisualizacao === 'intervalo') {
            this.formatarDataComIntervaloParaInput(dataFormatada.start, dataFormatada.end)
          }
        }
      }
    },
    modoVisualizacao () { return this.range ? 'intervalo' : 'unica' },
    inputDesabilitadoParaDigitacao () { return this.range },
      configuracaoLocale () {
      return {
        formatLocale: {
          firstDayOfWeek: 0,
          monthsShort: [
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.janeiro'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.fevereiro'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.marco'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.abril'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.maio'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.junho'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.julho'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.agosto'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.setembro'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.outubro'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.novembro'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.meses.dezembro')
          ],
          weekdaysMin: [
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Dom'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Seg'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Ter'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Qua'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Qui'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Sex'),
            this.$t('sheets.Timesheet.Header.DateSlider.configuracaoDatePicker.dias.Sab')
          ]
        }
      }
    }
  }
}
</script>

<style lang='scss'>
.p-datepicker {
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  animation: slide-down .2s ease-out;
}

.p-datepicker__seletor {
  position: absolute !important;
  z-index: 2000 !important;
  margin-top: 50px;
  align-self: baseline;
}

/* Sobreposições de customização nos componentes do vue2-datepicker */

.mx-datepicker-main {
  /* Sobrepoe font utilizada em todo componente */
  font-family: var(--front-font)!important;
}
.mx-calendar-header-label > * {
  /* Sobrepoe legendas do dia da semana  */
  font-size: 14px!important;
  color: var(--color-primary)!important;
}
.mx-calendar-content .cell.active {
  /* Sobrepoe celula do dia selecionado  */
  background-color: var(--color-primary)!important;
  color: var(--color-primary-contrast)!important;
}

.mx-table-date td {
  /* Sobrepoe font-size dos dias do calendário */
  font-size: 14px!important;
}
.mx-table-date td:not(.not-current-month) {
  color: var(--color-primary)!important;
}
</style>
