import { MODULOS_VALORES_PADRAO } from 'src/core/modulos'
/*
  Modulos do sistema.
  O valor dos modulos, exceto o modulo_base, são definidos no Flagsmith.

  Os valores são recuperados através do serviço Flagsmith após o login do usuário.

  O modulo_base é um modulo padrão do sistema, e não pode ser desativado.
*/


export default {
  state: {
    modulo_base: MODULOS_VALORES_PADRAO.modulo_base,
    modulo_registro_de_ponto: MODULOS_VALORES_PADRAO.modulo_registro_de_ponto,
    modulo_reducao_de_riscos: MODULOS_VALORES_PADRAO.modulo_reducao_de_riscos,
    modulo_calculos_e_tratamento: MODULOS_VALORES_PADRAO.modulo_calculos_e_tratamento,
    modulo_gestao_de_ponto_participativo: MODULOS_VALORES_PADRAO.modulo_gestao_de_ponto_participativo,
    modulo_controle_de_custos: MODULOS_VALORES_PADRAO.modulo_controle_de_custos,
    modulo_timesheet: MODULOS_VALORES_PADRAO.modulo_timesheet,
    modulo_gestao_de_ferias_e_ausencias: MODULOS_VALORES_PADRAO.modulo_gestao_de_ferias_e_ausencias
  },
  getters: {},
  mutations: {
    DEFINIR_MODULOS (state, modulos) {
      for (const [key, value] of Object.entries(modulos)) {
        state[key] = value
      }
    }
  },

  actions: {}
}
