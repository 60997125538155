var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-header" },
    [
      !_vm.onlySheetView
        ? [
            _c(
              "router-link",
              { staticClass: "main-header__logo", attrs: { to: "/" } },
              [
                _c("img", {
                  staticClass: "to-click",
                  attrs: {
                    src: _vm.logo,
                    alt: _vm.$t("mainHeader.MainHeader.legendaLogo"),
                  },
                }),
              ]
            ),
          ]
        : [
            _c("a", { staticClass: "main-header__logo" }, [
              _c("img", {
                attrs: {
                  src: _vm.logo,
                  alt: _vm.$t("mainHeader.MainHeader.legendaLogo"),
                },
              }),
            ]),
          ],
      _c(
        "ul",
        { staticClass: "main-header__links" },
        [
          !_vm.onlySheetView
            ? [
                _c("Notification"),
                _vm._l(_vm.menus, function (menu) {
                  return menu.show && menu.values.find((i) => i.show)
                    ? _c(
                        "uiv-dropdown",
                        {
                          key: menu.category,
                          ref: `dropdown-${menu.category}`,
                          refInFor: true,
                          staticClass: "category-dropdown",
                          class: _vm.categoriaEstaSelecionada(menu.category),
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "to-click main-header__button--category noselect lowercase dropdown-toggle",
                              attrs: {
                                type: "primary",
                                tabindex: "0",
                                "aria-haspopup": "true",
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return (() =>
                                    _vm.$refs[
                                      `dropdown-${menu.category}`
                                    ][0].toggle()).apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(menu.category))]),
                              _c("div", {
                                staticClass: "category-icon",
                                class: `icone-chapado-${menu.icon}`,
                              }),
                              _c("div", {
                                staticClass:
                                  "arrow-icon icone-chapado-seta-baixo",
                              }),
                            ]
                          ),
                          _c("template", { slot: "dropdown" }, [
                            _c(
                              "li",
                              _vm._l(menu.values, function (item, index) {
                                return item.show
                                  ? _c(
                                      "router-link",
                                      {
                                        key: index,
                                        staticClass:
                                          "to-click noselect lowercase",
                                        attrs: { tag: "a", to: item.link },
                                      },
                                      [
                                        item.adapted
                                          ? [_vm._v(_vm._s(item.adapted))]
                                          : [_vm._v(_vm._s(item.name))],
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              }),
                              1
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e()
                }),
              ]
            : [
                _vm.possuiAlgumaPermissaoDeRelatorio
                  ? _c("Notification")
                  : _vm._e(),
                _c("label", { staticStyle: { "grid-column": "2/-2" } }, [
                  _vm._v(_vm._s(_vm.$store.state.userInfo.name)),
                ]),
              ],
          !_vm.ehPecom
            ? _c(
                "uiv-popover",
                {
                  attrs: {
                    trigger: "hover",
                    title: _vm.$t("mainHeader.MainHeader.sair"),
                  },
                },
                [
                  _vm.showLogoutButton
                    ? _c(
                        "ul",
                        {
                          staticClass: "to-click",
                          staticStyle: { padding: "0" },
                          attrs: { id: "botao-sair" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "to-click main-header__button--category",
                              attrs: { tabindex: "0" },
                              on: {
                                click: _vm.sair,
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.sair.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("mainHeader.MainHeader.sair"))
                                ),
                              ]),
                              _c("div", {
                                staticClass: "icone-saida icone-chapado-saida",
                                staticStyle: { "font-size": "1.4em" },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("template", { slot: "popover" }, [
                    _vm.infoDoUsuario
                      ? _c(
                          "div",
                          { staticClass: "info-do-usuario" },
                          _vm._l(_vm.infoDoUsuario, function (item, key) {
                            return _c(
                              "div",
                              {
                                key: key,
                                class: { [`info-do-usuario__${key}`]: true },
                              },
                              [
                                item.conteudo
                                  ? [
                                      _c("div", { staticClass: "legenda" }, [
                                        _vm._v(_vm._s(item.legenda)),
                                      ]),
                                      _c("div", { staticClass: "conteudo" }, [
                                        _vm._v(_vm._s(item.conteudo)),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.ehPecom
        ? _c("SeletorIdioma", {
            attrs: {
              infoDoUsuario: _vm.infoDoUsuario,
              mostrarSeletorDeIdioma: _vm.possuiModuloIdioma,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }