import { ErroFlagsmith } from './flagsmith.excecoes';
import { IModulosDoSistema, MODULOS, MODULOS_VALORES_PADRAO } from 'src/core/modulos'
import { Store } from 'vuex';
import * as Sentry from '@sentry/vue'
import flagsmith from 'flagsmith';

class ServicoFlagSmith {
  store: Store<unknown>;
  constructor (store: Store<unknown>) {
    this.store = store
  }

  private async inicializar (idEmpresaUsuario: string, nomeEmpresaUsuario: string): Promise<void> {
    flagsmith.init({
      api: process.env.VUE_APP_FLAGSMITH_URL,
      environmentID: process.env.VUE_APP_FLAGSMITH_API_KEY,
      evaluationContext: {
        identity: {
          identifier: idEmpresaUsuario,
          traits: {
            nome_cliente: nomeEmpresaUsuario
          }
        },
      },
      enableAnalytics: true,
      onChange: () => {
        this.atribuirModulosParaAplicacao();
      },
      onError: (erro) => {
        Sentry.captureException(new ErroFlagsmith(erro.message), {
          extra: {
            cliente_id: idEmpresaUsuario,
            cliente_nome: nomeEmpresaUsuario
          }
        })
        return
       }
    }).catch(() => {})
  }

  async obterAtributosDoUsuario () {
    try {
      const atributos = flagsmith.getAllTraits()
      const modulos = Object.values(MODULOS) as IModulosDoSistema[]
      const modulosRecuperadosFlagsmith = {} as Record<IModulosDoSistema, unknown>

      for (const modulo of modulos) {
        modulosRecuperadosFlagsmith[modulo] = atributos[modulo] ?? MODULOS_VALORES_PADRAO[modulo]
      }
      return modulosRecuperadosFlagsmith
    } catch {
      Sentry.captureException(new ErroFlagsmith('Erro ao obter atributos do usuário'), {
        level: 'info'
      })
      return MODULOS_VALORES_PADRAO
    }
  }

  async atribuirModulosParaAplicacao () {
    const modulos = await this.obterAtributosDoUsuario()
    if (modulos) {
      this.store.commit('DEFINIR_MODULOS', modulos)
    }
  }
}

export default ServicoFlagSmith
