var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        { attrs: { id: "header-space" } },
        [
          _vm.userInfo && !_vm.estaNaRotaDeAcessoNegado
            ? _c("MainHeader")
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "view-space" } },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view", { staticClass: "view" })],
            1
          ),
        ],
        1
      ),
      _vm.userInfo && _vm.usuarioEhRH
        ? _c(
            "BotaoFlutuante",
            {
              attrs: {
                texto: "Fale Conosco",
                direcaoExpansao: "direita",
                orientacao: "inferior-esquerda",
                backgroundColor: "#03ac55",
                "aria-label": "Converse conosco no WhatsApp",
              },
              on: { clique: _vm.abrirLinkWhatsapp },
            },
            [
              _c(
                "div",
                {
                  staticClass: "container-icone",
                  staticStyle: {
                    width: "30px",
                    height: "30px",
                    overflow: "hidden",
                    "border-radius": "100%",
                  },
                  attrs: { slot: "icon" },
                  slot: "icon",
                },
                [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: "https://www.pontotel.com.br/wp-content/uploads/2020/10/whatsapp-icon.png",
                      alt: "ícone do whatsapp",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.mostrarBotaoChatInkeep
        ? _c(
            "BotaoFlutuante",
            {
              attrs: {
                texto: "Assistente",
                direcaoExpansao: "esquerda",
                orientacao: "inferior-direita",
                backgroundColor: "#211b1f",
              },
              on: {
                clique: function ($event) {
                  _vm.mostrarModalInkeep = true
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "container-icone",
                  staticStyle: {
                    width: "30px",
                    height: "30px",
                    overflow: "hidden",
                    "border-radius": "100%",
                  },
                  attrs: { slot: "icon" },
                  slot: "icon",
                },
                [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: require("../public/favicon.png"), alt: "" },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("ModalInkeep", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mostrarModalInkeep,
            expression: "mostrarModalInkeep",
          },
        ],
        on: {
          fechar: function ($event) {
            _vm.mostrarModalInkeep = false
          },
        },
      }),
      false
        ? _c("BotaoFlutuante", {
            attrs: {
              texto: _vm.$t(
                "compartilhados.ModalReportarErroSentry.legendaBotaoReporte"
              ),
              orientacao: "inferior-esquerda",
              direcaoExpansao: "direita",
              espacamentoVertical: "75px",
              espacamentoHorizontal: "10px",
              backgroundColor: "#79397d",
            },
            on: { clique: _vm.abrirModalReportarErro },
          })
        : _vm._e(),
      _vm.mostrarModalReportarErro
        ? _c("ModalReportarErroSentry", {
            on: {
              fechar: function ($event) {
                _vm.mostrarModalReportarErro = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }