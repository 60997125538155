export const configHelpers = {
  defaultConfig () {
    return {
      headerItems: null
    }
  },
  storageName (name) {
    return `smart-table-${name}`
  },
  saveConfigs (name, configs) {
    localStorage.setItem(this.storageName(name), JSON.stringify(configs))
    return configs
  },
  getConfigs (name, defaultHeaderItems) {
    const defaults = configHelpers.defaultConfig()
    defaults.headerItems = defaultHeaderItems.map(i => ({...i, isToShow: i.isToShow === undefined ? true : i.isToShow}))

    try {
      let saved = localStorage.getItem(this.storageName(name))

      if (!saved) {
        throw new Error('Not saved yet or its corrupted')
      }
      saved = JSON.parse(saved)

      // We only use the order of headerItems saved in the localStorage and the field isToShow
      // If we use all the object saved in the storage can cause problem if we change some header default config
      defaults.headerItems.sort(function (a, b) {
        return (
          saved.headerItems.findIndex(i => i.field === a.field) - saved.headerItems.findIndex(i => i.field === b.field)
        )
      })

      // copy isToShow form saved
      defaults.headerItems = defaults.headerItems
        .map(i => ({...i, isToShow: Boolean(saved.headerItems.find(s => s.column === i.column).isToShow)}))

      return defaults
    } catch {
      return this.saveConfigs(name, defaults)
    }
  }
}

export default {
  data () {
    return {
      detailsBox: {
        show: false,
        index: null
      },
      scrollAreaWidth: null,
      showConfigBox: false,
      configs: configHelpers.defaultConfig()
    }
  },
  created () {
    this.loadConfigs()
  },
  watch: {
    headerItems: function () {
      this.loadConfigs()
    }
  },
  computed: {
    header () {
      return this.configs.headerItems.filter(i => i.isToShow)
    },
    configuracaoDeColunas () {
      const valorPadrao = '1fr'
      const colunaConfiguracao = this.mergedConfig.possuiColunaDeConfiguracao ? 'minmax(0, 20px)' : ''
      const colunas = this.header
        .map(item => `minmax(0, ${item.width || valorPadrao})`)
        .reduce((acumulador, valorAtual) => `${acumulador} ${valorAtual}`)

      return {
        'grid-template-columns': `${colunas} ${colunaConfiguracao}`
      }
    },
    estilosDinamicos () {
      const totalDeItens = this.isServerSidePaginated
        ? this.serverSidePagination.totalItems
        : this.items.length
      const ALTURA_LINHA_DO_CABECALHO = 45
      const ALTURA_LINHA_DOS_ITENS = 35

      if (this.header.length === 0) return {}

      const configuracaoDeColunas = this.configuracaoDeColunas
      const estilosQuandoNaoHouverConteudo = this.alturaMinimaDinamica(ALTURA_LINHA_DO_CABECALHO, ALTURA_LINHA_DOS_ITENS)
      const configuracaoDeLinhas = this.configuracaoDeLinhas(totalDeItens, ALTURA_LINHA_DO_CABECALHO, ALTURA_LINHA_DOS_ITENS)

      return {
        ...configuracaoDeLinhas,
        ...configuracaoDeColunas,
        ...estilosQuandoNaoHouverConteudo,
      }
    },
    configAndDetailsBoxPosition () {
      return {
        gridStyle: {
          'grid-column': `${this.mergedConfig.detailsBox.numberColumnsKeep + 1} / -1`,
          'grid-row': `1 / ${this.mergedConfig.perPage + 2}`,
          'min-height': `${this.mergedConfig.detailsBox.minHeight}px`
        }
      }
    },
    showingDetailsOrConfigBox () {
      return (this.toDisplay && this.detailsBox.show && !this.showConfigBox) || this.showConfigBox
    }
  },
  methods: {
    loadConfigs () {
      if (!this.mergedConfig.possuiColunaDeConfiguracao) {
        this.configs.headerItems = this.headerItems.map(i => ({...i, isToShow: true}))
      } else {
        this.configs = configHelpers.getConfigs(this.name, this.headerItems)
      }
      this.$forceUpdate()
    },
    closeDetailsBox () {
      this.detailsBox = {show: false, index: null, itemId: null}
      this.showConfigBox = false
    },
    openDetailsBox (rowItem, realIndex) {
      this.detailsBox.show = true
      this.setDetailsBoxInfoItem(rowItem, realIndex)
      this.showConfigBox = false
    },
    toggleConfig () {
      this.detailsBox.show = false
      this.showConfigBox = !this.showConfigBox
    },
    alturaMinimaDinamica (alturaDaLinhaDoCabecalho, alturaDaLinhaDoCorpo) {
      const porPagina = this.mergedConfig.perPage
      return {
        '--alturaMinimaDinamicaDaTabela': `${(porPagina * alturaDaLinhaDoCorpo) + alturaDaLinhaDoCabecalho}px`
      }
    },
    configuracaoDeLinhas (quantidadeDeItens, alturaDaLinhaDoCabecalho, alturaDaLinhaDoCorpo) {
      return {
        'grid-template-rows': `${alturaDaLinhaDoCabecalho}px repeat(${Math.min(quantidadeDeItens, this.mergedConfig.perPage)}, ${alturaDaLinhaDoCorpo + 1}px)`
      }
    }
  }
}
