import { ErroNoNavegadorPontotel } from 'src/tipos/erros/classes'
import i18n from '@/typescript/servicos/i18n/i18n.servico'

export class ErroNavegadorNaoSuportaNotificacoes extends ErroNoNavegadorPontotel {
  constructor () {
    super(i18n.t('tipos.erros.navegador.naoSuportado').toString())
  }
}

export class ErroNavegadorNaoSuportaGeolocalizacao extends ErroNoNavegadorPontotel {
  constructor () {
    super(i18n.t('tipos.erros.navegador.naoSuportaGeo').toString())
  }
}
