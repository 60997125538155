import posthog from 'posthog-js'
import { FiltradorDeErros } from 'src/erros'

export interface UsuarioPosthog {
  idUsuario: string
  emailUsuario: string
  nomeUsuario: string
  papelUsuario:string
  idEmpresaUsuario:string
  nomeEmpresaUsuario:string
  tamanhoEmpresaUsuario: string;
  canal: string;
}
function ehAmbienteProducao (): boolean {
  return process.env.NODE_ENV === 'production'
}
export function verificarSeUsuarioPermitido (emailUsuario: string): boolean {
  const usuarioEhLar21 = emailUsuario.includes('lar21')
  const usuararioEhPontotel = emailUsuario.includes('@pontotel')
  if (usuarioEhLar21 || usuararioEhPontotel) return false
  return true
}

export async function inicializarPosthog () {
  try {
    if (ehAmbienteProducao()) {
      posthog.init(String(process.env.VUE_APP_POSTHOG_API_KEY),
        {
          api_host: String(process.env.VUE_APP_POSTHOG_API_HOST),
          autocapture: false,
          capture_pageview: true,
          capture_pageleave: true,
        }
      )
    }
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
}

export async function identificarUsuarioPosthog (usuario: UsuarioPosthog) {
  try {
    posthog.identify(
      usuario.idUsuario,
      {...usuario,
        email: usuario.emailUsuario
      }
    )
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
}

export async function enviarEventoPosthog (name: string, details?: Record<string, string | number>) {
  try {
    if (verificarPosthogEstaAtivo()) {
      posthog.capture(name, details)
    }
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
}

export function verificarPosthogEstaAtivo (): boolean {
  try {
    return Boolean(posthog._loaded)
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
  return false
}

export async function resetarUsuarioPosthog (device: boolean = false) {
  try {
    posthog.reset(device)
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
}
