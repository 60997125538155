<template functional lang="pug">

  .p-btn(:class="[{'p-btn--block': props.block, 'fonte-customizada': props.fonteCustomizada}, data.class, data.staticClass]" :title="props.title")

    button.p-btn__content(
      v-if="!props.notShowButton"
      :class="`p-btn--${props.color} p-btn__main p-btn__content--size-${props.size}`",
      :disabled="props.disabled || props.loading",
      v-on="{...listeners}"
    )
      img(
        :class="`p-btn__loading-spinner--size-${props.size}`",
        v-if="props.loading",
        src="../../../static/images/loading.svg"
      )
      span(v-else)
        slot

    button.p-btn__content(
      v-if="props.showIcon",
      :class="`p-btn--${props.color} p-btn__icon p-btn__content--size-${props.size} p-btn__icon--${props.colorIcon}`",
      :disabled="props.disabled || props.loadingIcon",
      @click="_ => listeners['click-icon'] && listeners['click-icon']()",
      @keydown.enter="e => e.target.classList.toggle('p-btn--active')",
      @keyup.enter="e => e.target.classList.toggle('p-btn--active')"
    )
      img(
        :class="`p-btn__loading-spinner--size-${props.size}`",
        v-if="props.loadingIcon",
        src="../../../static/images/loading.svg"
      )
      slot(v-else-if="slots().icon", name="icon")
      span(v-else) X
</template>

<script>
export default {
  name: 'PButton',
  functional: true,
  props: {
    color: { type: String, required: true },
    colorIcon: { type: String, default: 'default' },
    size: { type: String, required: true },
    showIcon: { type: Boolean },
    notShowButton: { type: Boolean },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    loadingIcon: { type: Boolean },
    block: { type: Boolean },
    title: { type: String, default: '' },
    fonteCustomizada: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">

$border-bottom-height: 3px;

.p-btn {
  background-color: transparent;
  display: inline-block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  height: auto;
  padding-bottom: 3px;
  width: fit-content;
}

.p-btn > * {
  font-family: var(--front-font);
  font-style: normal;
  font-weight: 300;
}

.p-btn.fonte-customizada > * {
  font-family: 'Hanken Grotesk';
  font-weight: 700;
}

.p-btn--block {
  display: flex;
  width: 100%;

  .p-btn__content {
    width: 100%;
  }
}

.p-btn.btn-beta.margin-top {
  margin-top: 10px;
}
.p-btn.btn-beta.margin-left {
  margin-left: 10px;
}

.p-btn__content {
  border: 0;
  outline: 0;
  position: relative;
  padding: 0px 10px;
  flex: 0 1 auto;
  cursor: pointer;
  transition: all 0.12s ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  transition: background-color linear 0.2s;
}

.p-btn__main {
  flex-grow: 1;
}

.p-btn__icon {
  margin-left: 1px;
  min-width: 35px;
}

/* Tamanho do botão */
.p-btn__content--size-xm {
  font-size: 8px;
  min-height: 10px;
}

.p-btn__content--size-sm {
  font-size: 13px;
  min-height: 20px;
}

.p-btn__content--size-md {
  font-size: 16px;
  min-height: 29px;
}

.p-btn__content--size-medio {
  font-size: 16px;
  min-height: 40px;
}

.p-btn__content--size-lg {
  font-size: 21px;
  min-height: 40px;
}

/* Animação de loading */

.p-btn__loading-spinner--size-xm {
  width: 15px;
  padding: 0;
  -webkit-animation:spin .5s linear infinite;
  -moz-animation:spin .5s linear infinite;
  animation:spin .5s linear infinite;
}

.p-btn__loading-spinner--size-sm {
  width: 15px;
  padding: 0;
  margin-top: 4px;
  -webkit-animation:spin .5s linear infinite;
  -moz-animation:spin .5s linear infinite;
  animation:spin .5s linear infinite;
}

.p-btn__loading-spinner--size-medio,
.p-btn__loading-spinner--size-md {
  width: 15px;
  padding: 0;
  margin-top: 4px;
  -webkit-animation:spin .5s linear infinite;
  -moz-animation:spin .5s linear infinite;
  animation:spin .5s linear infinite;
}

.p-btn__loading-spinner--size-lg {
  width: 15px;
  margin-bottom: 6px;
  padding: 0;
  -webkit-animation:spin .5s linear infinite;
  -moz-animation:spin .5s linear infinite;
  animation:spin .5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

/* Efeito de clique com mouse */
.p-btn__content:active:not([disabled]) {
  box-shadow: 0 0 transparent;
  transform: translateY($border-bottom-height);
  outline: 0;
}

.p-btn__content:after {
  /* IMPORTANT. this make the click events worken even if click too close to top of element
  Its a old problem with 3d buttons. As we need to add a margin-top when clicked, the click event dont fire
  because after styles render, the mouse is not above the button anymore
 */
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: -4px;
  left: 0;
  z-index: 1;
}

/* Efeito de clique com enter */
.p-btn--active:not([disabled]) {
  box-shadow: 0 0 transparent;
  transform: translateY($border-bottom-height);
  outline: 0;
}

/* Definir cor de cada botão */
.p-btn--default {
  background-color: var(--color-default);
  box-shadow: 0 $border-bottom-height var(--color-default-border);
  color: var(--color-default-contrast)
}

.p-btn--danger {
  background-color: var(--color-danger);
  box-shadow: 0 $border-bottom-height var(--color-danger-border);
  color: var(--color-danger-contrast)
}

.p-btn--success {
  background-color: var(--color-success);
  box-shadow: 0 $border-bottom-height var(--color-success-border);
  color: var(--color-success-contrast)
}

.p-btn--info {
  background-color: var(--color-info);
  box-shadow: 0 $border-bottom-height var(--color-info-border);
  color: var(--color-info-contrast);
}

.p-btn--warning {
  background-color: var(--color-warning);
  box-shadow: 0 $border-bottom-height var(--color-warning-border);
  color: var(--color-warning-contrast)
}

.p-btn--primary {
  background-color: var(--color-primary);
  box-shadow: 0 $border-bottom-height var(--color-primary-border);
  color: var(--color-primary-contrast)
}

.p-btn--novoinfo {
  background-color: #79397D;
  box-shadow: 0 $border-bottom-height #401F44;
  color: #fff;
}

/* Botão desabilitado */
.p-btn__content:disabled {
  box-shadow: 0 0 transparent;
  cursor: default;
}

.p-btn--default:disabled {
  box-shadow: 0 $border-bottom-height #C4C4C4;
  background-color: #C4C4C4;
  cursor: not-allowed !important;
}

.p-btn--danger:disabled {
  background-color: var(--color-danger);
}

.p-btn--success:disabled {
  background-color: var(--color-success);
}

.p-btn--info:disabled {
  background-color: var(--color-info);
}

.p-btn--warning:disabled {
  background-color: var(--color-warning);
}

.p-btn--primary:disabled {
  background-color: var(--color-primary);
}

/* Retira estilo padrão de foco no mozilla */
::-moz-focus-inner {
  border:0;
}

/* Estilo de focado */

.p-btn__content:focus {
  outline-width: 1px;
  outline-style: solid;
  outline-offset: -1px
}

.p-btn--default:focus {
  outline-color: var(--color-default-border);
}

.p-btn--danger:focus {
  outline-color: var(--color-danger-border);
}

.p-btn--success:focus {
  outline-color: var(--color-success-border);
}

.p-btn--info:focus {
  outline-color: var(--color-info-border);
}

.p-btn--warning:focus {
  outline-color: var(--color-warning-border);
}

.p-btn--primary:focus {
  outline-color: var(--color-primary-border);
}

/* Estilo de hover */
.p-btn--default:hover:not([disabled]) {
  background-color: var(--color-default-hover);
}

.p-btn--danger:hover:not([disabled]) {
  background-color: var(--color-danger-hover);
}

.p-btn--success:hover:not([disabled]) {
  background-color: var(--color-success-hover);
}

.p-btn--info:hover:not([disabled]) {
  background-color: var(--color-info-hover);
}

.p-btn--warning:hover:not([disabled]) {
  background-color: var(--color-warning-hover);
}

.p-btn--primary:hover:not([disabled]) {
  background-color: var(--color-primary-hover);
}

/* Cor do icone */
.p-btn__icon--default {
  background-color: var(--color-default);
  box-shadow: 0 $border-bottom-height var(--color-default-border);
  color: var(--color-default-contrast)
}

.p-btn__icon--danger {
  background-color: var(--color-danger);
  box-shadow: 0 $border-bottom-height var(--color-danger-border);
  color: var(--color-danger-contrast)
}

.p-btn__icon--success {
  background-color: var(--color-success);
  box-shadow: 0 $border-bottom-height var(--color-success-border);
  color: var(--color-success-contrast)
}

.p-btn__icon--info {
  background-color: var(--color-info);
  box-shadow: 0 $border-bottom-height var(--color-info-border);
  color: var(--color-info-contrast);
}

.p-btn__icon--warning {
  background-color: var(--color-warning);
  box-shadow: 0 $border-bottom-height var(--color-warning-border);
  color: var(--color-warning-contrast)
}

.p-btn__icon--primary {
  background-color: var(--color-primary);
  box-shadow: 0 $border-bottom-height var(--color-primary-border);
  color: var(--color-primary-contrast)
}

/* Estilo de hover icone*/
.p-btn__icon--default:hover:not([disabled]) {
  background-color: var(--color-default-hover);
}

.p-btn__icon--danger:hover:not([disabled]) {
  background-color: var(--color-danger-hover);
}

.p-btn__icon--success:hover:not([disabled]) {
  background-color: var(--color-success-hover);
}

.p-btn__icon--info:hover:not([disabled]) {
  background-color: var(--color-info-hover);
}

.p-btn__icon--warning:hover:not([disabled]) {
  background-color: var(--color-warning-hover);
}

.p-btn__icon--primary:hover:not([disabled]) {
  background-color: var(--color-primary-hover);
}

</style>