var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "estado-erro" },
    [
      _vm.$slots.default
        ? _vm._t("default", null, { mensagemDeErro: _vm.mensagemDeErro })
        : _c("div", { staticClass: "estado-erro-padrao" }, [
            _c("img", {
              attrs: { src: require("../../../static/icones/emojiTriste.svg") },
            }),
            _c("div", { staticClass: "estado-erro-padrao__texto" }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t("compartilhados.SmartTable.EstadoErro.mensagemErro")
                  )
                ),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.mensagemDeErro))]),
            ]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }