import moment from 'moment';
import { HttpOptions, HttpResponse } from 'vue-resource/types/vue_resource'
import { DetalhesErroSentry } from '../http.interfaces';

type Resposta = (response: HttpResponse) => void
type FuncaoDeResposta = (response: Resposta) => void

const capturarErros = (requisicao: HttpOptions, obterResposta: FuncaoDeResposta): void => {
  try {
    obterResposta((resposta: HttpResponse): void => {
      if (resposta.ok) {
        return
      }
      const urlRequisitada = new URL(requisicao.url || '')

      const titulo = `[${resposta.status}] ${urlRequisitada.host.split('.')[0].toUpperCase()} - ${urlRequisitada.pathname}`

      const detalhesErroSentry: DetalhesErroSentry = {
        codigoErroApi: resposta?.status,
        mensagem: resposta?.statusText || 'nao-informado',
        horarioDaRequisicao: moment(new Date()).format('DD/MM/YYYY [às] HH:mm:ss'),
        urlCompleta: requisicao?.url || 'nao-informado',
        metodo: requisicao?.method?.toUpperCase() || 'nao-informado',
        // @ts-expect-error body pode existir ou não
        retornoApi: resposta?.body || resposta?.data || 'nao-informado',
        payload: requisicao.body || 'nao-informado',
        titulo,
      };

      // Anexar detalhes do erro à resposta
      (resposta as HttpResponse & { 'detalhesErroSentry': object }).detalhesErroSentry = detalhesErroSentry
    })
  } catch {
  }
}

export default capturarErros
