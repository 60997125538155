import ServicoHttpBase from '../http.axios'
import config from '@/config'
import Cookies from 'js-cookie'

export interface IStateAutenticacao {
  auth: {
    authorization: string | null;
    accessToken: string | null;
    userPoolId: string | null;
    userPoolWebClientId: string | null;
  }
}

type ObterDadosDeAutenticacao = () => IStateAutenticacao

class HttpCondominio extends ServicoHttpBase {
  autenticacao: IStateAutenticacao;
  protected urlBase: string = config.baseUrl + '/api'
  protected urlFallback: string = config.baseUrl + '/api'

  constructor (obterDadosDeAutenticacao: ObterDadosDeAutenticacao) {
    super(config.baseUrl + '/api')
    this.autenticacao = obterDadosDeAutenticacao()
  }

  protected adicionarInterceptadorDeRequisicao (): void {
    this.clienteHttp.interceptors.request.use((config) => {
      if (!config.url?.endsWith('oauth2/token')) {
        if (this.autenticacao.auth.authorization) {
          config.headers.set('Authorization', this.autenticacao.auth.authorization)
        } else {
          config.headers.set('Authorization', Cookies.get('Authorization'))
        }

        if (this.autenticacao.auth.accessToken || Cookies.get('accessToken')) {
          config.headers.set('X-PontoTel-Authorization', this.autenticacao.auth.accessToken || Cookies.get('accessToken'))
          config.headers.set('X-PontoTel-UP', this.autenticacao.auth.userPoolId || Cookies.get('userPoolId'))
          config.headers.set('X-PontoTel-DI', this.autenticacao.auth.userPoolWebClientId || Cookies.get('userPoolWebClientId'))
        }
      }

      const idiomaI18n = localStorage.getItem('idioma')
      if (idiomaI18n) {
        config.headers.set('Accept-Language', idiomaI18n)
      }
      return config
    }, (error) => {
      return Promise.reject(error)
    })
  }

  protected adicionarInterceptadorDeResposta (): void {
    this.clienteHttp.interceptors.response.use((response) => {
      return response
    }, (error) => {
      return Promise.reject(error)
    })
  }

  public obterUrlBase(): string {
    return this.urlBase
  }

  public obterUrlFallback(): string {
    return this.urlFallback
  }
}

export default HttpCondominio
