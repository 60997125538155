import moment from 'moment'
import { pick } from 'lodash'

import { formatarDataParaEnvio, obterDataValida } from 'src/utils/formatadoras/formatadoras'
import { multiSelect } from 'src/common/selectors'
import { API, retryRequest } from 'src/services'

function defaultState () {
  return {
    ...multiSelect.mapStates(['companies', 'employees', 'userGroups']),
    request: null,
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    verifyMode: false,
    verificationKind: 'oddPts',
    verificationFilters: {},
    options: {
      tmsQuery: 'gte',
      tmsIgnoreZeros: true,
      descontoBancoDeHoras: false,
      ehRubrica: false
    },
    pagination: {
      page: 0,
      total: null,
      perPage: null,
      pages: null
    },
    totals: {
      oddPts: 0,
      pendency: 0
    }
  }
}

const mutations = {
  ...multiSelect.mapMutations(['companies', 'employees', 'userGroups']),
  startDate: (state, payload) => { state.startDate = obterDataValida(payload) },
  endDate: (state, payload) => { state.endDate = obterDataValida(payload) },
  datePeriod: (state, payload) => {
    state.startDate = obterDataValida(payload.startDate)
    state.endDate = obterDataValida(payload.endDate)
  },
  request (state, request) {
    state.request = request
  },
  verificationKind (state, payload) {
    state.verificationKind = payload
  },
  verificationFilters (state, payload) {
    state.verificationFilters = payload
  },
  pagination (state, payload) {
    state.pagination = { ...state.pagination, ...payload }
  },
  resetPagination (state) {
    state.pagination = defaultState().pagination
  },
  totals (state, payload) {
    state.totals = payload
  },
  options (state, payload) {
    state.options = {...state.options, ...payload}
  }
}

const actions = {
  async verifyTimesheet ({ rootState, state, commit, dispatch }, kind) {
    const toLoad = {
      startDate: formatarDataParaEnvio(state.startDate),
      endDate: formatarDataParaEnvio(state.endDate),
      verifyKind: state.verificationKind,
      filters: Array.isArray(state.verificationFilters)
        ? state.verificationFilters.map(filtro => pick(filtro, 'field', 'value'))
        : state.verificationFilters,
      page: state.pagination.page,
      options: state.options
    }

    if (kind === 'work-place') {
      toLoad.companies = state.companies.selected.map(i => i.id)
    } else if (kind === 'user-group') {
      toLoad.userGroups = state.userGroups.selected.map(i => i.id)
    } else if (kind === 'employee') {
      toLoad.employees = state.employees.selected.map(i => i.id)
    }

    toLoad.ptsByCompany = rootState.sheet.toolBox.configs.ptsByCompany && kind === 'work-place'

    commit('sheet/loading', { lines: true }, { root: true })

    const result = await retryRequest(API.timesheetVerify.save, {}, {
      ...toLoad,
      before (request) {
        if (state.request && state.request !== null) {
          state.request.abort()
        }
        commit('request', request)
      }
    })

    commit('pagination', result.data.success.pagination)

    commit('sheet/state', result.data.success, { root: true })
    commit('sheet/loading', { lines: false }, { root: true })

    const onlyPopulateRealLines = state.verificationKind !== 'events' ||
      !state.verificationFilters.find(i => i.field === 'falta')

    dispatch(
      'sheet/populateLines',
      { startDate: state.startDate, endDate: state.endDate, onlyPopulateRealLines },
      { root: true }
    )
  },
  async getTotal ({ state, rootState, commit }, kind) {
    const toLoad = {
      startDate: formatarDataParaEnvio(state.startDate),
      endDate: formatarDataParaEnvio(state.endDate),
      companies: [rootState.userInfo.firstCompany],
      onlyTotal: true
    }

    if (kind === 'work-place') {
      toLoad.companies = state.companies.selected.map(i => i.id)
    } else if (kind === 'user-group') {
      toLoad.userGroups = state.userGroups.selected.map(i => i.id)
    } else if (kind === 'employee') {
      toLoad.employees = state.employees.selected.map(i => i.id)
    } else {
      toLoad.companies = [rootState.userInfo.firstCompany]
    }

    const result = await API.timesheetVerify.save({
      ...toLoad,
      before (request) {
        if (state.request !== null) {
          state.request.abort()
        }
        commit('request', request)
      }
    })

    commit('totals', result.body.success?.totals || defaultState().totals)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  actions,
  mutations
}
