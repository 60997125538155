import { API } from 'src/services'
import { multiSelect } from 'src/common/selectors'
import swal from 'sweetalert2'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import i18n from 'src/typescript/servicos/i18n/i18n.servico'

function defaultState () {
  return {
    loading: false,
    month: (new Date()).getMonth() + 1,
    year: (new Date()).getFullYear(),
    notLocked: [],
    locked: [],
    rejected: [],
    confirmed: [],
    employees: {selected: []},
    companies: {selected: []},
    employers: {selected: []},
    rejectObservation: '',
    filtroSelecionado: 'locais_de_trabalho',
    statusSelecionado: 'naoTravados',
    totalDeRequisicoesPorStatus: {
      naoTravados: null,
      rejeitados: null,
      confirmados: null,
      travados: null
    },
    emBusca: null,
    usarNovoMotor: false
  }
}

const getters = {
  currentUserKind (state, getters, rootState) {
    if (['all', 'group'].includes(rootState.userInfo.roleGroup.kind)) {
      return 'rh'
    }
    return 'employee'
  }
}

const mutations = {
  ...multiSelect.mapMutations(['companies', 'employees', 'employers']),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  loading (state, payload) {
    state.loading = Boolean(payload)
  },
  month (state, payload) {
    state.month = payload
  },
  year (state, payload) {
    state.year = payload
  },
  filtroSelecionado (state, payload) {
    state.filtroSelecionado = payload
  },
  statusSelecionado (state, payload) {
    state.statusSelecionado = payload
  },
  totalDeRequisicoesPorStatus (state, payload) {
    state.totalDeRequisicoesPorStatus = payload
  },
  emBusca (state, payload) {
    state.emBusca = payload
  },
  loadedData (state, payload) {
    // yeh, back (benson) left to the front the job of decide what is this locker fucking kind

    payload = payload
      .map(locker => {
        let kind

        if (locker.pretty_state && (['rh_denied', 'employee_denied'].includes(locker.pretty_state.state))) {
          kind = locker.pretty_state.state
        } else if (locker.locked) {
          kind = locker.pretty_state.state
        } else if (
          (
            (locker.rh_confirmation && locker.rh_confirmation.confirmation) ||
            (locker.employee_confirmation && locker.employee_confirmation.confirmation)
          ) && !locker.locked
        ) {
          kind = locker.rh_confirmation && locker.rh_confirmation.confirmation
            ? 'rh_confirmed'
            : 'employee_confirmed'
        } else {
          kind = 'not_locked'
        }

        return {
          kind,
          employee: locker.employee,
          year: locker.year,
          id: locker.id,
          locked: locker.locked,
          month: locker.month,
          lockedAt: locker.lockedAt,
          operations: locker.operations
        }
      })

    state.notLocked = payload.filter(i => i.kind === 'not_locked')
    state.locked = payload.filter(i => ['rh_unilateral', 'employee_unilateral', 'all_confirmed'].includes(i.kind))
    state.rejected = payload.filter(i => ['rh_denied', 'employee_denied'].includes(i.kind))
    state.confirmed = payload.filter(i => ['rh_confirmed', 'employee_confirmed'].includes(i.kind))
  },
  usarNovoMotor (state, payload) {
    state.usarNovoMotor = Boolean(payload)
  }
}

const actions = {
  async loadData ({ commit, state }, {mode}) {
    commit('loading', true)
    try {
      const result = await API.twoWayLockerGetData.save(
        {month: state.month, year: state.year},
        {
          month: state.month,
          year: state.year,
          [mode]: state[mode].selected.map(i => i.id)
        }
      )

      commit('loadedData', result.data.success || 'fei')
    } catch {}
    commit('loading', false)
  },
  async executeAction ({ dispatch, state }, {viewMode, applyTo = 'all', operation, targetGroup, usarNovoMotor = false}) {
    if (
      !['notLocked', 'confirmed', 'rejected', 'locked'].includes(viewMode) ||
      !(applyTo === 'all' || typeof applyTo === 'object') ||
      !['lock', 'lockUnilateral', 'unlock', 'confirm', 'reject', 'destravarFolhaBeta'].includes(operation)
    ) {
      throw new Error('invalid params given to execute action locker')
    }

    let kind = 'employee'
    let ids = []

    if (applyTo !== 'all') {
      ids = [applyTo.id]
    }

    if (applyTo === 'all') {
      const kindPorFiltroFiltrado = {
        'locais_de_trabalho': 'company',
        'empregados': 'employee',
        'empregadores': 'employer'
      }
      kind = kindPorFiltroFiltrado[state.filtroSelecionado]

      const listaPorFiltro = {
        'locais_de_trabalho': 'companies',
        'empregados': 'employees',
        'empregadores': 'employers'
      }
      const filtrados = state[listaPorFiltro[state.filtroSelecionado]]
      ids = filtrados.selected.map(i => i.id)
    }

    let busca = {}
    if (state.emBusca) {
      const payloadPorCampo = {
        'nome_do_empregado': 'employeeName',
        'nome_do_local_de_trabalho': 'companyName',
        'data_de_travamento': 'lockedAt',
        'estados_de_travamento': 'states'
      }
      Object.entries(state.emBusca).forEach(([campo, buscado]) => {
        busca[payloadPorCampo[campo]] = buscado
      })
    }

    const params = {
      year: state.year,
      month: state.month,
      target: targetGroup,
      kind,
      ids,
      busca,
      usarNovoMotor
    }

    return dispatch(operation, params)
  },
  async lockUnilateral (context, { year, month, kind, ids, target, busca, usarNovoMotor }) {
    try {
      const result = await API.unilateralLocker.save({
        year,
        month,
        kind,
        ids,
        target,
        ...busca,
        usarNovoMotor
      })
      swal({
        type: 'success',
        text: result.data.success || i18n.t('locker.State.avisoTravamentoRealizado')
      })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro?.data?.error ? erro.data.error : i18n.t('locker.State.erroTravamento')
      })
    }
  },
  async confirm (context, { year, month, kind, ids, busca, usarNovoMotor }) {
    try {
      const result = await API.twoWayLockerConfirmation.save({
        year,
        month,
        confirmation: true,
        observation: null,
        lockRequestId: null,
        kind,
        ids,
        ...busca,
        usarNovoMotor
      })
      swal({
        type: 'success',
        text: result.data.success || i18n.t('locker.State.avisoTravamentoConfirmado')
      })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro?.data?.error ? erro.data.error : i18n.t('locker.State.erroTravamentoConfirmado')
      })
    }
  },
  async reject ({ state }, {year, month, kind, ids, busca, usarNovoMotor}) {
    try {
      const result = await API.twoWayLockerConfirmation.save({
        year,
        month,
        confirmation: false,
        observation: state.rejectObservation,
        lockRequestId: null,
        kind,
        ids,
        ...busca,
        usarNovoMotor
      })
      swal({
        type: 'success',
        text: result.data.success || i18n.t('locker.State.avisoTravamentoRejeitado')
      })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro?.data?.error ? erro.data.error : i18n.t('locker.State.erroTravamentoRejeitado')
      })
    }
  },
  async unlock (context, { month, year, kind, ids, busca, usarNovoMotor }) {
    try {
      const result = await API.unlocker.save({
        year,
        month,
        kind,
        ids,
        ...busca,
        usarNovoMotor
      })
      swal({
        type: 'success',
        text: result.data.success || i18n.t('locker.State.avisoDestravamentoRealizado')
      })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro?.data?.error ? erro.data.error : i18n.t('locker.State.erroDestravamento')
      })
    }
  },
  async lock (context, { month, year, kind, ids, target, busca, usarNovoMotor }) {
    try {
      const result = await API.twoWayLocker.save(
        {month, year},
        {
          year,
          month,
          startConfirmed: true,
          kind,
          ids,
          target,
          ...busca,
          usarNovoMotor
        }
      )
      swal({
        type: 'success',
        text: result.data.success || i18n.t('locker.State.avisoConfirmacao')
      })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro?.data?.error ? erro.data.error : i18n.t('locker.State.erroConfirmacao')
      })
    }
  },
  async destravarFolhaBeta (context, { month, year, kind, ids }) {
    try {
      const resultado = await API.forcarDestravamento.save({
        ano: year,
        mes: month,
        tipoEntidade: kind,
        ids: ids
      })
      swal({
        type: 'success',
        text: resultado.data.success || i18n.t('locker.State.avisoDestravamentoRealizado')
      })
    } catch (erro) {
      swal({
        type: 'error',
        text: erro?.data?.error ? erro.data.error : i18n.t('locker.State.erroDestravamento')
      })
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
