import Vue from 'vue'
import VueResource from 'vue-resource'
import swal from 'sweetalert2'
import config from 'src/config'
import store from 'src/store'
import Cookies from 'js-cookie'
import router from 'src/router'
import { FiltradorDeErros } from 'src/erros'
import deslogarQuandoTokenExpirar from 'src/typescript/servicos/http/interceptadores/deslogarQuandoExpirar.ts'
import capturarErros from 'src/typescript/servicos/http/interceptadores/capturarErros.ts'

// Migration stuff
import { hasPermission } from 'src/utils'

import { requisitarComMultiplasTentativas } from 'src/utils/executoras/executoras'

// Exporte a função requisitarComMultiplasTentativas como retryRequest
// TODO: renomear os imports nos arquivos para a função com nome em português
export { requisitarComMultiplasTentativas as retryRequest }

const EndPoint = config.baseUrl + '/api/'

const EndPointIntegracao = `${config.integracaoUrl}` + '/'

const EndPointREP = config.repUrl

const EndPointGerenciadorDeColetores = config.gerenciadorDeColetoresUrl + '/'

const EndPointKeycloak = config.keycloakBaseUrl + '/'

export const EndPointArquivosDeImportadores = EndPoint + 'obter-arquivo-importador'

// const EndPointRepp = config.reppUrl + '/'

Vue.use(VueResource)
Vue.http.interceptors.push(function (request, next) {
  if (window.location.href.includes('/login')) {
    next()
  }

  if (!request.url.endsWith('oauth2/token')) {
    // SUPER IMPORTANT STEP HERE
    // the ...oauth2/token is used to get the togens from cognito of a user that
    // is logged using SAML. This API doesnt like to receive not expected headers

    if (store.state.auth && !!store.state.auth.authorization) {
      request.headers.set('Authorization', store.state.auth.authorization)
    } else {
      request.headers.set('Authorization', Cookies.get('Authorization'))
    }

    if (store.state.auth.accessToken || Cookies.get('accessToken')) {
      request.headers.set('X-PontoTel-Authorization', store.state.auth.accessToken || Cookies.get('accessToken'))
      request.headers.set('X-PontoTel-UP', store.state.auth.userPoolId || Cookies.get('userPoolId'))
      request.headers.set('X-PontoTel-DI', store.state.auth.userPoolWebClientId || Cookies.get('userPoolWebClientId'))
    }
  }

  if (request.url.includes('http://localhost:8000') || (EndPointREP !== '' && request.url.includes(EndPointREP))) {
    request.headers.set('CMP', store.state.userInfo.compMan.id)
  }

  const idiomaI18n = localStorage.getItem('idioma')
  if (idiomaI18n) {
    request.headers.set('Accept-Language', idiomaI18n)
  }

  next()
})

Vue.http.interceptors.push(_ => {
  return response => {
    if (response.status === 403) {
      const respostaEmString = response.data ? JSON.stringify(response.data) : ''
      const erro403Nginx = respostaEmString.includes('nginx') && respostaEmString.includes('403 Forbidden')
      if (erro403Nginx) {
        router.push('/acesso-negado')
      }
    }
  }
})

Vue.http.interceptors.push(deslogarQuandoTokenExpirar)
Vue.http.interceptors.push(capturarErros)

export async function gerarComprovanteDoPonto (pontoId, link) {
  const headers = new Headers()
  let token = Cookies.get('Authorization')

  if (store.state.auth && !!store.state.auth.authorization) {
    token = store.state.auth.authorization
  }

  if (store.state.auth.accessToken || Cookies.get('accessToken')) {
    token = store.state.auth.accessToken || Cookies.get('accessToken')
  }

  headers.append('Authorization', token)

  fetch(`${EndPoint}gerar-comprovante-da-marcacao/${pontoId}`, { headers })
    .then(response => {
      if (response.status === 200) {
        response.blob().then(blob => {
          const objUrl = window.URL.createObjectURL(blob)

          link.href = objUrl
          link.download = `comprovante_do_ponto_${pontoId}.pdf`
          link.click()

          URL.revokeObjectURL(objUrl)
        })
      }
    })
    .catch(erro => {
      FiltradorDeErros.capturarErro(erro)
    })
}

export async function gerarRelatorioDeColetores (link, filtros) {
  const headers = new Headers()
  let token = Cookies.get('Authorization')
  let url = `${EndPointGerenciadorDeColetores}coletores/relatorio`

  if (filtros) {
    url += `?${filtros}`
  }

  if (store.state.auth && !!store.state.auth.authorization) {
    token = store.state.auth.authorization
  }

  if (store.state.auth.accessToken || Cookies.get('accessToken')) {
    token = store.state.auth.accessToken || Cookies.get('accessToken')
  }

  headers.append('Authorization', token)

  fetch(url, { headers })
    .then(response => {
      if (response.status === 200) {
        Vue.prototype.$modalDeSucesso('Relatório sendo gerado... Por favor, aguarde.')
        response.blob().then(blob => {
          const objUrl = window.URL.createObjectURL(blob)

          link.href = objUrl
          link.download = 'relatorio_de_coletores.xlsx'
          link.click()

          URL.revokeObjectURL(objUrl)
        })
      } else {
        Vue.prototype.$modalDeErro('Erro ao gerar o relatório.')
      }
    })
    .catch(() => {
      this.$filtradorDeErros.capturarErroDandoFeedback(() => {
        Vue.prototype.$modalDeErro('Erro ao gerar o relatório.')
      })
    })
}

export const API = {
  login: Vue.resource(EndPoint + 'auth'),

  info: Vue.resource(EndPoint + 'personal/short'),

  sincronizarEmpregadoRepp: Vue.resource(EndPoint + 'employee-sincronizar-com-repp{/empregadoId}'),

  sincronizarLocalRepp: Vue.resource(EndPoint + 'company-sincronizar-com-repp{/localId}'),

  sincronizarEmpregadorRepp: Vue.resource(EndPoint + 'employer-sincronizar-com-repp{/empregadorId}'),

  notification: Vue.resource(EndPoint + 'notification'),

  forgotPassword: Vue.resource(EndPoint + 'esqueci-minha-senha{/email}{/recaptcha}'),

  applyAll: Vue.resource(EndPoint + 'apply-all'),

  holiday: Vue.resource(EndPoint + 'holiday-calendar{/id}'),

  frontVisual: Vue.resource(EndPoint + 'custom-visual{/userId}'), // -> api that returns the styles and layout

  logout: Vue.resource(EndPoint + 'auth-logout'),

  userFirebaseToken: Vue.resource(EndPoint + 'user/firebase-token{/entity}{/id}'),

  redirectSSORoute: Vue.resource(EndPoint + 'redirect-sso-route{/compManId}'), // -> returns route for logout

  getAuthUserPool: Vue.resource(EndPoint + 'auth-user-pool/frontend'),

  compManAcceptTerms: Vue.resource(EndPoint + 'accept-terms{/compManId}'),

  compManDetail: Vue.resource(EndPoint + 'compman-detail{/compManId}{?fields}'),

  legacyLogout: Vue.resource(EndPoint + 'logout'),

  employee: Vue.resource(EndPoint + 'employee{/id}'),

  approvals: Vue.resource(EndPoint + 'getallapprovals'),

  solicitacaoDeAprovacao: Vue.resource(EndPoint + 'solicitacao-de-aprovacao{/id}', {}, { patch: { method: 'PATCH' } }),

  gestaoDeFerias: Vue.resource(EndPoint + 'gestao-de-ferias{/id}{/tipo}/'),

  solicitacoesEmMassa: Vue.resource(EndPoint + 'solicitacoes-em-massa'),

  configuracaoDeSolicitacao: Vue.resource(EndPoint + 'configuracoes-de-solicitacao/{configuracaoId}'),

  approvalsRules: Vue.resource(EndPoint + 'getallapprovalsrules{/id}'),

  objectsApprovalRules: Vue.resource(EndPoint + 'objectapprovalrules'),

  regrasDeAprovacao: Vue.resource(EndPoint + 'regra-de-aprovacao{/id}', {}, { patch: { method: 'PATCH' } }),

  approveLines: Vue.resource(EndPoint + 'approvalapproval'),

  transferenciaDeApontamento: Vue.resource(EndPoint + 'apontamentos-para-transferencia{/empregado}{/dia}', {}),

  approvalsReport: Vue.resource(EndPoint + 'relatorio/gerar-r31'),

  reportInfoEmployee: Vue.resource(EndPoint + 'report-info-employee'),

  holidaysReport: Vue.resource(EndPoint + 'holidays-report'),

  routineReport: Vue.resource(EndPoint + 'routines-report'),

  journeyReport: Vue.resource(EndPoint + 'journeys-report'),

  groupReport: Vue.resource(EndPoint + 'groups-report'),

  employerReport: Vue.resource(EndPoint + 'employers-report'),

  company: Vue.resource(EndPoint + 'company{/id}'),

  companiesReport: Vue.resource(EndPoint + 'companies-report'),

  journey: Vue.resource(EndPoint + 'journey{/id}'),

  routine: Vue.resource(EndPoint + 'routine{/id}'),

  employer: Vue.resource(EndPoint + 'employer{/id}'),

  user: Vue.resource(EndPoint + 'user{/id}'),

  userPerfil: Vue.resource(EndPoint + 'usuarios-perfil{/perfil}'),

  userGroup: Vue.resource(EndPoint + 'user-group{/id}'),

  fastRegister: Vue.resource(EndPoint + 'fast-register'),

  customRules: Vue.resource(EndPoint + 'custom{/id}'),

  cloneCustomRules: Vue.resource(EndPoint + 'clone-custom-rules{/id}'),

  whbRules: Vue.resource(EndPoint + 'work-hours-bank-rules{/id}'),

  sumtime: Vue.resource(EndPoint + 'sumtime{/id}{/year}{/month}'),

  apontamentosMensais: Vue.resource(EndPoint + 'apontamentos-mensais{/idDoEmpregado}'),

  apontamentosDeBancoDeHoras: Vue.resource(EndPoint + 'apontamentos-mensais-gestao-banco-de-horas'),

  apontamentosDiarios: Vue.resource(EndPoint + 'apontamentos-diarios'),

  arbssumtime: Vue.resource(EndPoint + 'sumarbtms{/id}{/year}{/month}'),

  expectedCalcs: Vue.resource(EndPoint + 'expected-calcs{/id}{/calcId}'),

  timesheet: Vue.resource(EndPoint + 'timesheet-partial{/load}'),

  timesheetGeneric: Vue.resource(EndPoint + 'timesheet-partial-generic'),

  timesheetPtInfo: Vue.resource(EndPoint + 'timesheet-partial-pt-info{/kind}{/id}'),

  groupComment: Vue.resource(EndPoint + 'todo-comment{/id}'),

  timesheetSync: Vue.resource(EndPoint + 'timesheet-partial-sync'),

  fakeTimelog: Vue.resource(EndPoint + 'fake-pt{/id}'),

  ignoreTimelog: Vue.resource(EndPoint + 'ignore-fake-pt{/id}'),

  line: Vue.resource(EndPoint + 'line{/id}'),

  lineJoin: Vue.resource(EndPoint + 'linejoiner'),

  reasons: Vue.resource(EndPoint + 'reason{/id}'),

  roleGroup: Vue.resource(EndPoint + 'rolegroup{/id}'),

  roleDetails: Vue.resource(EndPoint + 'role/details'),

  abstractJustify: Vue.resource(EndPoint + 'abstract-justify{/employeeId}{/typeId}{/year}{/month}{/day}'),

  gerarUrlPreAssinadaDeArquivoParaDownload: Vue.resource(EndPoint + 'gerar-url-assinada-para-download-de-arquivo-do-bucket'),

  reason: Vue.resource(EndPoint + 'reason{/id}'),

  region: Vue.resource(EndPoint + 'region'),

  changeMultipleDayjourney: Vue.resource(EndPoint + 'change-multiple-dayjourney'),

  dashboardExtraTime: Vue.resource(EndPoint + 'dashboard-extra-time'),

  dashboardSpecial: Vue.resource(EndPoint + 'dashboard-special'),

  dashboardWHB: Vue.resource(EndPoint + 'dashboard-whb'),

  twoWayLocker: Vue.resource(EndPoint + 'two-way-locker{/month}{/year}'),

  estatisticaDiariaDeAusensiaEsperada: Vue.resource(EndPoint + 'dayjourney-statistics-absence-expected{/comp_man}{/user}'),

  estatisticaDiariaInesperada: Vue.resource(EndPoint + 'dayjourney-statistics-unexpected{/comp_man}{/user}'),

  estatisticaDeBloqueio: Vue.resource(EndPoint + 'locker-statistics{/comp_man}{/user}'),

  realTime: Vue.resource(EndPoint + 'real-time'),

  twoWayLockerNotifications: Vue.resource(EndPoint + 'two-way-locker/notifications'),

  unilateralLocker: Vue.resource(EndPoint + 'locker/unilateral'),

  unlocker: Vue.resource(EndPoint + 'unlocker'),

  forcarDestravamento: Vue.resource(EndPoint + 'destravar-folha'),

  twoWayLockerGetData: Vue.resource(EndPoint + 'two-way-locker/get-data{/month}{/year}'),

  twoWayLockerGetDataPage: Vue.resource(EndPoint + 'two-way-locker/get-data-page{/month}{/year}'),

  twoWayLockerGetEmployees: Vue.resource(EndPoint + 'two-way-locker/get-employees{/month}{/year}'),

  twoWayLockerConfirmation: Vue.resource(EndPoint + 'two-way-locker-confirmation'),

  audit: Vue.resource(EndPoint + 'audit{/kind}{/id}'),

  folha: Vue.resource(EndPoint.replace('/api', '') + 'mostra-folha{/employeeId}{/companyId}{/year}{/month}{/just}{/showWorkHoursBank}{/showComments}'),

  folhaWHB: Vue.resource(EndPoint.replace('/api', '') + 'mostra-folha-whb{/employeeId}{/companyId}{/year}{/month}{/just}/true'),

  whboCreate: Vue.resource(EndPoint + 'whbo{/kind}{/id}'),

  whboManager: Vue.resource(EndPoint + 'whb-manager{/kind}{/employeeId}{/startDate}{/endDate}'),

  whboManagerNew: Vue.resource(EndPoint + 'whb-manager-multiple-employees'),

  whbCloser: Vue.resource(EndPoint + 'whb-closer{/id}{/employeeId}{/date}{/dateBalance}'),

  whboAuth: Vue.resource(EndPoint + 'whb-auth{/whobId}{/approve}'),

  horasExtrasAprovadasHoje: Vue.resource(EndPoint + 'planejamento-de-escala/horas-extras'),

  conversaoDeApontamentoEmBh: Vue.resource(EndPoint + 'whbapprovalrequest'),

  biometric: Vue.resource(EndPoint + 'biometric/management'),

  aprovacaoBiometrias: Vue.resource(EndPoint + 'biometric/aprovacao'),

  biometriaFacialFotosRecentes: Vue.resource(EndPoint + 'biometric/fotos-recentes{/empregado_id}'),

  ocultarFotosRecentes: Vue.resource(EndPoint + 'biometric/management/delete'),

  biometricImport: Vue.resource(EndPoint + 'biometric/import{/employee_id}'),

  removerBiometria: Vue.resource(EndPoint + 'biometric/fotos-recentes/delete/{/marcacao_id}'),

  cids: Vue.resource(EndPoint + 'cids'),

  arbitraryTms: Vue.resource(EndPoint + 'arbtms{/id}'),

  arbitraryApprove: Vue.resource(EndPoint + 'arbtmsapprove'),

  arbitraryTmsNames: Vue.resource(EndPoint + 'arbtmsnames{/lineId}{/dayJourneyId}{/employeeId}'),

  autoCorrect: Vue.resource(EndPoint + 'autocorrect{/correct}{/refId}'),

  deviceManage: Vue.resource(EndPoint + 'management-comp-man'),

  approveDevices: Vue.resource(EndPoint + 'approve-devices'),

  devices: Vue.resource(EndPoint + 'devices'),

  closedPeriod: Vue.resource(EndPoint + 'closed-period{/id}{/month}{/year}'),

  workSchedule: Vue.resource(EndPoint + 'work-scheduler{/type}'),

  genEmpsDjs: Vue.resource(EndPoint + 'generate-employee-dayjourneys'),

  workScheduleReport: Vue.resource(EndPoint + 'work-scheduler-report'),

  authBlockUser: Vue.resource(EndPoint + 'block-user'),

  authUsersSessions: Vue.resource(EndPoint + 'auth-users-sessions'),

  authIpsBlackList: Vue.resource(EndPoint + 'auth-ips-blacklist{/kind}'),

  authSecurityEvents: Vue.resource(EndPoint + 'auth-security-events'),

  authCognitoForgotPassword: Vue.resource(EndPoint + 'auth-cognito-forgot-password'),

  confirmMigrationUserCognito: Vue.resource(EndPoint + 'auth-confirm-migration-user-cognito'),

  reprocessLinePts: Vue.resource(EndPoint + 'reproclinetls'),

  regrasDeNotificacao: Vue.resource(EndPoint + 'regras-de-notificacao{/id}'),

  editarRegrasDeNotificacao: Vue.resource(EndPoint + 'regras-de-notificacao{/id}', {}, { patch: { method: 'PATCH' } }),

  regraDeTravamento: Vue.resource(EndPoint + 'regra-de-travamento{/id}'),

  atualizarRegraDeTravamento: Vue.resource(EndPoint + 'regra-de-travamento{/id}', {}, { patch: { method: 'PATCH' } }),

  listarCategoriasDeAprovacao: Vue.resource(EndPoint + 'listar-categorias-de-aprovacao'),

  notificationTypes: Vue.resource(EndPoint + 'notification-types'),

  notificationTypesEmployee: Vue.resource(EndPoint + 'notification-types-employee'),

  clearUserFile: Vue.resource(config.baseUrl + '/clear-user-file{/compManId}{/userId}'),

  reports: Vue.resource(EndPoint + 'user-file'),

  filaRelatorioTravamento: Vue.resource(EndPoint + 'user-file-travamento'),

  journeyOnCalls: Vue.resource(EndPoint + 'oncall{/id}{/djId}'),

  editTimelog: Vue.resource(EndPoint + 'edittimelog'),

  recalculateKind: Vue.resource(EndPoint + 'recalculatekind'),

  generateDeviceReport: Vue.resource(config.baseUrl + '/generate-status-report'),

  whbWindowManagement: Vue.resource(EndPoint + 'whb-window-management{/employeeId}'),

  whbRulesManagement: Vue.resource(EndPoint + 'work-hours-bank-rules-all'),

  whbCount: Vue.resource(EndPoint + 'whb-count'),

  timesheetVerify: Vue.resource(EndPoint + 'timesheet-verify'),

  usersReport: Vue.resource(EndPoint + 'relatorio/gerar-planilha-de-usuarios'),

  autoComplete: Vue.resource(EndPoint + 'auto-complete'),

  afastamento: Vue.resource(EndPoint + 'workjourney_folga{/employeeId}{/startDate}{/endDate}{/creatorId}{/journeyId}'),

  editarAfastamento: Vue.resource(EndPoint + 'workjourney_folga/set_end{/employeeId}{/endDate}{/creatorId}'),

  vacation: Vue.resource(EndPoint + 'vacation{/employeeId}{/startDate}{/endDate}'),

  authTerms: Vue.resource(EndPoint.replace('/api', '') + 'auth-terms{/date}{/employeeId}'),

  repModel: Vue.resource(EndPointREP + '/v2/rep/fields'),

  rep: Vue.resource(EndPointREP + '/v2/rep/{rep_id}'),

  pegaponto: Vue.resource(EndPointREP + '/v2/pegaponto/{pegaponto_id}'),

  reasonReport: Vue.resource(EndPoint + 'reason-report'),

  safeGetImages: Vue.resource(EndPoint + 'safe-get-images{/model}{/id}'),

  safeGetAudios: Vue.resource(EndPoint + 'safe-get-audios{/model}{/id}'),

  gerenciamentoDeArquivos: Vue.resource(EndPoint + 'aprovacao_de_arquivo{/id}'),

  aprovacaoIndividualDeArquivo: Vue.resource(EndPoint + 'aprovacao-individual{/id}{/acoes}'),

  trabalhoRemoto: Vue.resource(EndPoint + 'trabalho-remoto'),
  demitirEmpregado: Vue.resource(EndPoint + 'demitir-empregado'),

  readmitirEmpregado: Vue.resource(EndPoint + 'readmitir-empregado'),

  janelaDiaria: Vue.resource(EndPoint + 'janela-diaria{/idFuncionario}{/diaInicial}{/diaFinal}'),

  controleDeAcesso: Vue.resource(EndPoint + 'controle-de-acesso/cidr{/id}', {}, { patch: { method: 'PATCH' } }),

  gerenciarListaDeAcesso: Vue.resource(EndPoint + 'controle-de-acesso/gerenciar-listas-de-acesso'),

  informacoesDeUsuario: Vue.resource(EndPoint + 'user-info'),

  gerarEEnviarQrCodeEmail: Vue.resource(EndPoint + 'relatorio/gerar-relatorio-de-qrcode'),

  logDeOperacoes: Vue.resource(EndPoint + 'audit-log'),

  rubricas: Vue.resource(EndPoint + 'rubricas{/id}'),

  arquivarRubrica: Vue.resource(EndPoint + 'rubricas/arquivar{/id}', {}, { patch: { method: 'PATCH' } }),

  grupoDeRubrica: Vue.resource(EndPoint + 'grupos-rubricas{/id}'),

  arquivarGrupoDeRubrica: Vue.resource(EndPoint + 'grupos-rubricas/arquivar{/id}', {}, { patch: { method: 'PATCH' } }),

  credenciaisMensageriaFirebase: Vue.resource(EndPoint + 'credenciais-firebase'),

  listagemDeApontamentos: Vue.resource(EndPoint + 'folhas-apontamentos-api'),

  acompanhamento: Vue.resource(EndPoint + 'acompanhamento/horarios-previstos{/id}'),

  historicoPonto: Vue.resource(EndPoint + 'historico-ponto'),

  historicoPontoMes: Vue.resource(EndPoint + 'historico-ponto-mes'),

  historicoPontoDia: Vue.resource(EndPoint + 'historico-ponto-dia'),

  // ParametrizadorE02
  verbas: Vue.resource(EndPoint + 'recursos-para-parametrizacao'),

  parametrizador: Vue.resource(EndPoint + 'parametrizacao'),

  // Coletores
  aprovarColetor: Vue.resource(EndPointGerenciadorDeColetores + 'coletor{/id}/aprovar'),
  reprovarColetor: Vue.resource(EndPointGerenciadorDeColetores + 'coletor{/id}/reprovar'),
  aprovarTodosColetores: Vue.resource(EndPointGerenciadorDeColetores + 'coletores/aprovar-pendentes'),
  coletor: Vue.resource(EndPointGerenciadorDeColetores + 'coletor{/id}'),
  coletores: Vue.resource(EndPointGerenciadorDeColetores + 'coletores'),
  relatorioColetores: Vue.resource(EndPointGerenciadorDeColetores + 'coletores/relatorio'),
  urlsDeRedirecionamento: Vue.resource(EndPointGerenciadorDeColetores + 'urls-de-redirecionamento/gestao'),

  // Perfil
  perfis: Vue.resource(EndPointGerenciadorDeColetores + 'perfis-de-coletor'),
  perfil: Vue.resource(EndPointGerenciadorDeColetores + 'perfil-de-coletor{/id}'),
  definirPerfilPadrao: Vue.resource(EndPointGerenciadorDeColetores + 'perfil-de-coletor{/id}/definir-como-padrao'),

  criarGerenciamentoDeArquivo: async (parametros) => {
    const {
      descricao, dataLimitePedidoUtc, arquivo,
      categoriaId, acoesDeNotificacao,
      mensagemCustomizada, usuariosQueAprovarao,
      usuarioVinculado
    } = parametros

    const formData = new FormData()
    formData.append('descricao', descricao)
    formData.append('data_limite_pedido_utc', dataLimitePedidoUtc)
    formData.append('motivo_padrao_id', categoriaId)
    formData.append('acoes_de_notificacao', acoesDeNotificacao)
    formData.append('usuarios_que_aprovarao', usuariosQueAprovarao)
    formData.append('usuario_vinculado', usuarioVinculado)
    formData.append('arquivo_vindo_do_front', arquivo)
    formData.append('mensagem_customizada', mensagemCustomizada)

    const result = await Vue.resource(`${EndPoint}aprovacao_de_arquivo`).save(formData)
    return result
  },

  R01: Vue.resource(EndPoint.replace('/api', '') + 'comp_man_timelogsheet'),

  BetaR01: Vue.resource(EndPoint + 'relatorio/gerar-r01'),

  R02: Vue.resource(EndPoint + 'relatorio/gerar-r02'),

  BetaR03: Vue.resource(EndPoint + 'relatorio/gerar-r03'),

  R05: Vue.resource(EndPoint.replace('/api', '') + 'export_default_apontamento_excel_compman'),

  BetaR05: Vue.resource(EndPoint + 'relatorio/gerar-r05'),

  R06: Vue.resource(EndPoint + 'generate-custom-report'),

  BetaR06: Vue.resource(EndPoint + 'relatorio/gerar-r06'),

  R08: Vue.resource(EndPoint + 'reportpdf{/startDate}{/endDate}'),

  R09: Vue.resource(EndPoint + 'generate-timelogs-xls-report'),

  BetaR09: Vue.resource(EndPoint + 'relatorio/gerar-r09'),

  R10: Vue.resource(EndPoint + 'generate-whb-report'),

  BetaR10: Vue.resource(EndPoint + 'relatorio/gerar-r10'),

  R11: Vue.resource(EndPoint + 'weekday-divider-report'),

  R12: Vue.resource(EndPoint + 'report-punched{/reportType}'),

  R13: Vue.resource(EndPoint + 'report-info-employee'),

  BetaR13: Vue.resource(EndPoint + 'relatorio/gerar-r13'),

  R14: Vue.resource(EndPoint + 'generate-transfer-xls-report'),

  R15: Vue.resource(EndPoint + 'changes-report'),

  R16: Vue.resource(EndPoint + 'relatorio/gerar-r16'),

  R17: Vue.resource(EndPoint + 'workjourney-folga-report'),

  BetaR17: Vue.resource(EndPoint + 'relatorio/gerar-r17'),

  R18: Vue.resource(EndPoint + 'benefits-report'),

  BetaR18: Vue.resource(EndPoint + 'relatorio/gerar-r18'),

  R19: Vue.resource(EndPoint + 'relatorio/gerar-r19'),

  R20: Vue.resource(EndPoint + 'timelog-company-location-report'),

  R21: Vue.resource(EndPoint + 'approve-whb-report'),

  R21Afya: Vue.resource(EndPoint + 'gerar-r21-afya'),

  BetaR21: Vue.resource(EndPoint + 'relatorio/gerar-r21'),

  R22: Vue.resource(EndPoint + 'ver-folhas-report'),

  BetaR22: Vue.resource(EndPoint + 'relatorio/gerar-r22'),

  R23: Vue.resource(EndPoint + 'activity-lines-report'),

  R24: Vue.resource(EndPoint + 'activity-sum-report'),

  R25: Vue.resource(EndPoint + 'relatorio/gerar-r25'),

  R26: Vue.resource(EndPoint + 'on-call-spreadsheet'),

  BetaR26: Vue.resource(EndPoint + 'relatorio/gerar-r26'),

  R27: Vue.resource(EndPoint + 'absent-report'),

  R28: Vue.resource(EndPoint + 'whbcloser-report'),

  BetaR28: Vue.resource(EndPoint + 'relatorio/gerar-r28'),

  R29: Vue.resource(EndPoint + 'whb-management-report'),

  BetaR29: Vue.resource(EndPoint + 'relatorio/gerar-r29'),

  R30: Vue.resource(EndPoint + 'whb-daily-tracking-report'),
  BetaR30: Vue.resource(EndPoint + 'relatorio/gerar-r30'),

  R31: Vue.resource(EndPoint + 'relatorio/gerar-r31'),

  R32: Vue.resource(EndPoint + 'situation-report'),

  R33: Vue.resource(EndPoint + 'manager-report'),

  R35: Vue.resource(EndPoint + 'acompanhamento-de-funcionarios-report'),

  R36: Vue.resource(EndPoint + 'relatorio-movimentacao-jornadas-escalas'),

  BetaR36: Vue.resource(EndPoint + 'relatorio/gerar-r36'),

  R37: Vue.resource(EndPoint + 'relatorio/gerar-r37'),

  relatorioAuditoria: Vue.resource(EndPoint + 'relatorio/gerar-auditoria'),

  E01: Vue.resource(EndPoint.replace('/api', '') + 'export_script_ponto_compman'),

  BetaE01: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e01'),

  E02: Vue.resource(EndPoint.replace('/api', '') + 'export_script_apontamento_compman'),

  BetaE02: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e02'),

  E03: Vue.resource(EndPoint + 'export_whbe'),
  BetaE03: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e03'),

  E04: Vue.resource(EndPoint + 'audio-extractor'),

  E05: Vue.resource(EndPoint + 'export_adf'),

  E05_671: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e05/portaria-671'),

  E06: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e06'),

  E07: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e07'),

  E08: Vue.resource(EndPoint.replace('/api', '') + 'export_script_justify_compman'),

  E09: Vue.resource(EndPoint.replace('/api', '') + 'export_e09'),

  BetaE09: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e09'),

  E10: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e10'),

  E11: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e11'),

  E12: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e12'),

  E13: Vue.resource(EndPoint + 'relatorio/exportador/gerar-e13'),

  I01: Vue.resource(EndPoint + 'import-afd{/forceKind}'),

  I01Beta: Vue.resource(EndPoint + 'importador-afd-gestao'),

  I02: Vue.resource(EndPoint + 'import-escalas'),

  I03: Vue.resource(EndPoint + 'importar_sobreaviso'),

  I04: Vue.resource(EndPoint + 'importar-funcionarios'),

  I05: Vue.resource(EndPoint + 'importadores-gestao-web'),

  ArquivosDeImportadores: Vue.resource(EndPointArquivosDeImportadores + '{/tipoDeImportador}{/tipoDeArquivo}'),

  EmpregadosPonto: Vue.resource(EndPoint + 'relatorio/gerar-empregados-batendo-ponto'),

  RelatorioFaltas: Vue.resource(EndPoint + 'relatorio/gerar-relatorio-de-faltas'),

  acompanhamentoDiarioGeral: Vue.resource(EndPoint + 'acompanhamento-diario/geral'),

  cadastrarLimiteDeHoraExtras: Vue.resource(EndPoint + 'acompanhamento-diario{/idEmpregado}{/ano}{/mes}{/dia}'),

  R38: Vue.resource(EndPoint + 'relatorio/gerar-template-de-espelho'),

  RelatorioSolicitacaoHorasExtras: Vue.resource(EndPoint + 'relatorio/gerar-relatorio-de-solicitacoes-de-horas-extras'),

  uploadFile: async (bucket, file, filename) => {
    const formData = new FormData()
    formData.append('file', file)

    const result = await Vue.resource(`${EndPoint}upload-file-to-bucket/${bucket}/${filename}`)
      .save(formData)
    return result
  },

  obterUrlAssinadaParaUploadDeArquivo: async (nomeDoBucket, tipoDoArquivo, idEmpregado, ano, mes, dia, nomeDoArquivo) => {
    const urlAssinadaParaUpload = await Vue.resource(`${EndPoint}gerar-url-assinada-para-upload-de-arquivo-no-bucket`)
      .save({
        bucket: nomeDoBucket,
        tipo_de_arquivo: tipoDoArquivo,
        empregado: idEmpregado,
        ano: ano,
        mes: mes,
        dia: dia,
        nome_do_arquivo: nomeDoArquivo
      })
    return urlAssinadaParaUpload.body.success
  },

  uploadDoArquivo: async (urlAssinada, arquivo) => {
    const respostaDoUploadDoArquivoParaOBucket = await fetch(urlAssinada, {
      method: 'PUT',
      body: arquivo,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }).then(function (respostaDoUploadDoArquivoParaOBucket) {
      return respostaDoUploadDoArquivoParaOBucket
    }).catch(erro => {
      this.$modalDeErro(erro)
    })
    return respostaDoUploadDoArquivoParaOBucket
  },

  consultaIp: Vue.resource('https://us-central1-pontotel-tools.cloudfunctions.net/consultar-ip'),

  consultarFilaDeRelatorios: Vue.resource(EndPoint + 'relatorio/fila'),
  cancelarGeracaoDeRelatorio: Vue.resource(EndPoint + 'relatorio/cancelar'),

  consultarTamanhoCLiente: Vue.resource('https://us-central1-pontotel-tools.cloudfunctions.net/consultador-de-tamanho-do-cliente-no-auth'),

  integrarVerbasFaltas: Vue.resource(EndPoint + 'integracao/recalcular-e-iniciar'),
  acompanhamentoIntegracao: Vue.resource(EndPointIntegracao + 'acompanhamento/verbas-faltas/status'),
  novasIntegracoesSankhyaAtivas: Vue.resource(EndPointIntegracao + 'integracao/calendario-de-etapa/visualizacao-de-entidades'),

  listarDocumentosCadastroEmpregador: Vue.resource(EndPoint + 'listar-documentos-do-empregador-por-pais'),
  listarDocumentosCadastroEmpregado: Vue.resource(EndPoint + 'listar-documentos-do-empregado-por-pais'),
  supervisor: Vue.resource(EndPoint + 'supervisor{/id}'),

  gerarLogLoginSSO: Vue.resource(EndPoint + 'salvar-log-usuario-logou'),

  obterTokenDoKeycloakAPartirDaSessao: async (realm, codigo, sessao, redirectUri) => {
    const result = await Vue.http.post(`${EndPointKeycloak}realms/${realm}/protocol/openid-connect/token`, {
      grant_type: 'authorization_code',
      client_id: 'pontotel',
      session_state: sessao,
      code: codigo,
      redirect_uri: redirectUri
    }, {
      emulateJSON: true
    })
    return result
  },

  obterAmbienteDoUsuario: async (email) => {
    const result = await Vue.http.get(`${EndPoint}ambientes-do-usuario/${email}`, {})
    return result
  },

  listarDDDeFusosHorarios: Vue.resource(EndPoint + `/company/listar-ddds`)
}
export const mixins = {
  methods: {
    $dispatchUseState: (useState, payload) => store.dispatch(useState, payload),
    $commitUseState: (useState, payload) => store.commit(useState, payload)
  }
}
Vue.mixin(mixins)

Vue.prototype.$API = API
Vue.prototype.$swal = swal
Vue.prototype.$hasPermission = hasPermission

Vue.prototype.$checkUserFiles = function (relatorioInfo = {}) {
  this.$root.$emit('Notifications__checkUserFiles', relatorioInfo)
}
