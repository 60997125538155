import * as Sentry from '@sentry/vue'

const TAXA_DE_AMOSTRAS_EM_PRODUCAO = 0.000001

export function utilizarSentry (app, router) {
  const ambienteNode = process.env.NODE_ENV
  const ehAmbienteDeProducao = ambienteNode === 'production'

  const definirTaxaDeAmostras = () => {
    const taxaDeAmostrasEmProducao = TAXA_DE_AMOSTRAS_EM_PRODUCAO
    const taxaDeAmostrasEmDesenvolvimento = 0

    if (ehAmbienteDeProducao) {
      return taxaDeAmostrasEmProducao
    } else {
      return taxaDeAmostrasEmDesenvolvimento
    }
  }

  const porcentagemDeAmostras = definirTaxaDeAmostras()

  if (ehAmbienteDeProducao) {
    Sentry.init({
      app,
      dsn: 'https://b1e34487e00040cfb5d327aa229ac224@sentry.pontotel.com.br/11',
      /**
       * Defina logErros para exibir ou não os erros no console do navegador
       * Para produção recomendamos ocultar
       */
      logErros: !ehAmbienteDeProducao,
      integrations: [
        Sentry.browserTracingIntegration({
          router: router,
          routeLabel: 'path'
        }),
      ],
      /**
       * Defina tracesSampleRate como 1.0 para capturar 100%
       * de transações para monitoramento de desempenho.
       * Recomendamos ajustar este valor na produção
       */
      tracesSampleRate: porcentagemDeAmostras,
      beforeSend(evento) {
        /**
         * Customiza o envio de erros a partir dos dados adicionados
         * no interceptador de captura de erro do Vue resource e axios
         */
        try {
          if (
            evento?.extra && 'detalhesErroSentry' in evento.extra ||
            evento?.extra?.__serialized__ && 'detalhesErroSentry' in evento.extra.__serialized__
          ) {
            const detalhesErroSentry = evento?.extra?.detalhesErroSentry || evento.extra.__serialized__.detalhesErroSentry

            // ao cancelar uma requisicao, o axios retorna um erro com o codigo ERR_CANCELED que nao deve ser enviado para o sentry
            if (detalhesErroSentry.codigoErroApi === 'ERR_CANCELED') {
              return null
            }

            // para nao enviar dados duplicados, deletamos os detalhes ja existentes no evento
            delete evento.extra.detalhesErroSentry
            delete evento.extra.__serialized__.detalhesErroSentry

            // texto que fica ao lado do nome do erro (que fica em azul no sentry)
            evento.transaction = 'ERRO_API'

            if (evento.exception && evento.exception.values && evento.exception.values.length > 0) {
              // personalizar o texto abaixo do nome do erro (que fica em azul no sentry)
              evento.exception.values[0].value = detalhesErroSentry.titulo
            }

            delete detalhesErroSentry.titulo

            evento.extra = {
              ...evento.extra,
              ...detalhesErroSentry
            }
          }

          return evento
        } catch {
          return evento
        }
      }
    })
  }
}
