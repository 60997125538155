import { API } from 'src/services'
import Cookies from 'js-cookie'

export default {
  getters: {
    visual: function (state) {
      return state.visualConfigs ? state.visualConfigs.visual : {}
    }
  },
  mutations: {
    _visualConfigs (state, { isDefault, visual, fileFront }) {
      state.visualConfigs = { isDefault, visual, fileFront }
    },
    mudarCategoriaBarradeNavegacao (state, category) {
      Cookies.set('CategoriaBarradeNavegacao', category)
      state.CategoriaBarradeNavegacao = category
    }
  },
  actions: {
    async getVisual ({ state, commit, dispatch }, { reloadIfIsDefault = false, forceReload = false }) {
      // 0º Get Route to signalize
      state.CategoriaBarradeNavegacao = Cookies.get('CategoriaBarradeNavegacao')
      // 1º Load the layout saved in backend
      const reloadIfDefault = state.visualConfigs?.isDefault && reloadIfIsDefault === true
      const reload = !state.visualConfigs || forceReload === true || reloadIfDefault

      if (state.userInfo && reload) {
        const result = await API.frontVisual.get({ userId: state.userInfo.id })

        commit('_visualConfigs', Object.freeze(result.body.success))
      }
      // 2º Load the css file
      dispatch('_getFileVisual',
        { fileUrl: state.visualConfigs.fileFront, faviconUrl: state.visualConfigs.visual.favicon }
      )
    },
    // eslint-disable-next-line no-empty-pattern
    _getFileVisual ({}, { fileUrl }) {
      // Load the custom style of comp_man/employer or the default one
      const customStyleContainerId = 'customStyleContainer'

      let container = document.getElementById(customStyleContainerId)

      // Para habilitar o favicon para alterar de acordo com a API de CustomVisuals basta descomentar as linhas abaixo
      // const favicon = document.getElementById('favicon')

      if (container) {
        container.href = fileUrl
        // favicon.href = faviconUrl
      } else if (!container) {
        container = document.createElement('link')
        container.href = fileUrl
        container.id = customStyleContainerId
        container.type = 'text/css'
        container.rel = 'stylesheet'
        document.getElementsByTagName('head')[0].append(container)
        // favicon.href = faviconUrl
      }
    }
  }
}
