import type { LocaleMessageObject } from 'vue-i18n'

import AcessoNegado from '@/components/AcessoNegado/acessoNegado.pt-br.i18n.json'
import AcompanhamentoTempoReal from '@/components/AcompanhamentoTempoReal/acompanhamentoTempoReal.pt-br.i18n.json'
import Atividades from '@/components/Activities/atividades.pt-br.i18n.json'
import Auditoria from '@/components/Auditor/auditoria.pt-br.i18n.json'
import Compartilhados from '@/common/compartilhados.pt-br.i18n.json'
import Biometria from '@/components/FaceBiometric/facebiometric.pt-br.i18n.json'
import Coletor from '@/components/Coletores/coletor.pt-br.i18n.json'
import CustomizacoesDeCalculo from '@/components/CustomizacoesDeCalculo/customizacao.pt-br.i18n.json'
import Compman from '@/components/CompMan/compman.pt-br.i18n.json'
import Dashboards from '@/components/DashBoards/dashboards.pt-br.i18n.json'
import Dispositivos from '@/components/Devices/dispositivos.pt-br.i18n.json'
import Empregado from '@/components/Employee/employee.pt-br.i18n.json'
import Empregador from '@/components/Employer/employer.pt-br.i18n.json'
import FeriasEAfastamentos from '@/components/FeriasEAfastamentos/feriasEAfastamento.pt-br.i18n.json'
import LocalDeTrabalho from '@/components/Company/local.pt-br.i18n.json'
import RegraAprovacao from '@/components/ApprovalsRules/regraAprovacao.pt-br.i18n.json'
import Solicitacao from '@/components/Approvals/solicitacao.pt-br.i18n.json'
import Comuns from '@/typescript/servicos/i18n/locales/comuns.pt-br.i18n.json'
import Home from '@/components/Home/home.pt-br.i18n.json'
import MainHeader from '@/components/MainHeader/mainHeader.pt-br.i18n.json'
import Cognito from '@/components/Cognito/cognito.pt-br.i18n.json'
import Groups from '@/components/Groups/groups.pt-br.i18n.json'
import Holiday from '@/components/Holiday/holiday.pt-br.i18n.json'
import Journey from '@/components/Journey/journey.pt-br.i18n.json'
import Locker from '@/components/Locker/locker.pt-br.i18n.json'
import LogDeOperacoes from '@/components/LogDeOperacoes/logDeOperacoes.pt-br.i18n.json'
import HourBankRules from '@/components/HourBankRules/hourBankRules.pt-br.i18n.json'
import FileManagement from '@/components/FileManagement/fileManagement.pt-br.i18n.json'
import Monitors from '@/components/Monitors/monitors.pt-br.i18n.json'
import LockerRules from '@/components/LockerRules/lockerRules.pt-br.i18n.json'
import MassChanges from '@/components/MassChanges/massChanges.pt-br.i18n.json'
import PainelDePontos from '@/components/PainelDePontos/paineldepontos.pt-br.i18n.json'
import Permissions from '@/components/Permissions/permissions.pt-br.i18n.json'
import Reason from '@/components/Reason/reason.pt-br.i18n.json'
import Reports from '@/components/Reports/reports.pt-br.i18n.json'
import Routine from '@/components/Routine/routine.pt-br.i18n.json'
import Sheets from '@/components/Sheets/sheets.pt-br.i18n.json'
import Supervisor from '@/components/Supervisor/supervisor.pt-br.i18n.json'
import User from '@/components/User/user.pt-br.i18n.json'
import Whb from '@/components/Whb/whb.pt-br.i18n.json'
import workSchedule from '@/components/WorkSchedule/workSchedule.pt-br.i18n.json'
import router from '@/router/router.pt-br.i18n.json'
import store from '@/store/store.pt-br.i18n.json'
import tipos from '@/tipos/tipos.pt-br.i18n.json'
import typescript from '@/typescript/typescript.pt-br.i18n.json'
import ModuloSolicitacoes from '@/typescript/modulos/governanca/solicitacoes/solicitacoes.pt-br.i18n.json'
import ModuloFolha from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/folha.pt-br.i18n.json'
import PainelSolicitacoesDeHorasExtras from '@/typescript/modulos/acompanhamento/PainelSolicitacoesDeHorasExtras/painelSolicitacoesDeHorasExtras.pt-br.i18n.json'
import Utils from '@/utils/utils.pt-br.i18n.json'
import ModuloGerenciamentoDeFerias from '@/typescript/modulos/calendario-de-trabalho/GerenciamentoDeFerias/gerenciamentoDeFeria.pt-br.i18n.json'

const mensagens: LocaleMessageObject = {
  words: {
    user: 'usuário | usuários',
    employee: 'empregado | empregados',
    company: 'local de trabalho | locais de trabalho',
    companyShortName: 'local | locais',
    employer: 'empregador | empregadores',
    supervisor: 'responsável | responsáveis',
    routine: 'escala | escalas',
    journey: 'jornada | jornadas',
    customRules: 'regra de cálculo | regras de cálculo',
    locker: 'travamento | travamentos',
    group: 'grupo | grupos',
    all: 'aplicar a todos'
  },
  acessoNegado: AcessoNegado,
  acompanhamentoTempoReal: AcompanhamentoTempoReal,
  atividades: Atividades,
  auditoria: Auditoria,
  biometria: Biometria,
  compartilhados: Compartilhados,
  compman: Compman,
  coletor: Coletor,
  customizacoesDeCalculo: CustomizacoesDeCalculo,
  dashboards: Dashboards,
  dispositivos: Dispositivos,
  empregado: Empregado,
  empregador: Empregador,
  feriasEAfastamento: FeriasEAfastamentos,
  local: LocalDeTrabalho,
  regraAprovacao: RegraAprovacao,
  solicitacao: Solicitacao,
  comuns: Comuns,
  home: Home,
  mainHeader: MainHeader,
  cognito: Cognito,
  groups: Groups,
  holiday: Holiday,
  journey: Journey,
  locker: Locker,
  logDeOperacoes: LogDeOperacoes,
  hourBankRules: HourBankRules,
  fileManagement: FileManagement,
  monitors: Monitors,
  lockerRules: LockerRules,
  massChanges: MassChanges,
  paineldepontos: PainelDePontos,
  permissions: Permissions,
  reason: Reason,
  reports: Reports,
  routine: Routine,
  sheets: Sheets,
  supervisor: Supervisor,
  user: User,
  whb: Whb,
  workSchedule: workSchedule,
  router: router,
  store: store,
  tipos: tipos,
  typescript: typescript,
  solicitacoes: ModuloSolicitacoes,
  folha: ModuloFolha,
  painelSolicitacoesDeHorasExtras: PainelSolicitacoesDeHorasExtras,
  utils: Utils,
  gerenciamentoDeFerias: ModuloGerenciamentoDeFerias
}

export default mensagens
