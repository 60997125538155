<template>
  <div class="grafico-de-barra" ref="container">
    <div v-if="carregamentoNaMontagem || carregandoPagina" class="grafico-de-barra__carregando" :style="carregandoPagina && 'opacity: 0.4;'">
      <PulseLoading color="#969696"/>
    </div>
    <div class="grafico-de-barra__container">
      <div :style="{ height: alturaContainer }" style="width: 100%; outline: none">
        <canvas ref="canvas" @click="lidarComClique" />
      </div>
    </div>
  </div>
</template>
<script>
import { Chart } from "chart.js";
import PulseLoading from "@/common/Loading/PulseLoading.vue";
export default {
  props: {
    plugins: {
      type: Array,
      default: () => [],
    },
    options: {
      default: () => ({}),
    },
    possuiPaginacao: {
      type: Boolean,
      default: false,
    },
    itensPorPagina: {
      type: Number,
      default: 10
    },
    funcaoDeBusca: {
      type: Function,
      required: true
    },
    alturaContainer: {
      default: '250px',
      type: String
    }
  },
  data() {
    /**
     * instaciamos assim para evitar o erro:
     * Maximum call stack size exceeded
     * do grafico
     */
    this.grafico = null;
    return {
      itens: [],
      carregandoPagina: false,
      carregamentoNaMontagem: false,
      paginacao: {
        totalDePaginas: null,
        totalDeItens: 0,
        paginaAtual: 1,
      }
    };
  },
  computed: {
    graficoPossuiPaginacao() {
      return Boolean(this.possuiPaginacao && this.itensPorPagina)
    },
    chegouNaUltimaPaginaDaApi() {
      return this.paginacao.paginaAtual === this.paginacao.totalDePaginas
    }
  },
  methods: {
    proximaPagina(proximaPagina = this.paginacao.paginaAtual + 1) {
      this.paginacao.paginaAtual = proximaPagina
    },
    async buscarDadosComPaginacao() {
      try {
        const { itens, totalDeItens, totalDePaginas } = await this.funcaoDeBusca({
          pagina: this.paginacao.paginaAtual,
          porPagina: this.itensPorPagina
        })
        this.paginacao.totalDeItens = totalDeItens
        this.paginacao.totalDePaginas = totalDePaginas

        if (this.paginacao.paginaAtual > 1 && Object.keys(this.itens).length !== 0) {
          itens.datasets.forEach((set, index) => {
            this.itens.datasets[index].data.push(...set.data)
          })
          this.itens.labels.push(...itens.labels)
        } else {
          this.itens = itens
        }
      } catch {
      }
    },
    async buscarDadosSemPaginacao() {
      try {
        const { itens } = await this.funcaoDeBusca({
          pagina: this.paginacao.paginaAtual,
          porPagina: this.itensPorPagina
        })

        this.itens = itens

      } catch {}
    },
    /**
     * Realize o tratamento de erros na sua funcao de paginação,
     * adicionamos o try catch aqui somente para não continuar tentando
     * manipular os dados que seriam recebidos da api, como possui paginacao
     * se quebrar em uma pagina especifica o usuario ainda consegue ver as outras paginas
     */
    async buscarDados() {
      if (this.graficoPossuiPaginacao) {
        await this.buscarDadosComPaginacao()
        return
      }

      await this.buscarDadosSemPaginacao()
    },
    atualizarGrafico() {
      if (this.grafico) {
        this.grafico.config.data = this.itens;
        this.grafico.update();
        return
      }
      this.construirGrafico();
    },
    construirGrafico() {
      const options = this.options;
      const plugins = this.plugins;

      if (this.graficoPossuiPaginacao) {
        options.scales.x.min = 0;
        options.scales.x.max = this.itensPorPagina;
      }

      this.grafico = new Chart(this.$refs.canvas, {
        type: "bar",
        data: this.itens,
        options,
        plugins,
      });

      if (this.graficoPossuiPaginacao) {
        this.grafico.ctx.canvas.onclick = this.paginarGrafico.bind(this);
      }
    },
    async paginarGrafico(event) {
      const { canvas, chartArea: { left, right, top, height } } = this.grafico;

      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const ITENS_POR_PAGINA = this.itensPorPagina;
      const clicouNaPosicaoBotaoAvancarNoGrafico = Boolean(x >= right - 15 && x <= right + 15 && y >= height / 2 + top - 15 && y <= height / 2 + top + 15);
      const clicouNaPosicaoBotaoRetrocederNoGrafico = Boolean(x >= left - 15 && x <= left + 15 && y >= height / 2 + top - 15 && y <= height / 2 + top + 15);

      if (clicouNaPosicaoBotaoAvancarNoGrafico) {
        /**
         * se o ultimo indice visivel no grafico for maior ou igual que a quantidade de itens
         * tiver chegado na ultima pagina na API não avançamos mais
         */
        if (this.grafico.options.scales.x.max >= this.itens.datasets[0].data.length && this.chegouNaUltimaPaginaDaApi) {
          return
        }

        const indiceMinimo = this.grafico.options.scales.x.min
        const indiceMaximo = this.grafico.options.scales.x.max
        this.grafico.options.scales.x.min = indiceMinimo + ITENS_POR_PAGINA;
        this.grafico.options.scales.x.max = indiceMaximo + ITENS_POR_PAGINA;

        if (!this.chegouNaUltimaPaginaDaApi) {
          this.carregandoPagina = true;
          this.proximaPagina();
          await this.buscarDados();
          this.atualizarGrafico();
          await this.$nextTick();
          this.carregandoPagina = false;
          return;
        }
      }

      if (clicouNaPosicaoBotaoRetrocederNoGrafico) {
        this.grafico.options.scales.x.min = this.grafico.options.scales.x.min - ITENS_POR_PAGINA;
        this.grafico.options.scales.x.max = this.grafico.options.scales.x.max - ITENS_POR_PAGINA;

        if (this.grafico.options.scales.x.min <= 0) {
          const porPagina = ITENS_POR_PAGINA - 1;
          this.grafico.options.scales.x.min = 0;
          this.grafico.options.scales.x.max = porPagina < ITENS_POR_PAGINA ? ITENS_POR_PAGINA : porPagina;
        }
      }

      this.grafico.update();
    },
    lidarComClique(eventoDeClique) {
      const resposta = this.grafico.getElementsAtEventForMode(
        eventoDeClique,
        "nearest",
        { intersect: true },
        true
      );
      if (resposta.length) {
        this.$emit("clicouNaBarra", {
          ...resposta[0].element.$context.raw,
          posicaoXNoCanvas: resposta[0].element.x,
        });
      }
    },
  },
  async mounted() {
    this.carregamentoNaMontagem = true
    await this.buscarDados();
    this.atualizarGrafico();
    this.carregamentoNaMontagem = false
  },
  components: {
    PulseLoading,
  },
};
</script>

<style scoped lang="scss">
.grafico-de-barra {
  position: relative;
  height: 100%;

  &__carregando {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--front-background-color);
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__container {
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    position: relative;
    overflow-x: auto;
    outline: none;
  }
}
</style>
