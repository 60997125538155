<template>
  <div class="modal-chat-inkeep__wrapper" @click="clicouNoContainer">
    <div class="modal-chat-inkeep__container" @click="prevenirFechamento">
      <div id="ikp-embedded-chat-target"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalInkeep',
  computed: {
    ...mapState(['userInfo']),
    ambienteProducao () {
      return process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    configurarEIniciarChatInkeeep () {
      if (!window.Inkeep) return
      const config = {
        baseSettings: {
          apiKey: process.env.VUE_APP_INKEEP_API_KEY,
          organizationDisplayName: "Pontotel",
          primaryBrandColor: "#79397D",
          userProperties: {
            id: this.userInfo?.id,
            email: this.userInfo?.email,
            name: this.userInfo?.name,
          }
        },
        aiChatSettings: {
          introMessage: `Olá, Eu sou o Assistente da Pontotel \n\n Sou uma IA treinada para te ajudar com dúvidas sobre a plataforma. \n\n Me pergunte qualquer coisa da Pontotel.`,
          aiAssistantName: "Assistente da Pontotel",
          chatSubjectName: "Pontotel",
          placeholder: "Digite sua pergunta...",
          aiAssistantAvatar: "favicon.png",
          getHelpOptions: [
              {
                  name: "Suporte Pontotel",
                  icon: {
                      builtIn: "IoHelpBuoyOutline"
                  },
                  isPinnedToToolbar: true,
                  action: {
                    type: "open_link",
                    url: "https://suporte.pontotel.com.br/",
                    shouldCloseModal: true
                  }
              },
              {
                  name: "Blog Pontotel",
                  isPinnedToToolbar: true,
                  icon: {
                      builtIn: "IoHelpBuoyOutline"
                  },
                  action: {
                    type: "open_link",
                    url: "https://www.pontotel.com.br/blog/",
                    shouldCloseModal: true
                  }
              },
          ],
          exampleQuestions: [
            "Como verificar as faltas através do App gestão?",
            "Como cadastrar um novo usuário?",
            "Como realizar o fechamento da folha de ponto?"
          ],
          exampleQuestionsLabel: "Perguntas frequentes",
          toolbarButtonLabels: {
            clear: "Limpar",
            getHelp: "Ajuda",
            stop: "Parar",
          },
          searchLabel: "Pesquisar",
        },
        searchSettings: {
          placeholder: "Pesquisar...",
        },
        askAILabel: "Pergunte algo",
        searchLabel: "Pesquisar",
        label: 'Assistente',
      }
      window.Inkeep.EmbeddedChat("#ikp-embedded-chat-target", config)
    },
    clicouNoContainer () {
      this.$emit('fechar')
    },
    prevenirFechamento (event) {
      event.stopPropagation()
    }
  },
  watch: {
    userInfo () {
      if (this.ambienteProducao && this.userInfo.roleGroup.kind === 'all') {
        this.configurarEIniciarChatInkeeep()
      }
    }
  }
}
</script>

<style scoped>
.modal-chat-inkeep__wrapper {
  background-color: rgba(104, 112, 118, 0.5);
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  overflow: hidden;
  padding: 1rem;
}

.modal-chat-inkeep__container {
  max-height: 600px;
  max-width: 720px;
  height: 100%;
}
</style>
