import { DadosGraficoEmpregados, IStatePainelSolicitacoesDeHorasExtras, TipoHierarquia } from "./painelSolicitacoesDeHorasExtras.interface";
import { ID } from "@/tipos";

const stateResetada = (): IStatePainelSolicitacoesDeHorasExtras => ({
  carregandoFiltros: false,
  estaBuscandoDados: false,
  estaBuscandoDadosGraficoEmpregados: false,
  realizouPrimeiraBusca: false,
  idLocalDeTrabalhoGraficoEmpregados: null,
  filtros: {
    empregadoresSelecionados: [],
    gruposSelecionados: [],
    locaisTrabalhoSelecionados: [],
    usuariosLideresSelecionados: [],
    tipoHierarquiaSelecionada: null,
    dataInicial: null,
    dataFinal: null,
  },
  placar: {
    quantidadeTotalDeSolicitacoes: 0,
    aprovadas: {
      consolidado: '',
      quantidadeTotalSolicitacoesAprovadas: 0,
      quantidadeHorasAprovadas: '',
      quantidadeHorasJustificadasPosteriormente: '',
      quantidadeHorasSolicitadasAntecipadamente: '',
      quantidadeJustificativasPosteriores: 0,
      quantidadeSolicitacoesAntecipadas: 0,
    },
    pendentes: {
      consolidado: '',
      quantidadeTotalSolicitacoesPendentes: 0,
      quantidadeHorasJustificadasPosteriormenteAindaPendentes: '',
      quantidadeHorasPendentesDeJustificativa: '',
      quantidadeHorasSolicitadasAntecipadamenteAindaPendentes: '',
      quantidadeJustificativasPendentes: 0,
      quantidadeJustificativasPosterioresAindaPendentesDeResposta: 0,
      quantidadeSolicitacoesAindaPendentes: 0,
    },
    reprovadas: {
      consolidado: '',
      quantidadeTotalSolicitacoesReprovadas: 0,
      quantidadeHorasJustificadasPosteriormente: '',
      quantidadeHorasReprovadas: '',
      quantidadeHorasSolicitadasAntecipadamente: '',
      quantidadeJustificativasPosteriores: 0,
      quantidadeSolicitacoesAntecipadas: 0,
    }
  },
  detalhesPorEmpregado: {},
})

const mutations = {
  definirCarregamentoFiltros(state: IStatePainelSolicitacoesDeHorasExtras, carregandoFiltros: boolean) {
    state.carregandoFiltros = carregandoFiltros;
  },
  definirEstaBuscandoDados(state: IStatePainelSolicitacoesDeHorasExtras, estaBuscandoDados: boolean) {
    state.estaBuscandoDados = estaBuscandoDados;
  },
  definirEstaBuscandoDadosGraficoEmpregados(state: IStatePainelSolicitacoesDeHorasExtras, estaBuscandoDadosGraficoEmpregados: boolean) {
    state.estaBuscandoDadosGraficoEmpregados = estaBuscandoDadosGraficoEmpregados;
  },
  definirRealizouPrimeiraBusca(state: IStatePainelSolicitacoesDeHorasExtras, realizouPrimeiraBusca: boolean) {
    state.realizouPrimeiraBusca = realizouPrimeiraBusca;
  },
  definirIdLocalDeTrabalhoGraficoEmpregados(state: IStatePainelSolicitacoesDeHorasExtras, idLocalDeTrabalhoGraficoEmpregados: ID | null) {
    state.idLocalDeTrabalhoGraficoEmpregados = idLocalDeTrabalhoGraficoEmpregados;
  },
  definirEmpregadoresSelecionados(state: IStatePainelSolicitacoesDeHorasExtras, empregadoresSelecionados: any) {
    state.filtros.empregadoresSelecionados = empregadoresSelecionados
  },
  definirGruposSelecionados(state: IStatePainelSolicitacoesDeHorasExtras, gruposSelecionados: any) {
    state.filtros.gruposSelecionados = gruposSelecionados
  },
  definirLocaisTrabalhoSelecionados(state: IStatePainelSolicitacoesDeHorasExtras, locaisTrabalhoSelecionados: any) {
    state.filtros.locaisTrabalhoSelecionados = locaisTrabalhoSelecionados
  },
  definirUsuariosLideresSelecionados(state: IStatePainelSolicitacoesDeHorasExtras, usuariosLideresSelecionados: any) {
    state.filtros.usuariosLideresSelecionados = usuariosLideresSelecionados
  },
  definirTipoHierarquiaSelecionada(state: IStatePainelSolicitacoesDeHorasExtras, tipoHierarquiaSelecionada: TipoHierarquia) {
    state.filtros.tipoHierarquiaSelecionada = tipoHierarquiaSelecionada;
  },
  definirDataInicial(state: IStatePainelSolicitacoesDeHorasExtras, dataInicial: Date) {
    state.filtros.dataInicial = dataInicial;
  },
  definirDataFinal(state: IStatePainelSolicitacoesDeHorasExtras, dataFinal: Date) {
    state.filtros.dataFinal = dataFinal;
  },
  definirPlacar(state: IStatePainelSolicitacoesDeHorasExtras, placar: IStatePainelSolicitacoesDeHorasExtras['placar']) {
    state.placar = placar;
  },
  definirGraficoEmpregadosPorLocalDeTrabalho(state: IStatePainelSolicitacoesDeHorasExtras, dados: { idLocalDeTrabalho: ID, placar: Array<DadosGraficoEmpregados> }) {
    dados.placar.forEach((item: DadosGraficoEmpregados) => {
      state.detalhesPorEmpregado[item.id] = item;
    })
  },
  definirTodosOsFiltros(state: IStatePainelSolicitacoesDeHorasExtras, filtros: IStatePainelSolicitacoesDeHorasExtras['filtros']) {
    state.filtros = filtros;
  },
  limparDadosDaApi(state: IStatePainelSolicitacoesDeHorasExtras) {
    state.placar = stateResetada().placar;
    state.detalhesPorEmpregado = stateResetada().detalhesPorEmpregado;
  },
  destruirState(state: IStatePainelSolicitacoesDeHorasExtras) {
    Object.assign(state, stateResetada());
  }
};

const actions = {
};

export default {
  namespaced: true,
  state: stateResetada(),
  mutations,
  actions,
};
