import { formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { modalDeAlerta, modalDeSucesso, modalDeErro } from 'src/utils'
import router from 'src/router'
import i18n from 'src/typescript/servicos/i18n/i18n.servico.ts'

import {
  vuexMapDefaultsMutations
} from 'src/utils.store'

import moment from 'moment'
import {API} from 'src/services'

const clearInput = (item) => item ? item.replace('.', '').replace('-', '').replace('/', '') : item

function fromBackend (employee) {
  // returns a object that we can use in Object.assign to update all state once

  function customFieldsFormater ({key, value}) {
    return {
      field: key,
      text: value
    }
  }

  function regraDeBancoDeHoras (regraDeCalculo) {
    if (!regraDeCalculo) {
      return {id: 'none', name: i18n.t('empregado.details.State.semRegraBh')}
    }
    return regraDeCalculo
  }

  let empregador = employee.employer

  if (empregador instanceof Array) {
    empregador = empregador[0]
  }

  return {
    name: employee.name,
    ehProfissionalAloque: employee.ehProfissionalAloque,
    code: employee.code,
    phoneclockPassword: employee.phoneclockPassword,
    closingDate: employee.closingDate,
    company: employee.company[0],
    employer: empregador,
    customRules: employee.customRules,
    whbRules: regraDeBancoDeHoras(employee.workHourBankRules),
    dataDeInicioDoBancoDeHoras: employee.dataDeInicioDoBancoDeHoras ? moment(employee.dataDeInicioDoBancoDeHoras).toDate() : '',
    admission: moment(employee.admission).toDate(),
    email: employee.email,
    cellPhone: clearInput(employee.cellPhone),
    phone: clearInput(employee.phone),
    ctps: clearInput(employee.ctps),
    pis: clearInput(employee.pis),
    esocial: clearInput(employee.esocial),
    routineInfo: {
      phaseShift: employee.routinePhaseShift,
      initialDay: moment(employee.initialDay).toDate(),
      routine: employee.routine
    },
    informacaoDeEscalaOriginal: {
      phaseShift: employee.routinePhaseShift,
      initialDay: moment(employee.initialDay).toDate(),
      routine: employee.routine
    },
    rg: clearInput(employee.rg),
    cpf: clearInput(employee.cpf),
    department: employee.department,
    funct: employee.funct,
    hasVr: employee.hasVr,
    hasVt: employee.hasVt,
    customFields: employee.customFields.map(customFieldsFormater),
    customFieldsFolha: employee.customFieldsFolha.map(customFieldsFormater),
    allowAppVoice: employee.allowAppVoice,
    allowAppPhoto: employee.allowAppPhoto,
    allowAppShowDayJourney: employee.allowAppShowDayJourney,
    allowAppFeedback: employee.allowAppFeedback,
    canSelectCompany: employee.canSelectCompany,
    gravarPontoComFotoWeb: employee.gravarPontoComFotoWeb,
    allowWebVoice: employee.allowWebVoice,
    forcarModalDeRequisicaoDeVoz: employee.forcarModalDeRequisicaoDeVoz,
    doubleCheckWeb: employee.doubleCheckWeb,
    allowPunchSerial: employee.allowPunchSerial,
    hasOnCall: employee.hasOnCall,
    phoneclockAll: employee.phoneclockAll,
    autoPauseFill: employee.autoPauseFill,
    omitirBotoesDePausaNoRegistro: employee.omitirBotoesDePausaNoRegistro,
    avisarNaoCumprimentoInterjornada: employee.avisarNaoCumprimentoInterjornada,
    sincronizarRepp: employee.sincronizarComRepp,
    usuarioLiderDireto: employee.usuarioLiderDireto,
    urlFotoPessoal: employee.urlFotoPessoal,
    origemDaUltimaAlteracao: employee.origemDaUltimaAlteracao,
    lastModified: employee.lastModified,
    desativarConfiguracaoDeFimDeSetorAbonado: employee.desativarConfiguracaoDeFimDeSetorAbonado
  }
}

function toBackEnd (state) {
  function customFieldsFormater ({field, text}) {
    return {
      key: field,
      value: text
    }
  }

  function workRules (selected, regraDeCalculo) {
    if (selected?.id && selected.id !== 'none' && !regraDeCalculo.calculoPassivo) {
      return selected.id
    }
    return {id: 'none', name: i18n.t('empregado.details.State.semRegraBh')}
  }

  function validarEscala (state) {
    if (state.routineInfo.routine) {
      return {id: state.routineInfo.routine.id}
    }
    const erro = new Error()
    erro.data = {error: i18n.t('empregado.details.State.informacaoEscalaObrigatoria')}
    throw erro
  }

  function dataDeInicioDeBancoDeHorasInserida (dataDeInicioDoBancoDeHoras) {
    return dataDeInicioDoBancoDeHoras ? formatarDataParaEnvio(dataDeInicioDoBancoDeHoras) : null
  }

  function formatarUsuarioLiderDiretoParaEnvio () {
    const usuarioLiderDireto = state.usuarioLiderDireto.selected
    if (usuarioLiderDireto) {
      return { id: usuarioLiderDireto.id, name: usuarioLiderDireto.name }
    }
    return null
  }

  return {
    name: state.name,
    ehProfissionalAloque: state.ehProfissionalAloque,
    code: String(state.code),
    phoneclockPassword: String(state.phoneclockPassword),
    closingDate: state.closingDate,
    company: [{id: state.company.selected.id}],
    employer: [{id: state.employer.selected.id}],
    customRules: {
      id: state.customRules.selected.id
    },
    workHourBankRules: workRules(state.whbRules.selected, state.customRules.selected),
    dataDeInicioDoBancoDeHoras: dataDeInicioDeBancoDeHorasInserida(state.dataDeInicioDoBancoDeHoras),
    admission: formatarDataParaEnvio(state.admission),
    email: state.email,
    cellPhone: clearInput(state.cellPhone),
    phone: clearInput(state.phone),
    ctps: clearInput(state.ctps),
    pis: clearInput(state.pis),
    esocial: clearInput(state.esocial),
    routinePhaseShift: state.routineInfo.phaseShift,
    initialDay: formatarDataParaEnvio(state.routineInfo.initialDay),
    routine: validarEscala(state),
    rg: clearInput(state.rg),
    cpf: clearInput(state.cpf),
    department: state.department,
    funct: state.funct,
    hasVr: state.hasVr,
    hasVt: state.hasVt,
    customFields: state.customFields.map(customFieldsFormater),
    customFieldsFolha: state.customFieldsFolha.map(customFieldsFormater),
    allowAppVoice: state.allowAppVoice,
    allowAppPhoto: state.allowAppPhoto,
    allowAppShowDayJourney: state.allowAppShowDayJourney,
    allowAppFeedback: state.allowAppFeedback,
    canSelectCompany: state.canSelectCompany,
    gravarPontoComFotoWeb: state.gravarPontoComFotoWeb,
    allowWebVoice: state.allowWebVoice,
    forcarModalDeRequisicaoDeVoz: state.forcarModalDeRequisicaoDeVoz,
    doubleCheckWeb: state.doubleCheckWeb,
    allowPunchSerial: state.allowPunchSerial,
    hasOnCall: state.hasOnCall,
    phoneclockAll: state.phoneclockAll,
    autoPauseFill: state.autoPauseFill,
    omitirBotoesDePausaNoRegistro: state.omitirBotoesDePausaNoRegistro,
    avisarNaoCumprimentoInterjornada: state.avisarNaoCumprimentoInterjornada,
    sincronizarComRepp: state.sincronizarRepp,
    usuarioLiderDireto: formatarUsuarioLiderDiretoParaEnvio(),
    urlFotoPessoal: state.urlFotoPessoal,
    desativarConfiguracaoDeFimDeSetorAbonado: state.desativarConfiguracaoDeFimDeSetorAbonado
  }
}

function defaultState () {
  return {
    id: null,
    loading: false,
    ehProfissionalAloque: false,
    origemDaUltimaAlteracao: '',
    lastModified: null,
    name: '',
    code: '',
    phoneclockPassword: '',
    closingDate: 1,
    company: {
      selected: null,
      loaded: []
    },
    employer: {
      selected: null,
      loaded: []
    },
    customRules: {
      selected: null,
      loaded: []
    },
    whbRules: {
      selected: null,
      loaded: []
    },
    usuarioLiderDireto: {
      selected: null,
      loaded: []
    },
    dataDeInicioDoBancoDeHoras: null,
    admission: null,
    email: '',
    cellPhone: '',
    phone: '',
    ctps: '',
    pis: '',
    esocial: '',
    routineInfo: {
      phaseShift: 0,
      initialDay: null,
      routine: null
    },
    informacaoDeEscalaOriginal: null,
    rg: '',
    cpf: '',
    department: '',
    funct: '',
    hasVr: false,
    hasVt: false,
    customFields: [],
    customFieldsFolha: [],
    allowAppVoice: false,
    allowAppPhoto: false,
    allowAppShowDayJourney: false,
    allowAppFeedback: false,
    canSelectCompany: false,
    gravarPontoComFotoWeb: false,
    allowWebVoice: false,
    forcarModalDeRequisicaoDeVoz: false,
    doubleCheckWeb: false,
    allowPunchSerial: false,
    hasOnCall: false,
    phoneclockAll: false,
    autoPauseFill: false,
    omitirBotoesDePausaNoRegistro: false,
    desativarConfiguracaoDeFimDeSetorAbonado: false,
    avisarNaoCumprimentoInterjornada: false,
    sincronizarRepp: false,
    carregandoLocaisDeTrabalho: false,
    urlFotoPessoal: null,
    jurisdicao: {
      selected: null,
      loaded: []
    },
    numeroIdentificador: ''
  }
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  setFromState (state, {company, employer, customRules, whbRules, usuarioLiderDireto, ...employee}) {
    state.employer.selected = employer
    state.customRules.selected = customRules
    state.whbRules.selected = whbRules
    state.company.selected = company
    state.usuarioLiderDireto.selected = usuarioLiderDireto

    delete employee.employer
    delete employee.customRules
    delete employee.whbRules
    delete employee.company
    Object.assign(state, employee)
  },
  changeChecksTimelogs (state, {field, value}) {
    state[field] = Boolean(value)
  },
  loading (state, payload) {
    state.loading = payload
  },
  resetEscala (state) {
    state.routineInfo = state.informacaoDeEscalaOriginal
  },
  atribuiLocaisDeTrabalho (state, payload) {
    state.company.loaded = payload.filter(local => local.active && local.syncedWithRepp && local.visivel)
  },
  salvarSincronizarComRepp (state, payload) {
    state.sincronizarRepp = payload
  },
  carregandoLocaisDeTrabalho (state, payload) {
    state.carregandoLocaisDeTrabalho = payload
  },
  resetCompany (state) {
    state.company.selected = null
    state.company.loaded = []
  },
  sobreAvisoSempreAtivo (state, valor) {
    state.hasOnCall = valor
  },
  setJurisdicao (state, paises) {
    state.jurisdicao = paises
  },
  resetEmployer (state) {
    state.employer.selected = null
    state.employer.loaded = []
  }
}

const actions = {
  async loadEmployee ({ commit, rootState, state, dispatch }, employeeId) {
    commit('loading', true)

    const api = await API.employee.get({id: employeeId, active: false, fired: 'all'})

    const info = api.data.success[0]

    if (rootState.userInfo.compMan.portaria671 && info.sincronizarComRepp) {
      commit('carregandoLocaisDeTrabalho', true)

      let empregadorRequisicao = info.employer

      if (info.employer instanceof Array) {
        empregadorRequisicao = empregadorRequisicao[0].id
      } else {
        empregadorRequisicao = empregadorRequisicao.id
      }

      API.company.get({
        empregador: empregadorRequisicao,
        fields: JSON.stringify(['id', 'code', 'name', 'active', 'syncedWithRepp', 'visivel'])
      }).then(completeInfo => {
        if (completeInfo.status === 200) {
          commit('atribuiLocaisDeTrabalho', completeInfo.data.success)
        } else {
          state.company = { selected: null, loaded: [] }
        }
        commit('carregandoLocaisDeTrabalho', false)
      })
    }

    if (info.cellPhone && info.cellPhone.startsWith('55')) {
      info.cellPhone = info.cellPhone.substring(2)
    }
    if (info.phone && info.phone.startsWith('55')) {
      info.phone = info.phone.substring(2)
    }

    const possuiModuloInternacionalizacao = rootState.userInfo.compMan.hasInternacionalizacao
    if (possuiModuloInternacionalizacao) {
      await dispatch('carregarPaisesParaRegistro')

      commit('setJurisdicao', { selected: state.jurisdicao.loaded.find(pais => pais.id === info.jurisdicao), loaded: state.jurisdicao.loaded })
      if (info.numeroIdentificador) {
        commit('numeroIdentificador', info.numeroIdentificador)
      }
    }

    commit('setFromState', {id: employeeId, ...fromBackend(info)})

    commit('loading', false)
  },
  async save ({ state, rootState, dispatch }) {
    const info = toBackEnd(state)

    const possuiAcessoPorLideranca = rootState.userInfo.compMan.hasAcessoPorLideranca

    if (!possuiAcessoPorLideranca) {
      // Condição sera removido na v2, quando todas as migraçoes do back estiver em produçao.
      delete info.usuarioLiderDireto
    }

    const possuiModuloInternacionalizacao = rootState.userInfo.compMan.hasInternacionalizacao

    if (possuiModuloInternacionalizacao) {
      if (state.jurisdicao.selected.id === 'BR') {
        info.jurisdicao = 'BR'
      } else {
        info.jurisdicao = state.jurisdicao.selected.id
        info.numeroIdentificador = state.numeroIdentificador
      }
    }

    if (info.cellPhone) info.cellPhone = '55'.concat(info.cellPhone.replace(/[^\d]+/g, ''))
    if (info.phone) info.phone = '55'.concat(info.phone.replace(/[^\d]+/g, ''))

    if (state.id) {
      const resultado = await API.employee.save({id: state.id}, info)
      await dispatch('lidarComResponseParaDarMensagemDeFeedback', resultado)
    } else {
      const resultado = await API.employee.save(info)
      await dispatch('lidarComResponseParaDarMensagemDeFeedback', resultado)
    }
  },
  async lidarComResponseParaDarMensagemDeFeedback ({ state }, response) {
    const ehEdicao = state.id
    const mensagemDeAlerta = response.data._alerta
    const texto = response.data.obs || ' '
    const titulo = response.data.success

    if (mensagemDeAlerta) {
      await modalDeAlerta(mensagemDeAlerta, 'Atenção')
      return
    }

    if (ehEdicao) {
      const tituloFormatado = titulo || i18n.t('empregado.details.State.sucessoEditarEmpregado')
      await modalDeSucesso(texto, tituloFormatado)
    } else {
      const tituloFormatado = titulo || i18n.t('empregado.details.State.sucessoCriarEmpregado')
      await modalDeSucesso(texto, tituloFormatado)
    }
  },
  async carregarPaisesParaRegistro ({ commit }) {
    try {
      commit('loading', true)
      const paises = await API.listarDocumentosCadastroEmpregado.get()
      const paisesComNome = {
        'BR': 'Brasil',
        'AR': 'Argentina',
        'XX': 'Internacional'
      }
      const paisesFormatados = Object.keys(paises.data).map(pais => {
        if (pais === 'BR') return {id: pais, name: paisesComNome[pais], label: 'CPF'}
        return {id: pais, name: paisesComNome[pais], label: paises.data[pais].numero_identificador.nome}
      })

      commit('setJurisdicao', { selected: null, loaded: paisesFormatados })
    } catch {
      modalDeErro(i18n.t('empregado.details.State.erroCarregarPaises'), '', () => {
        router.push({name: 'employee.list'})
      })
    } finally {
      commit('loading', false)
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions
}
