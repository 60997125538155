import headerTimesheet from './Header/state'
import { API, retryRequest } from 'src/services'
import moment from 'moment'
import { modalDeErro } from 'src/utils'
import { FiltradorDeErros } from 'src/erros'
import { copiarObjetoProfundamente, congelarObjetoProfundamente, formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'
import _ from 'lodash'
import i18n from 'src/typescript/servicos/i18n/i18n.servico.ts'

const defaultState = () => ({
  // here we will store all requests pending to url timesheet-partial, it's
  sumTimes: [],
  abaDeAcumuladosAberta: false,
  apontamentosMesAberto: false,
  sumWhb: { monthly: null, overal: null },
  timesheetHash: null,
  locking: false,
  loadingSumTimes: false,
  carregandoAcumuladoBancoDeHoras: false,
  loadingTimesheetHash: false,
  dataUsedToPopulate: null // a immutable object with data sent to back to populate lines
})

const state = defaultState()

const getters = {
  sheet (state, getters, rootState) {
    return rootState.sheet
  },
  loading (state, getters) {
    return getters.sheet.loading
  },
  loadingAny (state, getters) {
    return getters.sheet.loading.lines || getters.sheet.loading.pts || getters.sheet.loading.djs
  },
  buildingSheet (state, getters) {
    return (getters.sheet.populating || getters.sheet.loading.lines) === true
  },
  showWHB (state, getters, rootState) {
    if (!rootState.userInfo || !rootState.userInfo.compMan || state.header.type !== 'employee') {
      return false
    }

    const compManHasWhb = rootState.userInfo.compMan.hasWhbank

    return compManHasWhb
  },
  empregadoTemRegraDeCalculoPassivaAtivada (state) {
    const empregado = state.header.queueEmployees[state.header.queueCurrentIndex]
    return empregado?.customRules?.calculoPassivo
  },
  possuiAlgumaLinhaComInformacoesDeBH (state, getters) {
    return getters.sheet.lines.find(linha => {
      const possuiBancoDeHorasAtivo =
        linha.workHoursBank && (linha.workHoursBank.workHourBank.active || linha.workHoursBank.rule)
      return Boolean(possuiBancoDeHorasAtivo)
    })
  }
}

const mutations = {
  sumTimes (state, payload) { state.sumTimes = payload },
  loadingSumTimes (state, payload) { state.loadingSumTimes = payload },
  carregandoAcumuladoBancoDeHoras (state, payload) { state.carregandoAcumuladoBancoDeHoras = payload },
  loadingTimesheetHash (state, payload) { state.loadingTimesheetHash = payload },
  locking (state, payload) {
    state.locking = payload
  },
  sumWhb (state, payload) {
    state.sumWhb = payload
  },
  dataUsedToPopulate (state, payload) {
    state.dataUsedToPopulate = congelarObjetoProfundamente(copiarObjetoProfundamente(payload))
  },
  timesheetHash (state, payload) {
    state.timesheetHash = payload
  },
  abaDeAcumuladosAberta (state, payload) {
    state.abaDeAcumuladosAberta = payload
  },
  apontamentosMesAberto (state, payload) {
    state.apontamentosMesAberto = payload
  },
  resetarApontamentosMes(state) {
    state.sumTimes = [];
  }
}

const actions = {
  async loadVerifySheet ({ dispatch, state }) {
    let toLoad

    if (state.header.type === 'work-place') {
      toLoad = {
        companies: state.header.companies.selected.length ? state.header.companies.selected.map(i => i.id) : state.dataUsedToPopulate.companies
      }
    } else if (state.header.type === 'user-group') {
      toLoad = {
        userGroups: state.header.userGroups.selected.length === 0 ? state.dataUsedToPopulate.userGroups : state.header.userGroups.selected.map(i => i.id)
      }
    } else if (state.header.type === 'employee') {
      toLoad = {
        employees: state.header.employees.selected.length === 0 ? state.dataUsedToPopulate.employees : state.header.employees.selected.map(i => i.id)
      }
    }

    const args = {
      startDate: moment(state.header.startDate).toISOString(),
      endDate: moment(state.header.endDate).toISOString(),
      verifyKind: state.header.verificationKind,
      filters: state.header.verificationFilters,
      ...toLoad
    }
    dispatch('populateLines', args)
  },
  async loadSheet ({ state, dispatch, commit }) {
    const startDate = state.header.startDate
    const endDate = state.header.endDate

    const args = {
      startDate: formatarDataParaEnvio(moment(startDate)),
      endDate: formatarDataParaEnvio(moment(endDate))
    }

    const headerCompanies = state.header.companies.selected.map(i => i.id)
    let companiesToUse = headerCompanies.length === 0 ? state.dataUsedToPopulate?.companies : headerCompanies
    companiesToUse = companiesToUse || []

    commit('sheet/lineActions/close', true, {root: true})

    if (state.header.type === 'work-place' && state.header.typeLoadCompany === 'peremp') {
      // load employees and add to queue
      const result = await API.employee.get({
        fields: '["id", "name", "closing_date", "active", "code", "company", "funct", "custom_rules"]',
        fired: 'not-fired',
        'order_by': state.header.typeEmployeeOrder,
        companies: JSON.stringify(companiesToUse)
      })
      if (result.data.success) {
        commit('header/type', 'employee')
        commit('header/queueEmployees', result.data.success)
        state.header.queueCurrentIndex = -1
        dispatch('header/nextEmployee')
        return
      } else {
        args.companies = companiesToUse
      }
    } else if (state.header.type === 'work-place') {
      args.companies = companiesToUse
    } else if (state.header.type === 'user-group') {
      args.userGroups = state.header.userGroups.selected.length === 0 ? state.dataUsedToPopulate.userGroups : state.header.userGroups.selected.map(i => i.id)
    } else {
      args.employees = [state.header.queueEmployees[state.header.queueCurrentIndex].id]
    }

    dispatch('populateLines', args)
  },
  async populateLines ({ commit, dispatch, state }, args) {
    commit('sumTimes', [])
    commit('sumWhb', defaultState().sumWhb)
    commit('sheet/cancelarTodasAsRequisicoesPendentes', undefined, { root: true })
    commit('sheet/loading', {lines: true}, {root: true})

    commit('dataUsedToPopulate', args)

    commit('loadingSumTimes', true)
    await dispatch('sheet/loadSheet', args, { root: true })

    // The order matter here, DONT change if you dont have sure
    commit('header/_updateUrlAndHeaderSelectors', {
      userGroup: state.dataUsedToPopulate.userGroups?.length ? state.dataUsedToPopulate.userGroups[0] : null,
      company: state.dataUsedToPopulate.companies?.length ? state.dataUsedToPopulate.companies[0] : null
    })
    commit('header/employees', { selected: [] })
    commit('header/companies', { selected: [] })
    commit('header/userGroups', { selected: [] })

    if (state.header.type === 'employee') {
      // Verifica se o accordion está aberto para realizar uma nova requisição ao alterar o mês ou empregado
      // O accordion de acumulados BH depende dos apontamentos, quando aberto, deve disparar requisição
      if (state.apontamentosMesAberto || state.abaDeAcumuladosAberta) {
        dispatch('getSumTimes');
      }

      if (state.abaDeAcumuladosAberta) {
        dispatch("pegarSomatorioBancoDeHoras");
      }
    }
  },

  getSumTimes: _.debounce(async function getSumTimes ({ commit, state, rootState, getters }) {
    try {
      // lets block the sumtimes request if there's a show-timelogs (calculate TM) request unfinished
      if (
        getters.sheet.processing.tms.length ||
        state.header.type === 'work-place' ||
        getters.sheet.loading.tms
      ) return;
  
      let date;
      if (state.header.type === 'employee') {
        date = moment(new Date(state.header.closedPeriod.year, state.header.closedPeriod.month - 1, 1));
      } else {
        date = moment(state.header.endDate);
      }
  
      let result;
  
      if (!getters.empregadoTemRegraDeCalculoPassivaAtivada) {
        const sumsAPI = rootState.userInfo.compMan.hasArbTms ? API.arbssumtime : API.sumtime;
  
        result = await retryRequest(sumsAPI.get, {
          id: state.header.queueEmployees[state.header.queueCurrentIndex].id,
          month: date.get('month') + 1,
          year: date.get('year'),
          oldFormat: true
        });
      } else {
        result = await retryRequest(API.apontamentosMensais.get, {
          idDoEmpregado: state.header.queueEmployees[state.header.queueCurrentIndex].id,
          'mes_referencia': date.get('month') + 1,
          'ano_referencia': date.get('year')
        });
      }
  
      commit('sumTimes', result.body.success);
    } catch (error) {
      FiltradorDeErros.capturarErro(error);
      commit('resetarApontamentosMes');
    } finally {
      commit('loadingSumTimes', false);
    }
  }, 4000),

  async pegarSomatorioBancoDeHoras ({ state, commit, getters }) {
    try {
      commit('carregandoAcumuladoBancoDeHoras', true)
      if (
        state.header.type === 'work-place' ||
        getters.sheet.loading.tms
      ) return

      const startDate = moment(state.header.startDate).format('YYYY-MM-DD')
      const endDate = moment(state.header.endDate).format('YYYY-MM-DD')

      const params = {
        kind: 'overall',
        employeeId: state.header.queueEmployees[state.header.queueCurrentIndex].id,
        startDate,
        endDate
      }

      const overal = await retryRequest(API.whboManager.get, { ...params, endDate: undefined })
      const monthly = await retryRequest(API.whboManager.get, { ...params, kind: 'monthly' })

      commit('sumWhb', { overal: overal.data.success, monthly: monthly.data.success })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      modalDeErro(i18n.t('sheets.Timesheet.LockerHistory.state.erro'))
      commit('sumWhb', { overal: null, monthly: null })
    } finally {
      commit('carregandoAcumuladoBancoDeHoras', false)
    }
  },

  async getTimesheetHash ({ state, commit }) {
    if (state.header.type !== 'employee' || !state.header.closedPeriod) {
      return
    }

    commit('loadingTimesheetHash', true)

    const params = {
      employee: state.header.closedPeriod.employee,
      year: state.header.closedPeriod.year,
      month: state.header.closedPeriod.month
    }

    const result = await API.timesheetHash.get(params)
    commit('timesheetHash', result.data.success)
    commit('loadingTimesheetHash', false)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: { header: headerTimesheet }
}
