var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "p-textfield",
      class: { "icon-slot": _vm.$slots.icon && _vm.$slots.icon.length },
    },
    [
      _vm.type === "checkbox"
        ? _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.state,
                      expression: "state",
                    },
                  ],
                  ref: "input",
                  staticClass: "p-textfield__input",
                  class: {
                    error: _vm.error,
                    naoMostrarPlaceholder:
                      !_vm.mostrarPlaceholderQuandoForaDeFoco,
                    sinalizarErroSemMensagem: _vm.sinalizarErroSemMensagem,
                    [_vm.estilo]: _vm.estilo,
                  },
                  style: _vm.hideLabel ? { paddingTop: "0" } : {},
                  attrs: {
                    disabled: _vm.disabled || _vm.loading,
                    autocomplete: _vm.autocomplete,
                    spellcheck: "false",
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.state)
                      ? _vm._i(_vm.state, null) > -1
                      : _vm.state,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.state,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.state = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.state = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.state = $$c
                      }
                    },
                  },
                },
                "input",
                _vm.atributosLocais,
                false
              ),
              _vm.escutadoresDeEventosLocais
            )
          )
        : _vm.type === "radio"
        ? _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.state,
                      expression: "state",
                    },
                  ],
                  ref: "input",
                  staticClass: "p-textfield__input",
                  class: {
                    error: _vm.error,
                    naoMostrarPlaceholder:
                      !_vm.mostrarPlaceholderQuandoForaDeFoco,
                    sinalizarErroSemMensagem: _vm.sinalizarErroSemMensagem,
                    [_vm.estilo]: _vm.estilo,
                  },
                  style: _vm.hideLabel ? { paddingTop: "0" } : {},
                  attrs: {
                    disabled: _vm.disabled || _vm.loading,
                    autocomplete: _vm.autocomplete,
                    spellcheck: "false",
                    type: "radio",
                  },
                  domProps: { checked: _vm._q(_vm.state, null) },
                  on: {
                    change: function ($event) {
                      _vm.state = null
                    },
                  },
                },
                "input",
                _vm.atributosLocais,
                false
              ),
              _vm.escutadoresDeEventosLocais
            )
          )
        : _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.state,
                      expression: "state",
                    },
                  ],
                  ref: "input",
                  staticClass: "p-textfield__input",
                  class: {
                    error: _vm.error,
                    naoMostrarPlaceholder:
                      !_vm.mostrarPlaceholderQuandoForaDeFoco,
                    sinalizarErroSemMensagem: _vm.sinalizarErroSemMensagem,
                    [_vm.estilo]: _vm.estilo,
                  },
                  style: _vm.hideLabel ? { paddingTop: "0" } : {},
                  attrs: {
                    disabled: _vm.disabled || _vm.loading,
                    autocomplete: _vm.autocomplete,
                    spellcheck: "false",
                    type: _vm.type,
                  },
                  domProps: { value: _vm.state },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.state = $event.target.value
                    },
                  },
                },
                "input",
                _vm.atributosLocais,
                false
              ),
              _vm.escutadoresDeEventosLocais
            )
          ),
      _c(
        "label",
        {
          staticClass: "p-textfield__title",
          class: { error: _vm.error },
          attrs: { for: "textfield" },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideLabel,
                  expression: "!hideLabel",
                },
              ],
              staticClass: "p-textfield__title-text",
              class: { required: _vm.required, [_vm.estilo]: _vm.estilo },
            },
            [
              _vm._v(_vm._s(_vm.label)),
              _vm.mensagemDeInformacao ? _vm._t("logoIcon") : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.$slots.icon && _vm.$slots.icon.length
        ? _c(
            "span",
            { staticClass: "p-textfield__icon--span" },
            [_vm._t("icon")],
            2
          )
        : _vm._e(),
      _vm.copyicon
        ? _c(
            "span",
            {
              staticClass: "p-textfield__icon--span-copy",
              on: { click: _vm.copyToClipBoard },
            },
            [
              _c(
                "tooltip",
                {
                  attrs: {
                    text: _vm.$t("compartilhados.inputs.PTextField.copiado"),
                    trigger: "manual",
                  },
                  model: {
                    value: _vm.showTooltip,
                    callback: function ($$v) {
                      _vm.showTooltip = $$v
                    },
                    expression: "showTooltip",
                  },
                },
                [
                  _c("img", {
                    staticClass: "p-textfield__icon",
                    attrs: {
                      src: require("../../../static/images/copy_content.svg"),
                    },
                  }),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.error || !_vm.mensagemDeInformacao
        ? _c("ErrorMsg", { attrs: { error: _vm.error } })
        : _vm._e(),
      _vm.mensagemDeInformacao
        ? _c("InfoMsg", { attrs: { info: _vm.mensagemDeInformacao } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }